<template>
  <div class="timeline-event">
    <div class="timeline">
      <div class="icon">
        <slot name="icon"></slot>
      </div>
      <div class="line"></div>
    </div>
    <div class="event">
      <small class="name">{{ formattedDate }}</small>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return moment(this.date).format("DD-MM-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/assets/css/variables";

.timeline-event {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1rem;

  .timeline {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20px;

    .icon {
      width: 20px;
      height: 24px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .line {
      flex: 1 1 0;
      border-left: 1px;
      border-right: 1px;
      border-style: solid;
      border-color: $brand-pearl-silver;
    }
  }

  .event {
    flex: 1 1 0;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    .name {
      margin-top: 2px;
      margin-bottom: 0.25rem;
      color: $brand-navy;
    }
  }

  &:last-of-type {
    .event {
      padding-bottom: 0;
    }
  }
}

p {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
