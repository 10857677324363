<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-striped _dataTable hull-inspections-js-data-table"
        >
          <thead>
            <tr>
              <th>Activity</th>
              <th>Requirement</th>
              <th>Last Inspection</th>
              <th>Next Inspection</th>
              <th>Comments</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hullInspection, i) in hullInspections" :key="i">
              <td>{{ hullInspection.activity }}</td>
              <td>{{ hullInspection.requirement }}</td>
              <td>
                {{
                  hullInspection.date
                    ? moment(hullInspection.date).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  hullInspection.nextDue
                    ? moment(hullInspection.nextDue).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>{{ hullInspection.comments }}</td>
              <td>
                <button
                  class="btn btn-sm btn-primary me-2"
                  type="button"
                  @click="openForEdit(i)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  title="Remove Surveys"
                  type="button"
                  @click="deleteHullInspection(i)"
                >
                  <span class="sr-only">Remove Hull Inspection</span>
                  <i class="fa fa-minus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HullInspectionEditorModal from "./HullInspectionEditorModal.vue";
import moment from "moment";

export default {
  name: "HullInspectionsTable",
  props: {
    hullInspections: {
      type: Array,
      default: () => [],
    },
    hullInspectionDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    /* Table row operations */
    /**
     * Public method used from parent page to open a particular row for editing (used on creating a new Hull Inspection)
     */
    openForEdit(index) {
      const field = this.hullInspections[index];
      const modalOptions = {
        name: "hull-inspection-editor-modal",
        width: "80%",
      };
      if (field.id) {
        modalOptions.scrollable = true;
        modalOptions.minHeight = 420;
        modalOptions.height = "auto";
      } else {
        modalOptions.height = "auto";
      }

      const docs = this.getHullInspectionDocumentsForHullInspection(field);

      this.$modal.show(
        HullInspectionEditorModal,
        {
          hullInspection: field,
          hullInspectionDocuments: docs,
        },
        modalOptions,
        {
          "before-close": async (e) => {
            const newValue = e.params?.value;
            if (!newValue) return;
            this.hullInspections[index] = e.params.value;
            // The next 2 lines force an update for DataTables to re-draw the table
            this.hullInspections.push({});
            this.hullInspections.pop();
            // TODO : Try to fix the above with the 'invalidate' methods from DataTables
          },
        }
      );
    },
    deleteHullInspection(index) {
      if (confirm("Are you sure you want to delete this HullInspection?"))
        this.$emit("removeHullInspectionByIndex", index);
    },
    /* END of Table row operations */

    getHullInspectionDocumentsForHullInspection(hullInspection) {
      return this.hullInspectionDocuments.filter(
        (doc) =>
          doc.indexOf("/hull-inspections/" + hullInspection.id + "/") > -1
      );
    },

    isInvalid() {
      // TODO : This is redundant until we find a way how to validate each Hull Inspection
      // Before we had the editor for each Hull Inspection to check, now there isn't anymore
      return false;
    },
  },
  // watch: {
  //   hullInspections() {
  //     if (this.table) this.table.destroy()
  //     setTimeout(() => {
  //       this.table = window.jsDataTable(".hull-inspections-js-data-table");
  //     }, 250); // Just giving Vue some time to render the table
  //   },
  // },
};
</script>

<style>
.table tr td .vdp-datepicker {
  white-space: normal;
}

.table-responsive {
  overflow-x: visible;
}
</style>
