<template>
  <div class="card-body">
    <div class="row">
      <div class="col-12 d-flex flex-column align-items-center mb-3">
        <p class="my-2 text-center">Are you sure?</p>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-primary me-3"
          type="button"
          @click="() => close(false)"
        >
          Cancel
        </button>
        <button class="btn btn-danger" type="button" @click="() => close(true)">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  methods: {
    close(state = null) {
      this.$modal.hide("confirm-modal", state);
    },
  },
};
</script>
