<template>
  <div class="col-12">
    <div class="row">
      <div
        v-for="field in fields"
        :key="`machinery-${index}-${field.name}`"
        class="col-lg-3 col-md-6"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
        />
        <form-input-select
          v-else-if="
            field.type === 'select' &&
            field.name === 'make' &&
            !isLoadingVesselMachineryMakeAndModelsOptions
          "
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
          @input="makeSelected"
        />
        <form-input-select
          v-else-if="
            field.type === 'select' &&
            field.name === 'model' &&
            !isLoadingVesselMachineryMakeAndModelsOptions
          "
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
          :parent="machinery.$model"
        />
        <form-input-select
          v-else-if="
            field.type === 'select' &&
            field.name !== 'make' &&
            field.name !== 'model'
          "
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
          :parent="machinery.$model"
        />
        <form-input-check
          v-else-if="field.type === 'check'"
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
        />
        <form-input-radio
          v-else-if="field.type === 'radio'"
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
          :groupIndex="groupIndex"
        />
        <form-input-text
          v-else-if="field.name === 'original_power' && showOriginalPower"
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
        />
        <form-input-text
          v-else-if="field.name !== 'original_power'"
          v-model="$v.machinery_[field.name].$model"
          :error="$v.machinery_[field.name].$error"
          :error-message="validationMessage($v.machinery_[field.name])"
          :field="field"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputCheck from "../ui/FormInputCheck.vue";
import FormInputDate from "../ui/FormInputText.vue";
import FormInputRadio from "../ui/FormInputRadio.vue";
import FormInputSelect from "../ui/FormInputSelect.vue";
import FormInputText from "../ui/FormInputText.vue";
import { VesselMachineryFields } from "@/constants/vessel_fields";
import { requiredIf } from "vuelidate/lib/validators";
import { notifyError } from "@/helpers/notification";
import { getVesselMachineryMakeModels } from "@/apis/vessels";
import { getMachineryMakers } from "@/apis/machineryMakers";
import { validationMessage } from "@/helpers/validations";
import CreateMachineryMakerModal from "./CreateMachineryMakerModal.vue";
import _ from "lodash";

export default {
  name: "Machinery",
  components: {
    FormInputDate,
    FormInputText,
    FormInputRadio,
    FormInputCheck,
    FormInputSelect,
  },
  props: {
    machinery: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      machinery_: null,
      isLoadingVesselMachineryMakeAndModelsOptions: false,
      groupIndex: Math.random(),
      vesselMakeOptions: [],
      fields: _.cloneDeep(VesselMachineryFields),
      rawMakeList: [],
    };
  },
  validations: {
    machinery_: {
      model: {},
      serial_number: {},
      type: {},
      make: {},
      year: {},
      number_of_cylinders: {},
      cycle: {},
      fuel: {},
      power: {
        positiveNumber: (value) => {
          if (!value)
            // allow empty (field is not required)
            return true;

          return Number(value) > 0;
        },
      },
      propulsion: {},
      number_of_propellors: {},
      type_of_propellors: {},
      starting_arrangement: {},
      gearbox: {},
      engine_hours: {
        positiveNumber: (value) => {
          if (!value)
            // allow empty (field is not required)
            return true;

          return Number(value) > 0;
        },
      },
      engine_hours_date: {
        required: requiredIf((machinery) => {
          return Number(machinery.engine_hours) > 0;
        }),
      },
      position: {},
      emission_standard: {},
      is_main_engine: {},
      is_derated: {},
      original_power: {},
    },
  },
  computed: {
    showOriginalPower() {
      //      if (!this.$v.machinery_.is_derated.$model) return false
      return this.machinery_.is_derated;
    },
  },
  methods: {
    validationMessage,
    async getVesselMachineryMakeAndModelsOptions() {
      this.isLoadingVesselMachineryMakeAndModelsOptions = true;

      const machineryMakes = await getMachineryMakers().catch((err) => {
        notifyError(this, err?.response?.data);
      });
      this.rawMakeList = machineryMakes?.data;

      const machineryMakeModels = await getVesselMachineryMakeModels().catch(
        (err) => {
          notifyError(this, err?.response?.data);
        }
      );

      this.vesselMakeOptions = machineryMakes?.data?.map((make) => ({
        text: make.name,
        value: make.name,
        models: this.getModelOptionsByMake(
          machineryMakeModels?.data,
          make.name
        ),
      }));

      this.vesselMakeOptions.unshift({
        text: "Create new",
        value: 0,
      });

      const modelFieldIndex = this.fields.findIndex((f) => f.name === "model");
      const make = this.machinery?.make;
      if (make) {
        const makeObject = this.vesselMakeOptions.find(
          (makeObj) => makeObj.value === make
        );
        this.$set(this.fields[modelFieldIndex], "options", makeObject.models);
      } else {
        const vesselModelOptions = machineryMakeModels?.data?.reduce(
          (acc, makemodel) => {
            if (!makemodel || !makemodel.model) {
              return acc;
            }
            return [
              ...acc,
              {
                text: makemodel.model,
                value: makemodel.model,
              },
            ];
          },
          []
        );

        this.$set(this.fields[modelFieldIndex], "options", vesselModelOptions);
      }

      const makeFieldIndex = this.fields.findIndex((f) => f.name === "make");
      this.$set(this.fields[makeFieldIndex], "options", this.vesselMakeOptions);

      this.isLoadingVesselMachineryMakeAndModelsOptions = false;
    },
    getModelOptionsByMake(makeModelList, make) {
      return makeModelList?.reduce((acc, modelForMake) => {
        if (!modelForMake || !modelForMake.make || !modelForMake.model) {
          return acc;
        }
        if (modelForMake.make === make) {
          acc.push({
            text: modelForMake.model,
            value: modelForMake.model,
          });
        }
        return acc;
      }, []);
    },
    makeSelected(make_value) {
      if (make_value === "0") {
        this.createNewMaker();
        return;
      }
      const modelFieldIndex = this.fields.findIndex((f) => f.name === "model");
      this.machinery_.model = null; // Reset model if Make is changed.

      if (!make_value) {
        this.$set(this.fields[modelFieldIndex], "options", []);
        return;
      }
      const make = this.vesselMakeOptions.find(
        (make) => make.value === make_value
      );
      this.machinery_.maker_id = this.rawMakeList?.find(
        (rawMake) => rawMake.name === make_value
      )?.id;
      this.$set(this.fields[modelFieldIndex], "options", make.models);
    },
    createNewMaker() {
      this.$modal.show(
        CreateMachineryMakerModal,
        {},
        {
          name: "create-machinery-maker-modal",
          height: "auto",
          width: "400px",
        },
        {
          "before-close": async (e) => {
            const createdMakerId = e.params?.maker_id;

            // if vessel builder was created
            if (createdMakerId) {
              // re-retrieve vessel builders
              await this.getVesselMachineryMakeAndModelsOptions();

              // select the created vessel machinery maker
              const makerFieldIndex = this.fields.findIndex(
                (f) => f.name === "maker_id"
              );
              const options = this.machinery[makerFieldIndex].options;

              this.machinery.maker_id = options.find(
                (o) => o.value === createdMakerId
              ).value;
            }
          },
        }
      );
    },
    prepareInternalMachinery(machinery) {
      const emptyMachinery = {
        make: null,
        model: null,
        cycle: null,
        is_derated: null,
        power: null,
        engine_hours: null,
        engine_hours_date: null,
      };
      this.machinery_ = { ...emptyMachinery, ...machinery };
    },
    isInvalid() {
      return this.$v.machinery_.$anyError;
    },
  },
  watch: {
    machinery(newMachinery) {
      this.prepareInternalMachinery(newMachinery);
    },
  },
  created() {
    this.getVesselMachineryMakeAndModelsOptions();
    this.prepareInternalMachinery(this.machinery);
  },
};
</script>
