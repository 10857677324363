import _ from "lodash";
import { getUsers } from "@/apis/users";
import { notifyError } from "@/helpers/notification";

export const defaultStore = {
  state: {
    users: null,
    jobOptions: null,
    vesselTypeOptions: null,
    otherServiceOptions: null,
    vesselBuilderOptions: null,
    vesselHullModelOptions: null,
    vesselMakeOptions: null,
    vesselModelOptions: null,
    dafultFormState: null,
    vesselDocuments: [],
  },
  mutations: {
    storeUsers(state, users) {
      state.users = _.cloneDeep(users);
    },
    storeJobOptions(state, jobOptions) {
      state.jobOptions = jobOptions;
    },
    storeVesselTypeOptions(state, vesselTypeOptions) {
      state.vesselTypeOptions = vesselTypeOptions;
    },
    storeOtherServiceOptions(state, otherServiceOptions) {
      state.otherServiceOptions = otherServiceOptions;
    },
    storeVesselBuilderOptions(state, vesselBuilderOptions) {
      state.vesselBuilderOptions = vesselBuilderOptions;
    },
    storeVesselHullModelOptions(state, vesselHullModelOptions) {
      state.vesselHullModelOptions = vesselHullModelOptions;
    },
    storeVesselMakeOptions(state, vesselMakeOptions) {
      state.vesselMakeOptions = vesselMakeOptions;
    },
    storeVesselModelOptions(state, vesselModelOptions) {
      state.vesselModelOptions = vesselModelOptions;
    },
    storeDefaultFormState(state, dafultFormState) {
      state.dafultFormState = _.cloneDeep(dafultFormState);
    },
    storeVesselDocuments(state, vesselDocuments, vesselId) {
      state.vesselDocuments[vesselId] = vesselDocuments;
    },
  },
  actions: {
    async retrieveUsers(ctx) {
      await getUsers()
        .then(({ data }) => {
          ctx.commit("storeUsers", data);
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
};
