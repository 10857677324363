<template>
  <hull-inspections-tab ref="tabHullInspections" :vessel="vessel" />
</template>

<script>
import HullInspectionsTab from "./HullInspectionsTab.vue";

export default {
  name: "HullAndMachineryTab",
  components: { HullInspectionsTab },
  props: {
    vessel: Object,
  },
  computed: {
    tabHullInspections() {
      return this.$refs.tabHullInspections;
    },
  },
};
</script>
