<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-custom dataTable vessel-builders-js-data-table"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Document Type</th>
              <th>Date Uploaded</th>
              <th>Deleted</th>
              <th>Download</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(document, index) in filteredDocs()"
              :key="index"
              :class="{ deleted: document.deleted }"
            >
              <td class="td-name">
                <a :title="`${document.name}`">{{ document.name }}</a>
              </td>
              <td>
                <a>{{ document.document_type }}</a>
              </td>
              <td>
                <a>{{ getDateString(document.created_on) }}</a>
              </td>
              <td>{{ document.deleted ? "true" : "false" }}</td>
              <td>
                <button
                  class="btn btn-primary me-3"
                  type="button"
                  @click="downloadDocument(document)"
                >
                  Download
                </button>
              </td>
              <td>
                <button
                  class="btn btn-danger"
                  title="Delete document"
                  type="button"
                  @click="deleteFile(document)"
                >
                  Delete Document
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const config = require("@/apis/config.json");
import { deleteDocument } from "@/apis/files";
import { notifyChangesSaved, notifyError } from "@/helpers/notification";

export default {
  name: "VesselDocumentsTable",
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldFilters: [],
      storageEndpoint:
        config.apis.baseUrl.az_storage_endpoint || "http://localhost:8081/",
    };
  },
  methods: {
    deleteFile(document) {
      if (!document) {
        return;
      }
      const confirmMessage = document.deleted
        ? `Are you sure you want to entirely remove this file from the system? ${document.name} Your action is irreversible!`
        : `Are you sure you want to delete this file? ${document.name}`;

      const hardDelete = document.deleted ? "true" : "false";
      if (confirm(confirmMessage)) {
        deleteDocument(document.path, hardDelete)
          .then(() => {
            this.documentList = this.documentList?.filter(
              (doc) => doc.path !== document.path
            );
            this.$root.$emit("reloadVesselDocumentsAfterDocumentsIsDeleted");
            notifyChangesSaved(this);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
    },
    downloadDocument(document) {
      window.open(`${this.storageEndpoint}${document.path}`);
    },
    getDateString(date) {
      if (!date) return "unknown";
      const docUploadDate = new Date(date).toISOString().split("T")[0];
      return docUploadDate || "unknown";
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },
    filteredDocs: function () {
      let result = [...this.documents];
      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v?.[element.field]
              ?.toLowerCase()
              ?.includes(element.searchString.toLowerCase())
          );
        }
      });
      return result;
    },

    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".vessel-builders-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  mounted() {
    this.buildDataTable();
  },
  watch: {
    documents() {
      this.buildDataTable();
    },
  },
};
</script>

<style lang="scss" scoped>
.td-name {
  max-width: 230px;
  overflow: hidden;
}

.deleted {
  color: #a5a8ad !important;
}
</style>
