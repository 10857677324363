import { getJobs } from "@/apis/projects";
import { notifyError } from "@/helpers/notification";
import { store } from "../../main";

export default {
  data() {
    return {
      isLoadingJobOptions: false,
      jobOptions: [],
      jobCategoryInput: null,
      jobInput: null,
      jobs: [],
    };
  },
  methods: {
    async getJobOptions() {
      if (store?.state?.jobOptions && store.state.jobOptions.length > 0) {
        this.jobOptions = store.state.jobOptions;
        return store.state.jobOptions;
      }

      this.isLoadingJobOptions = true;

      let jobOptions = [];

      await getJobs()
        .then(({ data }) => {
          // get a list of unique categories from the jobs
          const categories = data
            .map((j) => j.category)
            .filter((value, index, self) => self.indexOf(value) === index);

          // add every category as an object in jobOptions with an empty options array
          jobOptions = categories.map((category) => ({
            category,
            options: [],
          }));

          // add each job to the respective category's options array
          data.forEach((job) => {
            const categoryIndex = jobOptions.findIndex(
              (option) => option.category === job.category
            );
            jobOptions[categoryIndex].options.push({
              text: job.title,
              value: job.id,
            });
          });
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.jobOptions = jobOptions;
      store.commit("storeJobOptions", jobOptions);

      this.isLoadingJobOptions = false;
    },
    jobCategorySelected() {
      if (this.jobOptionsForCategory.length)
        this.jobInput = this.jobOptionsForCategory[0].value;
      else this.jobInput = null;
    },
    addJob() {
      if (!this.jobCategoryInput || !this.jobInput) return;

      if (!this.jobs.includes(+this.jobInput)) this.jobs.push(+this.jobInput);
    },
    removeJob(index) {
      this.jobs.splice(index, 1);
    },
  },
  computed: {
    jobCategoryOptions() {
      return this.jobOptions.map((o) => ({
        text: o.category,
        value: o.category,
      }));
    },
    jobOptionsForCategory() {
      if (!this.jobCategoryInput) return [];

      return this.jobOptions.find(
        (option) => option.category === this.jobCategoryInput
      ).options;
    },
    attachedJobs() {
      const attached = [];
      let selectedJobs = [...this.jobs];

      if (!selectedJobs.length) return attached;

      this.jobOptions.forEach((category) => {
        category.options.forEach((option) => {
          if (selectedJobs.includes(option.value)) {
            attached.push({ ...option, category: category.category });
            const index = selectedJobs.findIndex((j) => j === option.value);
            selectedJobs.splice(index, 1);
          }
        });
      });

      return attached;
    },
  },
  mounted() {
    this.getJobOptions();
  },
};
