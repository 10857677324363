<template>
  <ParticularsSection
    v-if="certificates.length"
    :with-narrow-columns="true"
    title="Certifications"
  >
    <div
      v-for="({ key, cells }, i) in certificates"
      :key="key"
      class="certificate"
    >
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :class="cell.key === 'certificate' && 'col-start-1'"
        :is-prominent="cell.key === 'certificate'"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </div>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";
import moment from "moment";

export default {
  name: "VesselParticularsCertification",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    certificationCells() {
      let certifications = [];

      // sort certifications by latest issue date
      [...this.vessel.certifications]
        .filter(
          (certification) =>
            certification.issue_date && certification.expiry_date
        )
        .sort((a, b) => {
          if (moment(a.issue_date).isBefore(moment(b.issue_date))) return 1;
          else if (moment(a.issue_date).isAfter(moment(b.issue_date)))
            return -1;
          else return 0;
        })
        .forEach((certification) => {
          // if certifications does not already include a certification of this type
          if (
            !certifications.some(
              (c) => c.certificate === certification.certificate
            )
          ) {
            certifications.push(certification);
          }
        });

      return extractCells(
        certifications,
        [
          { key: "certificate" },
          {
            label: "Issue Date",
            key: "issue_date",
            format: "date",
          },
          {
            label: "Expiry Date",
            key: "expiry_date",
            format: "date",
          },
          {
            label: "Due Open",
            key: "due_open",
            format: "date",
          },
          {
            label: "Due Close",
            key: "due_close",
            format: "date",
          },
        ],
        this.excludeEmptyFields
      );
    },
    certificates() {
      return [
        ...this.certificationCells.map((cells, i) => ({
          key: `certification-${i}`,
          cells,
        })),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  display: contents;

  &:not(:last-child) {
    div:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
