<template>
  <div class="card-body">
    <form @submit.prevent="saveDetails">
      <div class="row">
        <div v-for="field in fields" :key="field.name" class="col-12">
          <form-input-text
            v-model="details[field.name]"
            :error="$v.details[field.name].$error"
            :error-message="validationMessage($v.details[field.name])"
            :field="field"
          />
        </div>
      </div>
      <address-editor :address="details.address" />
      <div class="row">
        <div class="col-12 mt-3 d-flex justify-content-end">
          <button class="btn btn-danger" type="button" @click="close">
            Cancel
          </button>
          <button
            :class="{ disabled: $v.$anyError }"
            class="btn btn-primary ms-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "@/components/ui/FormInputText";
import { notifyError } from "@/helpers/notification";
import { createMachineryMaker } from "@/apis/machineryMakers";
import AddressEditor from "../ui/AddressEditor.vue";

export default {
  name: "CreateMachineryMakerModal",
  components: { FormInputText, AddressEditor },
  data() {
    return {
      isSavingDetails: false,
      details: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postcode: null,
          country: null,
        },
      },
      fields: [{ label: "Name", name: "name", type: "text" }],
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
    },
  },
  methods: {
    validationMessage,
    close(state = null) {
      this.$modal.hide("create-machinery-maker-modal", state);
    },
    async saveDetails() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        notifyError(this, "not all required fields have been entered");
        return;
      }

      this.isSavingDetails = true;

      await createMachineryMaker(this.details)
        .then(({ data }) => {
          this.close({ maker_id: data.id });
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.isSavingDetails = false;
    },
  },
};
</script>
