<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-start align-items-center mb-4"
            >
              <div class="page-title-box pb-0">
                <h4 class="mb-0">Certifications</h4>
              </div>
              <button
                class="ms-2 btn btn-sm btn-primary"
                title="Add Certifications"
                type="button"
                @click="addCertifications"
              >
                <span class="sr-only">Add Certifications</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <certifications-table
            v-show="certifications?.length"
            id="tblCertifications"
            ref="tblCertifications"
            :certificationDocuments="certificationDocuments"
            :certifications="certifications"
            @removeCertificationByIndex="removeCertifications($event)"
            @updateCertificate="updateCertification($event)"
          />
        </div>
      </div>
    </div>
    <div v-show="supersededCertifications?.length" class="col-12">
      <div class="card mb-0">
        <div class="card-body">
          <div
            class="d-flex justify-content-between cursor-pointer"
            @click="toggleSection(SUPERSEDED_CERTIFICATES)"
          >
            <h6 class="mb-0">Superseded Certificates</h6>
            <div>
              <i
                v-if="isExpanded === SUPERSEDED_CERTIFICATES"
                class="fa fa-chevron-up"
              ></i>
              <i v-else class="fa fa-chevron-down"></i>
            </div>
          </div>

          <div v-show="isExpanded === SUPERSEDED_CERTIFICATES" class="mt-3">
            <certifications-table
              id="tblSupersededCertifications"
              ref="tblSupersededCertifications"
              :certificationDocuments="certificationDocuments"
              :certifications="supersededCertifications"
              @getAllDocuments="getAllDocuments()"
              @removeCertificationByIndex="removeCertifications($event)"
              @updateCertificate="updateCertification($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocuments } from "@/apis/files";
import CertificationsTable from "./CertificationsTable.vue";
import { getExpiryLevel } from "@/helpers/certificates";

const SUPERSEDED_CERTIFICATES = "SUPERSEDED_CERTIFICATES";

export default {
  name: "CertificationsTab",
  components: { CertificationsTable },
  props: {
    vessel: Object,
  },
  data() {
    return {
      certificationDocuments: [],
      isExpanded: SUPERSEDED_CERTIFICATES,
      SUPERSEDED_CERTIFICATES,
    };
  },
  methods: {
    toggleSection(section) {
      if (this.isExpanded === section) this.isExpanded = null;
      else this.isExpanded = section;
    },
    addCertifications() {
      this.vessel.certifications.push({
        vessel_id: this.vessel.id,
        certificate: null,
        issue_date: null,
        superseded: false,
      });

      this.$nextTick(() => {
        this.$refs.tblCertifications.openForEdit(
          this.certifications.length - 1
        );
      });
    },
    removeCertifications(certification) {
      const index = this.vessel.certifications.findIndex(
        (cert) => cert.id === certification.id
      );

      this.vessel.certifications.splice(index, 1);
    },
    updateCertification(certification) {
      const index = this.vessel.certifications.findIndex(
        (cert) => cert.id === certification.id
      );

      this.$set(this.vessel.certifications, index, certification);
    },

    isInvalid() {
      return this.$refs.tblCertifications.isInvalid();
    },
    /**
     * Since we add the property 'isExpired' to the certifications array, we need to remove it before saving to the backend
     * Include also the superseded certificates
     */
    getCertificationsForSavingToBackend() {
      return this.vessel.certifications.map((c) => {
        let certification = { ...c };
        delete certification.isExpired;
        return certification;
      });
    },
    getAllDocuments() {
      getDocuments(
        `vessels/${this.vessel.id}/certifications`,
        this.vessel.id
      ).then((resp) => (this.certificationDocuments = resp.data));
    },
  },
  computed: {
    certifications() {
      if (!this.vessel.certifications) return [];
      const isInService = this.vessel?.service_status === "In Service";

      return this.vessel.certifications
        .filter((c) => !c.superseded)
        .map((c) => ({ ...c, isExpired: getExpiryLevel(c, isInService) }));
    },
    supersededCertifications() {
      return this.vessel?.certifications?.filter((c) => c.superseded) ?? [];
    },
  },
  mounted() {
    if (this.vessel && this.vessel.certifications) {
      this.getAllDocuments();
    }
    if (this.vessel && !this.vessel.certifications) {
      this.$set(this.vessel, "certifications", []);
    }

    this.$root.$on("reloadVesselDocumentsAfterDocumentsIsDeleted", () => {
      this.getAllDocuments();
    });
  },
};
</script>
