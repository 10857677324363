import { getUsers } from "@/apis/users";
import { notifyError } from "@/helpers/notification";
import { store } from "../../main";

export default {
  data() {
    return {
      isLoadingUsers: false,
      users: [],
    };
  },
  methods: {
    async getUsers() {
      if (store?.state?.users && store.state.users.length > 0) {
        this.users = store.state.users;
        return store.state.users;
      }

      this.isLoadingUsers = true;
      await getUsers()
        .then(({ data }) => {
          this.users = data;
          store.commit("storeUsers", data);
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
      this.isLoadingUsers = false;
    },
  },
  computed: {
    /**
     * A list of user name-value pairs to use in dropdowns
     * @returns An array of {text, value } objects
     */
    usersOptions() {
      return this.users.map((c) => ({
        text: c.name,
        value: c.id,
      }));
    },
  },
  mounted() {
    this.getUsers();
  },
};
