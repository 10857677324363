<template>
  <div class="d-flex flex-column">
    <div
      v-for="item in columnArray"
      :key="`${item.table}-${item.column}`"
      class="main-card mb-2"
    >
      <div class="subcard" @click="() => changeActive(item)">
        <span class="ms-2">{{ item?.displayName || item.column }}</span
        ><i class="fa fa-chevron-right me-2"></i>
      </div>

      <div v-if="itemselected(item)">
        <button
          class="btn btn-primary py-1 ms-2"
          type="button"
          @click="addFilter(item)"
        >
          Filter by
        </button>
        <button
          class="btn btn-primary py-1 ms-2"
          type="button"
          @click="addSearchBy(item)"
        >
          Search by
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnSelector",
  components: {},
  props: {
    columnArray: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  mounted() {},
  methods: {
    addFilter(item) {
      this.$root.$emit("addStructuredFilter", item);
    },
    addSearchBy(item) {
      this.$root.$emit("addStructuredSearchBy", item);
    },
    changeActive(item) {
      if (this.selectedItem === item) {
        this.selectedItem = null;
        return;
      }
      this.selectedItem = item;
    },
    itemselected(item) {
      return this.selectedItem === item;
    },
  },
  computed: {},
};
</script>

<style>
.main-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;

  margin-bottom: 10px;
  margin: 0 2px 0 2px;
}

.subcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 700;
  width: 250px;
}
</style>
