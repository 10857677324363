<template>
  <wrapper-page ref="wrapper">
    <div class="row clearfix">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column gap-y-3">
              <QuickButton
                :is-selected="
                  btnEnabled === btnNames.SEARCH_VESSELS_BY_GENERATOR_MAKE_MODEL
                "
                title="Search vessels by generator model and make"
                @toggled="
                  enableButton(btnNames.SEARCH_VESSELS_BY_GENERATOR_MAKE_MODEL)
                "
              >
                <VesselsByGeneratorMakeModel />
              </QuickButton>

              <QuickButton
                :is-selected="
                  btnEnabled === btnNames.SEARCH_VESSELS_BY_MACHINERY_MAKE_MODEL
                "
                title="Search vessels by machinery model and make"
                @toggled="
                  enableButton(btnNames.SEARCH_VESSELS_BY_MACHINERY_MAKE_MODEL)
                "
              >
                <VesselsByMachineryMakeModel />
              </QuickButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../../components/layout/WrapperPage.vue";
import VesselsByMachineryMakeModel from "@/components/search/quickSearchComponents/VesselsByMachineryMakeModel.vue";
import VesselsByGeneratorMakeModel from "@/components/search/quickSearchComponents/VesselsByGeneratorMakeModel.vue";
import { quickSearchBtnNames as btnNames } from "@/constants";
import QuickButton from "@/components/search/QuickButton.vue";

export default {
  name: "MakersSearch",
  components: {
    QuickButton,
    VesselsByGeneratorMakeModel,
    VesselsByMachineryMakeModel,
    WrapperPage,
  },
  data() {
    return {
      btnEnabled: null,
      btnNames,
    };
  },
  methods: {
    enableButton(btnName) {
      if (this.btnEnabled === btnName) {
        this.btnEnabled = null;
        return;
      }

      this.btnEnabled = btnName;
    },
  },
};
</script>
