<template>
  <div>
    <form
      @submit.prevent="getVesselsByMakeAndModel"
      @reset.prevent="resetDropdowns"
    >
      <div v-if="!isLoadingGeneratorMakeAndModels" class="row">
        <div class="col-md-6">
          <multi-select-dropdown
            v-model="selectedGeneratorMake"
            :close-on-select="true"
            :field="generatorMakeList"
            :multiple="false"
          />
        </div>

        <div class="col-md-6">
          <multi-select-dropdown
            v-model="selectedGeneratorModel"
            :close-on-select="true"
            :field="generatorModelList"
            :multiple="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex gap-x-3">
          <button class="btn btn-primary" type="submit">Search</button>
          <button class="btn btn-danger" type="reset">Reset</button>
        </div>
      </div>
    </form>

    <search-results-table
      v-if="searchResult"
      v-model="searchResult"
      class="mt-4"
      @getAllSelected="getAllSelected"
    />
  </div>
</template>

<script>
import { search } from "@/apis/search";
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import { quickSearchBtnNames as btnNames } from "../../../constants";
import { getVesselGeneratorsMakeModels } from "@/apis/vessels";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";

export default {
  name: "VesselsByGeneratorMakeModel",
  components: {
    MultiSelectDropdown,
    SearchResultsTable,
  },
  props: {},
  data() {
    return {
      btnNames,
      searchResult: null,
      makeModelList: [],
      generatorMakeList: {
        label: "Select Make",
        placeholder: "Select Make",
        options: [],
      },
      generatorModelList: {
        label: "Select Model",
        placeholder: "Select Model",
        options: [],
      },
      selectedGeneratorMake: null,
      selectedGeneratorModel: null,
      isLoadingGeneratorMakeAndModels: false,
    };
  },
  mounted() {
    this.getGeneratorMakeAndModelList();
  },
  watch: {
    selectedGeneratorMake() {
      const selectableModels = this.makeModelList
        ?.map((item) => {
          if (item.make === this.selectedGeneratorMake?.value) {
            return item;
          }
        })
        .filter((item) => item);

      const uniqueModelList = [
        ...new Set(selectableModels?.map((item) => item.model)),
      ];
      const modelOptions = uniqueModelList
        .filter((model) => model)
        .map((model) => {
          return {
            text: model,
            value: model,
          };
        });

      this.generatorModelList = {
        placeholder: "Select Model",
        name: "generatorModel",
        label: "Select Model",
        options: modelOptions,
        sortOptions: true,
      };
    },

    selectedGeneratorModel() {
      const selectableMakes = this.makeModelList
        ?.map((item) => {
          if (item.model === this.selectedGeneratorModel?.value) {
            return item;
          }
        })
        .filter((item) => item);

      const uniqueMakelList = [
        ...new Set(selectableMakes?.map((item) => item.make)),
      ];
      const makeOptions = uniqueMakelList
        .filter((make) => make)
        .map((make) => {
          return {
            text: make,
            value: make,
          };
        });

      this.generatorMakeList = {
        placeholder: "Select Make",
        label: "Select Make",
        name: "generatorMake",
        options: makeOptions,
        sortOptions: true,
      };
    },
  },
  methods: {
    resetDropdowns() {
      this.selectedGeneratorMake = null;
      this.selectedGeneratorModel = null;
      this.getGeneratorMakeAndModelList();
    },
    async getVesselsByMakeAndModel() {
      const filterMake = this.selectedGeneratorMake
        ? [
            {
              operand: this.selectedGeneratorMake?.value,
              operator: "=",
              property: "vessels_electric_generators.make",
            },
          ]
        : [];
      const filterModel = this.selectedGeneratorModel
        ? [
            {
              operand: this.selectedGeneratorModel?.value,
              operator: "=",
              property: "vessels_electric_generators.model",
            },
          ]
        : [];

      let request = {
        distinct: [
          "vessels.id",
          "vessels.name",
          "vessels_electric_generators.make",
          "vessels_electric_generators.model",
          "vessels_electric_generators.power",
          "vessels_electric_generators.year",
          "vessels_electric_generators.fuel",
        ],

        filter: [...filterMake, ...filterModel],
      };

      const response = await search(request);
      this.searchResult = response.data;
    },
    async getGeneratorMakeAndModelList() {
      this.isLoadingGeneratorMakeAndModels = true;

      const response = await getVesselGeneratorsMakeModels();
      this.makeModelList = response?.data;

      // Get make list.
      const uniqueMakeList = [
        ...new Set(response?.data.map((item) => item.make)),
      ];
      const makeOptions = uniqueMakeList
        .filter((make) => make)
        .map((make) => {
          return {
            text: make,
            value: make,
          };
        });

      // Get model list.
      const uniqueModelList = [
        ...new Set(response?.data.map((item) => item.model)),
      ];
      const modelOptions = uniqueModelList
        .filter((model) => model)
        .map((model) => {
          return {
            text: model,
            value: model,
          };
        });

      // Set state values
      this.generatorMakeList = {
        placeholder: "Select Make",
        label: "Select Make",
        name: "generatorMake",
        options: makeOptions,
        sortOptions: true,
      };
      this.generatorModelList = {
        placeholder: "Select Model",
        label: "Select Model",
        name: "generatorModel",
        options: modelOptions,
        sortOptions: true,
      };
      this.isLoadingGeneratorMakeAndModels = false;
    },
    async getAllSelected(value, clickedIndex) {
      let routeDetails = {};

      if (clickedIndex >= 2) {
        routeDetails = {
          name: "vessels-edit",
          params: { vesselId: value[0] },
          query: { tab: "Generators" },
        };
      } else {
        routeDetails = {
          name: "vessels-show",
          params: { vesselId: value[0] },
        };
      }

      const route = this.$router.resolve(routeDetails);
      window.open(route.href, "_blank");
      return;
    },
  },
  computed: {},
};
</script>

<style></style>
