<template>
  <wrapper-page ref="wrapper">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <vessel-builders-table
              v-if="!isLoadingVesselBuilders"
              :showSearchBar="false"
              :vesselBuilders="vesselBuilders"
            />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../../components/layout/WrapperPage.vue";
import VesselBuildersTable from "../../../components/vessels/VesselBuildersTable.vue";
import { getVesselBuilders } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";

export default {
  name: "VesselBuildersIndex",
  components: {
    WrapperPage,
    VesselBuildersTable,
  },
  data() {
    return {
      isLoadingVesselBuilders: true,
      vesselBuilders: [],
    };
  },
  async mounted() {
    this.isLoadingVesselBuilders = true;

    await getVesselBuilders(true)
      .then(({ data }) => {
        this.vesselBuilders = [...data];
      })
      .catch((err) => {
        notifyError(this, err?.response?.data);
      });

    this.isLoadingVesselBuilders = false;
  },
};
</script>
