import { render, staticRenderFns } from "./VesselDocumentsTable.vue?vue&type=template&id=2afb866d&scoped=true&"
import script from "./VesselDocumentsTable.vue?vue&type=script&lang=js&"
export * from "./VesselDocumentsTable.vue?vue&type=script&lang=js&"
import style0 from "./VesselDocumentsTable.vue?vue&type=style&index=0&id=2afb866d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2afb866d",
  null
  
)

export default component.exports