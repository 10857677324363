<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <button
          :class="{ disabled: $v.$anyError }"
          class="btn btn-sm btn-primary"
          @click="saveDetails"
        >
          Create Vessel
        </button>
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li
            v-for="tab in tabs"
            :key="tab"
            :class="{
              invalid:
                (tab === 'Details' &&
                  ($v.details.$anyError || tabWarnings('details').length)) ||
                (tab === 'Dimensions' &&
                  ($v.details.dimensions.$anyError ||
                    tabWarnings('dimensions').length)) ||
                (tab === 'Hull' && $v.details.hull.$anyError) ||
                (tab === 'Machinery' && machineryTabIsInvalid) ||
                (tab === 'Generators' &&
                  $v.details.electricGenerators.$anyError) ||
                (tab === 'Surveys & Certifications' &&
                  surveysAndCertsTabIsInvalid),
            }"
            class="nav-item"
          >
            <a
              :class="{ active: active_tab === tab }"
              class="nav-link"
              data-toggle="tab"
              href="#"
              @click="active_tab = tab"
            >
              {{ tab }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex justify-content-between align-items-start gap-x-8"
            >
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="vessel-h-and-m-switch"
                  v-model="details.h_and_m"
                />
                <label class="form-check-label" for="vessel-h-and-m-switch"
                  >H &amp; M</label
                >
              </div>

              <VesselStatus v-model="details.service_status" />
            </div>
          </div>
        </div>

        <div v-if="tabWarnings(active_tab).length" class="col-12 mt-4">
          <div class="alert alert-warning mb-0" role="alert">
            <p
              v-for="warning in tabWarnings(active_tab)"
              :key="`warning-${warning}`"
              class="mb-0"
            >
              {{ warning }}
            </p>
          </div>
        </div>

        <form @submit.prevent="saveDetails" class="d-flex flex-column">
          <div v-show="active_tab === 'Details'" class="card mt-4">
            <div class="card-body">
              <h5 class="mb-3 card-title">Identification</h5>

              <div class="row">
                <div
                  v-for="field in fields.slice(0, 7)"
                  :key="`details-${field.name}`"
                  :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-text
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
              </div>

              <h5 class="mt-4 mb-3 card-title">Registry Details</h5>

              <div class="row">
                <div
                  v-for="field in fields.slice(7, 10)"
                  :key="`details-${field.name}`"
                  :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-select
                    v-if="field.type === 'select'"
                    v-model.number="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                  <form-input-text
                    v-else
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
              </div>

              <h5 class="mt-4 mb-3 card-title">Location Details</h5>

              <div class="row">
                <div
                  v-for="field in fields.slice(10, 12)"
                  :key="`details-${field.name}`"
                  :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-select
                    v-if="field.type === 'select'"
                    v-model.number="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                  <form-input-text
                    v-else
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
              </div>

              <h5 class="mt-4 mb-3 card-title">Other details</h5>

              <div class="row">
                <div
                  v-for="field in fields.slice(12)"
                  :key="`details-${field.name}`"
                  :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
                  class="col-lg-3 col-md-6"
                >
                  <multi-select-dropdown
                    v-if="field.type === 'multiselect'"
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                  <form-input-select
                    v-else-if="
                      field.type === 'select' &&
                      field.name === 'operational_range'
                    "
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                    :parent="$v.details.$model"
                  />
                  <form-input-select
                    v-else-if="field.type === 'select'"
                    v-model.number="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                  <form-input-check
                    v-else-if="field.type === 'check'"
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                  <form-input-text
                    v-else
                    v-model="$v.details[field.name].$model"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
              </div>
            </div>
          </div>

          <operational-restrictions-editor
            v-show="active_tab === 'Details'"
            ref="operationalRestrictions"
            :vessel="details"
            class="mt-4"
          />

          <div v-show="active_tab === 'Details'" class="row mt-4">
            <div
              class="col-12 d-flex justify-content-start align-items-center mb-3"
            >
              <div class="page-title-box pb-0">
                <h4 class="mb-0">Stakeholders</h4>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="saveDetails" class="col-12">
                    <div class="d-flex">
                      <div class="row flex-grow-1 d-flex">
                        <div class="col-md-6">
                          <form-input-select
                            v-model.number="clientInput"
                            :field="{
                              label: 'Client',
                              name: 'client',
                              options: clientOptions,
                            }"
                            @input="stakeholderClientSelected"
                          />
                        </div>
                        <div class="col-md-6">
                          <form-input-select
                            v-model.number="stakeholderInput"
                            :field="{
                              label: 'Contact',
                              name: 'stakeholder',
                              options: stakeholderOptionsForClient,
                            }"
                          />
                        </div>
                      </div>
                      <div class="ms-4 flex-shrink-0 d-flex">
                        <button
                          style="margin-top: 29px"
                          class="mb-auto btn btn-primary"
                          title="Add Stakeholder"
                          type="button"
                          @click="addStakeholder"
                        >
                          <span class="sr-only">Add Stakeholder</span>
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  <div v-if="stakeholders.length" class="mt-2 col-12">
                    <div
                      v-for="(stakeholder, i) in $v.stakeholders.$each.$iter"
                      :key="`stakeholders-${i}`"
                      class="d-flex mb-2"
                    >
                      <div class="row flex-grow-1 d-flex">
                        <div
                          v-for="field in stakeholderFields"
                          :key="`stakeholder-${i}-${field.name}`"
                          class="col-lg-3 col-md-4"
                        >
                          <form-input-select
                            v-if="field.type === 'select'"
                            v-model="stakeholder[field.name].$model"
                            :error="stakeholder[field.name].$error"
                            :error-message="
                              validationMessage(stakeholder[field.name])
                            "
                            :field="field"
                          />
                          <form-input-text
                            v-else
                            v-model="stakeholder[field.name].$model"
                            :error="stakeholder[field.name].$error"
                            :error-message="
                              validationMessage(stakeholder[field.name])
                            "
                            :field="field"
                          >
                            <template
                              v-if="
                                field.name === 'name' &&
                                stakeholder.$model.client_id
                              "
                              slot="input-group-append"
                            >
                              <span class="input-group-text h-100">
                                <a
                                  :href="`/clients/${stakeholder.$model.client_id}`"
                                  class="fa fa-eye"
                                  target="_blank"
                                  title="View Client"
                                ></a>
                              </span>
                            </template>
                          </form-input-text>
                        </div>
                      </div>

                      <div class="ms-4 flex-shrink-0 d-flex">
                        <button
                          style="margin-top: 29px"
                          class="mb-auto btn btn-danger"
                          title="Remove Contact"
                          type="button"
                          @click="removeStakeholder(i)"
                        >
                          <span class="sr-only">Remove Stakeholder</span>
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="active_tab === 'Dimensions'" class="card mt-4">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="field in dimensionsFields"
                  :key="`dimensions-${field.name}`"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-text
                    v-if="field.type === 'number'"
                    v-model.number="$v.details.dimensions[field.name].$model"
                    :error="$v.details.dimensions[field.name].$error"
                    :error-message="
                      validationMessage($v.details.dimensions[field.name])
                    "
                    :field="field"
                  />
                  <form-input-text
                    v-else
                    v-model="$v.details.dimensions[field.name].$model"
                    :error="$v.details.dimensions[field.name].$error"
                    :error-message="
                      validationMessage($v.details.dimensions[field.name])
                    "
                    :field="field"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-show="active_tab === 'Hull'" class="card mt-4">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="field in hullFields"
                  :key="`hull-${field.name}`"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-select
                    v-if="
                      field.type === 'select' && field.name === 'builder_id'
                    "
                    v-model.number="$v.details.hull[field.name].$model"
                    :error="$v.details.hull[field.name].$error"
                    :error-message="
                      validationMessage($v.details.hull[field.name])
                    "
                    :field="field"
                    @input="(builder_id) => builderSelected(builder_id)"
                  />
                  <form-input-select
                    v-else-if="field.type === 'select'"
                    v-model="$v.details.hull[field.name].$model"
                    :error="$v.details.hull[field.name].$error"
                    :error-message="
                      validationMessage($v.details.hull[field.name])
                    "
                    :field="field"
                    :parent="$v.details.hull.$model"
                  />
                  <form-input-text
                    v-else
                    v-model="$v.details.hull[field.name].$model"
                    :error="$v.details.hull[field.name].$error"
                    :error-message="
                      validationMessage($v.details.hull[field.name])
                    "
                    :field="field"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-show="active_tab === 'Hull'" class="card mt-4">
            <div class="card-body">
              <h5 class="mb-3 card-title">Build Address</h5>
              <address-editor
                :key="`address-${details?.name}`"
                :address="details?.hull?.build_address"
                class="col-lg-6"
              />
            </div>
          </div>

          <!-- Machinery -->
          <div v-show="active_tab === 'Machinery'" class="card mt-4">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <template v-if="details?.machinery?.length">
                    <p>
                      Number of Machines:
                      <strong>{{ details.machinery.length }}</strong>
                    </p>
                    <p>
                      Total Power: <strong>{{ totalMachineryPower }}</strong>
                    </p>
                  </template>
                </div>
              </div>

              <div class="row" v-if="!details?.machinery?.length">
                <div class="col-12 d-flex justify-content-end">
                  <button
                    class="btn btn-primary btn-sm"
                    title="Add Machinery"
                    type="button"
                    @click="addMachinery"
                  >
                    <span class="sr-only">Add Machinery</span>
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="col-12">
                  <p class="text-center">No machinery entered so far.</p>
                </div>
              </div>

              <div
                v-for="(machinery, i) in details.machinery"
                :key="`machinery-${i}`"
                :class="`row ${
                  details.machinery.length > 1 &&
                  i < details.machinery.length - 1
                    ? 'border-bottom pb-3 mb-4'
                    : ''
                }`"
              >
                <div class="col-6">
                  <h6 class="card-title">Machinery {{ parseInt(i) + 1 }}</h6>
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center gap-x-2"
                >
                  <button
                    class="btn btn-danger btn-sm"
                    title="Remove Machinery"
                    type="button"
                    @click="removeMachinery(i)"
                  >
                    <span class="sr-only">Remove Machinery</span>
                    <i class="fa fa-minus"></i>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    title="Add Machinery"
                    type="button"
                    @click="addMachinery"
                  >
                    <span class="sr-only">Add Machinery</span>
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    title="Clone Machinery"
                    type="button"
                    @click="cloneMachinery(i)"
                  >
                    <span class="sr-only">Clone Machinery</span>
                    <i class="fa fa-clone"></i>
                  </button>
                </div>
                <machinery
                  class="mt-3"
                  ref="tabMachinery"
                  :index="i"
                  :machinery="machinery"
                />
              </div>
            </div>
          </div>

          <!-- Generators -->
          <div v-show="active_tab === 'Generators'" class="card mt-4">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <template v-if="details?.electricGenerators?.length">
                    <p>
                      Number of Generators:
                      <strong>{{ details.electricGenerators.length }}</strong>
                    </p>
                    <p>
                      Total Power:
                      <strong>{{ totalElectricGeneratorPower }}</strong>
                    </p>
                  </template>
                </div>
              </div>

              <div class="row" v-if="!details?.electricGenerators?.length">
                <div class="col-12 d-flex justify-content-end">
                  <button
                    class="btn btn-primary btn-sm"
                    title="Add Generator"
                    type="button"
                    @click="addGenerator"
                  >
                    <span class="sr-only">Add Generator</span>
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="col-12">
                  <p class="text-center">No generators entered so far.</p>
                </div>
              </div>

              <div
                v-for="(generator, i) in $v.details.electricGenerators.$each
                  .$iter"
                :key="`generator-${i}`"
                :class="`row ${
                  details.electricGenerators.length > 1 &&
                  i < details.electricGenerators.length - 1
                    ? 'border-bottom pb-3 mb-4'
                    : ''
                }`"
              >
                <div class="col-6">
                  <h6 class="card-title">Generator {{ parseInt(i) + 1 }}</h6>
                </div>
                <div
                  class="col-6 d-flex justify-content-end align-items-center gap-x-2"
                >
                  <button
                    class="btn btn-danger btn-sm"
                    title="Remove Generator"
                    type="button"
                    @click="removeGenerator(i)"
                  >
                    <span class="sr-only">Remove Generator</span>
                    <i class="fa fa-minus"></i>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    title="Add Generator"
                    type="button"
                    @click="addGenerator"
                  >
                    <span class="sr-only">Add Generator</span>
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    title="Clone Generator"
                    type="button"
                    @click="cloneGenerator(i)"
                  >
                    <span class="sr-only">Clone Generator</span>
                    <i class="fa fa-clone"></i>
                  </button>
                </div>

                <div class="col-12 mt-3">
                  <div class="row">
                    <div
                      v-for="field in generatorFields"
                      :key="`generator-${i}-${field.name}`"
                      class="col-lg-3 col-md-6"
                    >
                      <form-input-select
                        v-if="field.type === 'select' && field.name === 'make'"
                        v-model="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$model
                        "
                        :error="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$error
                        "
                        :error-message="
                          validationMessage(
                            $v.details.electricGenerators.$each.$iter[i][
                              field.name
                            ]
                          )
                        "
                        :field="field"
                        :parent="generator.$model"
                        @input="makeSelected"
                      />
                      <form-input-select
                        v-else-if="field.type === 'select'"
                        v-model="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$model
                        "
                        :error="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$error
                        "
                        :error-message="
                          validationMessage(
                            $v.details.electricGenerators.$each.$iter[i][
                              field.name
                            ]
                          )
                        "
                        :field="field"
                        :parent="generator.$model"
                      />
                      <form-input-text
                        v-else
                        v-model="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$model
                        "
                        :error="
                          $v.details.electricGenerators.$each.$iter[i][
                            field.name
                          ].$error
                        "
                        :error-message="
                          validationMessage(
                            $v.details.electricGenerators.$each.$iter[i][
                              field.name
                            ]
                          )
                        "
                        :field="field"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Events -->
          <events-tab
            v-show="active_tab === 'Events'"
            ref="tabEvents"
            :vessel="details"
          />

          <!-- Surveys & Certifications -->
          <surveys-and-certs-tab
            v-show="active_tab === 'Surveys & Certifications'"
            ref="tabSurveysAndCerts"
            :vessel="details"
          />
        </form>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputText from "../../components/ui/FormInputText.vue";
import FormInputSelect from "../../components/ui/FormInputSelect";
import FormInputCheck from "@/components/ui/FormInputCheck";
import MultiSelectDropdown from "../../components/ui/MultiSelectDropdown.vue";
import { createVessel, getVessel } from "@/apis/vessels";
import stakeholdersMixin from "../../mixins/vessels/stakeholders";
import { nullToDoubleDash, toDecimalPlaces } from "@/helpers/generic";
import vesselsMixin from "../../mixins/vessels/vessels";
import {
  VesselDimensionsFields,
  VesselFields,
  VesselGeneratorFields,
  VesselHullFields,
  VesselStakeholderFields,
} from "@/constants/vessel_fields";
import {
  decimal,
  email,
  integer,
  minValue,
  required,
} from "vuelidate/lib/validators";
import { notifyError, notifySuccess } from "@/helpers/notification";
import { validationMessage } from "@/helpers/validations";
import moment from "moment";
import EventsTab from "../../components/vessels/tabs/events/EventsTab.vue";
import SurveysAndCertsTab from "../../components/vessels/tabs/surveysAndCerts/SurveysAndCertsTab.vue";
import OperationalRestrictionsEditor from "../../components/vessels/OperationalRestrictionsEditor.vue";
import Machinery from "../../components/vessels/Machinery.vue";
import { confirmRedirect } from "../../helpers/generic";
import { store } from "../../main";
import _ from "lodash";
import VesselStatus from "@/components/vessels/VesselStatus.vue";
import { RegulatoryRegimeOptions } from "@/constants/vessels";
import AddressEditor from "../../components/ui/AddressEditor.vue";

export default {
  name: "VesselsCreate",
  mixins: [stakeholdersMixin, vesselsMixin],
  components: {
    VesselStatus,
    FormInputCheck,
    WrapperPage,
    FormInputText,
    FormInputSelect,
    MultiSelectDropdown,
    EventsTab,
    Machinery,
    SurveysAndCertsTab,
    OperationalRestrictionsEditor,
    AddressEditor,
  },
  data: function () {
    return {
      moment,
      isSavingDetails: false,

      surveysAndCertsTabIsInvalid: false,
      machineryTabIsInvalid: false,

      tabs: [
        "Details",
        "Dimensions",
        "Hull",
        "Machinery",
        "Generators",
        "Events",
        "Surveys & Certifications",
      ],
      active_tab: "Details",
      formChanged: false,
      details: {
        name: null,
        official_number: null,
        imo_number: null,
        s_reg: null,
        fisheries_id: null,
        vessel_type: null,
        other_service: null,
        class: null,
        regulatory_regime: [],
        radio_range: null,
        dimensions: {
          length_overall: null,
          length: null,
          length_between_perp: null,
          lightship: null,
          displacement: null,
          length_hull: null,
          length_engine_room: null,
          breadth: null,
          depth: null,
          draught: null,
          deadweight: null,
          assigned_freeboard: null,
        },
        hull: {
          stem: null,
          stern: null,
          max_power: null,
          max_persons: null,
          max_weight: null,
          gt_and_nt: null,
          gt: null,
          nt: null,
          builder_id: null,
          model: null,
          masts: null,
          build_address: {
            line1: null,
            line2: null,
            city: null,
            postcode: null,
            country: null,
          },
        },
        formerNames: [],
        machinery: [],
        electricGenerators: [],
      },
      documents: [],

      fields: VesselFields,
      dimensionsFields: VesselDimensionsFields,
      hullFields: VesselHullFields,
      generatorFields: VesselGeneratorFields,
      stakeholderFields: VesselStakeholderFields,
    };
  },
  validations: {
    details: {
      name: { required },
      official_number: {},
      s_reg: {},
      fisheries_id: {},
      call_sign: {},
      imo_number: {},
      mmsi_number: {},
      registry: {},
      flag: {},
      former_registry: {},
      vessel_type: {},
      other_service: {},
      speed: {},
      crew_accommodation: {},
      passengers: {},
      day_charter_passenger: {},
      class: {},
      class_notation: {},
      class_maintained: {},
      regulatory_regime: {},
      operational_range: {},
      radio_range: {},
      base_port: {},
      country: {},
      dimensions: {
        length_overall: {},
        length: {},
        length_between_perp: {},
        length_hull: {},
        length_engine_room: {},
        breadth: {},
        depth: {},
        draught: {},
        lightship: {},
        displacement: {},
        deadweight: {},
        assigned_freeboard: {},
      },
      hull: {
        hull_material: {},
        hull_type: {},
        build: {},
        stem: {},
        stern: {},
        propulsion: {},
        rigging: {},
        frame_spacing: {},
        number_of_decks: {},
        number_of_watertight_bulkheads: {},
        superstructure_material: {},
        keel_laid: {},
        build_year: {},
        builder_id: {},
        model: {},
        country_outfitted: {},
        hull_number: {},
        ce_category: {},
        max_power: {
          positiveNumber: (value) => {
            if (!value)
              // allow empty (field is not required)
              return true;

            return Number(value) > 0;
          },
        },
        max_weight: {
          positiveNumber: (value) => {
            if (!value)
              // allow empty (field is not required)
              return true;

            return Number(value) > 0;
          },
        },
        max_persons: { minValue: minValue(0) },
        hull_colour: {},
        gt_and_nt: {
          decimal,
          decimal2DP: (value) => {
            if (!value) return true;
            return /^-?[0-9]+\.[0-9][0-9]$/.test(value);
          },
          minValue: minValue(0),
        },
        gt: {
          integer,
          minValue: minValue(0),
        },
        nt: {
          integer,
          minValue: minValue(0),
        },
        masts: {
          integer,
          minValue: minValue(0),
        },
      },
      electricGenerators: {
        $each: {
          make: {},
          model: {},
          year: {},
          serial_number: {},
          power: {
            positiveNumber: (value) => {
              if (!value)
                // allow empty (field is not required)
                return true;

              return Number(value) > 0;
            },
          },
          frequency: {},
          total_electrical_power: {},
          starting_arrangement: {},
          fuel: {},
          emission_standard: {},
          type: {},
        },
      },
    },
    stakeholders: {
      $each: {
        type: {
          required,
        },
        name: {},
        phone: {},
        email: {
          email,
        },
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      confirmRedirect(next);
    } else {
      next();
    }
  },
  computed: {
    totalMachineryPower() {
      const machines = this.details.machinery;
      if (machines.length === 0) return "";
      return this.totalPower(machines);
    },
    totalElectricGeneratorPower() {
      const gens = this.details.electricGenerators;
      if (gens.length === 0) return "";
      return this.totalPower(gens);
    },
  },
  methods: {
    validationMessage,
    /**
     * @param engines - Array of either Machinery or Generators
     */
    totalPower(engines) {
      let value = engines.reduce(
        (previousValue, engine) =>
          previousValue + (Number(engine.power ?? 0) || 0),
        0
      );

      return (
        (Number.isInteger(value) ? value : parseFloat(value.toFixed(2))) + " kW"
      );
    },
    addMachinery() {
      this.details.machinery.push({
        make: null,
        model: null,
        type: null,
        cycle: null,
        fuel: null,
        power: null,
        propulsion: null,
        gearbox: null,
        engine_hours: null,
        engine_hours_date: null,
        is_derated: null,
      });
    },
    cloneMachinery(index) {
      this.details.machinery.push({
        ...this.$refs.tabMachinery[index].machinery_,
      });
    },
    removeMachinery(index) {
      this.details.machinery.splice(index, 1);
    },
    addGenerator() {
      this.details.electricGenerators.push({
        make: null,
        model: null,
        power: null,
        total_electrical_power: null,
        fuel: null,
      });
    },
    cloneGenerator(index) {
      this.details.electricGenerators.push({
        ...this.$v.details.electricGenerators.$each.$iter[index].$model,
      });
    },
    removeGenerator(index) {
      this.details.electricGenerators.splice(index, 1);
    },
    async saveDetails() {
      this.$v.$touch();
      this.surveysAndCertsTabIsInvalid =
        this.$refs.tabSurveysAndCerts.isInvalid();
      this.machineryTabIsInvalid =
        (this.$refs.tabMachinery || []).length > 0 &&
        this.$refs.tabMachinery.some((machTab) => machTab.isInvalid());
      if (
        this.$v.$anyError ||
        this.surveysAndCertsTabIsInvalid ||
        this.machineryTabIsInvalid
      ) {
        notifyError(this, "not all required fields have been entered");
        return;
      }

      this.isSavingDetails = true;

      const details = {
        ...this.details,
        operational_restrictions:
          this.$refs.operationalRestrictions.getOperationalResitrictions(),
        stakeholders: this.stakeholders.map((s) => ({
          id: s.id,
          type: s.type,
        })),
        regulatory_regime: this.details.regulatory_regime.map(
          (elem) => elem.value
        ),
        formerNames: this.$refs.tabEvents.tabFormerNames.former_names,
        surveys: this.$refs.tabSurveysAndCerts.tabSurveys.surveys,
        certifications: this.$refs.tabSurveysAndCerts.tabCertifications
          .getCertificationsForSavingToBackend()
          .map((c) => ({ ...c, isExpired: undefined })),
        machinery: (this.$refs.tabMachinery || []).map(
          (machTab) => machTab.machinery_
        ),
        events: this.$refs.tabEvents.getAllEventsExceptFormerNames(),
        valuations: this.$refs.tabSurveysAndCerts.tabValuations.valuations,
        hullInspections:
          this.$refs.tabSurveysAndCerts.tabHullAndMachinery.tabHullInspections
            .hullInspections,
      };

      // set decimal places to dimension fields
      const {
        length_overall,
        length,
        length_between_perp,
        length_engine_room,
        lightship,
        displacement,
      } = details.dimensions;

      if (length_overall)
        details.dimensions.length_overall = toDecimalPlaces(length_overall, 3);

      if (length) details.dimensions.length = toDecimalPlaces(length, 3);

      if (length_between_perp)
        details.dimensions.length_between_perp = toDecimalPlaces(
          length_between_perp,
          3
        );

      if (length_engine_room)
        details.dimensions.length_engine_room = toDecimalPlaces(
          length_engine_room,
          3
        );

      if (lightship)
        details.dimensions.lightship = toDecimalPlaces(lightship, 3);

      if (displacement)
        details.dimensions.displacement = toDecimalPlaces(displacement, 3);

      // Set empty spaces to null in numeric fields
      // Number(undefined), Number("a") => NaN
      // Number(null), Number(""), Number(" ") => 0
      // Previous validation makes sure we don't get strings. Only numbers, empty strings, null or undefined are expected
      let tmp = details.hull.gt_and_nt;
      if (isNaN(Number(tmp)) || tmp === "" || tmp === null) {
        details.hull.gt_and_nt = null;
      }
      tmp = details.hull.gt;
      if (isNaN(Number(tmp)) || tmp === "" || tmp === null) {
        details.hull.gt = null;
      }
      tmp = details.hull.nt;
      if (isNaN(Number(tmp)) || tmp === "" || tmp === null) {
        details.hull.nt = null;
      }

      await createVessel(details)
        .then(({ data }) => {
          if (data.id) {
            notifySuccess(this, "Vessel has been created");
            this.$router.push({
              name: "vessels-show",
              params: { vesselId: data.id },
            });
          } else {
            notifyError(this, "Vessel not created");
          }
          this.getbuilderModels();
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
      this.formChanged = false;
      this.isSavingDetails = false;
    },
    async getCloningDetails(vesselId) {
      this.isLoadingDetails = true;
      await getVessel(vesselId)
        .then(({ data }) => {
          const details = data;
          delete details.id;

          details.name = null;
          details.official_number = null;
          details.s_reg = null;
          details.fisheries_id = null;
          details.call_sign = null;
          details.imo_number = null;
          details.mmsi_number = null;

          details.events = [];

          if (
            details.stakeholders &&
            details.stakeholders.constructor === Array &&
            details.stakeholders.length > 0
          ) {
            this.stakeholders = [...details.stakeholders];
            delete details.stakeholders;
          }

          if (!details.vessel_type) details.vessel_type = null;

          if (!details.other_service) details.other_service = null;

          if (!details.class) details.class = null;

          if (details.class_maintained)
            details.class_maintained = !!details.class_maintained;

          if (
            details.regulatory_regime &&
            Array.isArray(details.regulatory_regime) &&
            details.regulatory_regime.length > 0
          ) {
            details.regulatory_regime = details.regulatory_regime.map(
              (regRegime) =>
                RegulatoryRegimeOptions.find((opt) => opt.value == regRegime)
            );
          } else {
            details.regulatory_regime = [];
          }

          if (!details.radio_range) details.radio_range = null;

          // We need the empty with nulls so that the bindings for errors and computed properties work
          const emptyDimensions = {
            length_overall: null,
            length: null,
            length_between_perp: null,
            lightship: null,
            displacement: null,
          };
          const emptyHull = {
            stem: null,
            stern: null,
            max_power: null,
            max_persons: null,
            max_weight: null,
            gt_and_nt: null,
            gt: null,
            nt: null,
            builder_id: null,
            masts: null,
            build_address: {
              line1: null,
              line2: null,
              city: null,
              postcode: null,
              country: null,
            },
          };
          details.dimensions = { ...emptyDimensions, ...details.dimensions };
          details.hull = {
            ...emptyHull,
            ...details.hull,
            ...{ hull_number: null },
          };
          details.hull.build_address = {
            ...(details.hull.build_address
              ? details.hull.build_address
              : emptyHull.build_address),
          };

          if (!details.machinery) {
            details.machinery = [];
          } else {
            details.machinery.forEach((machinery) => {
              if (machinery.is_main_engine !== undefined)
                machinery.is_main_engine = String(machinery.is_main_engine);
              if (machinery.is_derated !== undefined)
                machinery.is_derated = !!machinery.is_derated;
              if (machinery.engine_hours_date) {
                machinery.engine_hours_date = moment(
                  machinery.engine_hours_date
                ).format("YYYY-MM-DD");
              }

              machinery.serial_number = null;
            });
          }

          if (details.hull.gt_and_nt)
            details.hull.gt_and_nt = toDecimalPlaces(details.hull.gt_and_nt, 2);

          if (details.hull.builder)
            details.hull.builder_id = details.hull.builder?.id;

          if (!details.electricGenerators) details.electricGenerators = [];
          details.electricGenerators = details.electricGenerators.map((g) => ({
            ...g,
            serial_number: null,
          }));

          this.details = nullToDoubleDash(details, []);

          this.isLoadingDetails = false;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
          this.isLoadingDetails = false;
        });
    },
  },
  async mounted() {
    store.commit("storeDefaultFormState", this.details);

    if (this.$route.query?.clone) {
      await this.getCloningDetails(this.$route.query.clone);
    }
  },
  watch: {
    "details.dimensions": {
      deep: true,
      handler() {
        this.$asyncComputed.hullWarnings.update();
      },
    },
    async "details.hull.builder_id"() {
      await this.getVesselHullModelOptions(this.details.hull.builder_id);
      // If the current model does not exist for this builder, reset it
      const field = this.hullFields.find((f) => f.name === "model");
      const modelFound = field.options.find(
        (option) => option.value === this.details.hull.model
      );
      if (!modelFound) {
        this.details.hull.model = null;
      }
    },
    details: {
      handler(newValue) {
        if (!this.formChanged) {
          this.formChanged = true;
          return;
        }
        const defaultState = store.state.dafultFormState;
        if (_.isEqual(newValue, defaultState)) {
          this.formChanged = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.table tr td {
  padding: 0em;
}
</style>
