const config = require("./config.json");
const { fileUpload, get, remove } = require("./common").default;

const filesUrl = config.apis.baseUrl.url;

exports.uploadDocument = async (file, folder = "files", filename) => {
  if (!filename) filename = file.name;
  return await fileUpload(filesUrl + `${folder}?filename=${filename}`, file);
};

/**
 * Get documents in container
 * @param {string} documentType Prefix/folder in which to look. Ex: pics, manuals, etc..
 * @returns An array of filenames
 */
exports.getDocuments = async (folder = "", itemId = "") => {
  return await get(
    filesUrl + `files?prefix=${folder}` + (itemId ? `&itemId=${itemId}` : "")
  );
};

/**
 * Get documents in container
 * @param {string} documentType Prefix/folder in which to look. Ex: pics, manuals, etc..
 * @returns [{"path": "vessels/1/certifications/10/Address orig.csv",
        "document_type": "certifications",
        "deleted": 0,
        "created_on": "2022-09-29T15:57:50.000Z",
        "name": "Address orig.csv"}] 
 */
exports.getDocumentsDetails = async (
  folder = "",
  itemId = "",
  showDeleted = ""
) => {
  return await get(
    filesUrl +
      `files/details?prefix=${folder}` +
      (itemId ? `&itemId=${itemId}` : "") +
      (showDeleted ? `&showDeleted=${showDeleted}` : "")
  );
};

/**
 * Get documents in container
 * @param {string} documentType Prefix/folder in which to look. Ex: pics, manuals, etc..
 * @returns An array of filenames
 */
exports.deleteDocument = async (path = "", hardDelete = "") => {
  return await remove(filesUrl + `files?path=${path}&hardDelete=${hardDelete}`);
};
