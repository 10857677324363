<template>
  <div class="col-12">
    <ul class="nav nav-tabs">
      <li
        v-for="tab in tabs"
        :key="tab"
        :class="{
          invalid:
            (tab === 'Details' && $v.details.$anyError) ||
            (tab === 'Surveys' && surveysTabIsInvalid) ||
            (tab === 'Certifications' && certificatesTabIsInvalid),
        }"
        class="nav-item"
      >
        <a
          :class="{ active: active_tab === tab }"
          class="nav-link"
          data-toggle="tab"
          href="#"
          @click="active_tab = tab"
        >
          {{ tab }}
        </a>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <!-- Surveys -->
        <surveys-tab
          v-show="active_tab === 'Surveys'"
          ref="tabSurveys"
          :vessel="vessel"
        />
        <certifications-tab
          v-show="active_tab === 'Certifications'"
          ref="tabCertifications"
          :vessel="vessel"
        />
        <hull-and-machinery-tab
          v-show="active_tab === 'H&M'"
          ref="tabHullAndMachinery"
          :vessel="vessel"
        />
        <valuations-tab
          v-show="active_tab === 'Insurance & Valuation'"
          ref="tabValuations"
          :vessel="vessel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SurveysTab from "./SurveysTab.vue";
import ValuationsTab from "./ValuationsTab.vue";
import HullAndMachineryTab from "./hullAndMachinery/HullAndMachineryTab.vue";
import CertificationsTab from "./CertificationsTab.vue";

export default {
  name: "VesselsSurveysAndCertsTab",
  components: {
    SurveysTab,
    CertificationsTab,
    ValuationsTab,
    HullAndMachineryTab,
  },
  props: {
    vessel: Object,
  },
  data() {
    return {
      tabs: ["Surveys", "Certifications", "H&M", "Insurance & Valuation"],
      active_tab: "Surveys",

      surveysTabIsInvalid: false,
      certificatesTabIsInvalid: false,
    };
  },
  methods: {
    isInvalid() {
      this.surveysTabIsInvalid = this.tabSurveys.isInvalid();
      this.certificatesTabIsInvalid = this.tabCertifications.isInvalid();
      return this.surveysTabIsInvalid || this.certificatesTabIsInvalid;
    },
  },
  computed: {
    /**
     * Public property to fetch the list of Surveys
     */
    tabSurveys() {
      return this.$refs.tabSurveys;
    },
    tabCertifications() {
      return this.$refs.tabCertifications;
    },
    tabValuations() {
      return this.$refs.tabValuations;
    },
    tabHullAndMachinery() {
      return this.$refs.tabHullAndMachinery;
    },
  },
};
</script>
