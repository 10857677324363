<template>
  <div>
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="input-group has-validation mb-3">
      <datepicker
        v-if="field.type === 'date'"
        :bootstrap-styling="true"
        :calendar-button="true"
        :calendar-button-icon="`fa fa-calendar`"
        :class="{ 'is-invalid': error }"
        :format="`dd-MM-yyyy`"
        :highlighted="{ dates: [new Date()] }"
        :input-class="error ? 'parsley-error is-invalid' : ''"
        :typeable="false"
        :value="value"
        class="form-date-control"
        placeholder="Choose date"
        @input="(e) => $emit('input', e.toISOString().substring(0, 10))"
      ></datepicker>
      <div v-if="error" class="invalid-feedback">
        <template v-if="errorMessage">{{ errorMessage }}</template>
        <template v-else>Error has been encountered</template>
      </div>
    </div>
  </div>
</template>

<script>
// https://github.com/charliekassel/vuejs-datepicker
import Datepicker from "vuejs-datepicker";

export default {
  name: "FormInputDate",
  components: {
    Datepicker,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    value: [String, Date],
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
