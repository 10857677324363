<template>
  <wrapper-page>
    <h1>not found</h1>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";

export default {
  name: "NotFound",
  components: {
    WrapperPage,
  },
};
</script>
