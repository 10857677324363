<template>
  <div>
    <form @submit.prevent="getExpiringCertificates">
      <div
        v-if="!isLoadingCertificateTypes && certificateTypes?.options?.length"
        class="row"
      >
        <div class="col-md-6">
          <multi-select-dropdown
            v-model="selectedCertificates"
            :close-on-select="true"
            :field="certificateTypes"
            :multiple="true"
          />
        </div>
        <div class="col-md-6">
          <form-input-text v-model="numberOfDays" :field="textInputField" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary" type="submit">Search</button>
        </div>
      </div>
    </form>

    <div v-if="searchResult" class="card mt-3 mb-0">
      <div class="header">
        <button
          v-if="resultsLevel > 0"
          class="btn btn-secondary"
          type="button"
          @click="resultsLevel--"
        >
          Back
        </button>
      </div>
      <div class="mt-2">
        <search-results-table
          v-model="searchResult"
          @getAllSelected="getAllSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { search } from "@/apis/search";
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import FormInputText from "../../ui/FormInputText.vue";
import { getDateRange } from "@/helpers/generic";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";

export default {
  name: "NumberOfVesselsExpiringInXDays",
  components: {
    MultiSelectDropdown,
    SearchResultsTable,
    FormInputText,
  },
  props: {},
  data() {
    return {
      searchResult: null,
      resultsLevel: 0,
      selectedCertificates: [],
      certificateTypesList: null,
      textInputField: {
        name: "certificate-select",
        type: "number",
        label: "Number of days",
      },
      numberOfDays: null,
      isLoadingCertificateTypes: false,
    };
  },
  mounted() {
    this.getCertificateTypes().then((resp) => {
      this.certificateTypesList = resp;
    });
  },
  methods: {
    async getAllSelected(value) {
      if (this.resultsLevel === 1) {
        const route = this.$router.resolve({
          name: "vessels-show",
          params: { vesselId: value[0] },
        });
        window.open(route.href, "_blank");
        return;
      } else if (this.resultsLevel !== 0) return;

      // show modal to display the vessels in this type
      const certificate = value[0];
      const vesselName = value[1];
      const filterVessel = vesselName
        ? [
            {
              operand: `${vesselName}`,
              operator: "=",
              property: "vessels.name",
            },
          ]
        : [];

      const filterCertificate = certificate
        ? [
            {
              operand: `${certificate}`,
              operator: "=",
              property: "vessels_certifications.certificate",
            },
          ]
        : [];

      let request = {
        show: [
          "vessels.id",
          "vessels.name",
          "vessels_certifications.certificate",
          "vessels.official_number",
          "vessels.s_reg",
          "vessels.call_sign",
          "vessels.imo_number",
          "vessels.mmsi_number",
          "vessels.registry",
          "vessels.flag",
          "vessels.former_registry",
          "vessels.other_service",
          "vessels.speed",
          "vessels.crew_accommodation",
          "vessels.passengers",
          "vessels.day_charter_passenger",
          "vessels.class",
          "vessels.class_notation",
          "vessels.class_maintained",
          "vessels.operational_range",
          "vessels.radio_range",
          "vessels.operational_restrictions",
          "vessels.base_port",
          "vessels.country",
          "vessels.service_status",
          "vessels.h_and_m",
        ],

        filter: [...filterCertificate, ...filterVessel],
      };

      const response = await search(request);

      this.searchResult = response.data;
      this.resultsLevel = 1;
    },
    async getExpiringCertificates() {
      const dateRange = getDateRange(this.numberOfDays);
      if (!dateRange) {
        return;
      }
      const operand = this.selectedCertificates
        ?.map((cert) => `'${cert.text}'`)
        .toString();

      let request = {
        show: [
          "vessels_certifications.certificate",
          "vessels.name",
          "vessels.id",
        ],
        filter: [
          ...(this.selectedCertificates?.length
            ? [
                // TODO filtering query
                {
                  operand: `${operand}`,
                  operator: "IN",
                  property: "vessels_certifications.certificate",
                },
              ]
            : []),
          {
            operand: dateRange.today,
            operand2: dateRange.endDay,
            operator: "between",
            property: "vessels_certifications.expiry_date",
          },
        ],
      };

      const response = await search(request);
      this.searchResult = response.data;
      this.resultsLevel = 0;
    },

    async getCertificateTypes() {
      this.isLoadingCertificateTypes = true;

      let request = {
        show: ["vessels_certifications.certificate"],
        filter: [],
      };

      const response = await search(request);
      const uniqueValues = [
        ...new Set(response?.data.map((item) => item.certificate)),
      ];
      const options = uniqueValues.map((cert) => {
        return {
          displayName: cert,
          value: { id: cert },
        };
      });

      this.isLoadingCertificateTypes = false;
      return options;
    },

    options(columns) {
      if (!columns) {
        return [];
      }
      return columns
        .map((col) => {
          return { text: col.displayName, value: col.value };
        })
        .filter((item) => item); // console.log(options);
    },
  },
  watch: {
    resultsLevel(newResultsLevel) {
      if (newResultsLevel === 0) this.getExpiringCertificates();
    },
  },
  computed: {
    certificateTypes() {
      const options = this.options(this.certificateTypesList);
      return {
        label: "Certificate Types",
        placeholder: "Select Certificate Types",
        name: "certificate-select",
        options,
      };
    },
  },
};
</script>
