<template>
  <div class="card-body">
    <div class="row">
      <div class="col-12 flex-grow-1 d-flex flex-column">
        <documents-list
          :documents="certificationDocuments"
          title="Download Certificates"
        />
      </div>
      <div class="col-12 d-flex justify-content-end mt-3">
        <button class="btn btn-danger" type="button" @click="close">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import DocumentsList from "../../../ui/DocumentsList.vue";

export default {
  name: "CertificationDownloaderModal",
  components: {
    DocumentsList,
  },
  props: {
    certification: {
      type: Object,
    },
    certificationDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      certification_: null,
      uploadField: {
        label: "Certification Documents",
        name: "cert_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload certification document/s",
      },
    };
  },
  validations: {
    certification_: {
      certificate: { required },
      issue_date: { Date },
      expiry_date: { Date },
      last_endorsed: { Date },
      due_open: { Date },
      due_close: { Date },
      remarks: {},
      issued_by: {},
      certificates_to_add: {},
      change: {},
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("CertificationDownloaderModal", state);
    },
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    prepareInternalCertification(certification) {
      const emptyCertification = { certificate: null, issued_by: null };
      this.certification_ = { ...emptyCertification, ...certification };
      if (this.certification_.issue_date)
        this.certification_.issue_date = moment(
          this.certification_.issue_date
        ).format("YYYY-MM-DD");
      if (this.certification_.expiry_date)
        this.certification_.expiry_date = moment(
          this.certification_.expiry_date
        ).format("YYYY-MM-DD");
      if (this.certification_.last_endorsed)
        this.certification_.last_endorsed = moment(
          this.certification_.last_endorsed
        ).format("YYYY-MM-DD");
      if (this.certification_.due_open)
        this.certification_.due_open = moment(
          this.certification_.due_open
        ).format("YYYY-MM-DD");
      if (this.certification_.due_close)
        this.certification_.due_close = moment(
          this.certification_.due_close
        ).format("YYYY-MM-DD");
    },
  },
  watch: {
    certification(newCertification) {
      this.prepareInternalCertification(newCertification);
    },
  },
  created() {
    this.prepareInternalCertification(this.certification);
  },
};
</script>
