<template>
  <wrapper-page page-title="Clients Dashboard">
    <ClientKpis
      v-if="!loadingKpis && clientDashboardKpis"
      :clientKpis="clientDashboardKpis"
    />
    <ClientsInCountries
      v-if="!loadingKpis && clientDashboardKpis"
      :clientKpis="clientDashboardKpis"
      class="mt-3"
    />
    <div v-if="!loadingKpis && clientDashboardKpis" class="row">
      <chart-card
        :value="clientCountriesChartData"
        label="Number of clients by countries"
        size="col-lg-6 col-md-12"
      ></chart-card>
    </div>
  </wrapper-page>
</template>

<script>
import { getClientDashboardKpis } from "@/apis/kpis";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import ClientsInCountries from "../../components/clients/ClientsInCountries.vue";
import ClientKpis from "../../components/clients/ClientKpis.vue";
import { updateCountryNamesInArray } from "../../helpers/generic";
import ChartCard from "@/components/ui/ChartCard.vue";

export default {
  name: "ClientsDashboard",
  components: {
    ChartCard,
    WrapperPage,
    ClientsInCountries,
    ClientKpis,
  },
  data() {
    return {
      loadingKpis: true,
      clientDashboardKpis: null,
    };
  },
  computed: {
    clientCountriesChartData() {
      if (!this.clientDashboardKpis?.countries) return [];

      //Get first five countries from the list. They are already ordered according to amount.
      const topFiveCountries = this.clientDashboardKpis?.countries?.slice(0, 5);

      // Get the sum of all clients in all countries. Check if this is the corretc way. Maybe companies+individuals should work too.
      const summCountries = this.clientDashboardKpis?.countries
        ?.map((country) => country?.amount)
        ?.reduce((accumulator, amount) => {
          if (amount) {
            return accumulator + amount;
          }
          return accumulator;
        }, 0);

      // Generate the metrics data.
      return topFiveCountries?.reduce((accumulator, country) => {
        if (!country?.amount || !country?.country) {
          return accumulator;
        }
        // Get the country client percentage according to all clients.
        const percentage = ((country?.amount / summCountries) * 100)?.toFixed(
          1
        );
        return [
          ...accumulator,
          // Chart data:
          {
            type: `${percentage}% ${country.country}`,
            number: country.amount,
          },
        ];
      }, []);
    },
  },
  methods: {
    sortCountriesBycustomers(a, b) {
      if (!a || !b) {
        return;
      }
      if (a.amount > b.amount) {
        return -1;
      }
      if (a.amount < b.amount) {
        return 1;
      }
      // a must be equal to b
      return 0;
    },
    async fetchClientDashboardKpis() {
      this.loadingKpis = true;
      try {
        const response = await getClientDashboardKpis();

        const countries = updateCountryNamesInArray(
          response.data.clientsCountries
            .filter((country) => country.country) // Eliminate records without name.
            .sort(this.sortCountriesBycustomers) // Sort the array by amount
        );

        this.clientDashboardKpis = { ...response.data, countries };
      } finally {
        this.loadingKpis = false;
      }
    },
  },
  mounted() {
    this.fetchClientDashboardKpis();
  },
};
</script>
