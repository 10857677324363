export const StatusOptions = [
  {
    text: "In Progress",
    value: "in_progress",
    class: "text-warning",
  },
  {
    text: "Closed",
    value: "closed",
    class: "text-danger",
  },
  {
    text: "On Hold",
    value: "on_hold",
    class: "text-warning",
  },
  {
    text: "Cancelled",
    value: "cancelled",
    class: "text-danger",
  },
];

export const InvoicingStatusOptions = [
  {
    text: "To Invoice",
    value: "to_invoice",
    class: "text-warning",
  },
  {
    text: "RFP",
    value: "rfp",
    class: "text-danger",
  },
  {
    text: "Cash",
    value: "cash",
    class: "text-warning",
  },
  {
    text: "Paid",
    value: "paid",
    class: "text-danger",
  },
  {
    text: "No Fee",
    value: "no_fee",
    class: "text-warning",
  },
  {
    text: "Part Payment",
    value: "part_payment",
    class: "text-warning",
  },
  {
    text: "Cancelled",
    value: "cancelled",
    class: "text-danger",
  },
  {
    text: "To Pay",
    value: "to_pay",
    class: "text-warning",
  },
];

export const DepartmentOptions = [
  {
    text: "SS&CS",
    value: "SS&CS",
    categories: [
      { text: "S-NCV ", value: "S-NCV " },
      { text: "S-CVC", value: "S-CVC" },
      { text: "S-CY", value: "S-CY" },
      { text: "S-FLAG", value: "S-FLAG" },
      { text: "S-FISH", value: "S-FISH" },
      { text: "S-SSR", value: "S-SSR" },
      { text: "S-VLT", value: "S-VLT" },
      { text: "S", value: "S" },
    ],
  },
  {
    text: "ME&SS",
    value: "ME&SS",
    categories: [
      { text: "S", value: "S" },
      { text: "S-CARGO", value: "S-CARGO" },
      { text: "D-DDE", value: "D-DDE" },
      { text: "S-MECH", value: "S-MECH" },
    ],
  },
  {
    text: "D&NA",
    value: "D&NA",
    categories: [
      { text: "D-CI", value: "D-CI" },
      { text: "D-DDE", value: "D-DDE" },
      { text: "D-DS", value: "D-DS" },
    ],
  },
  {
    text: "RD&I",
    value: "RD&I",
    categories: [
      { text: "D-CI", value: "D-CI" },
      { text: "CFD", value: "CFD" },
      { text: "D-DDE", value: "D-DDE" },
      { text: "OTHER", value: "OTHER" },
    ],
  },
  {
    text: "Consultancy",
    value: "Consultancy",
    categories: [
      { text: "Regulatory", value: "Regulatory" },
      { text: "New Build / Refit", value: "New Build / Refit" },
      { text: "Projects", value: "Projects" },
      { text: "Management", value: "Management" },
    ],
  },
  {
    text: "Administration",
    value: "Administration",
    categories: [],
  },
];

export const PaymentMethodOptions = [
  {
    text: "Bank Transfer",
    value: "Bank Transfer",
  },
  {
    text: "Cash",
    value: "Cash",
  },
  {
    text: "Cheque",
    value: "Cheque",
  },
];
