<template>
  <div>
    <form
      @submit.prevent="getVesselsByMakeAndModel"
      @reset.prevent="resetDropdowns"
    >
      <div v-if="!isLoadingMachineryMakeAndModel" class="row">
        <div class="col-md-6">
          <multi-select-dropdown
            v-model="selectedMachineryMake"
            :close-on-select="true"
            :field="machineryMakeList"
            :multiple="false"
          />
        </div>

        <div class="col-md-6">
          <multi-select-dropdown
            v-model="selectedMachineryModel"
            :close-on-select="true"
            :field="machineryModelList"
            :multiple="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex gap-x-3">
          <button class="btn btn-primary" type="submit">Search</button>
          <button class="btn btn-danger" type="reset">Reset</button>
        </div>
      </div>
    </form>

    <search-results-table
      v-if="searchResult"
      v-model="searchResult"
      class="mt-4"
      @getAllSelected="getAllSelected"
    />
  </div>
</template>

<script>
import { search } from "@/apis/search";
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import { quickSearchBtnNames as btnNames } from "../../../constants";
import { getVesselMachineryMakeModels } from "@/apis/vessels";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";
import { getMachineryMakers } from "@/apis/machineryMakers";

export default {
  name: "VesselsByMachineryMakeModel",
  components: {
    MultiSelectDropdown,
    SearchResultsTable,
  },
  props: {},
  data() {
    return {
      btnNames,
      searchResult: null,
      makeModelList: [],
      machineryMakeList: {
        label: "Select Make",
        placeholder: "Select Make",
        options: [],
      },
      machineryModelList: {
        label: "Select Model",
        placeholder: "Select Model",
        options: [],
      },
      selectedMachineryMake: null,
      selectedMachineryModel: null,
      isLoadingMachineryMakeAndModel: false,
    };
  },
  mounted() {
    this.getMachineryMakeAndModelList();
  },
  watch: {
    selectedMachineryMake() {
      const selectableModels = this.makeModelList
        ?.map((item) => {
          if (item.make === this.selectedMachineryMake?.value) {
            return item;
          }
        })
        .filter((item) => item);

      const uniqueModelList = [
        ...new Set(selectableModels?.map((item) => item.model)),
      ];
      const modelOptions = uniqueModelList
        .filter((model) => model)
        .map((model) => {
          return {
            text: model,
            value: model,
          };
        });

      this.machineryModelList = {
        placeholder: "Select Model",
        name: "machineryModel",
        label: "Select Model",
        options: modelOptions,
        sortOptions: true,
      };
    },

    selectedMachineryModel() {
      const selectableMakes = this.makeModelList
        ?.map((item) => {
          if (item.model === this.selectedMachineryModel?.value) {
            return item;
          }
        })
        .filter((item) => item);

      const uniqueMakelList = [
        ...new Set(selectableMakes?.map((item) => item.make)),
      ];
      const makeOptions = uniqueMakelList
        .filter((make) => make)
        .map((make) => {
          return {
            text: make,
            value: make,
          };
        });

      this.machineryMakeList = {
        placeholder: "Select Make",
        label: "Select Make",
        name: "machineryMake",
        options: makeOptions,
        sortOptions: true,
      };
    },
  },
  methods: {
    resetDropdowns() {
      this.selectedMachineryMake = null;
      this.selectedMachineryModel = null;
      this.getMachineryMakeAndModelList();
    },
    async getVesselsByMakeAndModel() {
      const filterMake = this.selectedMachineryMake
        ? [
            {
              operand: this.selectedMachineryMake?.value,
              operator: "=",
              property: "vessels_machinery.make",
            },
          ]
        : [];
      const filterModel = this.selectedMachineryModel
        ? [
            {
              operand: this.selectedMachineryModel?.value,
              operator: "=",
              property: "vessels_machinery.model",
            },
          ]
        : [];

      let request = {
        distinct: [
          "vessels.id",
          "vessels.name",
          "vessels_machinery.make",
          "vessels_machinery.model",
          "vessels_machinery.power",
          "vessels_machinery.year",
          "vessels_machinery.number_of_cylinders",
          "vessels_machinery.fuel",
        ],

        filter: [...filterMake, ...filterModel],
      };

      const response = await search(request);
      this.searchResult = response.data;
    },
    async getMachineryMakeAndModelList() {
      this.isLoadingMachineryMakeAndModel = true;

      // Get make list.
      const machineryMakes = await getMachineryMakers();
      const makeOptions = machineryMakes?.data.map((make) => {
        return {
          text: make?.name,
          value: make?.name,
        };
      });

      const machineryModels = await getVesselMachineryMakeModels();
      this.makeModelList = machineryModels?.data;
      // Get model list.
      const uniqueModelList = [
        ...new Set(machineryModels?.data.map((item) => item.model)),
      ];
      const modelOptions = uniqueModelList
        .filter((model) => model && model !== "Other")
        .map((model) => {
          return {
            text: model,
            value: model,
          };
        });

      // Set state values
      this.machineryMakeList = {
        placeholder: "Select Make",
        label: "Select Make",
        name: "machineryMake",
        options: makeOptions,
        sortOptions: true,
      };
      this.machineryModelList = {
        placeholder: "Select Model",
        label: "Select Model",
        name: "machineryModel",
        options: modelOptions,
        sortOptions: true,
      };

      this.isLoadingMachineryMakeAndModel = false;
    },
    async getAllSelected(value, clickedIndex) {
      let routeDetails = {};

      if (clickedIndex >= 2) {
        routeDetails = {
          name: "vessels-edit",
          params: { vesselId: value[0] },
          query: { tab: "Machinery" },
        };
      } else {
        routeDetails = {
          name: "vessels-show",
          params: { vesselId: value[0] },
        };
      }

      const route = this.$router.resolve(routeDetails);
      window.open(route.href, "_blank");
      return;
    },
  },
  computed: {},
};
</script>

<style></style>
