<template>
  <Bar :data="data" :options="options" />
</template>

<script lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "BarChartComponent",
  components: {
    Bar,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
