<template>
  <div class="row">
    <div
      v-for="project in projects"
      :key="project.id"
      class="col-lg-3 col-md-6 col-sm-12 d-flex flex-col"
    >
      <div class="card flex-grow-1">
        <router-link
          class="h-100"
          :to="{ name: 'projects-show', params: { projectId: project.id } }"
        >
          <div
            class="card-body h-100 cursor-pointer d-flex justify-content-between flex-wrap"
          >
            <div class="flex-grow-1">
              <h4 class="card-title">{{ project.name }}</h4>
              <h6 class="card-subtitle text-muted fs-6">
                {{ project.project_number }}
              </h6>
            </div>
            <small
              v-if="project.status"
              style="flex: 1 1 180px"
              :class="`pt-2 mt-auto ${statusClass(project.status)}`"
            >
              {{ statusLabel(project.status) }}
            </small>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusClass, getStatusLabel } from "@/helpers/projects";

export default {
  name: "ProjectsGrid",
  components: {},
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    statusLabel(value) {
      return getStatusLabel(value);
    },
    statusClass(value) {
      return getStatusClass(value);
    },
  },
};
</script>

<script setup></script>
