import {
  DepartmentOptions,
  InvoicingStatusOptions,
  PaymentMethodOptions,
  StatusOptions,
} from "./projects";

export const ProjectFields = [
  { label: "Project Number", name: "project_number", readonly: true },
  { label: "Project Name", name: "name" },
  {
    label: "Department",
    name: "department",
    type: "select",
    options: DepartmentOptions,
  },
  { label: "Category", name: "category", type: "select", options: [] },
  { label: "Status", name: "status", type: "select", options: StatusOptions },
  { label: "Date Assigned", name: "date_assigned", type: "date" },
  {
    label: "Target Completion Date",
    name: "target_completion_date",
    type: "date",
  },
  { label: "Date Closed", name: "date_closed", type: "date" },
  { label: "Client", name: "client_id", type: "select" },
  { label: "Invoicing Client", name: "invoicing_client_id", type: "select" },
  {
    label: "Invoicing Status",
    name: "invoicing_status",
    type: "select",
    options: InvoicingStatusOptions,
  },
  { label: "Comments", name: "comments", type: "textarea" },
  {
    label: "Responsibles",
    name: "responsibles",
    type: "multiselect",
    options: [],
  },
];

export const ProjectInvoiceFields = [
  { label: "Inv. Number", name: "invoice_number" },
  { label: "Net Amount", name: "net_amount", type: "number" },
  { label: "Disbursements", name: "disbursements", type: "number" },
  { label: "VAT", name: "tax_amount", type: "number" },
  { label: "Gross", name: "gross_amount", type: "number" },
  { label: "Credit Note", name: "credit_note_amount", type: "number" },
  { label: "Invoice Date", name: "invoice_date", type: "date" },
  { label: "Due Date", name: "due_date", type: "date" },
  { label: "Paid On", name: "paid_on", type: "date" },
  { label: "Notes", name: "notes", type: "textarea", rows: 3 },
  {
    label: "Payment Method",
    name: "payment_method",
    type: "select",
    options: PaymentMethodOptions,
  },
  { label: "Cheque No", name: "cheque_number" },
];

export const ProjectContactFields = [
  { label: "Role", name: "type" },
  { label: "Name", name: "name" },
  { label: "Phone", name: "phone" },
  { label: "Email", name: "email", type: "email" },
];

export const ExcludedFieldsFromOverride = ["Vessel Type"];
