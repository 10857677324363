<template>
  <LineChart :data="data" :options="options" />
</template>

<script lang="ts">
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "LineChartComponent",
  components: {
    LineChart,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
