const config = require("./config.json");
const { doubleDashToNull } = require("@/helpers/generic");
const { get, create, update, remove } = require("./common").default;

const projectsUrl = config.apis.baseUrl.url + "projects/";

/**
 * Retrieve all projects
 * @param {boolean?} active Whether to fetch, active, non-active or all (if null)
 * @param {object} filters
 * @returns [Promise/Axios] Array of Project objects
 */
exports.getProjects = async (
  active,
  { filters = [], matchAny = false } = {}
) => {
  let params = [];
  if (active !== null && active !== undefined) {
    params.push("status=" + (active ? "active" : "inactive"));
  }
  if (Array.isArray(filters) && filters.length > 0) {
    params = [...params, ...filters];
    if (matchAny) params.push("matchAny=1");
  }

  return await get(projectsUrl + "?" + params.join("&"));
};

/**
 * Retrieve all projects of a user
 * @param {number} userId To fetch the user's projects
 * @param {boolean?} inProgress Whether to fetch, in progress, not, or all (if null)
 * @param {object} filters
 * @returns [Promise/Axios] Array of Project objects
 */
exports.projectsByUserId = async (
  byUser,
  userId,
  inProgress,
  { filters = [], matchAny = false } = {}
) => {
  let params = [`user_id=${userId}`];
  if (inProgress !== null && inProgress !== undefined) {
    params.push("in_progress=" + (inProgress ? "true" : "false"));
  }
  if (Array.isArray(filters) && filters.length > 0) {
    params = [...params, ...filters];
    if (matchAny) params.push("matchAny=1");
  }

  return await get(projectsUrl + "user/" + byUser + "?" + params.join("&"));
};

/**
 * Retrieve a project with a specific ID
 * @param {number} id ID of the project to retrieve
 * @returns Promise with an Axios response
 */
exports.getProject = async (id) => {
  return await get(`${projectsUrl}${id}`);
};

/**
 * Create a new project
 * @param {object} data Details of the project
 * @returns Promise with an Axios response
 */
exports.createProject = async (data) => {
  data = doubleDashToNull(data);

  if (data.contacts && data.contacts.constructor === Array) {
    for (let i = 0; i < data.contacts.length; i++)
      data.contacts[i] = doubleDashToNull(data.contacts[i]);
  }

  return await create(projectsUrl, data);
};

/**
 * Updates a project with a specific ID
 * @param {number} id ID of the project to update
 * @param {object} data Details of the project
 * @returns Promise with an Axios response
 */
exports.updateProject = async (id, data) => {
  data = doubleDashToNull(data);

  if (data.contacts && data.contacts.constructor === Array) {
    for (let i = 0; i < data.contacts.length; i++)
      data.contacts[i] = doubleDashToNull(data.contacts[i]);
  }

  return await update(`${projectsUrl}${id}`, data);
};

/**
 * Deletes a project with a specific ID
 * @param {number} id ID of the project to delete
 * @returns Promise with an Axios response
 */
exports.deleteProject = async (id) => {
  return await remove(`${projectsUrl}${id}`);
};

/**
 * Retrieve all Jobs that can be assigned to Projects
 * @returns Promise with an Axios response
 */
exports.getJobs = async () => {
  return await get(`${projectsUrl}lookups/jobs`);
};

/**
 * Create a new Job type (for projects)
 * @param {object} data Details of the job
 * @returns Promise with an Axios response
 */
exports.createProjectJob = async (data) => {
  return await create(`${projectsUrl}lookups/jobs`, data);
};

/**
 * Retrieve vw_project_invoices_report table from BE
 * @returns Promise with an Axios response
 */
exports.getInvoicesReport = async () => {
  return await get(`${projectsUrl}invoices/report`);
};
