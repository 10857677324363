<template>
  <div class="row">
    <!-- Search bar -->
    <div v-if="showSearchBar" class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Project Number"
                  type="text"
                  @input="searchField('project_number', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Name"
                  type="text"
                  @input="searchField('name', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Department"
                  type="text"
                  @input="searchField('department', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6 ms-auto">
              <a
                class="btn btn-sm btn-primary btn-block"
                href="javascript:void(0);"
                title=""
                >Search</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body table-responsive">
          <table
            class="table table-hover table-custom dataTable projects-js-data-table"
          >
            <thead>
              <tr>
                <th>Project Number</th>
                <th>Name</th>
                <th>Department</th>
                <th>Status</th>
                <th>Start Date</th>
                <th>Target Completion</th>
                <th>Responsibles</th>
                <th>Invoicing Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(project, index) in filteredProjects()"
                :key="index"
                class="cursor-pointer"
                @click="openProjectDetails(project)"
              >
                <td>{{ project.project_number }}</td>
                <td>
                  {{ project.name }}
                </td>
                <td>
                  {{ project.department }}
                </td>
                <td>{{ statusLabel(project.status) }}</td>
                <td>
                  {{
                    project.date_assigned
                      ? moment(project.date_assigned).format("DD-MM-YYYY")
                      : "--"
                  }}
                </td>
                <td>
                  {{
                    project.target_completion_date
                      ? moment(project.target_completion_date).format(
                          "DD-MM-YYYY"
                        )
                      : "--"
                  }}
                </td>
                <td>{{ responsiblesCellText(project) }}</td>
                <td>{{ project.invoicing_status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusLabel } from "@/helpers/projects";
import moment from "moment";

export default {
  name: "ProjectsTable",
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    showSearchBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      fieldFilters: [],
    };
  },
  methods: {
    statusLabel(value) {
      return getStatusLabel(value);
    },
    responsiblesCellText(project) {
      return (project.responsibles || "").replace(",", ", ");
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },
    openProjectDetails(project) {
      let resolvedRoute = this.$router.resolve({
        name: "projects-show",
        params: { projectId: project.id },
      });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    },
    filteredProjects: function () {
      let result = [...this.projects];
      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v?.[element.field]
              ?.toLowerCase()
              ?.includes(element.searchString.toLowerCase())
          );
        }
      });
      return result;
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".projects-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  watch: {
    projects() {
      this.buildDataTable();
    },
  },
};
</script>
