<template>
  <Pie :data="data" :options="options" />
</template>

<script lang="ts">
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "PieChartComponent",
  components: {
    Pie,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
