<template>
  <div class="row clearfix">
    <multi-select-dropdown
      v-model="selectedColumn"
      :close-on-select="true"
      :field="columnList"
      :multiple="false"
      :showLabels="false"
      class="col-lg-5 col-md-4 col-sm-4"
    />
    <multi-select-dropdown
      v-model="operator"
      :close-on-select="true"
      :field="operatorsField"
      :multiple="false"
      :showLabels="false"
      class="col-lg-1 col-md-2 col-sm-2 pl-0 pr-0"
    />
    <!-- Different inputs for different field types and operators -->
    <template v-if="operator?.value?.endsWith('between')">
      <!-- 2 fields for '[not] between'. Can be numbers or dates -->
      <form-input-text
        v-if="field && field.type !== 'date'"
        v-model="modelField"
        :field="field"
        class="col-3"
      />
      <form-input-date
        v-if="field && field.type === 'date'"
        v-model="modelField"
        :field="field"
        class="col-3"
      />
      <form-input-text
        v-if="field && field.type !== 'date'"
        v-model="modelField2"
        :field="field"
        class="col-3"
      >
        <template slot="input-group-prepend"
          ><span class="input-group-text text-white bg-secondary"
            >and</span
          ></template
        >
      </form-input-text>
      <form-input-date
        v-if="field && field.type === 'date'"
        v-model="modelField2"
        :field="field"
        class="col-3"
      >
        <template slot="input-group-prepend"
          ><span class="input-group-text text-white bg-secondary"
            >and</span
          ></template
        >
      </form-input-date>
    </template>
    <template v-else-if="operator?.value?.endsWith('in')">
      <multi-select-dropdown
        v-if="field"
        v-model="modelField"
        :field="field"
        class="col-6"
      />
    </template>
    <template v-else>
      <form-input-select
        v-if="
          field && (field.type === 'select' || field.type === 'multiselect')
        "
        v-model="modelField"
        :field="field"
        class="col-6"
      />
      <form-input-check
        v-else-if="field && field.type === 'check'"
        v-model="modelField"
        :field="field"
        class="col-6"
      />
      <form-input-text
        v-else-if="field"
        v-model="modelField"
        :field="field"
        class="col-6"
      />
    </template>
  </div>
</template>

<script>
const operatorValues = [
  "=",
  "<>",
  ">",
  "<",
  ">=",
  "<=",
  "between",
  "not between",
  "in",
  "not in",
  "like",
  "not like",
];

import FormInputSelect from "../ui/FormInputSelect.vue";
import FormInputText from "../ui/FormInputText.vue";
import FormInputDate from "../ui/FormInputText.vue";
import FormInputCheck from "../ui/FormInputCheck.vue";
import MultiSelectDropdown from "../ui/MultiSelectDropdown.vue";
import { getAllColumns } from "@/apis/search";

export default {
  name: "SimpleFilterCriterion",
  components: {
    FormInputSelect,
    FormInputText,
    FormInputDate,
    FormInputCheck,
    MultiSelectDropdown,
  },
  data() {
    return {
      operator: { value: "=", text: "=" },
      modelField: "",
      modelField2: null,
      columns: [],
      selectedColumn: null,
    };
  },
  computed: {
    field() {
      const split = this.selectedColumn?.value?.toString()?.split(".");

      let column;
      if (split) {
        column = this.columns.find((column) => {
          if (
            column.value.tableName === split[0] &&
            column.value.columnName === split[1]
          ) {
            return column;
          }
        });
      }

      return {
        type: column?.type || "text",
        readonly: false,
        includeOther: false,
      };
    },

    columnList() {
      return {
        name: "field_property_groups",
        type: "select",
        options: this.columns.map((col) => ({
          text: col.displayName,
          value: `${col.value.tableName}.${col.value.columnName}`,
          type: col.type,
        })),
      };
    },

    operatorsField() {
      return {
        name: "field_operator",
        type: "select",
        options: operatorValues.map((f) => ({ text: f, value: f })),
      };
    },
  },
  methods: {
    getAsJson() {
      let obj = {
        property: this.selectedColumn?.value,
        operator: this.operator?.value,
        operand: this.modelField,
      };
      if (this.operator?.value?.endsWith("between")) {
        obj.operand2 = this.modelField2;
      }
      if (
        this.operator?.value?.endsWith("in" && Array.isArray(this.modelField))
      ) {
        obj.operand = this.modelField.map((val) => `'${val.value}'`);
      }
      return obj;
    },
  },
  mounted() {
    getAllColumns().then((resp) => {
      this.columns = resp?.result;
      this.selectedColumn = this.columnList?.options?.[0];
    });
  },
  watch: {
    operator(newOperator, oldOperator) {
      // If we're switching between a single value and multiselect,
      // then we need to convert the value to/from an array
      let oldModel = this.modelField;
      if (!oldModel) {
        //this.getDefaultValue();
        return;
      }
      if (
        newOperator?.value?.endsWith("in") &&
        !oldOperator?.value?.endsWith("in")
      ) {
        // Convert value to array
        this.modelField = [
          this.field.options.find((opt) => opt.value === oldModel),
        ];
      }
      if (
        !newOperator?.value?.endsWith("in") &&
        oldOperator?.value?.endsWith("in")
      ) {
        // Get first value from array
        this.modelField = oldModel?.[0].value;
      }
    },
    value() {
      const val = this.value?.default_value;
      if (!val) return;
      this.fieldName = val.property;
      this.operator = val.operator || { value: "=", text: "=" };
      this.modelField = val.operand;
      this.modelField2 = val.operand2;
    },
  },
};
</script>
