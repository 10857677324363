<template>
  <wrapper-page ref="wrapper">
    <div class="">UNDER CONSTRUCTION PUBLICATIONS</div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "@/components/layout/WrapperPage.vue";

export default {
  name: "ResourcesPublications",
  components: { WrapperPage },
  data() {
    return {};
  },
  methods: {},
};
</script>
