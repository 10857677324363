<template>
  <div :class="`${size}`">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <a :href="url" target="_blank">
              <h5 class="card-title">{{ label }}</h5>
            </a>
          </div>

          <div class="flex-shrink-0">
            <ul class="header-dropdown dropdown ps-2">
              <li class="d-flex" style="margin-top: 3px">
                <a
                  v-if="showChartTypes.includes('pie') && chartType !== 'pie'"
                  href="javascript:void(0);"
                  @click="toggleChart('pie')"
                  class="px-1"
                >
                  <i class="ri-pie-chart-line" />
                </a>
                <a
                  v-if="showChartTypes.includes('bar') && chartType !== 'bar'"
                  href="javascript:void(0);"
                  @click="toggleChart('bar')"
                  class="px-1"
                >
                  <i class="ri-bar-chart-2-line" />
                </a>
                <a
                  v-if="showChartTypes.includes('line') && chartType !== 'line'"
                  href="javascript:void(0);"
                  @click="toggleChart('line')"
                  class="px-1"
                >
                  <i class="ri-line-chart-line" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-2F">
          <search-results-pie-chart
            v-if="chartType === 'pie'"
            v-model="value"
            :datasets="datasets"
            :options="options"
          />
          <search-results-bar-chart
            v-if="chartType === 'bar'"
            v-model="value"
            :data-label="label"
            :datasets="datasets"
            :options="options"
          />
          <search-results-line-chart
            v-if="chartType === 'line'"
            v-model="value"
            :data-label="label"
            :datasets="datasets"
            :options="options"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultsPieChart from "@/pages/search/SearchResultsPieChart.vue";
import SearchResultsBarChart from "@/pages/search/SearchResultsBarChart.vue";
import SearchResultsLineChart from "@/pages/search/SearchResultsLineChart.vue";

export default {
  name: "ChartCard",
  components: {
    SearchResultsLineChart,
    SearchResultsBarChart,
    SearchResultsPieChart,
  },
  props: {
    label: String,
    value: [Array],
    datasets: {
      type: Array,
      required: false,
    },
    url: String,
    size: {
      type: String,
      default: "col-lg-3 col-md-6",
    },
    showChartTypes: {
      type: Array,
      default: () => ["pie", "bar"],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartType: null,
    };
  },
  methods: {
    toggleChart(newType) {
      this.chartType = newType;
    },
  },

  mounted() {
    this.chartType = this.showChartTypes[0];
  },
};
</script>
