<template>
  <ParticularsSection
    v-if="surveys.length"
    :with-narrow-columns="true"
    title="Valuations"
  >
    <div v-for="({ key, cells }, i) in surveys" :key="key" class="survey">
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :class="cell.key === 'date' && 'col-start-1'"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </div>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";
import moment from "moment";

export default {
  name: "VesselParticularsValuation",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valuationCells() {
      // sort valuations by latest date
      const valuations = [...this.vessel.valuations].sort((a, b) => {
        if (moment(a.date).isBefore(moment(b.date))) return 1;
        else if (moment(a.date).isAfter(moment(b.date))) return -1;
        else return 0;
      });

      return extractCells(
        valuations,
        [
          {
            label: "Date",
            key: "date",
            format: "date",
          },
          {
            label: "Auxiliary Engine",
            key: "auxiliary_engine",
            format: "currency",
          },
          { label: "Equipment", key: "equipment", format: "currency" },
          { label: "Hull", key: "hull", format: "currency" },
          { label: "Machinery", key: "machinery", format: "currency" },
          { label: "Tender", key: "tender", format: "currency" },
          { label: "Trailer", key: "trailer", format: "currency" },
          { label: "Total", key: "total", format: "currency" },
        ],
        this.excludeEmptyFields
      );
    },
    surveys() {
      return [
        ...this.valuationCells.map((cells, i) => ({
          key: `valuation-${i}`,
          cells,
        })),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  display: contents;

  &:not(:last-child) {
    div:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
