<template>
  <div class="row flex-grow-1 d-flex">
    <!-- All but the Notes field -->
    <div
      v-for="field in fields.slice(0, 9)"
      :key="`${field.name}`"
      class="col-lg-2 col-md-2"
    >
      <form-input-text
        v-model="$v.invoice[field.name].$model"
        :error="$v.invoice[field.name].$error"
        :error-message="validationMessage($v.invoice[field.name])"
        :field="field"
      >
        <template
          v-if="field.name === 'gross_amount'"
          slot="input-group-prepend"
        >
          <button
            class="mt-auto mb-auto btn btn-secondary"
            title="Calculate Gross Amount"
            type="button"
            @click="calculateGross()"
          >
            <span class="sr-only">Calculate Gross Amount</span>
            <i class="fa fa-cogs"></i>
          </button>
        </template>
      </form-input-text>
    </div>
    <!-- Payment method -->
    <div class="col-lg-2 col-md-2">
      <form-input-select
        v-model="invoice.payment_method"
        :error="$v.invoice.payment_method.$error"
        :error-message="validationMessage($v.invoice.payment_method)"
        :field="fields[10]"
      />
    </div>
    <!-- Optional Cheque number if Payment method is 'Cheque' -->
    <div class="col-lg-2 col-md-2">
      <form-input-text
        v-if="invoice.payment_method === 'Cheque'"
        v-model="invoice.cheque_number"
        :error="$v.invoice['cheque_number'].$error"
        :error-message="validationMessage($v.invoice['cheque_number'])"
        :field="fields[11]"
      />
    </div>
    <!-- Notes field -->
    <div class="col-lg-6 col-md-6">
      <form-input-text
        v-model="$v.invoice.notes.$model"
        :error="$v.invoice.notes.$error"
        :error-message="validationMessage($v.invoice.notes)"
        :field="fields[9]"
      />
    </div>
    <!-- Documents, Upload & List -->
    <div v-if="invoice?.project_id" class="col-lg-6 col-md-6">
      <form-input-file
        ref="formInputMultiFile"
        :field="uploadField()"
        :show-action-button="true"
        @action="uploadEventDocuments"
      />
    </div>
    <div v-if="invoiceDocuments?.length > 0" class="col-12">
      <documents-list
        :documents="invoiceDocuments"
        _title="Invoice documents"
      />
    </div>
  </div>
</template>

<script>
import { ProjectInvoiceFields } from "@/constants/project_fields";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../ui/FormInputText.vue";
import FormInputSelect from "../ui/FormInputSelect.vue";
import FormInputFile from "../ui/FormInputFile.vue";
import { uploadDocument, getDocuments } from "../../apis/files";
import { notifyError, notifySuccess } from "@/helpers/notification";
import DocumentsList from "../ui/DocumentsList.vue";

export default {
  name: "ProjectInvoiceEditor",
  components: { FormInputText, FormInputSelect, FormInputFile, DocumentsList },
  props: {
    invoice: Object,
    parrentIndex: String,
  },
  data() {
    return {
      invoiceDocuments: [],
      fields: ProjectInvoiceFields,
    };
  },

  validations: {
    invoice: {
      invoice_number: { required },
      invoice_date: { required },
      due_date: {},
      paid_on: {},
      net_amount: {},
      disbursements: {},
      credit_note_amount: {},
      gross_amount: {},
      tax_amount: {},
      notes: {},
      payment_method: {},
      cheque_number: {},
    },
  },
  methods: {
    uploadField() {
      return {
        label: "Invoice Documents",
        name: this.parrentIndex, // This is the uniq index of the invoice in the array from the parrent.
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload",
      };
    },
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    calculateGross() {
      this.invoice.gross_amount =
        Number(this.invoice.net_amount) +
        Number(this.invoice.tax_amount) +
        Number(this.invoice.disbursements);
    },
    async getInvoiceDocuments() {
      if (this.invoice.id) {
        try {
          const uri = `projects/${this.invoice?.project_id}/invoices/${this.invoice?.id}`;
          const downloadedDocs = await getDocuments(
            uri,
            this.invoice?.project_id
          );
          this.invoiceDocuments = downloadedDocs.data;
        } catch (err) {
          console.log(err);
          notifyError(this, err?.response?.data); //, "Document upload");
        }
      }
    },
    async uploadEventDocuments() {
      const fileControl = this.$refs?.formInputMultiFile;
      if (fileControl?.files?.length == 0) return; // no file selected, nothing to upload
      if (this.invoice.id) {
        try {
          const uri = `projects/${this.invoice?.project_id}/invoices/${this.invoice?.id}/files`;
          const promises = [];
          // Upload the documents (and update the database)
          for (let i = 0; i < fileControl.files.length; i++) {
            const file = fileControl.files[i];
            promises.push(
              uploadDocument(file, uri).then(() => {
                notifySuccess(this, "File(s) have been uploaded!");
                this.getInvoiceDocuments();
                this.$refs?.formInputMultiFile?.reset();
              })
            );
          }
          await Promise.all(promises);
        } catch (err) {
          console.log(err);
          notifyError(this, err?.response?.data); //, "Document upload");
        }
      }
    },
  },
  mounted() {
    this.getInvoiceDocuments();
  },
};
</script>
