<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex align-items-center">
        <!-- LOGO -->
        <div class="d-none d-lg-flex navbar-brand-box text-center">
          <template v-if="showLogo">
            <router-link class="logo logo-dark" to="/">
              <span class="logo-lg">
                <img
                  alt="logo-dark"
                  height="24"
                  src="/assets/images/icon.png"
                />
              </span>
            </router-link>
          </template>
          <h4 v-else style="margin-left: 0; text-align: left">Test NOE</h4>
        </div>

        <button
          id="vertical-menu-btn"
          class="btn btn-sm px-4 font-size-24 header-item waves-effect"
          type="button"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
        <div class="app-search py-0">
          <slot name="search"></slot>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block user-dropdown">
          <button
            v-if="user"
            id="page-header-user-dropdown"
            aria-expanded="false"
            aria-haspopup="true"
            class="btn header-item waves-effect d-flex align-items-center"
            data-bs-toggle="dropdown"
            type="button"
          >
            <div
              v-if="user"
              :data-original-title="user?.name"
              :title="user?.name"
              class="rounded-circle header-profile-user d-flex justify-content-center align-items-center"
              data-placement="top"
              data-toggle="tooltip"
            >
              <span>{{ user?.initials }}</span>
            </div>
            <span class="d-none d-xl-inline-block ms-1">{{ user?.name }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item text-danger" href="#" @click="logout"
              ><i class="ri-shut-down-line align-middle me-1 text-danger"></i>
              Logout</a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { store } from "@/main";

export default {
  name: "LeftSideBarNavigation",
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  computed: {
    user() {
      if (!store?.state?.users) return null;

      return store.state.users.find((u) => u.email === this.$auth.user.email);
    },
    showLogo() {
      return window.location.host.startsWith("noe.nas.com.mt");
    },
  },
};
</script>
