<template>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <h2 class="card-title">Generate Certificate</h2>
      </div>

      <div class="col-12 mb-3">
        <h3 class="card-title d-flex gap-x-2 align-items-center">
          <span>{{ vesselData.name }}</span>
          <a
            :href="`/vessels/${vesselData.id}`"
            target="_blank"
            title="View Vessel"
            ><i class="fa fa-eye"></i
          ></a>
        </h3>
      </div>
      <div class="col-12 col-sm-6">
        <multi-select-dropdown
          v-model="selectedCertificates"
          :field="certificateTypeDropdown"
        />
      </div>
      <div class="col-12 col-sm-6">
        <form-input-select
          v-if="projectNumberDropDown.type === 'select'"
          v-model="projectNumber.value"
          :field="projectNumberDropDown"
          :parent="projectNumber"
        />
      </div>

      <div class="col-12 d-flex justify-content-end mt-3 gap-x-3">
        <MultiTemplateDownloader
          v-if="selectedCertificates?.length > 0"
          :docTypes="selectedCertificates"
          :projectNumber="projectNumber.value"
          :vesselId="vesselData?.id"
          :projectsInvoicingClientList="projectsInvoicingClientList"
        >
          Generate selected certificate(s) and download
        </MultiTemplateDownloader>
        <button
          class="btn btn-warning"
          type="button"
          @click="onShowAllCertificates"
        >
          Show all certificates
        </button>
        <button class="btn btn-danger ms-4" type="button" @click="close">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import ReportComponent from "@/components/docxTemplater/index.vue";
import MultiTemplateDownloader from "@/components/docxTemplater/MultiTemplateDownloader.vue";
import { CertificateTypes } from "../../constants/certificates";
import { CreatePossibleCertificateTypes } from "../../helpers/certificates";
import FormInputSelect from "@/components/ui/FormInputSelect";
import { getVesselProjects } from "@/apis/vessels";
import MultiSelectDropdown from "../../components/ui/MultiSelectDropdown.vue";

export default {
  name: "CertificationDownloaderModal",
  components: {
    // ReportComponent,
    FormInputSelect,
    MultiSelectDropdown,
    MultiTemplateDownloader,
  },
  props: {
    vesselData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedCertificates: [],
      selectedType: null,
      projectsInvoicingClientList: [],
      projectNumber: {
        value: null,
      },
      certificateTypeDropdown: {
        name: "certificateTypeDropdown",
        type: "select",
        options: CreatePossibleCertificateTypes(this.vesselData) || [],
        placeholder: "Select certificate type",
      },
      projectNumberDropDown: {
        name: "value",
        type: "select",
        options: [],
        placeholder: "Select project number",
        includeOther: true,
      },
    };
  },
  methods: {
    close(state = null) {
      this.$modal.hide("certification-downloader-modal", state);
    },
    onShowAllCertificates() {
      this.certificateTypeDropdown.options = CertificateTypes;
      alert("All certificates are added to the menu.");
    },
  },
  mounted() {
    if (!this.vesselData) return;
    getVesselProjects(this.vesselData?.id).then((data) => {
      const projectNumberOptions = data.data.map((item) => {
        this.projectsInvoicingClientList.push({
          invoicingClientId: item.invoicing_client_id,
          projectNumber: item.project_number,
        });
        return {
          text: `${item.project_number} -- Client: ${item.client_name || ""}`,
          value: item.project_number,
        };
      });
      this.projectNumberDropDown.options = projectNumberOptions;
    });
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 450px;
}
</style>
