<template>
  <div class="table-responsive">
    <table
      v-if="data"
      class="table table-hover table-custom dataTable projects-js-data-table"
    >
      <thead>
        <tr>
          <th v-for="header in data[0]" :key="header">
            {{ toTitle(header) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in data.slice(1)"
          :key="`row-${index}`"
          class="cursor-pointer"
        >
          <td
            v-for="(cell, index) in row"
            :key="`cell-${index}`"
            @click="getAllSelected(row, index)"
          >
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <div v-for="(object, index) in data" :key="`row-${index}`"></div>
    </div>
  </div>
</template>

<script>
import { toTitle } from "@/helpers/generic";

const jsonToTable = require("json-to-table");

export default {
  name: "SearchResultsTable",
  props: {
    value: Array,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    toTitle,
    getAllSelected(value, clickedIndex) {
      this.$emit("getAllSelected", value, clickedIndex);
    },
    buildDataTable() {
      this.data = jsonToTable(this.value);
      if (this.table) this.table.destroy();
      setTimeout(() => {
        // Exportable options

        this.table = window.jsDataTable(".projects-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  mounted() {
    this.buildDataTable();
  },
  watch: {
    value() {
      this.buildDataTable();
    },
  },
};
</script>
