<template>
  <div class="col-12">
    <div v-if="hullInspection_" class="row flex-grow-1 d-flex">
      <div
        v-for="field in hullInspectionFields"
        :key="`${field.name}`"
        :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-2'"
        class="col-md-4"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.hullInspection_[field.name].$model"
          :error="$v.hullInspection_[field.name].$error"
          :error-message="validationMessage($v.hullInspection_[field.name])"
          :field="field"
        />
        <form-input-select
          v-else-if="field.type === 'select'"
          v-model="$v.hullInspection_[field.name].$model"
          :error="$v.hullInspection_[field.name].$error"
          :error-message="validationMessage($v.hullInspection_[field.name])"
          :field="field"
          :parent="$v.hullInspection_.$model"
        />
        <form-input-text
          v-else
          v-model="$v.hullInspection_[field.name].$model"
          :error="$v.hullInspection_[field.name].$error"
          :error-message="validationMessage($v.hullInspection_[field.name])"
          :field="field"
        />
      </div>
    </div>

    <!-- Documents, Upload & List -->
    <div v-if="this.hullInspection_ && this.hullInspection_.id" class="row">
      <div class="col-6">
        <form-input-file
          :field="uploadField"
          :show-action-button="true"
          @action="uploadHullInspectionDocuments"
        />
      </div>
      <div class="col-6">
        <documents-list
          :documents="hullInspectionDocuments"
          _title="hullInspection Documents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import { notifyError } from "@/helpers/notification";
import { uploadDocument } from "../../../../../apis/files";
import {
  HullInspectionActivityOptions,
  HullInspectionRequirementOptions,
} from "@/constants/vessels";
import FormInputText from "../../../../ui/FormInputText.vue";
import FormInputFile from "../../../../ui/FormInputFile.vue";
import FormInputDate from "../../../../ui/FormInputText.vue";
import DocumentsList from "../../../../ui/DocumentsList.vue";
import FormInputSelect from "../../../../ui/FormInputSelect.vue";

export default {
  name: "HullInspectionEditor",
  components: {
    FormInputText,
    FormInputFile,
    FormInputDate,
    DocumentsList,
    FormInputSelect,
  },
  props: {
    hullInspection: {
      type: Object,
    },
    hullInspectionDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hullInspection_: null,
      hullInspectionFields: [
        {
          label: "Activity",
          name: "activity",
          type: "select",
          options: HullInspectionActivityOptions,
        },
        {
          label: "Requirement",
          name: "requirement",
          type: "select",
          options: HullInspectionRequirementOptions,
        },
        { label: "Last Inspection", name: "date", type: "date" },
        { label: "Next Inspection", name: "nextDue", type: "date" },
        { label: "Comments", name: "comments", type: "textarea" },
      ],
      uploadField: {
        label: "Hull Inspection Documents",
        name: "hull_inspection_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload Hull Inspection document/s",
      },
    };
  },
  validations: {
    hullInspection_: {
      activity: { required },
      requirement: { required },
      date: { Date, required },
      nextDue: { Date },
      comments: {},
    },
  },
  methods: {
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    prepareInternalHullInspection(hullInspection) {
      const emptyHullInspection = {
        activity: null,
        requirement: null,
        date: null,
        nextDue: null,
        comments: null,
      };
      this.hullInspection_ = { ...emptyHullInspection, ...hullInspection };
      if (this.hullInspection_.date)
        this.hullInspection_.date = moment(this.hullInspection_.date).format(
          "YYYY-MM-DD"
        );
      if (this.hullInspection_.nextDue)
        this.hullInspection_.nextDue = moment(
          this.hullInspection_.nextDue
        ).format("YYYY-MM-DD");
    },
    async uploadHullInspectionDocuments(fileControl) {
      if (fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const uri = `vessels/${this.hullInspection_.vessel_id}/hull-inspections/${this.hullInspection_.id}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(
            uploadDocument(file, uri).then((response) =>
              this.hullInspectionDocuments.push(response.data.path)
            )
          );
        }
        await Promise.all(promises);
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
      }
    },
  },
  watch: {
    hullInspection(newHullInspection) {
      this.prepareInternalHullInspection(newHullInspection);
    },
  },
  created() {
    this.prepareInternalHullInspection(this.hullInspection);
  },
};
</script>
