<template>
  <wrapper-page ref="wrapper" @search="search">
    <template v-slot:search>
      <div class="d-flex align-items-center gap-x-2 position-relative">
        <SearchInput @search="search" :results="searchResult?.results ?? []" />
        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            type="button"
          >
            <i class="ri-settings-4-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-4">
            <div class="d-flex flex-column gap-y-2">
              <div
                v-for="field in searchProperties"
                :key="field.value"
                class="w-100 d-flex gap-x-2 align-items-center"
              >
                <input
                  type="checkbox"
                  v-model="searchFields[field.value]"
                  :name="`search-property-${field.value}`"
                />
                <label :for="`search-property-${field.value}`" class="mb-0">
                  {{ field.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <VesselsResourcesCertTable :vessels="vessels" />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "@/components/layout/WrapperPage.vue";
import { getVessels, getVesselsCertifications } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";
import VesselsResourcesCertTable from "../../components/resources/VesselsResourcesCertTable.vue";
import SearchInput from "@/components/SearchInput.vue";

const VesselSearchableProperties = [
  { text: "Vessel Name", value: "vessel_name" },
  { text: "Certificate", value: "certificate" },
  { text: "Issue Date", value: "issue_date" },
  { text: "Expiry Date", value: "expiry_date" },
  { text: "Last Endorsed", value: "last_endorsed" },
  { text: "Due Open", value: "due_open" },
  { text: "Due Close", value: "due_close" },
];

export default {
  name: "ResourcesCertificates",
  components: {
    SearchInput,
    WrapperPage,
    VesselsResourcesCertTable,
  },
  data() {
    return {
      vessels: [],
      searchFields: {},
      searchProperties: VesselSearchableProperties,
      searchResult: { results: [] },
    };
  },
  methods: {
    async search(searchString) {
      if (
        Object.keys(this.searchFields).filter((key) => this.searchFields[key])
          .length === 0
      ) {
        notifyError(this, "Choose 1 or more properties to search in");
        return;
      }

      let filters = Object.keys(this.searchFields)
        .filter((key) => this.searchFields[key])
        .map((f) => ({
          property: f,
          operator: "like",
          operand: `%${searchString}%`,
        }));

      const searchStartTime = new Date().getTime();
      await getVesselsCertifications({ filters, matchAny: 1 })
        .then(({ data }) => {
          var searchDuration = new Date().getTime() - searchStartTime;
          this.searchResult = {
            durationInSeconds: searchDuration / 1000,
            results: data.map((certification) => ({
              icon: {
                text: certification?.certificate,
                backgroundColour: "green",
              },
              title: certification.certificate,
              subtitle: certification.vessel_name,
              link: `/vessels/certificates/${certification.certification_id}`,
            })),
          };
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async loadVessels() {
      await getVessels({})
        .then(({ data }) => {
          this.vessels = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  async mounted() {
    this.loadVessels();
  },
};
</script>
