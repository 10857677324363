<template>
  <div class="row">
    <div
      v-for="client in clients"
      :key="client.id"
      class="col-lg-3 col-md-6 col-sm-12 d-flex flex-col"
    >
      <div class="card flex-grow-1">
        <router-link
          :to="{ name: 'clients-show', params: { clientId: client.id } }"
        >
          <div class="card-body cursor-pointer">
            <h4 class="card-title">{{ client.name }}</h4>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientsGrid",
  components: {},
  props: {
    clients: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
