<template>
  <div class="row mt-4">
    <div class="col-12 d-flex justify-content-start align-items-center mb-3">
      <div class="page-title-box pb-0">
        <h4 class="mb-0">Contacts</h4>
      </div>
      <button
        class="ms-2 btn btn-sm btn-primary"
        title="Add Contact"
        type="button"
        @click="addContact"
      >
        <span class="sr-only">Add Contact</span>
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-sm btn-primary" @click="saveContacts">
                Save Contacts
              </button>
            </div>
          </div>
          <form>
            <div
              v-for="(contact, i) in $v.contacts.$each.$iter"
              :key="`contact-${i}`"
              class="d-flex mb-2"
            >
              <div class="row flex-grow-1 d-flex">
                <div
                  v-for="field in contactFields"
                  :key="`${i}-${field.name}`"
                  class="col-lg-3 col-md-4"
                >
                  <form-input-text
                    v-model="$v.contacts.$each.$iter[i][field.name].$model"
                    :error="$v.contacts.$each.$iter[i][field.name].$error"
                    :error-message="
                      validationMessage($v.contacts.$each.$iter[i][field.name])
                    "
                    :field="field"
                    :readonly="contacts[i].id !== undefined"
                  />
                </div>
              </div>
              <div
                v-if="contacts.length !== 1"
                class="ms-4 flex-shrink-0 d-flex"
              >
                <button
                  style="margin-top: 29px"
                  class="mb-auto btn btn-danger"
                  title="Remove Contact"
                  type="button"
                  @click="removeContact(i)"
                >
                  <span class="sr-only">Remove Contact</span>
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createVesselBuildersContact,
  deleteVesselBuildersContact,
  getVesselBuildersContacts,
  updateVesselBuildersContact,
} from "@/apis/vessels";
import { email, required } from "vuelidate/lib/validators";
import FormInputText from "../../../components/ui/FormInputText.vue";
import { VesselBuilderContactFields } from "../../../constants/vessel_fields";
import { validationMessage } from "@/helpers/validations";
import { notifyChangesSaved, notifyError } from "@/helpers/notification";

export default {
  name: "VesselBuildersContacts",
  components: { FormInputText },
  data: function () {
    return {
      dataTable: null,
      contacts: [{ type: null, name: null, phone: null, email: null }],
      contactFields: VesselBuilderContactFields,
      contactsToDelete: [],
    };
  },

  async mounted() {
    this.getBuilderContacts();
  },

  validations: {
    contacts: {
      $each: {
        type: {},
        name: {
          required,
        },
        phone: {},
        email: {
          email,
        },
      },
    },
  },
  methods: {
    validationMessage,
    getBuilderContacts() {
      getVesselBuildersContacts().then((result) => {
        const builder = result?.data?.find(
          (builder) => builder?.id == this.$route.params.builderId
        );
        if (builder && builder.contacts) {
          this.contacts = [...builder.contacts];
        }
        this.$emit("saveDefaultContacts", this.contacts);
      });
    },

    async saveContacts() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        notifyError(this, "not all required fields have been entered");
        return;
      }

      const valami = this.contactsToDelete;
      const masik = this.contacts;
      console.log(valami, masik);
      // Delete unrequired conatcts.
      if (this.contactsToDelete.length > 0)
        for (const id of this.contactsToDelete) {
          await deleteVesselBuildersContact({
            builder_id: this.$route.params.builderId,
            contact_id: id,
          })
            .then(() => {
              notifyChangesSaved(this);
            })
            .catch((err) => {
              notifyError(this, err?.response?.data);
            });
        }

      // Update or create all contacts.
      for (const contact of this.contacts) {
        if (contact?.contact_id) {
          const dataToUpdate = {
            builder_id: this.$route.params.builderId,
            contacts: [
              {
                id: contact.contact_id,
                type: contact?.type,
                name: contact?.name,
                email: contact?.email,
                phone: contact?.phone,
              },
            ],
          };
          await updateVesselBuildersContact(dataToUpdate)
            .then(() => {
              notifyChangesSaved(this);
            })
            .catch((err) => {
              notifyError(this, err?.response?.data);
            });
          continue;
        }
        // If no id then we create the new contact.
        const data = {
          builder_id: this.$route.params.builderId,
          type: contact?.type,
          contact: {
            name: contact?.name,
            email: contact?.email,
            phone: contact?.phone,
          },
        };

        await createVesselBuildersContact(data)
          .then(() => {
            notifyChangesSaved(this);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
      this.getBuilderContacts();
    },
    addContact() {
      this.contacts.push({ type: null, name: null, phone: null, email: null });
      this.$emit("contactFormChanged", this.contacts);
    },
    removeContact(index) {
      if (this.contacts.length === 1) return;

      if (this.contacts[index]?.contact_id) {
        this.contactsToDelete.push(this.contacts[index]?.contact_id);
      }
      this.contacts.splice(index, 1);
      this.$emit("contactFormChanged", this.contacts);
    },
  },
  computed: {},
  watch: {
    contacts: {
      handler() {
        this.$emit("contactFormChanged", this.contacts);
      },
      deep: true,
    },
  },
};
</script>
