const config = require("./config.json");
const { get } = require("./common").default;

/**
 * Retrieve all users
 * @returns Promise with an Axios response
 */
exports.getUsers = async () => {
  return await get(config.apis.baseUrl.url + "users");
};
