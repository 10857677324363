export const CertificateTypeUrlTemplateMapper = {
  CoS: "/templates/templateCos.docx",
  Tonn: "/templates/templateCosUnder24.docx",
  BWM: "/templates/templateBallastWaterManagementConvention.docx",
  ITC: "/templates/templateITC.docx",
  AFSD: "/templates/templateAFS_ST.docx",
  AFS: "/templates/templateAFS.docx",
  IAPP: "/templates/templateIAPP.docx",
  MLC: "/templates/templateMLC_Voluntary.docx",
  ILLC: "/templates/templateILLC.docx",
  SAFRAD: "/templates/templateSAFRAD.docx",
  WOF: "/templates/templateWOF.docx",
  SSR: "/templates/templateSurveyStatement.docx",
};

export const CertificateTypes = [
  { value: "CoS", text: "Certificate of Survey" },
  { value: "Tonn", text: "Certificate of Survey Under 24" },
  { value: "BWM", text: "BWM Statement" },
  { value: "ITC", text: "International Tonnage Certificate" },
  { value: "AFSD", text: "AFS Declaration" },
  { value: "IAPP", text: "IAPP" },
  { value: "AFS", text: "AFS" },
  { value: "MLC", text: "MLC Report" },
  { value: "ILLC", text: "ILLC" },
  { value: "SAFRAD", text: "SAFRAD" },
  { value: "WOF", text: "WOF" },
  { value: "SSR", text: "Survey Statement Report" },

  // These certificates are not yet implemented.
  { value: "CoSSmall", text: "Certificate of Survey (Small Ships)" },
  { value: "Insurance", text: "Insurance Survey Report" },
  { value: "MF", text: "MF Safety Certificate" },
  { value: "Seaworthiness", text: "Seaworthiness" },
  {
    value: "FA15m",
    text: "Certificate of Measurement Fisheries above 15 m",
  },
  { value: "Torremolinos", text: "Torremolinos" },
  { value: "ISPP", text: "ISPP" },
  { value: "UKT", text: "UK Tonnage" },
  { value: "IOPP", text: "IOPP" },
  { value: "IEE", text: "IEE" },

  { value: "Freeboard", text: "Freeboard Certificate" },
  { value: "Bilge", text: "Bilge System Statement" },
];
