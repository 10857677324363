<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row mb-3">
        <div
          class="main-card w-25 bg-blue"
          @click="() => changeActive('usersAndContacts')"
        >
          <span class="m-2">Users and contacts</span
          ><i class="fa fa-chevron-right me-2"></i>
        </div>
        <div v-if="isExpanded === 'usersAndContacts'" class="w-75">
          <SearchableList :tableObject="columnsStructure?.usersAndContacts" />
        </div>
      </div>

      <div class="d-flex flex-row mb-3">
        <div
          class="main-card w-25 bg-green"
          @click="() => changeActive('vessels')"
        >
          <span class="m-2">Vessels</span
          ><i class="fa fa-chevron-right me-2"></i>
        </div>
        <div v-if="isExpanded === 'vessels'" class="w-75">
          <SearchableList :tableObject="columnsStructure?.vessels" />
        </div>
      </div>

      <div class="d-flex flex-row mb-3">
        <div
          class="main-card w-25 bg-red"
          @click="() => changeActive('clients')"
        >
          <span class="m-2">Clients</span
          ><i class="fa fa-chevron-right me-2"></i>
        </div>
        <div v-if="isExpanded === 'clients'" class="w-75">
          <SearchableList :tableObject="columnsStructure?.clients" />
        </div>
      </div>

      <div class="d-flex flex-row mb-3">
        <div
          class="main-card w-25 bg-orange"
          @click="() => changeActive('projects')"
        >
          <span class="m-2 text-muted">Projects</span
          ><i class="fa fa-chevron-right me-2"></i>
        </div>
        <div v-if="isExpanded === 'projects'" class="w-75">
          <SearchableList :tableObject="columnsStructure?.projects" />
        </div>
      </div>

      <div v-if="selectedColumns.length > 0" class="border p-2">
        <div class="font-weight-bold mb-2">SearchBy properties:</div>

        <div
          v-for="item in selectedColumns"
          :key="`${item.table}-${item.column}-${item.data_type}`"
          class="multiselect__tags item-card"
        >
          <span class="multiselect__tag" @click="removeSearchByItem(item)">
            <span class="font-weight-bold">{{ item.column }}</span
            ><i class="multiselect__tag-icon" tabindex="1"></i>
          </span>
        </div>
      </div>
      <div
        v-for="filter in filters"
        :key="`${filter.table}-${filter.column}-${filter.data_type}`"
        class="row clearfix mt-3"
      >
        <div class="col-11">
          <StructuredFilter ref="structuredFilterRefs" :filter="filter" />
        </div>

        <div class="col-1">
          <button
            class="btn btn-danger me-3 btn-sm"
            title="Delete filter"
            type="button"
            @click="removeFilter(filter)"
          >
            <span class="sr-only">Delete filter</span>
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllColumns } from "@/apis/search";
import SearchableList from "./SearchableList.vue";
import StructuredFilter from "./StructuredFilter.vue";
import { notifySuccess, notifyWarning } from "@/helpers/notification";

export default {
  name: "StructuredSearch",
  components: {
    SearchableList,
    StructuredFilter,
  },
  props: {},
  data() {
    return {
      //   columns: { label: "Label", name: "valami", options: ["egz", "ketto"] }, // List of columns from all over the DB.
      columnsStructure: {},
      selectedColumns: [],
      filters: [],
      isExpanded: "",
    };
  },
  mounted() {
    this.$root.$on("addStructuredSearchBy", (item) => {
      this.addSearchBy(item);
    });
    this.$root.$on("addStructuredFilter", (item) => {
      this.addFilter(item);
    });
    getAllColumns().then((resp) => {
      resp?.structuredColumns.projects.projectsDetails.forEach((obj) => {
        if (obj.table === "users" && obj.column === "name") {
          obj.column = "responsibles";
        }
      });
      this.columnsStructure = resp?.structuredColumns;
    });
  },
  methods: {
    getColList() {
      if (!this.selectedColumns?.length) return [];

      // Check if there are conflicting names and add an 'as table_columnName' only to them
      return this.selectedColumns
        .map((column) => `${column.table}.${column.column}`)
        .flat();
    },
    getFilters() {
      const myfilters = this.filters.map((filter, index) => {
        const filterName = this.$refs.structuredFilterRefs[index].getProps();
        return filterName;
      });
      return myfilters;
    },
    addSearchBy(item) {
      if (this.selectedColumns.includes(item)) {
        notifyWarning(
          this,
          `${item.column} is already added to searchBy properties`
        );
        return;
      }
      this.selectedColumns.push(item);
      notifySuccess(this, `${item.column} added to searchBy properties`);
    },
    removeSearchByItem(item) {
      const indexToRemove = this.selectedColumns.findIndex(
        (x) => x.column === item.column && x.table === item.table
      );
      this.selectedColumns.splice(indexToRemove, 1);
    },
    changeActive(item) {
      if (this.isExpanded === item) {
        this.isExpanded = "";
        return;
      }
      this.isExpanded = item;
    },
    addFilter(item) {
      if (this.filters.includes(item)) {
        notifyWarning(this, `${item.column} is already added to filters`);
        return;
      }
      this.filters.push(item);
      notifySuccess(this, `${item.column} added to filters`);
    },
    removeFilter(item) {
      const indexToRemove = this.filters.findIndex(
        (x) => x.column === item.column && x.table === item.table
      );
      this.filters.splice(indexToRemove, 1);
    },
  },
  computed: {
    columnList() {
      const options = this.options();
      return {
        name: "DB_columns",
        type: "select",
        options,
      };
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "public/assets/css/variables";

.main-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.multiselect__tags {
  line-height: 0.5;
  min-height: 35px;
  display: contents;

  .item-card {
    background-color: $brand-navy;
  }
}
</style>
