<template>
  <div>
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="input-group has-validation mb-3">
      <label
        :class="{ 'parsley-error is-invalid': error }"
        class="fancy-checkbox form-control form-check-control me-0"
      >
        <input
          class="me-2"
          :id="`input-${field.name}`"
          :aria-label="field.name"
          :checked="value"
          :disabled="readonly || field.readonly"
          :name="field.name"
          :readonly="readonly || field.readonly"
          aria-describedby="basic-addon1"
          type="checkbox"
          @input="(e) => $emit('input', e.target.checked)"
        />
        <span>{{ field.placeholder || field.label }}</span>
      </label>
      <div v-if="error" class="invalid-feedback">
        <template v-if="errorMessage">{{ errorMessage }}</template>
        <template v-else>Error has been encountered</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputCheck",
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
