<template>
  <wrapper-page pageTitle="Projects Dashboard">
    <ProjectKpis
      v-if="projectDashboardKpis"
      :projectKpis="projectDashboardKpis"
    />

    <div v-if="projectDashboardKpis" class="row">
      <chart-card
        :value="chartData"
        label="Projects by department"
        size="col-lg-6 col-md-12"
      ></chart-card>
    </div>
  </wrapper-page>
</template>

<script>
import { getProjectDashboardKpis } from "@/apis/kpis";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import ProjectKpis from "../../components/projects/ProjectKpis.vue";
import ChartCard from "@/components/ui/ChartCard.vue";

export default {
  name: "ProjectsDashboard",
  components: {
    ChartCard,
    WrapperPage,
    ProjectKpis,
  },
  data() {
    return {
      projectDashboardKpis: null,
      dataLabel: "datalabel",
    };
  },
  methods: {
    fieldNameMapper(key) {
      const fieldnames = {
        totalProjects: "TOTAL",
        departmentDna: "D&NA",
        departmentSscs: "SS&CS",
        departmentOther: "OTHER",
      };
      return fieldnames[key];
    },
  },
  computed: {
    chartData() {
      return Object.keys(this.projectDashboardKpis).reduce(
        (accumulator, key) => {
          if (key === "totalProjects") {
            return accumulator;
          }
          return [
            ...accumulator,
            {
              type: this.fieldNameMapper(key),
              number: this.projectDashboardKpis[key],
            },
          ];
        },
        []
      );
    },
  },
  mounted() {
    getProjectDashboardKpis().then((response) => {
      this.projectDashboardKpis = response?.data;
    });
  },
};
</script>
