import _ from "lodash";
import { CountryOptions } from "../constants/address";

/**
 * Convert null values to double dashes ('--') for the given properties in a data object
 * @param {object} data The object to be processed
 * @param {array} properties An array of the properties in the data object which will be processed
 * @returns {object} The processed data object
 */
export const nullToDoubleDash = (data, properties = []) => {
  properties.forEach((property) => {
    if (data[property] === undefined || data[property] === null)
      data[property] = "--";
  });

  return data;
};

/**
 * Convert double dashes ('--') to nulls for the data object
 * @param {object} data The object to be processed
 * @returns {object} The processed data object
 */
export const doubleDashToNull = (data) => {
  Object.keys(data).forEach((property) => {
    if (data[property] === "--") data[property] = null;
  });

  return data;
};

/**
 * Convert a number into a decimal with decimal places
 * @param value The number to be parsed
 * @param dp The number of decimal places
 * @returns {string|*} The processed number
 */
export const toDecimalPlaces = (value, dp) => {
  if (!dp) return value;

  return parseFloat(value).toFixed(dp);
};

/**
 * Converts an array into string, separated with commas and a final separator for the last unit
 * @param list The array to convert
 * @param finalSeparator The string to separate the last unit with
 * @returns {string} The processed string
 */
export const toCommasAndSeparator = (list, finalSeparator = "and") => {
  if (list.length < 3) {
    return list.join(` ${finalSeparator} `);
  }

  return `${list.slice(0, -1).join(", ")}, ${finalSeparator} ${
    list[list.length - 1]
  }`;
};

/**
 * Determines if the provided `value` has data and is not empty
 * @param value
 * @param allowDoubleDash
 * @returns {boolean}
 */
export const hasEntry = (value, allowDoubleDash = false) => {
  if (value === undefined || value === null) return false;

  if (value === "--" && !allowDoubleDash) return false;

  // if value is a string
  if (typeof value === "string" || value instanceof String) {
    if (value.trim().length === 0) return false;
  }

  // if value is an array
  if (Array.isArray(value)) {
    if (value.length === 0) return false;
  }

  return true;
};
export const getDateRange = (numberOfdaysString) => {
  const numberOfdays = Number(numberOfdaysString);
  if (!numberOfdays) {
    return false;
  }
  // Create a date object for today
  const date = new Date();
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  const formattedDate = year + "-" + month + "-" + day;

  const endDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + numberOfdays
  )
    .toISOString()
    .split("T")[0];
  return {
    today: formattedDate,
    endDay,
  };
};

export const confirmRedirect = (next) => {
  const answer = window.confirm(
    "Do you really want to leave? You have unsaved changes! Click CANCEL to STAY!"
  );
  if (answer) {
    next();
  }
};

export const getCountryName = (countryCode) => {
  if (countryCode) {
    return (
      CountryOptions.find(
        (country) => country.value === countryCode.toUpperCase()
      )?.text?.toUpperCase() ?? ""
    );
  }
  return "";
};

export const getCountryCode = (countryName) => {
  if (countryName) {
    return (
      CountryOptions.find(
        (country) => country.text.toUpperCase() === countryName.toUpperCase()
      )?.value.toUpperCase() ?? ""
    );
  }
  return "";
};

export const updateCountryNames = (responseObj) => {
  try {
    let newObj = _.cloneDeep(responseObj);
    if (Array.isArray(newObj?.data)) {
      newObj?.data?.forEach((element) => {
        Object.keys(element)?.forEach((propertyName) => {
          if (propertyName?.includes("country")) {
            const countryName = getCountryName(element[propertyName]);
            if (countryName) {
              element[propertyName] = countryName;
            }
          }
        });
      });
      return newObj;
    }
  } catch (error) {
    console.log(error);
    return responseObj;
  }
};

export const updateCountryNamesInArray = (arrayOfObj) => {
  try {
    let newObj = _.cloneDeep(arrayOfObj);
    if (Array.isArray(newObj)) {
      newObj.forEach((element) => {
        Object.keys(element).forEach((propertyName) => {
          if (propertyName?.includes("country")) {
            const countryName = getCountryName(element[propertyName]);
            if (countryName) {
              element.countryCode = element[propertyName];
              element[propertyName] = countryName;
            }
          }
        });
      });
      return newObj;
    }
  } catch (error) {
    console.log(error);
    return arrayOfObj;
  }
};

const uppercaseDictionary = ["ID", "IMO", "MMSI", "VAT"];

export const toTitle = (input) => {
  const words = input.toLowerCase().split(/_|-/);

  return words
    .map(
      (w) =>
        uppercaseDictionary.find((u) => u === w.toUpperCase()) ??
        w.charAt(0).toUpperCase() + w.slice(1)
    )
    .join(" ");
};
