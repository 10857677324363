<template>
  <form class="vessel-status-container">
    <div />
    <label
      v-for="option in options"
      :key="`label-${option.value}`"
      :for="`input-${option.value}`"
    >
      <small class="select-none">{{ option.text }}</small>
    </label>
    <small class="select-none text-muted">Status</small>

    <div
      v-for="option in options"
      :key="`input-${option.value}`"
      class="form-check"
    >
      <input
        :id="`input-${option.value}`"
        class="form-check-input mx-auto"
        type="radio"
        :checked="value === option.value"
        :value="option.value"
        name="vessel-status"
        @click="selected(option.value)"
      />
    </div>
  </form>
</template>
<script>
export default {
  name: "VesselStatus",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: [
        { value: "In Service", text: "In Service" },
        { value: "In Progress", text: "In Progress" },
        { value: "Under Construction", text: "Under Construction" },
      ],
    };
  },
  methods: {
    selected(selectedValue) {
      let newValue = null;
      if (this.value !== selectedValue) {
        newValue = selectedValue;
      }

      this.$emit("input", newValue);
    },
  },
};
</script>
<style lang="scss">
.vessel-status-container {
  display: grid;
  grid-template-columns: repeat(4, fit-content(100%));
  column-gap: 1rem;
}
</style>
