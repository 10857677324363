<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-start align-items-center mb-4"
            >
              <div class="page-title-box pb-0">
                <h4 class="mb-0">Valuations</h4>
              </div>
              <button
                class="ms-2 btn btn-sm btn-primary"
                title="Add Valuations"
                type="button"
                @click="addValuations"
              >
                <span class="sr-only">Add Hull Valuations</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <div class="row">
            <valuations-table
              ref="tblValuations"
              :valuationDocuments="valuationDocuments"
              :valuations="valuations"
              @removeValuationByIndex="removeValuations($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocuments } from "@/apis/files";
import ValuationsTable from "./ValuationsTable.vue";

export default {
  name: "ValuationsTab",
  components: { ValuationsTable },
  props: {
    vessel: Object,
  },
  data() {
    return {
      valuations: [],
      valuationDocuments: [],
    };
  },
  methods: {
    addValuations() {
      const emptyValuation = {
        date: null,
        hull: null,
        machinery: null,
        tender: null,
        auxiliary_engine: null,
        equipment: null,
        trailer: null,
      };
      this.valuations.push(emptyValuation);
      this.$refs.tblValuations.openForEdit(this.valuations.length - 1);
    },
    removeValuations(index) {
      this.valuations.splice(index, 1);
    },
    isInvalid() {
      return this.$refs.tblValuations.isInvalid();
    },
    getAllDocuments() {
      getDocuments(`vessels/${this.vessel.id}/valuations`, this.vessel.id).then(
        (resp) => (this.valuationDocuments = resp.data)
      );
    },
  },
  mounted() {
    if (this.vessel && this.vessel.valuations) {
      this.valuations = [...this.vessel.valuations];
      this.getAllDocuments();
    }
    // This is a temporary solution for reloading documents in certifications an surveys tab.
    // Its is a shorcut until we store the documents in VUE store and we can soerve all documents from one source of thruth.
    // Please note that this functions will execute even if we are working with project documents.
    this.$root.$on("reloadVesselDocumentsAfterDocumentsIsDeleted", () => {
      this.getAllDocuments();
    });
  },
};
</script>
