<template>
  <wrapper-page :page-title="details?.name ?? 'Machinery Maker Update'">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <button class="btn btn-sm btn-primary me-3" @click="saveMaker">
          Save Maker
        </button>

        <button class="btn btn-sm btn-danger me-3" @click="deleteMaker">
          Delete Maker
        </button>
      </div>
    </template>

    <template v-if="!isLoadingMachineryMakers && details">
      <div class="row">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div v-for="field in this.makersFields" :key="field.name">
                      <form-input-text
                        v-model="details[field.name]"
                        :error="$v.details[field.name].$error"
                        :error-message="
                          validationMessage($v.details[field.name])
                        "
                        class="col-12"
                        :field="field"
                      />
                    </div>
                  </div>

                  <address-editor :address="details.address" />
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="card">
                <div class="card-body">
                  <strong>Existing models:</strong>

                  <multi-select-dropdown
                    v-if="modelList"
                    v-model="selectedModel"
                    :field="modelList"
                    class="col-6"
                  />
                  <div v-if="searchResult" class="">
                    <strong
                      >Vessels acording to maker and selected model:</strong
                    >
                    <div class="table-responsive machinery_makers_update_table">
                      <table
                        v-if="dataTable"
                        class="table table-hover table-custom dataTable projects-js-data-table"
                      >
                        <thead>
                          <tr>
                            <th v-for="header in dataTable[0]" :key="header">
                              {{ header }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(row, index) in dataTable.slice(1)"
                            :key="`row-${index}`"
                          >
                            <td
                              v-for="(cell, index) in row"
                              :key="`cell-${index}`"
                            >
                              <a href="#" @click="vesselClicked(row)">{{
                                cell
                              }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <div
                          v-for="(object, index) in dataTable"
                          :key="`row-${index}`"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MachineryMakersContacts
        v-if="detailsStored"
        ref="makerContacts"
        @contactFormChanged="contactFormChanged"
        @saveDefaultContacts="saveDefaultContacts"
      />
    </template>
  </wrapper-page>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import WrapperPage from "../../../components/layout/WrapperPage.vue";
import {
  createMachineryMaker,
  deleteMachineryMaker,
  getMachineryMakersList,
} from "@/apis/machineryMakers";
import { search } from "@/apis/search";
import { notifyChangesSaved, notifyError } from "@/helpers/notification";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../components/ui/FormInputText.vue";
import AddressEditor from "../../../components/ui/AddressEditor.vue";
import MultiSelectDropdown from "../../../components/ui/MultiSelectDropdown.vue";
import MachineryMakersContacts from "./MachineryMakersContacts.vue";
import { confirmRedirect } from "../../../helpers/generic";
import { CountryOptions } from "@/constants/address";
import { store } from "../../../main";
import { getVesselMachineryMakeModels } from "@/apis/vessels";
import _ from "lodash";

const jsonToTable = require("json-to-table");

export default {
  name: "MachineryMakersUpdate",
  components: {
    WrapperPage,
    FormInputText,
    AddressEditor,
    MultiSelectDropdown,
    MachineryMakersContacts,
  },
  data: function () {
    return {
      detailsStored: false,
      formChanged: false,
      dataTable: null,
      searchResult: null,
      modelList: null,
      selectedModel: null,
      isLoadingMachineryMakers: false,
      makersFields: [{ label: "Name", name: "name", type: "text" }],
      details: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postcode: null,
          country: null,
        },
      },
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      confirmRedirect(next);
    } else {
      next();
    }
  },
  async mounted() {
    this.isLoadingMachineryMakers = true;
    await getMachineryMakersList()
      .then(({ data }) => {
        const maker = data.find(
          (maker) =>
            maker.machinery_maker_id === Number(this.$route.params.makerId)
        );
        this.details = this.getMakerObjectFromListObject(maker);
      })
      .catch((err) => {
        notifyError(this, err?.response?.data);
      });
    store.commit("storeDefaultFormState", {
      details: this.details,
    });
    this.detailsStored = true;
    await this.getModelList();
    await this.searchVessels();
    this.formChanged = false;
    this.isLoadingMachineryMakers = false;
  },
  watch: {
    details: {
      handler() {
        this.updateFormChanged(this.$refs.makerContacts?.contacts);
      },
      deep: true,
    },
    selectedModel() {
      this.searchVessels();
    },
    searchResult() {
      this.buildDataTable();
    },
  },
  methods: {
    stateIsNotChanged(contacts) {
      const defaultDetails = store.state.dafultFormState?.details;
      const defaultContacts = store.state.dafultFormState?.contacts;

      const result =
        _.isEqual(this.details, defaultDetails) &&
        _.isEqual(contacts, defaultContacts);

      return result;
    },
    contactFormChanged(contacts) {
      if (this.stateIsNotChanged(contacts)) {
        this.formChanged = false;
        return;
      }
      this.formChanged = true;
    },
    saveDefaultContacts(contacts) {
      store.commit("storeDefaultFormState", {
        ...store.state.dafultFormState,
        contacts,
      });
      this.formChanged = false;
    },
    updateFormChanged(contacts) {
      if (!this.formChanged) {
        this.formChanged = true;
        return;
      }
      if (this.stateIsNotChanged(contacts)) {
        this.formChanged = false;
      }
    },
    vesselClicked(vessel) {
      let resolvedRoute = this.$router.resolve({
        name: "vessels-show",
        params: { vesselId: vessel[0] },
      });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    },
    buildDataTable() {
      this.dataTable = jsonToTable(this.searchResult);
    },
    async searchVessels() {
      const hasSelectedModel = this.selectedModel?.length > 0;
      const modelFilter = hasSelectedModel
        ? [
            {
              operand: [
                ...this.selectedModel.map((model) => `'${model?.value}'`),
              ],
              operator: "in",
              property: "vessels_machinery.model",
            },
          ]
        : [];
      let request = {
        distinct: [
          "vessels.id",
          "vessels.name",
          "vessels_machinery.make",
          "vessels_machinery.model",
        ],
        filter: [
          {
            operand: this.details?.name,
            operator: "=",
            property: "vessels_machinery.make",
          },
          ...modelFilter,
        ],
      };

      await search(request)
        .then(({ data }) => {
          this.searchResult = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async getModelList() {
      const machineryMakeModels = await getVesselMachineryMakeModels();

      const modelOptions = [
        ...new Set(
          machineryMakeModels?.data
            .filter((mm) => mm.make === this.details?.name)
            .map((modelForMake) => ({
              text: modelForMake.model,
              value: modelForMake.model,
            }))
        ),
      ];
      this.modelList = {
        name: "select_model",
        type: "select",
        placeholder: "Select",
        options: modelOptions,
      };
    },
    getMakerObjectFromListObject(makerFromList) {
      let maker = {
        id: makerFromList.id,
        name: makerFromList.name,
      };

      let address = { ...makerFromList };
      address.id = address.address_id;
      delete address.address_id;
      delete address.associated_vessels;
      delete address.name;

      address.country =
        CountryOptions.find(
          (country) =>
            country?.text?.toLowerCase() === address?.country?.toLowerCase()
        )?.value ?? address.country;
      maker.address = address;

      return maker;
    },
    validationMessage,
    async saveMaker() {
      const data = {
        ...this.details,
        id: this.$route.params.makerId,
      };
      delete data.address.machinery_maker_id;
      await createMachineryMaker(data)
        .then(() => {
          notifyChangesSaved(this);
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async deleteMaker() {
      if (confirm("Are you sure you want to delete this Maker?"))
        await deleteMachineryMaker(this.$route.params.makerId)
          .then(() => {
            notifyChangesSaved(this);
            this.$router.push({
              name: "machinery-makers-index",
            });
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
    },
  },
  computed: {},
};
</script>
<style>
.machinery_makers_update_table {
  max-height: 23rem;
  overflow: scroll;
}
</style>
