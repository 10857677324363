export const internalTableNames = Object.freeze({
  PROJECTS: "projects",
  VESSELS: "vessels",
  CLIENTS: "clients",
});

export const excludedColumns = ["deleted", "created_on", "updated_on"];

export const quickSearchBtnNames = Object.freeze({
  NUM_VESSELS_BY_VESSEL_TYPE: "NUM_VESSELS_BY_VESSEL_TYPE",
  NUMBER_OF_PROJ_PER_CLIENT: "NUMBER_OF_PROJ_PER_CLIENT",
  NUMBER_OF_PROJ_WITH_CERT_EXP: "NUMBER_OF_PROJ_WITH_CERT_EXP",
  SEARCH_VESSELS_BY_TYPE_COUNTRY_CERT_EXP:
    "SEARCH_VESSELS_BY_TYPE_COUNTRY_CERT_EXP",
  SEARCH_VESSELS_BY_GENERATOR_MAKE_MODEL:
    "SEARCH_VESSELS_BY_GENERATOR_MAKE_MODEL",
  SEARCH_VESSELS_BY_MACHINERY_MAKE_MODEL:
    "SEARCH_VESSELS_BY_MACHINERY_MAKE_MODEL",
  SEARCH_CLIENTS_AND_CONTACTS: "SEARCH_CLIENTS_AND_CONTACTS",
});
