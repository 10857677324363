<template>
  <wrapper-page :page-title="details?.name ?? 'Vessel Builder Update'">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <button class="btn btn-sm btn-primary me-3" @click="saveBuilder">
          Save Builder
        </button>

        <button class="btn btn-sm btn-danger me-3" @click="deleteBuilder">
          Delete Builder
        </button>
      </div>
    </template>

    <template v-if="!isLoadingVesselBuilders && details">
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="field in this.buildersFields"
                  :key="field.name"
                  class="col-12"
                >
                  <form-input-text
                    v-model="details[field.name]"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
              </div>

              <address-editor :address="details.address" />
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="card">
            <div class="card-body">
              <strong>Existing models:</strong>

              <multi-select-dropdown
                v-if="modelList"
                v-model="selectedModel"
                :field="modelList"
                class="col-6"
              />
              <div v-if="searchResult" class="">
                <strong
                  >Vessels according to builder and selected model:</strong
                >
                <div class="table-responsive vessel_builders_update_table">
                  <table
                    v-if="dataTable"
                    class="table table-hover table-custom dataTable projects-js-data-table"
                  >
                    <thead>
                      <tr>
                        <th v-for="header in dataTable[0]" :key="header">
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in dataTable.slice(1)"
                        :key="`row-${index}`"
                      >
                        <td v-for="(cell, index) in row" :key="`cell-${index}`">
                          <a href="#" @click="vesselClicked(row)">{{ cell }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <div
                      v-for="(object, index) in dataTable"
                      :key="`row-${index}`"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VesselBuildersContacts
        v-if="detailsStored"
        ref="builderContacts"
        @contactFormChanged="contactFormChanged"
        @saveDefaultContacts="saveDefaultContacts"
      />
    </template>
  </wrapper-page>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import WrapperPage from "../../../components/layout/WrapperPage.vue";
import {
  createVesselBuilder,
  deleteVesselBuilder,
  getVesselBuilders,
  getVesselBuilderModelsById,
  getVesselsForBuilderIdAndModels,
} from "@/apis/vessels";
import { notifyChangesSaved, notifyError } from "@/helpers/notification";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../components/ui/FormInputText.vue";
import AddressEditor from "../../../components/ui/AddressEditor.vue";
import MultiSelectDropdown from "../../../components/ui/MultiSelectDropdown.vue";
import VesselBuildersContacts from "./VesselBuildersContacts.vue";
import { confirmRedirect } from "../../../helpers/generic";
import { CountryOptions } from "@/constants/address";
import { store } from "../../../main";
import _ from "lodash";

const jsonToTable = require("json-to-table");

export default {
  name: "VesselBuilderUpdate",
  components: {
    WrapperPage,
    FormInputText,
    AddressEditor,
    MultiSelectDropdown,
    VesselBuildersContacts,
  },
  data: function () {
    return {
      detailsStored: false,
      formChanged: false,
      dataTable: null,
      searchResult: null,
      modelList: null,
      selectedModel: null,
      isLoadingVesselBuilders: false,
      buildersFields: [{ label: "Name", name: "name", type: "text" }],
      details: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postcode: null,
          country: null,
        },
      },
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      confirmRedirect(next);
    } else {
      next();
    }
  },
  async mounted() {
    this.isLoadingVesselBuilders = true;
    await this.getModelList();
    await getVesselBuilders(true)
      .then(({ data }) => {
        this.vesselBuilders = [...data];
        const builder = data.find(
          (builder) => builder.id === Number(this.$route.params.builderId)
        );
        this.details = this.getBuilderObjectFromListObject(builder);
      })
      .catch((err) => {
        notifyError(this, err?.response?.data);
      });
    store.commit("storeDefaultFormState", {
      details: this.details,
    });
    this.detailsStored = true;
    await this.searchVessels();
    this.formChanged = false;
    this.isLoadingVesselBuilders = false;
  },
  watch: {
    details: {
      handler() {
        this.updateFormChanged(this.$refs.builderContacts?.contacts);
      },
      deep: true,
    },
    selectedModel() {
      this.searchVessels();
    },
    searchResult() {
      this.buildDataTable();
    },
  },
  methods: {
    stateIsNotChanged(contacts) {
      const defaultDetails = store.state.dafultFormState?.details;
      const defaultContacts = store.state.dafultFormState?.contacts;

      const result =
        _.isEqual(this.details, defaultDetails) &&
        _.isEqual(contacts, defaultContacts);

      return result;
    },
    contactFormChanged(contacts) {
      if (this.stateIsNotChanged(contacts)) {
        this.formChanged = false;
        return;
      }
      this.formChanged = true;
    },
    saveDefaultContacts(contacts) {
      store.commit("storeDefaultFormState", {
        ...store.state.dafultFormState,
        contacts,
      });
      this.formChanged = false;
    },
    updateFormChanged(contacts) {
      if (!this.formChanged) {
        this.formChanged = true;
        return;
      }
      if (this.stateIsNotChanged(contacts)) {
        this.formChanged = false;
      }
    },
    vesselClicked(vessel) {
      let resolvedRoute = this.$router.resolve({
        name: "vessels-show",
        params: { vesselId: vessel[0] },
      });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    },
    buildDataTable() {
      this.dataTable = jsonToTable(this.searchResult);
    },
    async searchVessels() {
      const models = {
        models: this.selectedModel?.map((model) => `'${model?.value}'`),
      };
      await getVesselsForBuilderIdAndModels(
        this.$route.params.builderId,
        models
      )
        .then(({ data }) => {
          this.searchResult = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async getModelList() {
      await getVesselBuilderModelsById(this.$route.params.builderId)
        .then(({ data }) => {
          const options = data.map((obj) => {
            return { text: obj.model, value: obj.model };
          });

          this.modelList = {
            name: "select_model",
            type: "select",
            placeholder: "Select",
            options,
          };
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    getBuilderObjectFromListObject(builderFromList) {
      let builder = {
        id: builderFromList.id,
        name: builderFromList.name,
      };

      let address = { ...builderFromList };
      address.id = address.address_id;
      delete address.address_id;
      delete address.associated_vessels;
      delete address.name;

      address.country =
        CountryOptions.find(
          (country) =>
            country?.text?.toLowerCase() === address?.country?.toLowerCase()
        )?.value ?? address.country;
      builder.address = address;

      return builder;
    },
    validationMessage,
    async saveBuilder() {
      await createVesselBuilder(this.details)
        .then(() => {
          notifyChangesSaved(this);
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async deleteBuilder() {
      if (confirm("Are you sure you want to delete this Builder?"))
        await deleteVesselBuilder(this.$route.params.builderId)
          .then(() => {
            notifyChangesSaved(this);
            this.$router.push({
              name: "vessel-builders-index",
            });
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
    },
  },
  computed: {},
};
</script>
<style>
.vessel_builders_update_table {
  max-height: 23rem;
  overflow: scroll;
}
</style>
