<template>
  <div>
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="mb-3 d-flex flex-wrap gap-x-2">
      <div
        v-for="option in field.options"
        :key="option.value"
        class="form-check"
      >
        <input
          class="form-check-input"
          :id="`input-${field.name}-${groupIndex}-${option.value}`"
          :checked="value && option.value === value"
          :name="`${field.name}-${groupIndex}`"
          :readonly="field.readonly"
          :value="option.value"
          type="radio"
          @input="(e) => $emit('input', e.target.value)"
        />
        <label
          class="form-check-label"
          :for="`input-${field.name}-${groupIndex}-${option.value}`"
        >
          {{ option.text }}
        </label>
        <div v-if="error" class="invalid-feedback">
          <template v-if="errorMessage">{{ errorMessage }}</template>
          <template v-else>Error has been encountered</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputRadio",
  props: {
    field: {
      type: Object,
      required: true,
    },
    groupIndex: {
      type: [String, Number],
      default: 0,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    value: {
      type: String,
    },
  },
};
</script>
