import {
  getOtherServices,
  getVesselBuilders,
  getVesselBuildersModels,
  getVesselGeneratorsMakeModels,
  getVesselTypes,
} from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";
import CreateVesselBuilderModal from "@/components/vessels/CreateVesselBuilderModal";
import { store } from "../../main";
import { search } from "@/apis/search";
import { doubleDashToNull, getCountryCode } from "@/helpers/generic";

export default {
  data() {
    return {
      isLoadingVesselTypeOptions: false,
      isLoadingOtherServiceOptions: false,
      isLoadingVesselBuilderOptions: false,
      isLoadingVesselGeneratorMakeAndModelsOptions: false,
      vesselMakeOptions: [],
    };
  },
  computed: {
    warnings() {
      let warnings = [];
      // Identifiers - 1 of these must be present: IMO, Official Number, S-Reg OR Fisheries ID
      const details = doubleDashToNull({ ...this.details });
      if (
        !(
          details.official_number ||
          details.imo_number ||
          details.s_reg ||
          details.fisheries_id
        )
      ) {
        warnings.push(
          "[Details] At least 1 vessel identifier field is required"
        );
      }

      // Dimensions - Warn some fields are needed with certain values
      const dimensions = this.details?.dimensions; // this.$v.details.dimensions.$model;
      if (dimensions) {
        if (dimensions.length) {
          if (!dimensions.length_overall)
            warnings.push("[Dimensions] Length Overall field is required");
        }
        if (dimensions.length_overall > 24) {
          if (!dimensions.length)
            warnings.push("[Dimensions] Length field is required");
          if (!dimensions.length_between_perp)
            warnings.push(
              "[Dimensions] Length (between perp) field is required"
            );
          if (!dimensions.length_engine_room)
            warnings.push(
              "[Dimensions] Length (engine room) field is required"
            );
          if (!dimensions.lightship)
            warnings.push("[Dimensions] Lightship field is required");
          if (!dimensions.displacement)
            warnings.push("[Dimensions] Displacement field is required");
        }
      }

      return warnings;
    },
  },
  asyncComputed: {
    allWarnings: {
      async get() {
        return [...this.warnings, ...this.hullWarnings];
      },
      default: [],
    },
    hullWarnings: {
      async get() {
        let hullWarnings = [];

        const hullModell = this.details?.hull?.model;
        if (!hullModell) {
          return [];
        }
        let request = {
          show: [
            "vessels.name",
            "vessels.official_number",
            "vessels_hull.model",
            "vessels_dimensions.length_overall",
            "vessels_dimensions.length",
            "vessels_dimensions.length_between_perp",
            "vessels_dimensions.length_hull",
            "vessels_dimensions.length_engine_room",
            "vessels_dimensions.breadth",
            "vessels_dimensions.depth",
            "vessels_dimensions.draught",
            "vessels_dimensions.lightship",
            "vessels_dimensions.displacement",
            "vessels_dimensions.deadweight",
          ],
          filter: [
            {
              operand: hullModell,
              operator: "=",
              property: "vessels_hull.model",
            },
          ],
        };
        return search(request)
          .then(({ data }) => {
            const referenceList = this.createReferenceList(data); // This reference list contains all the values that most likely should be the correct.
            const dimensions = this.details?.dimensions;
            if (dimensions) {
              Object.keys(dimensions).forEach((key) => {
                if (
                  referenceList[key] &&
                  dimensions[key] != referenceList[key]
                ) {
                  hullWarnings.push(
                    `[Dimensions] ${key?.toUpperCase()} field has a different value on other vessels: ${
                      referenceList[key]
                    } !`
                  );
                }
              });
            }

            return hullWarnings;
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      },
      default: [],
    },
  },
  methods: {
    tabWarnings(tab) {
      return this.allWarnings.filter((w) =>
        w.toLowerCase().includes(`[${tab.toLowerCase()}]`)
      );
    },
    createReferenceList(data) {
      if (!data || data?.length < 1) {
        return {};
      }
      let referenceObj = {};
      // If we have one vessel to compare then get all valid values to compare them with the new vessel.
      if (data?.length === 1) {
        const vessel = data[0];
        for (const key in vessel) {
          if (vessel[key]) {
            referenceObj[key] = vessel[key];
          }
        }
        //If we have more vessels then gather all the vessel values into arrays under the same key.
      } else {
        data.forEach((vessel) => {
          for (const key in vessel) {
            if (vessel[key]) {
              referenceObj[key] = referenceObj[key] || []; //Needs to make sure that referenceObj[key] exists.
              referenceObj[key] = [...referenceObj[key], vessel[key]];
            }
          }
        });
        // Then we select the most occurance value from all property arrays and return it as the prefered value to compare.
        Object.keys(referenceObj).forEach((key) => {
          referenceObj[key] = this.getMostOccuranceFromArray(referenceObj[key]);
        });
      }

      return referenceObj;
    },
    getMostOccuranceFromArray(arr) {
      return arr
        .sort(
          (a, b) =>
            arr.filter((v) => v === a).length -
            arr.filter((v) => v === b).length
        )
        .pop();
    },
    async getVesselTypeOptions() {
      this.isLoadingVesselTypeOptions = true;

      let vesselTypeOptions = store.state.vesselTypeOptions;
      if (!vesselTypeOptions || vesselTypeOptions?.length < 1) {
        await getVesselTypes()
          .then(({ data }) => {
            vesselTypeOptions = data.map((c) => ({
              text: c.type,
              value: c.id,
            }));
            store.commit("storeVesselTypeOptions", vesselTypeOptions);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }

      const vesselTypeFieldIndex = this.fields.findIndex(
        (f) => f.name === "vessel_type"
      );
      this.$set(
        this.fields[vesselTypeFieldIndex],
        "options",
        vesselTypeOptions
      );

      this.isLoadingVesselTypeOptions = false;
    },
    async getOtherServiceOptions() {
      this.isLoadingOtherServiceOptions = true;

      let otherServiceOptions = store.state.otherServiceOptions;
      if (!otherServiceOptions || otherServiceOptions?.length < 1) {
        await getOtherServices()
          .then(({ data }) => {
            otherServiceOptions = data.map((c) => ({
              text: c.title,
              value: c.id,
            }));
            store.commit("storeOtherServiceOptions", otherServiceOptions);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
      const otherServiceFieldIndex = this.fields.findIndex(
        (f) => f.name === "other_service"
      );
      this.$set(
        this.fields[otherServiceFieldIndex],
        "options",
        otherServiceOptions
      );

      this.isLoadingOtherServiceOptions = false;
    },
    async getVesselBuilderOptions() {
      this.isLoadingVesselBuilderOptions = true;

      let vesselBuilderOptions = [];

      await getVesselBuilders(true)
        .then(({ data }) => {
          vesselBuilderOptions = data.map((c) => ({
            text: c.name,
            value: c.id,
            address_id: c.address_id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      vesselBuilderOptions.sort((a, b) => a.text.localeCompare(b.text)); // Sort now so that we can keep 'Create new' at the top

      vesselBuilderOptions.unshift({
        text: "Create new",
        value: 0,
      });
      store.commit("storeVesselBuilderOptions", vesselBuilderOptions);

      const builderFieldIndex = this.hullFields.findIndex(
        (f) => f.name === "builder_id"
      );
      this.$set(
        this.hullFields[builderFieldIndex],
        "options",
        vesselBuilderOptions
      );

      this.isLoadingVesselBuilderOptions = false;
    },
    async getbuilderModels() {
      return await getVesselBuildersModels()
        .then(({ data }) => {
          const vesselHullModelOptions = data
            .filter((obj) => obj.model)
            .map((obj) => ({
              text: obj.model,
              value: obj.model,
              builderId: obj.id,
            }));
          store.commit("storeVesselHullModelOptions", vesselHullModelOptions);
          return vesselHullModelOptions;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async getVesselHullModelOptions(builderId) {
      this.isLoadingVesselHullModelOptions = true;

      let vesselHullModelOptions = store.state.vesselHullModelOptions;
      if (!vesselHullModelOptions || vesselHullModelOptions?.length < 1) {
        vesselHullModelOptions = await this.getbuilderModels();
      }
      vesselHullModelOptions.sort((a, b) => a.text.localeCompare(b.text));

      const optionsByBuilderId = vesselHullModelOptions.filter(
        (obj) => obj.builderId === builderId
      );

      const modelFieldIndex = this.hullFields.findIndex(
        (f) => f.name === "model"
      );

      const resultOptions = builderId
        ? optionsByBuilderId
        : vesselHullModelOptions;

      this.$set(this.hullFields[modelFieldIndex], "options", resultOptions);

      this.isLoadingVesselHullModelOptions = false;
    },
    async getVesselGeneratorsMakeAndModelsOptions() {
      this.isLoadingVesselGeneratorMakeAndModelsOptions = true;

      let vesselModelOptions = store.state.vesselModelOptions; // We load all models in dropdown the first time, in case make is not known for some reason
      let vesselMakeOptions = store.state.vesselMakeOptions;
      if (
        //If any of them is missing.
        !vesselModelOptions ||
        vesselModelOptions?.length < 1 ||
        !vesselMakeOptions ||
        vesselMakeOptions?.length < 1
      ) {
        await getVesselGeneratorsMakeModels()
          .then(({ data }) => {
            // 'v => v' is to remove nulls
            vesselMakeOptions = [...new Set(data.map((m) => m.make))]
              .filter((v) => v)
              .map((make) => ({
                text: make,
                value: make,
                models: [
                  ...new Set(
                    data
                      .filter((mm) => mm.make === make)
                      .map((modelForMake) => ({
                        text: modelForMake.model,
                        value: modelForMake.model,
                      }))
                  ),
                ],
              }));
            vesselModelOptions = [...new Set(data.map((m) => m.model))]
              .filter((v) => v)
              .map((model) => ({
                text: model,
                value: model,
              }));
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
        //Store all data
        this.vesselMakeOptions = vesselMakeOptions;
        store.commit("storeVesselMakeOptions", vesselMakeOptions);
        store.commit("storeVesselModelOptions", vesselModelOptions);
      }
      const makeFieldIndex = this.generatorFields.findIndex(
        (f) => f.name === "make"
      );
      this.$set(
        this.generatorFields[makeFieldIndex],
        "options",
        this.vesselMakeOptions
      );
      const modelFieldIndex = this.generatorFields.findIndex(
        (f) => f.name === "model"
      );
      this.$set(
        this.generatorFields[modelFieldIndex],
        "options",
        vesselModelOptions
      );

      this.isLoadingVesselGeneratorMakeAndModelsOptions = false;
    },

    makeSelected(make_value) {
      const modelFieldIndex = this.generatorFields.findIndex(
        (f) => f.name === "model"
      );
      if (!make_value) {
        this.$set(this.generatorFields[modelFieldIndex], "options", []);
        return;
      }
      const make = this.vesselMakeOptions.find(
        (make) => make.value === make_value
      );

      this.$set(this.generatorFields[modelFieldIndex], "options", make.models);
    },

    lunchCreateVesselBuilderModal() {
      this.$modal.show(
        CreateVesselBuilderModal,
        {},
        {
          name: "create-vessel-builder-modal",
          height: "auto",
          width: "400px",
        },
        {
          "before-close": async (e) => {
            const createdBuilderId = e.params?.builder_id;

            // if vessel builder was created
            if (createdBuilderId) {
              // re-retrieve vessel builders
              await this.getVesselBuilderOptions();

              // select the created vessel builder
              const builderFieldIndex = this.hullFields.findIndex(
                (f) => f.name === "builder_id"
              );
              const options = this.hullFields[builderFieldIndex].options;

              this.details.hull.builder_id = options.find(
                (o) => o.value === createdBuilderId
              ).value;
            }
          },
        }
      );
    },

    async autoUpdateBuildAddress(builder_id) {
      const answer = window?.confirm(
        "Do you want to update the Build Address with the currently selected Builder Address?"
      );
      if (answer) {
        const builderList = await getVesselBuilders(true);
        const builder = builderList?.data?.find(
          (builder) => builder.id.toString() === builder_id
        );
        if (builder) {
          this.$set(this.details.hull, "build_address", {
            ...this.details.hull.build_address,
            country: getCountryCode(builder.country),
            city: builder.city,
            line1: builder.line1,
            line2: builder.line2,
            postcode: builder.postcode,
          });
        }
      }
    },

    async builderSelected(builder_id) {
      if (builder_id === "0") {
        this.details.hull.builder_id = 0; // reset selection

        // show modal to create vessel builder
        this.lunchCreateVesselBuilderModal();
      } else {
        this.autoUpdateBuildAddress(builder_id);
      }
    },
  },
  mounted() {
    this.getVesselTypeOptions();
    this.getOtherServiceOptions();
    this.getVesselBuilderOptions();
    this.getVesselGeneratorsMakeAndModelsOptions();
    this.getVesselHullModelOptions();
  },
};
