import { getInstance } from "./authWrapper";
import axios from "axios";
import { Buffer } from "buffer";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    return next(false);
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }

    return next(false);
  });

  authService.$watch("user", async (user) => {
    if (!authService.loading) return;

    try {
      if (!user) return; // If set already, we return or we end up in an infite loop

      let token;
      try {
        token = await authService.auth0Client.getTokenSilently();
      } catch (e) {
        console.error("In authGuard watch user", e);
        authService.auth0Client.loginWithRedirect();
        return;
      }

      // Setting Auth token for all requests
      axios.defaults.headers.common["authorization"] = "Bearer " + token;

      const parseJwt = (token) => {
        var base64Payload = token.split(".")[1];
        var payload = Buffer.from(base64Payload, "base64");
        return JSON.parse(payload.toString());
      };

      let userMeta = parseJwt(token);

      authService.user = {
        ...authService.user,
        permissions: userMeta.permissions,
      };
    } finally {
      authService.loading = false;
    }
  });
};
