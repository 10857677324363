<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <button
          :class="{ disabled: $v.$anyError }"
          class="btn btn-sm btn-primary"
          @click="saveDetails"
        >
          Create Client
        </button>
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <div class="page-title-box pb-3">
          <h4>New Client</h4>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="form-check form-switch mb-3" style="padding-left: 0">
              <label class="form-check-label" for="client-type-switch"
                >Person</label
              >
              <input
                class="form-check-input float-none mx-2"
                type="checkbox"
                v-model="details.isCompany"
                id="client-type-switch"
              />
              <label class="form-check-label" for="client-type-switch"
                >Company</label
              >
            </div>

            <form @submit.prevent="saveDetails">
              <div class="row">
                <div
                  v-for="field in respectiveFields"
                  :key="field.name"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-text
                    v-model="details[field.name]"
                    :error="$v.details[field.name].$error"
                    :error-message="validationMessage($v.details[field.name])"
                    :field="field"
                  />
                </div>
                <template v-if="!details.isCompany">
                  <div
                    v-for="field in contactFields"
                    :key="`${0}-${field.name}`"
                    class="col-lg-3 col-md-6"
                  >
                    <form-input-text
                      v-model="$v.contacts.$each.$iter[0][field.name].$model"
                      :field="field"
                    />
                  </div>
                </template>
              </div>
              <address-editor
                v-for="(address, i) in addresses"
                :key="`address-${i}`"
                :address="address"
                class="col-lg-6"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="details.isCompany" class="row mt-4">
      <div class="col-12 d-flex justify-content-start align-items-center mb-3">
        <div class="page-title-box pb-0">
          <h4 class="mb-0">Contacts</h4>
        </div>
        <button
          class="ms-2 btn btn-sm btn-primary"
          title="Add Contact"
          type="button"
          @click="addContact"
        >
          <span class="sr-only">Add Contact</span>
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="saveDetails">
              <div
                v-for="(contact, i) in $v.contacts.$each.$iter"
                :key="`contact-${i}`"
                class="d-flex mb-2"
              >
                <div class="row flex-grow-1 d-flex">
                  <div
                    v-for="field in contactFields"
                    :key="`${i}-${field.name}`"
                    class="col-lg-3 col-md-6"
                  >
                    <form-input-text
                      v-model="$v.contacts.$each.$iter[i][field.name].$model"
                      :error="$v.contacts.$each.$iter[i][field.name].$error"
                      :error-message="
                        validationMessage(
                          $v.contacts.$each.$iter[i][field.name]
                        )
                      "
                      :field="field"
                    />
                  </div>
                </div>
                <div
                  v-if="contacts.length !== 1"
                  class="ms-4 flex-shrink-0 d-flex"
                >
                  <button
                    style="margin-top: 29px"
                    class="mb-auto btn btn-danger"
                    title="Remove Contact"
                    type="button"
                    @click="removeContact(i)"
                  >
                    <span class="sr-only">Remove Contact</span>
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputText from "../../components/ui/FormInputText.vue";
import { createClient } from "@/apis/clients";
import { email, required } from "vuelidate/lib/validators";
import { notifyError, notifySuccess } from "@/helpers/notification";
import { validationMessage } from "@/helpers/validations";
import AddressEditor from "../../components/ui/AddressEditor.vue";
import { confirmRedirect } from "../../helpers/generic";
import { store } from "../../main";
import _ from "lodash";

export default {
  name: "ClientsCreate",
  components: {
    WrapperPage,
    FormInputText,
    AddressEditor,
  },
  data() {
    return {
      isSavingDetails: false,
      addresses: [{}],
      details: {
        isCompany: true,
      },
      contacts: [{ type: null, name: null, phone: null, email: null }],
      formChanged: false,
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
      id_card: {},
      company_number: {},
      imo_number: {},
      vat_number: {},
      website: {},
    },
    contacts: {
      $each: {
        type: {},
        name: {
          required,
        },
        phone: {},
        email: {
          email,
        },
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      confirmRedirect(next);
    } else {
      next();
    }
  },
  mounted() {
    store.commit("storeDefaultFormState", {
      addresses: this.addresses,
      details: this.details,
      contacts: this.contacts,
    });
  },
  methods: {
    validationMessage,
    addContact() {
      if (!this.details.isCompany && this.contacts.length >= 1) return;

      this.contacts.push({ type: null, name: null, phone: null, email: null });
    },
    removeContact(index) {
      if (this.contacts.length === 1) return;

      this.contacts.splice(index, 1);
    },
    async saveDetails() {
      if (!this.company && this.contacts.length && !this.contacts[0].name) {
        this.contacts[0].name = this.details.name;
      }

      this.$v.$touch();
      if (this.$v.$anyError) {
        notifyError(this, "not all required fields have been entered");
        return;
      }

      this.isSavingDetails = true;

      let client = { ...this.details };
      if (this.details.isCompany) {
        if (this.contacts && this.contacts.length && this.contacts[0].name)
          client.contacts = this.contacts;
      } else {
        const contactDetails = this.contacts[0];
        client.contacts = [
          {
            name: this.details.name,
            phone: contactDetails.phone,
            email: contactDetails.email,
          },
        ];
      }

      if (this.addresses && this.addresses.length && this.addresses[0].line1)
        client.addresses = this.addresses;

      await createClient(client)
        .then(async ({ data }) => {
          if (data.id) {
            notifySuccess(this, "Client has been created");
            this.$router.push({
              name: "clients-show",
              params: { clientId: data.id },
            });
          } else notifyError();
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
      this.formChanged = false;
      this.isSavingDetails = false;
    },
    stateIsNotChanged() {
      const defaultDetails = store.state.dafultFormState?.details;
      const defaultAddresses = store.state.dafultFormState?.addresses;
      const defaultContacts = store.state.dafultFormState?.contacts;
      return (
        _.isEqual(this.details, defaultDetails) &&
        _.isEqual(this.addresses, defaultAddresses) &&
        _.isEqual(this.contacts, defaultContacts)
      );
    },
    updateFormChanged() {
      if (!this.formChanged) {
        this.formChanged = true;
        return;
      }
      if (this.stateIsNotChanged()) {
        this.formChanged = false;
      }
    },
  },
  computed: {
    contactFields() {
      const fields = [];

      if (this.details.isCompany)
        fields.push(
          { label: "Role", name: "type" },
          { label: "Name", name: "name" }
        );

      fields.push(
        { label: "Phone", name: "phone" },
        { label: "Email", name: "email", type: "email" }
      );

      return fields;
    },
    respectiveFields() {
      let fields = [];

      if (!this.details.isCompany) {
        fields = [
          { label: "Client Name", name: "name" },
          { label: "ID Card", name: "id_card" },
        ];
      }

      if (this.details.isCompany) {
        fields = [
          { label: "Company Name", name: "name" },
          { label: "Company Number", name: "company_number" },
          { label: "IMO Number", name: "imo_number" },
          { label: "VAT Number", name: "vat_number" },
          { label: "Website", name: "website" },
        ];
      }

      return fields;
    },
  },
  watch: {
    details: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    addresses: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    contacts: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
  },
};
</script>
