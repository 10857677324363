<template>
  <div class="col-12 col-md-3">
    <div class="row">
      <div
        v-for="field in fields"
        :key="`${field.name}`"
        :class="field.type === 'textarea' ? 'col-12' : 'col-6'"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.event[field.name].$model"
          :error="$v.event[field.name].$error"
          :error-message="validationMessage($v.event[field.name])"
          :field="field"
        />

        <form-input-select
          v-else-if="field.type === 'select'"
          v-model="$v.event[field.name].$model"
          :error="$v.event[field.name].$error"
          :error-message="validationMessage($v.event[field.name])"
          :field="field"
        />

        <form-input-text
          v-else
          v-model="$v.event[field.name].$model"
          :error="$v.event[field.name].$error"
          :error-message="validationMessage($v.event[field.name])"
          :field="field"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
          v-if="isEditing === false"
          class="btn btn-primary me-3"
          type="button"
          @click="addEvent(event)"
        >
          Add event
        </button>
        <button
          v-if="isEditing !== false"
          class="btn btn-primary me-3"
          type="button"
          @click="saveEvent(event)"
        >
          Save event
        </button>
        <button
          v-if="isEditing !== false"
          class="btn btn-info me-3"
          type="button"
          @click="cancelEdit"
        >
          Cancel
        </button>
      </div>
    </div>

    <!-- Documents, Upload & List -->
    <div v-if="event.id" class="row mt-4">
      <div class="col-12">
        <form-input-file
          :field="uploadField"
          :show-action-button="true"
          @action="uploadEventDocuments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import { CountryOptions } from "@/constants/address";
import FormInputText from "../../../ui/FormInputText.vue";
import FormInputDate from "../../../ui/FormInputText.vue";
import FormInputFile from "../../../ui/FormInputFile.vue";
import FormInputSelect from "../../../ui/FormInputSelect.vue";
import { uploadDocument } from "../../../../apis/files";
import { notifyError } from "@/helpers/notification";
import {
  VesselEventOutcomeOptions,
  VesselEventAccidentTypeOptions,
  VesselEventRefitOrModificationsOptions,
} from "@/constants/vessels";

export default {
  name: "SubEventEditor",
  components: { FormInputText, FormInputDate, FormInputFile, FormInputSelect },
  props: {
    vessel: Object,
    event_type: String,
  },
  data() {
    return {
      event: {
        event_type: this.event_type,
        event_date: moment().format("YYYY-MM-DD"),
        port: null,
        country: null,
        outcome: null,
        deficiencies: null,
        days_detained: null,
        notes: null,
      },
      uploadField: {
        label: "Event Documents",
        name: "event_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload",
      },
      isEditing: false,
      origEventForEdit: null, // Reference to the original object
    };
  },
  validations: {
    event: {
      port: {},
      country: {},
      outcome: {},
      deficiencies: {},
      days_detained: {},
      notes: {},
      event_date: { required, Date },
    },
  },
  computed: {
    fields() {
      let fields = [
        { label: "Event Date", name: "event_date", type: "date" },
        { label: "Port", name: "port", type: "text" },
        {
          label: "Country",
          name: "country",
          type: "select",
          includeNA: true,
          options: CountryOptions,
          sortOptions: false,
        },
        {
          label: "Outcome",
          name: "outcome",
          type: "select",
          options: VesselEventOutcomeOptions,
        },
        { label: "Deficiencies", name: "deficiencies", type: "number" },
        { label: "Days Detained", name: "days_detained", type: "number" },
        { label: "Notes", name: "notes", type: "textarea" },
      ];
      if (
        this.event_type === "incidents" ||
        this.event_type === "modifications"
      ) {
        fields.splice(4, 2);
        // Change the field for; outcome
        fields[3].label = "Type";
        if (this.event_type === "incidents") {
          fields[3].options = VesselEventAccidentTypeOptions;
        } else {
          fields[3].options = VesselEventRefitOrModificationsOptions;
        }
      }
      return fields;
    },
  },
  methods: {
    validationMessage,
    /** Public Methods */
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    setEventForEdit(event, index) {
      this.origEventForEdit = event;
      this.event = { ...event };
      if (this.event.event_date)
        this.event.event_date = moment(this.event.event_date).format(
          "YYYY-MM-DD"
        );
      this.isEditing = index;
    },

    /** Private Methods */
    resetEditor() {
      this.event = {
        event_type: this.event_type,
        event_date: moment().format("YYYY-MM-DD"),
        port: null,
        country: null,
        outcome: null,
        deficiencies: null,
        days_detained: null,
        notes: null,
      };
      this.isEditing = false;
    },
    addEvent(vesselEvent) {
      this.$emit("addEvent", vesselEvent);
      this.resetEditor();
    },
    saveEvent(vesselEvent) {
      // Copy the changes details to the original object
      this.origEventForEdit.event_date = vesselEvent.event_date;
      this.origEventForEdit.port = vesselEvent.port;
      this.origEventForEdit.country = vesselEvent.country;
      this.origEventForEdit.outcome = vesselEvent.outcome;
      this.origEventForEdit.deficiencies = vesselEvent.deficiencies;
      this.origEventForEdit.days_detained = vesselEvent.days_detained;
      this.origEventForEdit.notes = vesselEvent.notes;

      this.$emit("updateEvent", vesselEvent);
      this.resetEditor();
    },
    cancelEdit() {
      this.resetEditor();
    },
    async uploadEventDocuments(fileControl) {
      if (fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const uri = `vessels/${this.vessel.id}/${this.event_type}/${this.event.id}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(
            uploadDocument(file, uri).then((response) =>
              this.$emit("documentUploaded", response.data.path)
            )
          );
        }
        await Promise.all(promises);
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
      }
    },
  },
};
</script>
