import { machinePropulsionShaft, stringNumbers } from "./constants";

export const getMeter = (number) => {
  if (typeof number != "number") {
    return;
  }
  const meter = Math.floor(number) || 0;
  if (meter > 9) {
    return meter;
  }

  return `0${meter}`;
};

export const getCentiMeter = (number) => {
  if (typeof number != "number") {
    return;
  }

  const decimal = number - Math.floor(number);
  const centimeter = Math.round(decimal * 100);
  if (centimeter > 9) {
    return centimeter;
  }

  return `0${centimeter}`;
};

export const getofficialNumber = (officialNumber, imoNumber) => {
  if (officialNumber === imoNumber) {
    return imoNumber;
  }
  const officialNumberResul = officialNumber ? officialNumber : "--";
  const imoNumberResul = imoNumber ? imoNumber : "--";
  return `${imoNumberResul}/${officialNumberResul}`;
};

export const getCylinders = (mainEngines) => {
  if (!mainEngines || mainEngines.length === 0) {
    return;
  }
  const numberOfCylinders = (index) =>
    stringNumbers[Number(mainEngines[index]?.number_of_cylinders)];

  if (mainEngines.length < 2 || mainEngines[0].year === mainEngines[1].year) {
    return numberOfCylinders(0) && numberOfCylinders(0);
  }
  return `${numberOfCylinders(0) && numberOfCylinders(0)} ${
    numberOfCylinders(1) && numberOfCylinders(1)
  }`;
};

export const getModelAndSerial = (mainEngines, type) => {
  if (!mainEngines || mainEngines.length === 0) {
    return;
  }

  const model = (index) => mainEngines[index]?.model || "";
  const serial = (index) => mainEngines[index]?.serial_number || "";
  if (type === "model") {
    return `Model: ${model(0)}`;
  }

  if (type === "serial") {
    return `S/N: ${serial(0)}`;
  }

  if (mainEngines[0].year === mainEngines[1]?.year) {
    return `${model(0)} ${serial(0)} ${serial(1)}`;
  }
  return `${model(0)} ${serial(0)} ${model(1)} ${serial(1)}`;
};

export const getShafts = (mainEngines) => {
  if (!mainEngines || mainEngines.length === 0) {
    return;
  }
  const numberOfShafts = mainEngines?.filter(
    (engine) => engine.propulsion?.toLowerCase() === machinePropulsionShaft
  )?.length;
  return stringNumbers[numberOfShafts];
};

export const createMakerAddress = (maker) => {
  if (!maker) {
    return "";
  }
  return `${maker.city ? maker.city : "--"}, ${
    maker.country ? maker.country : "--"
  } `;
};
