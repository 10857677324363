import { StatusOptions } from "@/constants/projects";

/**
 * Get the text property of a StatusOption for which the value property matches
 * @param value The value property of a StatusOption
 * @returns {string} The text property
 */
export const getStatusLabel = (value) =>
  StatusOptions.find((status) => status.value === value)?.text;

/**
 * Get the class property of a StatusOption for which the value property matches
 * @param value The value property of a StatusOption
 * @returns {string} The class property
 */
export const getStatusClass = (value) =>
  StatusOptions.find((status) => status.value === value)?.class;
