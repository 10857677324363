<template>
  <div class="col-12">
    <div class="row flex-grow-1">
      <div v-if="$v.certification_.$model.vessel_name" class="col-12">
        <div class="header d-flex align-items-center">
          <h2>{{ $v.certification_.$model.vessel_name }}</h2>
        </div>
      </div>
      <div
        v-for="field in certificationFields"
        :key="`${field.name}`"
        :class="
          field.type === 'textarea' ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-3'
        "
        class="col-md-6"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.certification_[field.name].$model"
          :error="$v.certification_[field.name].$error"
          :error-message="validationMessage($v.certification_[field.name])"
          :field="field"
        />
        <form-input-select
          v-else-if="field.type === 'multiselect'"
          v-model="$v.certification_[field.name].$model"
          :error="$v.certification_[field.name].$error"
          :error-message="validationMessage($v.certification_[field.name])"
          :field="field"
        />
        <form-input-select
          v-else-if="field.type === 'select'"
          v-model="$v.certification_[field.name].$model"
          :error="$v.certification_[field.name].$error"
          :error-message="validationMessage($v.certification_[field.name])"
          :field="field"
          :parent="$v.certification_.$model"
        />
        <template v-else-if="field.name === 'superseded'">
          <div
            v-if="certification.superseded"
            class="input-group mb-3"
            style="margin-top: 7px"
          >
            <label class="fancy-checkbox form-control form-check-control me-0">
              <input :checked="true" :disabled="true" type="checkbox" />
              <span class="ms-2">Superseded</span>
            </label>
          </div>
        </template>
        <form-input-text
          v-else
          v-model="$v.certification_[field.name].$model"
          :error="$v.certification_[field.name].$error"
          :error-message="validationMessage($v.certification_[field.name])"
          :field="field"
        />
      </div>
    </div>

    <!-- Documents, Upload & List -->
    <div v-if="this.certification_.id" class="row">
      <div class="col-6">
        <form-input-file
          ref="certificationDocumentsInput"
          :field="uploadField"
          :show-action-button="false"
        />
      </div>
      <div class="col-6">
        <documents-list
          :documents="certificationDocuments"
          _title="Certificates"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../ui/FormInputText.vue";
import FormInputDate from "../../../ui/FormInputText.vue";
import FormInputFile from "../../../ui/FormInputFile.vue";
import FormInputSelect from "../../../ui/FormInputSelect";
import {
  VesselCertificates,
  IssuedByCertificateOption,
} from "@/constants/vessels";
import DocumentsList from "../../../ui/DocumentsList.vue";

export default {
  name: "CertificationEditor",
  components: {
    FormInputText,
    FormInputDate,
    FormInputSelect,
    FormInputFile,
    DocumentsList,
  },
  props: {
    certification: {
      type: Object,
    },
    certificationDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      certification_: null,

      certificationFields: [
        {
          label: "Certificate",
          name: "certificate",
          type: "multiselect",
          options: VesselCertificates,
        },
        {
          label: "Issued by",
          name: "issued_by",
          type: "select",
          options: IssuedByCertificateOption,
          includeOther: true,
        },
        { label: "Issue Date", name: "issue_date", type: "date" },
        { label: "Expiry Date", name: "expiry_date", type: "date" },
        { label: "Last Endorsed", name: "last_endorsed", type: "date" },
        { label: "Due Open", name: "due_open", type: "date" },
        { label: "Due Close", name: "due_close", type: "date" },
        { label: "Remarks", name: "remarks", type: "textarea" },
        { label: "Superseded", name: "superseded", type: "check" },
      ],
      uploadField: {
        label: "Certification Documents",
        name: "cert_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
      },
    };
  },
  validations: {
    certification_: {
      certificate: { required },
      issue_date: { Date },
      expiry_date: { Date },
      last_endorsed: { Date },
      due_open: { Date },
      due_close: { Date },
      remarks: {},
      superseded: {},
      issued_by: {},
      certificates_to_add: {},
      change: {},
    },
  },
  methods: {
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    prepareInternalCertification(certification) {
      const emptyCertification = {
        certificate: null,
        issued_by: null,
        superseded: false,
      };
      this.certification_ = { ...emptyCertification, ...certification };
      if (this.certification_.issue_date)
        this.certification_.issue_date = moment(
          this.certification_.issue_date
        ).format("YYYY-MM-DD");
      if (this.certification_.expiry_date)
        this.certification_.expiry_date = moment(
          this.certification_.expiry_date
        ).format("YYYY-MM-DD");
      if (this.certification_.last_endorsed)
        this.certification_.last_endorsed = moment(
          this.certification_.last_endorsed
        ).format("YYYY-MM-DD");
      if (this.certification_.due_open)
        this.certification_.due_open = moment(
          this.certification_.due_open
        ).format("YYYY-MM-DD");
      if (this.certification_.due_close)
        this.certification_.due_close = moment(
          this.certification_.due_close
        ).format("YYYY-MM-DD");
    },
  },
  watch: {
    certification(newCertification) {
      this.prepareInternalCertification(newCertification);
    },
  },
  created() {
    this.prepareInternalCertification(this.certification);
  },
};
</script>
