<template>
  <ParticularsSection
    v-if="projects.length"
    :with-narrow-columns="true"
    title="Projects"
    with-sub-sections
  >
    <ParticularsSection
      v-for="({ key, title, cells }, i) in projects"
      :key="key"
      :title="title"
    >
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :class="cell.key === 'name' && 'col-start-1'"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </ParticularsSection>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";

export default {
  name: "VesselParticularsProject",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    projectCells() {
      return extractCells(
        this.vessel.projects,
        [
          { label: "Name", key: "name" },
          { label: "Project Number", key: "project_number" },
          { label: "Category", key: "category" },
          { label: "Client", key: "client_name" },
          { label: "Date Assigned", key: "date_assigned", format: "date" },
          { label: "Department", key: "department" },
        ],
        this.excludeEmptyFields
      );
    },
    projects() {
      return [
        ...this.projectCells.map((cells, i) => ({
          key: `project-${i}`,
          title: `Project ${
            cells.find((c) => c.key === "project_number")?.value ?? i + 1
          }`,
          cells,
        })),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.project {
  display: contents;

  &:not(:last-child) {
    div:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
