<template>
  <div>
    <form @submit.prevent="getVesselsByTypeCountryAndCertExp">
      <div class="row">
        <div v-if="simplifiedVesselTypesList?.options?.length" class="col-md-4">
          <multi-select-dropdown
            v-model="selectedVesselType"
            :close-on-select="true"
            :field="simplifiedVesselTypesList"
            :multiple="false"
            :showLabels="false"
          />
        </div>

        <div class="col-md-4">
          <multi-select-dropdown
            v-model="selectedCountry"
            :close-on-select="true"
            :field="countryList"
            :multiple="false"
            :showLabels="false"
          />
        </div>

        <div v-if="portList?.options?.length" class="col-md-4">
          <multi-select-dropdown
            v-model="selectedPort"
            :close-on-select="true"
            :field="portList"
            :multiple="false"
            :showLabels="false"
          />
        </div>

        <div class="col-md-4">
          <form-input-date
            v-if="field('start') && field('start').type === 'date'"
            v-model="certExpiryDateStart"
            :field="field('start')"
          />
        </div>

        <div class="col-md-4">
          <form-input-date
            v-if="field('end') && field('end').type === 'date'"
            v-model="certExpiryDateEnd"
            :field="field('end')"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary" type="submit">Search</button>
        </div>
      </div>
    </form>

    <search-results-table
      v-if="searchResult"
      v-model="searchResult"
      class="mt-4"
    />
  </div>
</template>

<script>
import { search } from "@/apis/search";
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import { quickSearchBtnNames as btnNames } from "../../../constants";
import { CountryOptions } from "@/constants/address";
import FormInputDate from "../../ui/FormInputText.vue";
import { getVesselTypes as getTypes } from "@/apis/vessels";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";

export default {
  name: "VesselsByTypeCountryPortCert",
  components: {
    MultiSelectDropdown,
    SearchResultsTable,
    FormInputDate,
  },
  props: {},
  data() {
    return {
      btnNames,
      searchResult: null,
      simplifiedVesselTypesList: {
        label: "Select Type",
        placeholder: "Select Type",
      },
      selectedVesselType: null,
      countryList: {
        placeholder: "Select Country",
        label: "Select Country",
        name: "country",
        options: CountryOptions,
        sortOptions: true,
      },
      selectedCountry: null,
      portList: { label: "Select Port", placeholder: "Select Port" },
      selectedPort: null,
      certExpiryDateStart: null,
      certExpiryDateEnd: null,
    };
  },
  mounted() {
    this.getVesselTypes();
    this.getPorts().then((resp) => {
      this.portList = {
        ...resp,
        label: "Select Port",
        placeholder: "Select Port",
      };
    });
  },
  watch: {},
  methods: {
    field(name) {
      return name === "start"
        ? {
            name: "start_date",
            type: "date",
            label: "Select expiry start date",
            placeholder: "Select expiry start date",
          }
        : {
            name: "end_date",
            type: "date",
            label: "Select expiry end date",
            placeholder: "Select expiry end date",
          };
    },
    async getPorts() {
      let request = {
        distinct: ["vessels.base_port"],
        filter: [],
      };

      const response = await search(request);
      const uniqueValues = [
        ...new Set(response?.data.map((item) => item.base_port)),
      ];
      const portOptions = uniqueValues
        .map((port) => {
          return {
            text: port,
            value: port,
          };
        })
        .filter((item) => item.text);
      return {
        placeholder: "Select",
        name: "posrts",
        type: "select",
        options: portOptions,
        sortOptions: true,
      };
    },
    filterExpiryDate() {
      if (this.certExpiryDateStart && this.certExpiryDateEnd) {
        return [
          {
            operand: this.certExpiryDateStart,
            operand2: this.certExpiryDateEnd,
            operator: "between",
            property: "vessels_certifications.expiry_date",
          },
        ];
      }
      if (this.certExpiryDateStart) {
        return [
          {
            operand: this.certExpiryDateStart,
            operator: ">",
            property: "vessels_certifications.expiry_date",
          },
        ];
      }
      if (this.certExpiryDateEnd) {
        return [
          {
            operand: this.certExpiryDateEnd,
            operator: "<",
            property: "vessels_certifications.expiry_date",
          },
        ];
      }
      return [];
    },
    async getVesselsByTypeCountryAndCertExp() {
      const filterCountry = this.selectedCountry
        ? [
            {
              operand: this.selectedCountry.value,
              operator: "=",
              property: "vessels.country",
            },
          ]
        : [];
      const filterPort = this.selectedPort
        ? [
            {
              operand: this.selectedPort.value,
              operator: "=",
              property: "vessels.base_port",
            },
          ]
        : [];
      const filterType = this.selectedVesselType
        ? [
            {
              operand: this.selectedVesselType.value,
              operator: "=",
              property: "vessel_types.type",
            },
          ]
        : [];

      let request = {
        show: [
          "vessels.id",
          "vessels.name",
          "vessel_types.type",
          "vessels.country",
          "vessels.base_port",
          "vessels_certifications.expiry_date",
        ],

        filter: [
          ...filterCountry,
          ...filterPort,
          ...filterType,
          ...this.filterExpiryDate(),
        ],
      };

      const response = await search(request);
      this.searchResult = response.data;
    },
    async getVesselTypes() {
      const response = await getTypes();
      const options = response?.data.map((type) => {
        return {
          text: type.type,
          value: type.type,
        };
      });

      this.simplifiedVesselTypesList = {
        label: "Select Type",
        placeholder: "Select Type",
        name: "posrts",
        type: "select",
        options: options,
        sortOptions: true,
      };
    },
  },
  computed: {},
};
</script>

<style></style>
