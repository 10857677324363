<template>
  <div class="vertical-menu">
    <div class="h-100" data-simplebar>
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled">
          <li class="menu-title">Menu</li>

          <li v-bind="menuItemClass('/')">
            <router-link :to="{ name: 'dashboard' }" class="waves-effect">
              <i class="ri-home-4-line"></i>
              <span>Dashboard</span>
            </router-link>
          </li>

          <li>
            <a class="has-arrow waves-effect" href="javascript: void(0);">
              <i class="ri-add-circle-line"></i>
              <span>Create</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li>
                <router-link :to="{ name: 'clients-create' }"
                  >Client
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'projects-create' }"
                  >Project
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'vessels-create' }"
                  >Vessel
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow waves-effect"
              href="javascript: void(0);"
              v-bind="menuItemClass('/clients')"
            >
              <i class="ri-contacts-book-line"></i>
              <span>Clients</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li v-bind="menuItemClass('/clients')">
                <router-link :to="{ name: 'clients-dashboard' }"
                  >Dashboard
                </router-link>
              </li>
              <li v-bind="menuItemClass('/clients/list')">
                <router-link :to="{ name: 'clients-index' }"
                  >List Clients
                </router-link>
              </li>
              <li v-bind="menuItemClass('/clients/create')">
                <router-link :to="{ name: 'clients-create' }"
                  >Add new Client
                </router-link>
              </li>
            </ul>
          </li>

          <li v-bind="menuItemClass('/projects')">
            <a class="has-arrow waves-effect" href="javascript: void(0);">
              <i class="ri-briefcase-line"></i>
              <span>Projects</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li v-bind="menuItemClass('/projects')">
                <router-link :to="{ name: 'projects-dashboard' }"
                  >Dashboard
                </router-link>
              </li>
              <li v-bind="menuItemClass('/projects/list')">
                <router-link :to="{ name: 'projects-index' }"
                  >List Open Projects
                </router-link>
              </li>
              <li v-bind="menuItemClass('/projects/closed')">
                <router-link :to="{ name: 'projects-index-closed' }"
                  >List Closed Projects
                </router-link>
              </li>
              <li v-bind="menuItemClass('/projects/create')">
                <router-link :to="{ name: 'projects-create' }"
                  >Add Project
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow waves-effect"
              href="javascript: void(0);"
              v-bind="menuItemClass('/vessels', false)"
            >
              <i class="ri-ship-line"></i>
              <span>Vessels</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li v-bind="menuItemClass('/vessels')">
                <router-link :to="{ name: 'vessels-dashboard' }"
                  >Dashboard
                </router-link>
              </li>
              <li v-bind="menuItemClass('/vessels/list')">
                <router-link :to="{ name: 'vessels-index' }"
                  >List Vessels
                </router-link>
              </li>
              <!-- <li v-bind="menuItemClass('/vessels/certificates')">
                  <router-link :to="{ name: 'vessels-certificates-index' }"
                    >Vessel Certificates</router-link
                  >
                </li> -->
              <li v-bind="menuItemClass('/vessels/create')">
                <router-link :to="{ name: 'vessels-create' }"
                  >Add Vessel
                </router-link>
              </li>
            </ul>
          </li>

          <li v-bind="menuItemClass('/makes', false)">
            <a class="has-arrow waves-effect" href="javascript: void(0);">
              <i class="ri-building-2-line"></i>
              <span>Builders &amp; Makers</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li v-bind="menuItemClass('/makes/vessel-builders')">
                <router-link :to="{ name: 'vessel-builders-index' }">
                  Builders
                </router-link>
              </li>
              <li v-bind="menuItemClass('/makes/machinery-makers')">
                <router-link :to="{ name: 'machinery-makers-index' }">
                  Makers
                </router-link>
              </li>
              <li v-bind="menuItemClass('/makes/makers')">
                <router-link :to="{ name: 'makers-search' }">
                  Search Makers
                </router-link>
              </li>
            </ul>
          </li>

          <!--          <li v-bind="menuItemClass('/files')">
            <router-link :to="{ name: 'files' }" class="waves-effect">
              <i class=" ri-file-copy-2-line"></i
              >
              <span>Files</span>
            </router-link>
          </li>-->

          <li v-bind="menuItemClass('/search')">
            <router-link :to="{ name: 'search' }" class="waves-effect">
              <i class="ri-search-line"></i>
              <span>Search</span>
            </router-link>
          </li>

          <li v-bind="menuItemClass('/reports')">
            <router-link :to="{ name: 'reports-index' }" class="waves-effect">
              <i class="ri-bar-chart-2-line"></i>
              <span>Reports</span>
            </router-link>
          </li>

          <li>
            <a
              class="has-arrow waves-effect"
              href="javascript: void(0);"
              v-bind="menuItemClass('/resources')"
            >
              <i class="ri-bank-line"></i>
              <span>Resources</span>
            </a>
            <ul aria-expanded="false" class="sub-menu mm-collapse">
              <li v-bind="menuItemClass('/resources/certificates')">
                <router-link :to="{ name: 'resources-certificates' }">
                  Certification/Reports
                </router-link>
              </li>
              <li v-bind="menuItemClass('/resources/invoices')">
                <router-link :to="{ name: 'invoice-reports' }">
                  Invoice-Reports
                </router-link>
              </li>
              <!-- <li v-bind="menuItemClass('/resources/publication')">
                  <router-link :to="{ name: 'resources-publication' }"
                    >Administration</router-link
                  >
                </li>
                <li v-bind="menuItemClass('/resources/generate-documents')">
                  <router-link :to="{ name: 'resources-documents' }">
                    Standards & Codes
                  </router-link>
                </li> -->
            </ul>
          </li>

          <template v-if="isAdmin || managesJobs">
            <li class="menu-title">Administration</li>

            <li v-if="isAdmin" v-bind="menuItemClass('/users')">
              <a class="waves-effect" href="/users">
                <i class="ri-parent-line"></i>
                <span>List Users</span>
              </a>
            </li>

            <li
              v-if="isAdmin || managesJobs"
              v-bind="menuItemClass('/settings', false)"
            >
              <a class="has-arrow waves-effect" href="javascript: void(0);">
                <i class="ri-settings-3-line"></i>
                <span>Settings</span>
              </a>
              <ul aria-expanded="false" class="sub-menu mm-collapse">
                <li v-bind="menuItemClass('/settings/project-jobs')">
                  <router-link :to="{ name: 'project-jobs' }"
                    >Jobs (Project)
                  </router-link>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
import { store } from "@/main";

export default {
  name: "LeftSideBarNavigation",
  methods: {
    /**
     * Apply style to menu item in navigation based on current route
     * @param {string} path The path for this menu item
     * @param {bool} isChildItem Is this a parent/group menu item or an actual link to a page?
     */
    menuItemClass(path, isChildItem = true) {
      if (
        (isChildItem === true && this.$route.path === path) ||
        (isChildItem === false && this.$route.path.startsWith(path))
      ) {
        return { class: "mm-active" };
      }
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  computed: {
    user() {
      if (!store?.state?.users) return null;

      return store.state.users.find((u) => u.email === this.$auth.user.email);
    },
    showLogo() {
      return window.location.host.startsWith("noe.nas.com.mt");
    },
    isAdmin() {
      if (!this.$auth?.user) return false;

      return (
        this.$auth?.user?.name === "Tony Sammut" ||
        this.$auth?.user?.email === "kurt.gutteridge@nas.com.mt"
      );
    },
    managesJobs() {
      if (!this.$auth?.user) return false;

      return (
        this.$auth?.user?.email === "gabriel.gauci@nas.com.mt" ||
        this.$auth?.user?.email === "tracy.zammit@nas.com.mt"
      );
    },
  },
  async mounted() {
    store.dispatch("retrieveUsers");
  },
};
</script>

<style lang="scss">
li a {
  display: flex !important;
  align-items: center;
}
</style>
