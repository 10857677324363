<template>
  <wrapper-page ref="wrapper">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <certifications-table
              id="certifications"
              :allowDelete="false"
              :allowEdit="false"
              :certifications="certifications"
              :showSearchBar="false"
              :showVesselNames="true"
            />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import { getVessels, getVesselsCertifications } from "@/apis/vessels";
import CertificationsTable from "@/components/vessels/tabs/surveysAndCerts/CertificationsTable.vue";
import { notifyError } from "@/helpers/notification";
import moment from "moment";
import { getExpiryLevel } from "@/helpers/certificates";

export default {
  name: "VesselsCertificatesIndex",
  components: {
    CertificationsTable,
    WrapperPage,
  },
  data() {
    return {
      vessels: [],
      certifications: [],
      isLoadingVessels: false,
    };
  },
  methods: {
    async loadVessels() {
      this.isLoadingVessels = true;
      await getVessels()
        .then(({ data }) => {
          this.vessels = [...data];
        })
        .catch((err) => {
          this.isLoadingVessels = false;
          notifyError(this, err?.response?.data);
        });
    },
    async loadVesselsCertifications() {
      let filters = [];
      if (this.$route.query.ids) {
        filters.push({
          property: "vessel_id",
          operator: "in",
          operand: `${this.$route.query.ids}`,
        });
      }
      if (this.$route.query.filterBy) {
        filters.push({
          property: this.$route.query.filterBy,
          operator: "<",
          operand: `${this.$route.query.cutoffDate}`,
        });
      }

      await getVesselsCertifications({ filters })
        .then(({ data }) => {
          let allCertifications = data;
          let certifications = [];

          allCertifications
            .sort((a, b) => {
              if (moment(a.issue_date).isBefore(moment(b.issue_date))) return 1;
              else if (moment(a.issue_date).isAfter(moment(b.issue_date)))
                return -1;
              else return 0;
            })
            .forEach((certification) => {
              // if certifications does not already include a certification of this type
              if (
                !certifications.some(
                  (c) =>
                    c.certificate === certification.certificate &&
                    c.vessel_id === certifications.vessel_id
                )
              ) {
                const certificationVessel = this.vessels.find(
                  (v) => v.id === certification.vessel_id
                );

                const isInService =
                  certificationVessel?.service_status === "In Service";

                certification.isExpired = getExpiryLevel(
                  certification,
                  isInService
                );

                certifications.push(certification);
              }
            });

          this.certifications = certifications;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  async mounted() {
    await this.loadVessels();
    this.loadVesselsCertifications();
  },
  watch: {
    "$route.query"() {
      // In case we had a filter at the top and we click on the 'List Vessels' link with no filters
      this.loadVesselsCertifications();
    },
  },
};
</script>
