<template>
  <div class="col-12">
    <ul class="nav nav-tabs">
      <li v-for="tab in eventTabs" :key="tab" class="nav-item">
        <a
          :class="{ active: active_event_tab === tab }"
          class="nav-link"
          data-toggle="tab"
          href="#"
          @click="active_event_tab = tab"
        >
          {{ tab }} ({{ getEventCountForTab(tab) }})
        </a>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <former-names
          v-show="active_event_tab === 'Former Names'"
          ref="formerNames"
          :formerNames="vessel.formerNames"
        />
        <sub-event-tab
          v-show="active_event_tab === 'PSC'"
          ref="psc"
          :vessel="vessel"
          event_type="psc"
        />
        <sub-event-tab
          v-show="active_event_tab === 'FSI'"
          ref="fsi"
          :vessel="vessel"
          event_type="fsi"
        />
        <sub-event-tab
          v-show="active_event_tab === 'Accidents & Incidents'"
          ref="incidents"
          :vessel="vessel"
          event_type="incidents"
        />
        <sub-event-tab
          v-show="active_event_tab === 'Refits & Modifications'"
          ref="modifications"
          :vessel="vessel"
          event_type="modifications"
        />
        <sub-event-tab
          v-show="active_event_tab === 'Stakeholders Principals & Status'"
          ref="stakeholdersPrincipalsStatus"
          :event_type_list="eventTypeList"
          :vessel="vessel"
          event_type="modifications"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormerNames from "./FormerNames.vue";
import SubEventTab from "./SubEventTab.vue";

export default {
  name: "VesselsEventsTab",
  components: { FormerNames, SubEventTab },
  props: {
    vessel: Object,
  },
  data() {
    return {
      eventTabs: [
        "Former Names",
        "PSC",
        "FSI",
        "Accidents & Incidents",
        "Refits & Modifications",
        "Stakeholders Principals & Status",
      ],
      active_event_tab: "Former Names",
      eventTypeList: [
        "stakeholder",
        "registry",
        "flag",
        "status",
        "vessel_type",
      ],
    };
  },
  methods: {
    /*
    UI Related
    */
    getEventCountForTab(tab) {
      if (tab === "Former Names") return this.vessel.formerNames?.length || 0;
      if ((this.vessel.events?.length || 0) === 0) return 0;

      let event_type = tab.toLowerCase();
      switch (tab) {
        case "Accidents & Incidents":
          event_type = "incidents";
          break;
        case "Refits & Modifications":
          event_type = "modifications";
          break;
      }
      return this.vessel.events.filter(
        (event) => event.event_type === event_type
      ).length;
    },
    getAllEventsExceptFormerNames() {
      let events = [];
      events = events.concat(this.$refs.psc.getEvents());
      events = events.concat(this.$refs.fsi.getEvents());
      events = events.concat(this.$refs.incidents.getEvents());
      events = events.concat(this.$refs.modifications.getEvents());

      return events;
    },
  },
  computed: {
    /**
     * Public property to fetch the list of Former Names
     */
    tabFormerNames() {
      return this.$refs.formerNames;
    },
  },
};
</script>
