<template>
  <div>
    <form @submit.prevent="getVesselsByType">
      <div class="row">
        <div class="col-md-6">
          <multi-select-dropdown
            ref="columnGroup"
            v-model="selectedTypes"
            :field="vesselTypes"
            :showLabels="false"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button class="btn btn-primary" type="submit">Search</button>
        </div>
      </div>
    </form>

    <div v-if="searchResult">
      <div class="header">
        <button
          v-if="resultsLevel > 0"
          class="btn btn-secondary"
          type="button"
          @click="resultsLevel--"
        >
          Back
        </button>

        <div class="row">
          <div class="col-12 d-flex justify-content-end mb-3">
            <button class="btn btn-lg p-0 me-2" @click="toggleChart">
              <i
                v-if="showAs === 'table' && chartType === 'pie'"
                class="ri-pie-chart-line"
              />
              <i
                v-else-if="showAs === 'table' && chartType === 'bar'"
                class="ri-bar-chart-2-line"
              />
              <i
                v-if="showAs === 'chart' && chartType === 'bar'"
                class="ri-pie-chart-line"
              />
              <i
                v-else-if="showAs === 'chart' && chartType === 'pie'"
                class="ri-bar-chart-2-line"
              />
            </button>
            <button class="btn btn-lg p-0 me-2" @click="showAs = 'table'">
              <i class="ri-list-check" />
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <search-results-table
          v-if="showAs === 'table'"
          v-model="searchResult"
          @getAllSelected="getAllSelected"
        />
        <search-results-pie-chart
          v-if="showAs === 'chart' && chartType === 'pie'"
          v-model="searchResult"
        />
        <search-results-bar-chart
          v-if="showAs === 'chart' && chartType === 'bar'"
          v-model="searchResult"
          data-label="Number of Vessels by Vessel Type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { search } from "@/apis/search";
import MultiSelectDropdown from "../../ui/MultiSelectDropdown.vue";
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import { getVesselTypes as getTypes } from "@/apis/vessels";
import SearchResultsPieChart from "@/pages/search/SearchResultsPieChart.vue";
import SearchResultsBarChart from "@/pages/search/SearchResultsBarChart.vue";

export default {
  name: "NumberOfVesselsByType",
  components: {
    SearchResultsBarChart,
    SearchResultsPieChart,
    MultiSelectDropdown,
    SearchResultsTable,
  },
  props: {},
  data() {
    return {
      searchResult: null,
      resultsLevel: 0,
      vesselTypesList: [],
      selectedTypes: [],
      numberOffVesselsByType: [],
      showAs: "chart",
      chartType: "pie",
    };
  },
  mounted() {
    this.getVesselTypes().then((resp) => {
      this.vesselTypesList = resp;
    });
  },

  methods: {
    async getAllSelected(value) {
      if (this.resultsLevel === 1) {
        const route = this.$router.resolve({
          name: "vessels-show",
          params: { vesselId: value[0] },
        });
        window.open(route.href, "_blank");
        return;
      } else if (this.resultsLevel !== 0) return;

      // show modal to display the vessels in this type
      const type = value[1];
      const filterType = type
        ? [
            {
              operand: type,
              operator: "=",
              property: "vessel_types.type",
            },
          ]
        : [];

      let request = {
        show: [
          "vessels.id",
          "vessels.name",
          "vessel_types.type",
          "vessels.official_number",
          "vessels.s_reg",
          "vessels.call_sign",
          "vessels.imo_number",
          "vessels.mmsi_number",
          "vessels.registry",
          "vessels.flag",
          "vessels.former_registry",
          "vessels.other_service",
          "vessels.speed",
          "vessels.crew_accommodation",
          "vessels.passengers",
          "vessels.day_charter_passenger",
          "vessels.class",
          "vessels.class_notation",
          "vessels.class_maintained",
          "vessels.operational_range",
          "vessels.radio_range",
          "vessels.operational_restrictions",
          "vessels.base_port",
          "vessels.country",
          "vessels.service_status",
          "vessels.h_and_m",
        ],

        filter: [...filterType],
      };

      const response = await search(request);

      this.searchResult = response.data;
      this.resultsLevel = 1;
    },
    toggleChart() {
      if (this.showAs === "chart") {
        if (this.chartType === "pie") {
          this.chartType = "bar";
        } else if (this.chartType === "bar") {
          this.chartType = "pie";
        }
      } else this.showAs = "chart";
    },
    async getVesselTypes() {
      const response = await getTypes();

      return response?.data.map((type) => {
        return {
          displayName: type.type,
          value: { type: type.type, id: type.id },
        };
      });
    },
    options(columns) {
      if (!columns) {
        return [];
      }
      return columns
        .map((col) => {
          return { text: col.displayName, value: col.value };
        })
        .filter((item) => item); // console.log(options);
    },
    async getVesselsByType() {
      let request = {
        show: [
          "vessels.vessel_type as 'Type' ",
          {
            name: "countall",
          },
        ],
        filter: [],
      };

      const selectedTypesList = this.selectedTypes
        ?.map((type) => {
          return type.value.id;
        })
        .filter((item) => item);

      const response = await search(request);
      // Inject types with null result into the response obj.
      selectedTypesList.forEach((id) => {
        const resultObj = response?.data.find((obj) => obj.Type === id);
        if (!resultObj) {
          response.data.push({ Type: id, "Count(*)": 0 });
        }
      });

      const result = response?.data
        .map((type) => {
          const typeName = this.vesselTypesList.find(
            (typeObj) => typeObj?.value?.id === type?.Type
          );
          if (!selectedTypesList || !selectedTypesList.length) {
            return {
              number: type["Count(*)"],
              type: typeName?.displayName || "Type is not available",
            };
          } else {
            if (selectedTypesList.includes(type.Type)) {
              return {
                number: type["Count(*)"],
                type: typeName?.displayName || "Type is not available",
              };
            }
          }
        })
        .filter((item) => item);

      this.searchResult = result;
      this.resultsLevel = 0;
      this.chartType = this.searchResult.length > 5 ? "bar" : "pie";
    },
  },
  watch: {
    resultsLevel(newResultsLevel) {
      if (newResultsLevel === 0) this.getVesselsByType();
    },
  },
  computed: {
    vesselTypes() {
      const options = this.options(this.vesselTypesList);
      return {
        label: "Vessel Types",
        placeholder: "Select Vessel Types",
        name: "vessel-types",
        options,
      };
    },
  },
};
</script>

<style></style>
