const config = require("./config.json");
const { doubleDashToNull } = require("@/helpers/generic");
const { get, create, update, remove } = require("./common").default;
const { updateCountryNames } = require("../helpers/generic");

const vesselsUrl = config.apis.baseUrl.url + "vessels/";

/**
 * Retrieve all vessels
 * @returns Promise with an Axios response
 */
exports.getVessels = async ({ filters = [], matchAny = false } = {}) => {
  let params = [];
  if (Array.isArray(filters) && filters.length > 0) {
    params = [...filters];
    if (matchAny) params.push("matchAny=1");
  }

  return await get(vesselsUrl + "?" + params.join("&"));
};

/**
 * Retrieve a vessel with a specific ID
 * @param {number} id ID of the vessel to retrieve
 * @returns Promise with an Axios response
 */
exports.getVessel = async (id) => {
  return await get(`${vesselsUrl}${id}`);
};

/**
 * Create a new vessel
 * @param {object} data Details of the vessel
 * @returns Promise with an Axios response
 */
exports.createVessel = async (data) => {
  data = doubleDashToNull(data);
  return await create(vesselsUrl, data);
};

/**
 * Updates a vessel with a specific ID
 * @param {number} id ID of the vessel to update
 * @param {object} data Details of the vessel
 * @returns Promise with an Axios response
 */
exports.updateVessel = async (id, data) => {
  data = doubleDashToNull(data);
  return await update(`${vesselsUrl}${id}`, data);
};

/**
 * Deletes a vessel with a specific ID
 * @param {number} id ID of the vessel to delete
 * @returns Promise with an Axios response
 */
exports.deleteVessel = async (id) => {
  return await remove(`${vesselsUrl}${id}`);
};

/**
 * Retrieve all vessel types
 * @returns Promise with an Axios response
 */
exports.getVesselTypes = async () => {
  return await get(vesselsUrl + "lookups/types");
};

/**
 * Retrieve all vessel other services
 * @returns Promise with an Axios response
 */
exports.getOtherServices = async () => {
  return await get(vesselsUrl + "lookups/other_services");
};

/**
 * Retrieve all vessel builders
 * @param {Bool} forList If true, includes extra data to show when listing for editing
 * @returns Promise with an Axios response
 */
exports.getVesselBuilders = async (forList = false) => {
  let url = vesselsUrl + "lookups/builders";
  if (forList) url += "-list";
  const response = await get(url);
  //Override all countryCodes with countryNames.
  return updateCountryNames(response);
};

/**
 * Retrieve all vessel builders' models
 * @returns Promise with an Axios response
 */
exports.getVesselBuildersModels = async () => {
  let url = vesselsUrl + "lookups/builders_models";
  return await get(url);
};

/**
 * Retrieve all vessel machinery makes and models
 * @returns Promise with an Axios response
 */
exports.getVesselMachineryMakeModels = async () => {
  return await get(vesselsUrl + "lookups/machinery_make_models");
};

/**
 * Retrieve all vessel generators makes and models
 * @returns Promise with an Axios response
 */
exports.getVesselGeneratorsMakeModels = async () => {
  return await get(vesselsUrl + "lookups/generator_make_models");
};

/**
 * Create a new vessel builder
 * @param {object} data Details of the vessel builder
 * @returns Promise with an Axios response
 */
exports.getVesselBuilderModelsById = async (id) => {
  return await get(config.apis.baseUrl.url + `vessel_builders/${id}`);
};

/**
 * Create a new vessel builder
 * @param {object} data Details of the vessel builder
 * @returns Promise with an Axios response
 */
exports.createVesselBuilder = async (data) => {
  return await create(config.apis.baseUrl.url + "vessel_builders/", data);
};

/**
 * Delete a  vessel builder by builderId
 * @returns Promise with an Axios response
 */
exports.deleteVesselBuilder = async (builderId) => {
  return await remove(config.apis.baseUrl.url + `vessel_builders/${builderId}`);
};

/**
 * Create a new vessel builder contact
 * @param {Object} data New contact information.
 * @returns Promise with an Axios response
 */
exports.createVesselBuildersContact = async (data) => {
  let url = config.apis.baseUrl.url + "builder/contacts";
  return await create(url, data);
};

/**
 * Retrieve all vessel builder contacts
 * @param {Bool} forList If true, includes extra data to show when listing for editing
 * @returns Promise with an Axios response
 */
exports.getVesselBuildersContacts = async () => {
  let url = config.apis.baseUrl.url + "builder/contacts";
  return await get(url);
};

/**
 * Update an existing vessel builder contact
 * @param {Object} data Updated contact information.
 * @returns Promise with an Axios response
 */
exports.updateVesselBuildersContact = async (data) => {
  let url = config.apis.baseUrl.url + "builder/contacts";
  return await update(url, data);
};

/**
 * Update an existing vessel builder contact
 * @param {Object} data Updated contact information.
 * @returns Promise with an Axios response
 */
exports.deleteVesselBuildersContact = async (data) => {
  let url = config.apis.baseUrl.url + "builder/contacts";
  return await remove(url, data);
};

/**
 * Retrieve all projects for a vessel with a specific ID
 * @param {number} id ID of the vessel to retrieve projects for
 * @returns Promise with an Axios response
 */
exports.getVesselProjects = async (id) => {
  return await get(`${vesselsUrl}${id}/projects`);
};

exports.getVesselsCertifications = async ({
  filters = [],
  matchAny = false,
} = {}) => {
  let params = [];
  if (Array.isArray(filters) && filters.length > 0) {
    params.push("q=" + encodeURIComponent(JSON.stringify(filters)));
    if (matchAny) params.push("matchAny=1");
  }
  return await get(`${vesselsUrl}certifications?` + params.join("&"));
};

exports.supersedeCertificate = async (
  vesselId,
  certificateId,
  undo = false
) => {
  return await update(
    `${vesselsUrl}${vesselId}/certifications/${certificateId}/supersede${
      undo ? "?superseded=false" : ""
    }`
  );
};

exports.getVesselsForClientId = async (clientId, params) => {
  return await get(`${vesselsUrl}client/${clientId}${params}`);
};

exports.deleteVesselEventById = async (eventId) => {
  return await remove(`${vesselsUrl}events/${eventId}`);
};

exports.getVesselsForBuilderIdAndModels = async (builderId, data) => {
  return await create(`${vesselsUrl}vessel_builders/${builderId}/models`, data);
};
