<template>
  <div class="row">
    <template v-for="field in addressFields.slice(0, 2)">
      <div :key="field.name" class="col-12">
        <form-input-text v-model="address[field.name]" :field="field" />
      </div>
    </template>
    <div
      v-for="field in addressFields.slice(2, 4)"
      :key="field.name"
      class="col-lg-6 col-12"
    >
      <form-input-text v-model="address[field.name]" :field="field" />
    </div>
    <div
      v-for="field in addressFields.slice(4, 5)"
      :key="field.name"
      class="col-12"
    >
      <form-input-select
        v-if="field.type === 'select'"
        v-model="address[field.name]"
        :field="field"
      />
      <form-input-text v-else v-model="address[field.name]" :field="field" />
    </div>
  </div>
</template>

<script>
import FormInputSelect from "./FormInputSelect.vue";
import FormInputText from "./FormInputText.vue";
import { CountryOptions } from "@/constants/address";

export default {
  name: "AddressEditor",
  components: { FormInputText, FormInputSelect },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addressFields: [
        { label: "Address Line 1", name: "line1" },
        { label: "Address Line 2", name: "line2" },
        { label: "City", name: "city" },
        { label: "Post Code", name: "postcode" },
        {
          label: "Country",
          name: "country",
          type: "select",
          options: CountryOptions,
          sortOptions: false,
        },
      ],
    };
  },
};
</script>

<style></style>
