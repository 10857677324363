import { getVessels } from "@/apis/vessels";
import { getClientsForVessel } from "@/apis/clients";
import { notifyError } from "@/helpers/notification";

export default {
  data() {
    return {
      isLoadingVesselOptions: false,
      vesselOptions: [],
      vesselInput: null,
      vessels: [],
      vessel_clients: null,
    };
  },
  methods: {
    async getVesselOptions() {
      this.isLoadingVesselOptions = true;

      let vesselOptions = [];

      await getVessels()
        .then(({ data }) => {
          vesselOptions = data.map((c) => ({
            text: c.name,
            value: c.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.vesselOptions = vesselOptions;

      this.isLoadingVesselOptions = false;
    },
    async addVessel() {
      if (!this.vesselInput) return;

      if (!this.vessels.includes(+this.vesselInput))
        this.vessels.push(+this.vesselInput);

      if (this.vessels.length === 1) {
        getClientsForVessel(this.vessels[0])
          .then(({ data }) => {
            this.vessel_clients = data;
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }

      // if proposeNewName function exists
      if (this.proposeNewName) {
        // get the added vessel details
        const vessel = this.vesselOptions.find(
          (v) => v.value === +this.vesselInput
        );

        this.proposeNewName(vessel.text);
      }
    },
    removeVessel(index) {
      this.vessels.splice(index, 1);

      if (this.vessels.length === 0) {
        this.vessel_clients = null;
      } else if (this.vessels.length > 0 && index === 0) {
        getClientsForVessel(this.vessels[0])
          .then(({ data }) => {
            this.vessel_clients = data;
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
    },
  },
  computed: {
    attachedVessels() {
      const attached = [];
      let selectedVessels = [...this.vessels];

      if (!selectedVessels.length) return attached;

      this.vesselOptions.forEach((option) => {
        if (selectedVessels.includes(option.value)) {
          attached.push(option);
          const index = selectedVessels.findIndex((j) => j === option.value);
          selectedVessels.splice(index, 1);
        }
      });

      return attached;
    },
  },
  mounted() {
    this.getVesselOptions();
  },
};
