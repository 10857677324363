<template>
  <wrapper-page :page-title="whoDashboard">
    <template v-slot:MainContentHeaderActions>
      <div
        v-if="!isLoadingKpisUsers"
        class="col-md-6 col-sm-12 d-flex justify-content-end"
      >
        <multi-select-dropdown
          v-if="isSuperUser && impersonateField.options?.length"
          v-model="impersonateUser"
          :close-on-select="true"
          :field="impersonateField"
          :multiple="false"
          :showLabels="false"
        />
      </div>
    </template>
    <div class="row">
      <number-card
        v-if="kpis.in_progress !== undefined"
        :url="`/projects/list?${userIdParam}`"
        :value="kpis.in_progress"
        icon="ri-briefcase-line"
        label="Open Projects"
        size="col-xl-4 col-sm-6"
      ></number-card>
      <number-card
        v-if="kpis.overdue !== undefined"
        :url="`/projects/list?overdue=1&${userIdParam}`"
        :value="kpis.overdue"
        icon="ri-error-warning-line"
        label="Open Projects: Target Overdue"
        size="col-xl-4 col-sm-6"
      ></number-card>
    </div>
    <div class="row">
      <chart-card
        v-if="openProjectsCategories?.length"
        :value="openProjectsCategories"
        label="Open Projects Categories"
        size="col-xl-4 col-lg-6 col-md-6"
        url="/projects/list"
      ></chart-card>

      <chart-card
        v-if="openProjectsCategoriesProminent?.length"
        :value="openProjectsCategoriesProminent"
        label="Categories w/ > 5 Open Projects"
        size="col-xl-4 col-lg-6 col-md-6"
        url="/projects/list"
      ></chart-card>
      <chart-card
        v-if="
          (isUserLevel1 || isUserLevel2) &&
          closedProjectsPercentagePerYear?.length
        "
        :show-chart-types="['line', 'bar']"
        :url="`/projects/closed?${userIdParam}&invoiced=true`"
        :value="closedProjectsPercentagePerYear"
        label="Year % Projects Closed & Invoiced"
        size="col-xl-4 col-lg-6 col-md-6"
      ></chart-card>
      <chart-card
        v-if="closedProjectsPerYear?.length"
        :show-chart-types="['line', 'bar', 'pie']"
        :url="`/projects/closed?${userIdParam}`"
        :value="closedProjectsPerYear"
        label="Closed Projects per Year"
        size="col-xl-4 col-lg-6 col-md-6"
      ></chart-card>
      <chart-card
        v-if="closedProjectsPerMonth?.length"
        :show-chart-types="['line']"
        :url="`/projects/closed?${userIdParam}`"
        :value="closedProjectsPerMonth"
        label="Completed Projects: Last 12 Months"
        size="col-xl-4 col-lg-6 col-md-6"
      ></chart-card>
    </div>

    <template v-if="canSeeFinanceMetrics && hasFinancialData">
      <div class="row mt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="isFinancialExpanded = !isFinancialExpanded"
              >
                <div class="flex-grow-1">
                  <h6 class="mb-0">Financial Metrics</h6>
                </div>
                <div class="flex-shrink-0">
                  <i v-if="isFinancialExpanded" class="fa fa-chevron-up"></i>
                  <i v-else class="fa fa-chevron-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isFinancialExpanded" class="row">
        <chart-card
          :show-chart-types="['line', 'bar']"
          :url="`/projects/closed?${userIdParam}&invoiced=true`"
          :datasets="amountPerYear"
          label="Amount Per Year"
          size="col-md-6"
        ></chart-card>

        <chart-card
          :show-chart-types="['line', 'bar']"
          :url="`/projects/closed?${userIdParam}&invoiced=true`"
          :datasets="amountPerMonth"
          label="Amount Per Month"
          size="col-md-6"
        ></chart-card>

        <chart-card
          v-if="isSuperUser"
          :datasets="invoicedAmountPerYearPerDepartment"
          :options="{
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
            onClick: clickedDepartmentInvoiceChart,
          }"
          :show-chart-types="['bar']"
          :url="`/projects/closed?${userIdParam}&invoiced=true`"
          :value="[]"
          label="Invoiced Amount Per Department"
          size="col-md-6"
        ></chart-card>
        <template v-if="isSuperUser && selectedInvoicedDepartment?.department">
          <chart-card
            v-if="invoicedAmountPerYearPerDepartmentPerCategory?.length"
            :label="`Invoiced Amount Per Category for ${selectedInvoicedDepartment.department} in ${selectedInvoicedDepartment.year}`"
            :show-chart-types="['pie']"
            :url="`/projects/closed?${userIdParam}&invoiced=true`"
            :value="invoicedAmountPerYearPerDepartmentPerCategory"
            size="col-md-6"
          ></chart-card>
          <p v-else class="col-md-6">no data for invoiced department</p>
        </template>
        <chart-card
          v-if="isSuperUser"
          :datasets="paidAmountPerYearPerDepartment"
          :options="{
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
            onClick: clickedDepartmentPaidChart,
          }"
          :show-chart-types="['bar']"
          :url="`/projects/closed?${userIdParam}&invoiced=true`"
          :value="[]"
          label="Paid Amount Per Department"
          size="col-md-6"
        ></chart-card>
        <template v-if="isSuperUser && selectedPaidDepartment?.department">
          <chart-card
            v-if="paidAmountPerYearPerDepartmentPerCategory?.length"
            :label="`Paid Amount Per Category for ${selectedPaidDepartment.department} in ${selectedPaidDepartment.year}`"
            :show-chart-types="['pie']"
            :url="`/projects/closed?${userIdParam}&invoiced=true`"
            :value="paidAmountPerYearPerDepartmentPerCategory"
            size="col-md-6"
          ></chart-card>
          <p v-else class="col-md-6">no data for paid department</p>
        </template>
        <chart-card
          v-if="isSuperUser"
          :datasets="outstandingAmountPerYearPerDepartment"
          :options="{
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
            onClick: clickedDepartmentOutstandingChart,
          }"
          :show-chart-types="['bar']"
          :url="`/projects/closed?${userIdParam}&invoiced=true`"
          :value="[]"
          label="Outstanding Amount Per Department"
          size="col-md-6"
        ></chart-card>
        <template
          v-if="isSuperUser && selectedOutstandingDepartment?.department"
        >
          <chart-card
            v-if="outstandingAmountPerYearPerDepartmentPerCategory?.length"
            :label="`Outstanding Amount Per Category for ${selectedOutstandingDepartment.department} in ${selectedOutstandingDepartment.year}`"
            :show-chart-types="['pie']"
            :url="`/projects/closed?${userIdParam}&invoiced=true`"
            :value="outstandingAmountPerYearPerDepartmentPerCategory"
            size="col-md-6"
          ></chart-card>
          <p v-else class="col-md-6">no data for outstanding department</p>
        </template>
      </div>
    </template>
  </wrapper-page>
</template>

<script>
import { getFinancialKPIs, getUserKPIs } from "@/apis/kpis";
import WrapperPage from "../components/layout/WrapperPage.vue";
import NumberCard from "@/components/ui/NumberCard.vue";
import ChartCard from "@/components/ui/ChartCard.vue";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";
import moment from "moment";
import { isSuperUser } from "@/helpers/roles";

export default {
  name: "Dashboard",
  components: {
    MultiSelectDropdown,
    ChartCard,
    NumberCard,
    WrapperPage,
  },
  data() {
    return {
      isLoadingKpisUsers: true,
      isLoadingKpisFinancial: true,
      isFinancialExpanded: false,
      kpis: {},
      financialKpis: {},
      impersonateField: {
        name: "impersonate-user",
        type: "select",
        placeholder: "Impersonate another user",
        options: [],
      },
      impersonateUser: null,
      selectedInvoicedDepartment: null,
      selectedPaidDepartment: null,
      selectedOutstandingDepartment: null,
    };
  },
  methods: {
    async updateKPIs() {
      this.updateUserKPIs();
      this.updateFinancialKPIs();
    },
    async updateUserKPIs() {
      this.isLoadingKpisUsers = true;

      const response = await getUserKPIs(this.forUserId);
      this.kpis = response.data;

      this.isLoadingKpisUsers = false;
    },
    async updateFinancialKPIs() {
      this.isLoadingKpisFinancial = true;

      const response = await getFinancialKPIs(this.forUserId);
      this.financialKpis = response.data;

      this.isLoadingKpisFinancial = false;
    },
    clickedDepartmentInvoiceChart(point, event) {
      const item = event?.[0];
      const yearIndex = item?.index;
      const departmentIndex = item?.datasetIndex;

      if (!item || yearIndex === undefined || departmentIndex === undefined)
        return (this.selectedInvoicedDepartment = null);

      this.selectedInvoicedDepartment = {
        year: this.invoicedAmountPerYearPerDepartment[departmentIndex].data[
          yearIndex
        ].type,
        department:
          this.invoicedAmountPerYearPerDepartment[departmentIndex].label,
      };
    },
    clickedDepartmentPaidChart(point, event) {
      const item = event?.[0];
      const yearIndex = item?.index;
      const departmentIndex = item?.datasetIndex;

      if (!item || yearIndex === undefined || departmentIndex === undefined)
        return (this.selectedPaidDepartment = null);

      this.selectedPaidDepartment = {
        year: this.paidAmountPerYearPerDepartment[departmentIndex].data[
          yearIndex
        ].type,
        department: this.paidAmountPerYearPerDepartment[departmentIndex].label,
      };
    },
    clickedDepartmentOutstandingChart(point, event) {
      const item = event?.[0];
      const yearIndex = item?.index;
      const departmentIndex = item?.datasetIndex;

      if (!item || yearIndex === undefined || departmentIndex === undefined)
        return (this.selectedOutstandingDepartment = null);

      this.selectedOutstandingDepartment = {
        year: this.outstandingAmountPerYearPerDepartment[departmentIndex].data[
          yearIndex
        ].type,
        department:
          this.outstandingAmountPerYearPerDepartment[departmentIndex].label,
      };
    },
  },
  computed: {
    hasFinancialData() {
      if (!this.financialKpis) return false;

      if (Object.entries(this.financialKpis)?.length === 0) return false;

      return Object.keys(this.financialKpis).some((key) => {
        return Object.entries(this.financialKpis[key])?.length;
      });
    },
    forUserId() {
      if (this.isSuperUser && this.impersonateUser?.value)
        return this.impersonateUser.value;

      if (this.isSuperUser) return "all";

      return this.storeUsers?.find((u) => u.email === this.$auth.user.email)
        ?.id;
    },
    whoDashboard() {
      if (this.forUserId === "all") return "Office Dashboard";

      const user = this.storeUsers?.find((u) => u.id === this.forUserId)?.name;
      return `${user}'s Dashboard`;
    },
    userIdParam() {
      if (this.forUserId === "all") return "";

      return `userId=${this.forUserId}`;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    isSuperUser() {
      return isSuperUser(this.$auth?.user?.email);
    },
    isUserLevel1() {
      return this.$auth?.user?.permissions?.includes("read:billing:all");
    },
    isUserLevel2() {
      return this.$auth?.user?.permissions?.includes("read:billing:own");
    },
    canSeeFinanceMetrics() {
      if (this.isSuperUser) return true;

      return [
        "jp.farrugia@nas.com.mt",
        "gabriel.gauci@nas.com.mt",
        "jan.bonello@nas.com.mt",
        "kurt.mizzi@nas.com.mt",
        "psg@nas.com.mt",
      ].includes(this.$auth?.user?.email);
    },
    closedProjectsPercentagePerYear() {
      const last3Years = [];
      let cursor = moment().subtract(2, "years");
      for (let i = 0; i < 3; i++) {
        last3Years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      return last3Years.map((year) => ({
        type: year,
        number: this.kpis.closedProjectsPercentagePerYear[year] ?? 0,
      }));
    },
    openProjectsCategories() {
      const keys = Object.keys(this.kpis.categories ?? {});
      if (!keys?.length) return [];

      return keys.map((p) => ({
        type: p,
        number: this.kpis.categories[p],
      }));
    },
    openProjectsCategoriesProminent() {
      return this.openProjectsCategories.filter((c) => c.number >= 5);
    },
    closedProjectsPerYear() {
      const last5Years = [];
      let cursor = moment().subtract(4, "years");
      for (let i = 0; i < 5; i++) {
        last5Years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      return last5Years.map((year) => ({
        type: year,
        number: this.kpis.closedProjectsPerYear[year] ?? 0,
      }));
    },
    closedProjectsPerMonth() {
      if (!Object.keys(this.kpis.closedProjectsPerMonth)?.length) return [];

      const last12Months = [];
      let cursor = moment().subtract(11, "months");
      for (let i = 0; i < 12; i++) {
        last12Months.push(cursor.format("YYYY-MM"));
        cursor = cursor.add(1, "months");
      }

      return last12Months.map((month) => ({
        type: month,
        number: this.kpis?.closedProjectsPerMonth?.[month] ?? 0,
      }));
    },
    amountPerYear() {
      const years = [];
      let cursor = moment().subtract(3, "years");
      for (let i = 0; i <= 3; i++) {
        years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      const types = [
        { key: "invoicedAmountPerYear", label: "Invoiced" },
        { key: "paidAmountPerYear", label: "Paid" },
        { key: "outstandingAmountPerYear", label: "Outstanding" },
      ];

      return types.map((type) => ({
        label: type.label,
        data: years.map((year) => ({
          type: year,
          number: this.financialKpis[type.key]?.[year]?.net ?? 0,
        })),
      }));
    },
    amountPerMonth() {
      const last14Months = [];
      let cursor = moment().subtract(13, "months");
      for (let i = 0; i < 14; i++) {
        last14Months.push(cursor.format("YYYY-MM"));
        cursor = cursor.add(1, "months");
      }

      const types = [
        { key: "invoicedAmountPerMonth", label: "Invoiced" },
        { key: "paidAmountPerMonth", label: "Paid" },
        { key: "outstandingAmountPerMonth", label: "Outstanding" },
      ];

      return types.map((type) => ({
        label: type.label,
        data: last14Months.map((month) => ({
          type: month,
          number: this.financialKpis[type.key]?.[month]?.net ?? 0,
        })),
      }));
    },
    invoicedAmountPerYearPerDepartment() {
      let departments = [];

      // get all departments that have data
      Object.keys(
        this.financialKpis.invoicedAmountPerYearPerDepartment
      ).forEach((year) => {
        departments = [
          ...departments,
          ...Object.keys(
            this.financialKpis.invoicedAmountPerYearPerDepartment[year]
          ),
        ];
      });
      departments = [...new Set(departments)];

      const years = [];
      let cursor = moment().subtract(3, "years");
      for (let i = 0; i <= 3; i++) {
        years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      return departments.map((department) => ({
        label: department,
        data: years.map((year) => ({
          type: year,
          number:
            this.financialKpis.invoicedAmountPerYearPerDepartment?.[year]?.[
              department
            ]?.net ?? 0,
        })),
      }));
    },
    paidAmountPerYearPerDepartment() {
      let departments = [];

      // get all departments that have data
      Object.keys(this.financialKpis.paidAmountPerYearPerDepartment).forEach(
        (year) => {
          departments = [
            ...departments,
            ...Object.keys(
              this.financialKpis.paidAmountPerYearPerDepartment[year]
            ),
          ];
        }
      );
      departments = [...new Set(departments)];

      const years = [];
      let cursor = moment().subtract(3, "years");
      for (let i = 0; i <= 3; i++) {
        years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      return departments.map((department) => ({
        label: department,
        data: years.map((year) => ({
          type: year,
          number:
            this.financialKpis.paidAmountPerYearPerDepartment?.[year]?.[
              department
            ]?.net ?? 0,
        })),
      }));
    },
    outstandingAmountPerYearPerDepartment() {
      let departments = [];

      // get all departments that have data
      Object.keys(
        this.financialKpis.outstandingAmountPerYearPerDepartment
      ).forEach((year) => {
        departments = [
          ...departments,
          ...Object.keys(
            this.financialKpis.outstandingAmountPerYearPerDepartment[year]
          ),
        ];
      });
      departments = [...new Set(departments)];

      const years = [];
      let cursor = moment().subtract(3, "years");
      for (let i = 0; i <= 3; i++) {
        years.push(cursor.format("YYYY"));
        cursor = cursor.add(1, "years");
      }

      return departments.map((department) => ({
        label: department,
        data: years.map((year) => ({
          type: year,
          number:
            this.financialKpis.outstandingAmountPerYearPerDepartment?.[year]?.[
              department
            ]?.net ?? 0,
        })),
      }));
    },
    invoicedAmountPerYearPerDepartmentPerCategory() {
      if (
        !this.selectedInvoicedDepartment?.department ||
        !this.selectedInvoicedDepartment?.year
      )
        return [];

      const { department, year } = this.selectedInvoicedDepartment;
      if (!department || !year) return [];

      return Object.keys(
        this.financialKpis.invoicedAmountPerYearPerDepartmentPerCategory?.[
          year
        ]?.[department]
      ).map((category) => ({
        type: category,
        number:
          this.financialKpis.invoicedAmountPerYearPerDepartmentPerCategory?.[
            year
          ]?.[department]?.[category]?.net ?? 0,
      }));
    },
    paidAmountPerYearPerDepartmentPerCategory() {
      if (
        !this.selectedPaidDepartment?.department ||
        !this.selectedPaidDepartment?.year
      )
        return [];

      const { department, year } = this.selectedPaidDepartment;
      if (!department || !year) return [];

      return Object.keys(
        this.financialKpis.paidAmountPerYearPerDepartmentPerCategory?.[year]?.[
          department
        ]
      ).map((category) => ({
        type: category,
        number:
          this.financialKpis.paidAmountPerYearPerDepartmentPerCategory?.[
            year
          ]?.[department]?.[category]?.net ?? 0,
      }));
    },
    outstandingAmountPerYearPerDepartmentPerCategory() {
      if (
        !this.selectedOutstandingDepartment?.department ||
        !this.selectedOutstandingDepartment?.year
      )
        return [];

      const { department, year } = this.selectedOutstandingDepartment;
      if (!department || !year) return [];

      return Object.keys(
        this.financialKpis.outstandingAmountPerYearPerDepartmentPerCategory?.[
          year
        ]?.[department]
      ).map((category) => ({
        type: category,
        number:
          this.financialKpis.outstandingAmountPerYearPerDepartmentPerCategory?.[
            year
          ]?.[department]?.[category]?.net ?? 0,
      }));
    },
  },
  watch: {
    impersonateUser() {
      this.updateKPIs();
    },
    storeUsers() {
      this.$set(
        this.impersonateField,
        "options",
        this.storeUsers
          .filter((u) => u.email)
          .map((u) => ({
            value: u.id,
            text: u.name,
          }))
      );
    },
  },
  mounted() {
    this.updateKPIs();
  },
};
</script>
