import { getClients } from "@/apis/clients";
import { notifyError } from "@/helpers/notification";

export default {
  data() {
    return {
      isLoadingClientOptions: false,
      clientOptions: [],
      clientInput: null,
      stakeholderInput: null,
      stakeholders: [],
    };
  },
  methods: {
    async getClientOptionsForStakeholders() {
      this.isLoadingClientOptions = true;

      let clientOptions = [];

      await getClients({ fetchClientContactsDetails: true })
        .then(({ data }) => {
          clientOptions = data.map((client) => {
            return {
              text: client.isCompany
                ? client.name
                : `${client.name} - ${client.id_card}`,
              value: client.id,
              contacts: client.contacts
                ? client.contacts.map((contact) => ({
                    ...contact,
                    text: `${contact.name} - ${contact.type}`,
                    value: contact.id,
                  }))
                : [],
            };
          });
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.clientOptions = clientOptions;

      this.isLoadingClientOptions = false;
    },
    stakeholderClientSelected() {
      if (this.stakeholderOptionsForClient.length)
        this.stakeholderInput = this.stakeholderOptionsForClient[0].value;
      else this.stakeholderInput = null;
    },
    addStakeholder() {
      if (!this.clientInput || !this.stakeholderInput) return;

      if (
        !this.stakeholders.some(
          (stakeholder) => stakeholder.value === +this.stakeholderInput
        )
      ) {
        const stakeholder = this.stakeholderOptionsForClient.find(
          (option) => option.id === +this.stakeholderInput
        );
        this.stakeholders.push({ ...stakeholder });
      }
    },
    removeStakeholder(index) {
      this.stakeholders.splice(index, 1);
    },
  },
  computed: {
    stakeholderOptionsForClient() {
      if (!this.clientInput) return [];

      return this.clientOptions.find(
        (client) => client.value === +this.clientInput
      ).contacts;
    },
  },
  mounted() {
    this.getClientOptionsForStakeholders();
  },
};
