<template>
  <div class="card">
    <div class="card-body">
      <projects-table :projects="projects" :showSearchBar="false" />
    </div>
  </div>
</template>

<script>
import { getVesselProjects } from "@/apis/vessels";
import ProjectsTable from "../../../projects/ProjectsTable.vue";

export default {
  name: "VesselsProjectsTab",
  components: { ProjectsTable },
  props: {
    vessel: Object,
  },
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    if (!this.vessel) return;
    getVesselProjects(this.vessel.id).then(
      (data) => (this.projects = data.data)
    );
  },
};
</script>
