export const StakeholderRoleOptions = [
  {
    text: "Owner",
    value: "Owner",
  },
  {
    text: "Invoicing",
    value: "Invoicing",
  },
  {
    text: "Legal Representative",
    value: "Legal Representative",
  },
  {
    text: "Captain",
    value: "Captain",
  },
  {
    text: "Crew",
    value: "Crew",
  },
  {
    text: "Agent",
    value: "Agent",
  },
  {
    text: "Manager",
    value: "Manager",
  },
  {
    text: "DPA / CSO",
    value: "DPA / CSO",
  },
  {
    text: "Superintendent",
    value: "Superintendent",
  },
];

// This is an enum in the DB
export const HullMaterialOptions = [
  {
    text: "GRP",
    value: "GRP",
  },
  {
    text: "FRP",
    value: "FRP",
  },
  {
    text: "Steel",
    value: "Steel",
  },
  {
    text: "Aluminium",
    value: "Aluminium",
  },
  {
    text: "Wood",
    value: "Wood",
  },
  {
    text: "Wood-Composite",
    value: "Wood-Composite",
  },
  {
    text: "GRP Hull with Rubber Tubes",
    value: "GRP Hull with Rubber Tubes",
  },
];

// This is an enum in the DB
export const BuildOptions = [
  {
    text: "Carvel",
    value: "Carvel",
  },
  {
    text: "Clencher",
    value: "Clencher",
  },
];

// This is an enum in the DB
export const PropulsionOptions = [
  {
    text: "Dumb, Non Self-Propelled Ship",
    value: "Dumb",
  },
  {
    text: "Dumb, Outboard Motor Propelled",
    value: "Dumb, Outboard MP",
  },
  {
    text: "Motor Ship, Single Screw",
    value: "Motor, Single Screw",
  },
  {
    text: "Motor Ship, Twin Screw",
    value: "Motor, Double Screw",
  },
  {
    text: "Motor Ship, Triple Screw",
    value: "Motor, Triple Screw",
  },
  {
    text: "Motor Ship, Quadruple Screw",
    value: "Motor, Quad Screw",
  },
  {
    text: "Sail & Motor Ship, Single Screw",
    value: "Sail&Motor, Single Screw",
  },
  {
    text: "Sail & Motor Ship, Twin Screw",
    value: "Sail&Motor, Double Screw",
  },
  {
    text: "Sail & Motor Ship, Triple Screw",
    value: "Sail&Motor, Triple Screw",
  },
  {
    text: "Motor Ship, Single Water Jet",
    value: "Motor, Water Jet",
  },
  {
    text: "Motor Ship, Twin Water Jet",
    value: "Motor, Twin Water Jet",
  },
  {
    text: "Motor Ship, Triple Water Jet",
    value: "Motor, Triple Water Jet",
  },
  {
    text: "Motor Ship, Quad Water Jet",
    value: "Motor, Quad Water Jet",
  },
];

// This is an enum in the DB
export const RiggingOptions = [
  {
    text: "Not",
    value: "Not",
  },
  {
    text: "Sloop",
    value: "Sloop",
  },
  {
    text: "Ketch",
    value: "Ketch",
  },
  {
    text: "Schooner",
    value: "Schooner",
  },
  {
    text: "Yawl",
    value: "Yawl",
  },
  {
    text: "Junk",
    value: "Junk",
  },
  {
    text: "Brigantine",
    value: "Brigantine",
  },
  {
    text: "Barque",
    value: "Barque",
  },
  {
    text: "Cutter",
    value: "Cutter",
  },
];

// This is an enum in the DB
export const ClassOptions = [
  {
    text: "ABS",
    value: "ABS",
  },
  {
    text: "BV",
    value: "BV",
  },
  {
    text: "CCS",
    value: "CCS",
  },
  {
    text: "CL",
    value: "CL",
  },
  {
    text: "CRS",
    value: "CRS",
  },
  {
    text: "DBS",
    value: "DBS",
  },
  {
    text: "DNV",
    value: "DNV",
  },
  {
    text: "KR",
    value: "KR",
  },
  {
    text: "INSB",
    value: "INSB",
  },
  {
    text: "IR",
    value: "IR",
  },
  {
    text: "LR",
    value: "LR",
  },
  {
    text: "NK",
    value: "NK",
  },
  {
    text: "PRS",
    value: "PRS",
  },
  {
    text: "RMRS",
    value: "RMRS",
  },
  {
    text: "RINA",
    value: "RINA",
  },
  {
    text: "TL", // Turk Loydu
    value: "TL",
  },
];

export const RegulatoryRegimeOptions = [
  {
    text: "Small Ships Register",
    value: "Small Ships Register",
  },
  {
    text: "Fisheries",
    value: "Fisheries",
  },
  {
    text: "Torremolinos",
    value: "Torremolinos",
  },
  {
    text: "CVC",
    value: "CVC",
  },
  {
    text: "NCV",
    value: "NCV",
  },
  {
    text: "IACS99",
    value: "IACS99",
  },
  {
    text: "CYC",
    value: "CYC",
  },
  {
    text: "ILLC",
    value: "ILLC",
  },
  {
    text: "HSC",
    value: "HSC",
  },
  {
    text: "MARPOL",
    value: "MARPOL",
  },
  {
    text: "SOLAS",
    value: "SOLAS",
  },
  {
    text: "MLC",
    value: "MLC",
  },
];

// This is an enum in the DB
export const RadioRangeOptions = [
  {
    text: "A1",
    value: "A1",
  },
  {
    text: "A1+A2",
    value: "A1+A2",
  },
  {
    text: "A1+A2+A3",
    value: "A1+A2+A3",
  },
  {
    text: "A1+A2+A3+A4",
    value: "A1+A2+A3+A4",
  },
];

// This is an enum in the DB
export const MachineryTypeOptions = [
  {
    text: "Inboard",
    value: "Inboard",
  },
  {
    text: "Outboard",
    value: "Outboard",
  },
];

export const MachineryCycleOptions = [
  {
    text: "2-stroke",
    value: "2-stroke",
  },
  {
    text: "4-stroke",
    value: "4-stroke",
  },
];

// This is an enum in the DB
export const MachineryFuelOptions = [
  {
    text: "Petrol",
    value: "Petrol",
  },
  {
    text: "Diesel",
    value: "Diesel",
  },
  {
    text: "LPG",
    value: "LPG",
  },
  {
    text: "Electric",
    value: "Electric",
  },
  {
    text: "Hydrogen",
    value: "Hydrogen",
  },
  {
    text: "Hybrid Diesel-Electric",
    value: "Hybrid Diesel-Electric",
  },
];

// This is an enum in the DB
export const MachineryPropulsionOptions = [
  {
    text: "Shaft",
    value: "Shaft",
  },
  {
    text: "Z-drive",
    value: "Z-drive",
  },
  {
    text: "IPS",
    value: "IPS",
  },
  {
    text: "Outboard",
    value: "Outboard",
  },
  {
    text: "Saildrive",
    value: "Saildrive",
  },
  {
    text: "Surface drives",
    value: "Surface drives",
  },
  {
    text: "Water jet",
    value: "Water jet",
  },
];

// This is an enum in the DB
export const MachineryGearboxOptions = [
  {
    text: "V-drive",
    value: "V-drive",
  },
  {
    text: "Straight drive",
    value: "Straight drive",
  },
];

// This is an enum in the DB
export const MachineryPositionOptions = [
  {
    text: "PS",
    value: "PS",
  },

  {
    text: "SB",
    value: "SB",
  },

  {
    text: "C",
    value: "C",
  },
];

export const MachineryEmissionStadardOptions = [
  {
    text: "TIER I",
    value: "TIER I",
  },

  {
    text: "TIER II",
    value: "TIER II",
  },

  {
    text: "TIER III",
    value: "TIER III",
  },
];

// This is an enum in the DB
export const GeneratorFuelOptions = [
  {
    text: "Petrol",
    value: "Petrol",
  },
  {
    text: "Diesel",
    value: "Diesel",
  },
  {
    text: "LPG",
    value: "LPG",
  },
  {
    text: "Hydrogen",
    value: "Hydrogen",
  },
  {
    text: "Hybrid Diesel-Electric",
    value: "Hybrid Diesel-Electric",
  },
];

export const VesselCertificates = [
  { value: "AFS", text: "Anti-fouling Systems Certificate" },
  { value: "BWMC", text: "Ballast Water Management Certificate" },
  { value: "CYC", text: "Certificate of Compliance - CYC" },
  { value: "IACS99", text: "Certificate of Compliance - IACS 99" },
  { value: "Tonn", text: "Certificate of Survey & Tonnage Measurement" },
  { value: "COR", text: "Certificate of Registry" },
  { value: "CoS", text: "Certificate of Survey" },
  { value: "Torr", text: "Certificate of Compliance (Torremolinos)" },
  { value: "CLASS", text: "Classification Certificate" },
  { value: "CVC", text: "Commercial Vessels Certificate" },
  { value: "FVSC", text: "Fishing Vessel Safety Certificate" },
  { value: "MF", text: "Fisheries Safety Certificate" },
  { value: "ITC", text: "International Tonnage Certificate" },
  { value: "ILLC", text: "International Load Line Certificate" },
  { value: "IOPP", text: "International Oil Pollution Prevention Certificate" },
  { value: "IAPP", text: "International Air Pollution Prevention Certificate" },
  { value: "IEE", text: "International Energy Efficiency" },
  { value: "ISSC", text: "International Ship Security Certificate" },
  {
    value: "ISPP",
    text: "International Sewage Pollution Prevention Certificate",
  },
  { value: "MLC", text: "Maritime Labour Certificate" },
  { value: "MSM", text: "Minimum Safe Manning Certificate" },
  { value: "NCV", text: "Non-Convention Vessel Certificate" },
  { value: "PSSC", text: "Passenger Ship Safety Certificate" },
  { value: "RSL", text: "Radio Station License" },
  { value: "PTO", text: "Permit to Operate High Speed Craft" },
  { value: "SAFRAD", text: "Safety Radio Certificate" },
  { value: "SAFCON", text: "Safety Construction Certificate" },
  { value: "SAFEQ", text: "Safety Equipment Certificate" },
  { value: "SMC", text: "Safety Management Certificate" },
  { value: "SOC", text: "Statement of Compliance - CYC" },
  { value: "SRR", text: "Ship Recycling Regulations Certificate" },
  { value: "BC", text: "Solid Bulk Cargoes Certificate" },
  {
    value: "ERA",
    text: "Hazardous Waste Carriers – Certificate of Inspection",
  },
  { value: "FAC", text: "Freeboard Assignment Certificate" },
];

export const OperationalRange = [
  {
    text: "Unrestricted",
    value: "Unrestricted",
  },

  {
    text: "60 miles from safe haven",
    value: "60 miles from safe haven",
  },

  {
    text: "150 miles from safe haven",
    value: "150 miles from safe haven",
  },

  {
    text: "Exclusively within the Valletta Grand Harbour & Marsamxett Harbour",
    value: "Exclusively within the Valletta Grand Harbour & Marsamxett Harbour",
  },

  {
    text: "Within 1NM from the coast of Malta",
    value: "Within 1NM from the coast of Malta",
  },

  {
    text: "Within 3NM from the coast of Malta",
    value: "Within 3NM from the coast of Malta",
  },

  {
    text: "Within Maltese territorial waters",
    value: "Within Maltese territorial waters",
  },

  {
    text: "Within Maltese territorial waters and permitted to operate within Med. Sea during the tuna season",
    value:
      "Within Maltese territorial waters and permitted to operate within Med. Sea during the tuna season",
  },

  {
    text: "Within 30NM from the coast of Malta",
    value: "Within 30NM from the coast of Malta",
  },

  {
    text: "Cat. 1(A)",
    value: "Cat. 1(A)",
  },

  {
    text: "Cat. 1(B)",
    value: "Cat. 1(B)",
  },

  {
    text: "Cat. 2(A)",
    value: "Cat. 2(A)",
  },

  {
    text: "Cat. 2(B)",
    value: "Cat. 2(B)",
  },

  {
    text: "Cat. 3(A)",
    value: "Cat. 3(A)",
  },

  {
    text: "Cat. 3(B)",
    value: "Cat. 3(B)",
  },

  {
    text: "Cat. 3(C)",
    value: "Cat. 3(C)",
  },

  {
    text: "Cat. 3(D)",
    value: "Cat. 3(D)",
  },

  {
    text: "Cat. 3(E)",
    value: "Cat. 3(E)",
  },

  {
    text: "Cat. 3(F)",
    value: "Cat. 3(F)",
  },
];

export const HullTypeOptions = [
  {
    text: "Monohull",
    value: "Monohull",
  },

  {
    text: "Catamaran",
    value: "Catamaran",
  },

  {
    text: "Trimaran",
    value: "Trimaran",
  },
];

export const StemOptions = [
  {
    text: "Raked",
    value: "Raked",
  },

  {
    text: "Straight",
    value: "Straight",
  },

  {
    text: "Axe",
    value: "Axe",
  },
  {
    text: "Clipper",
    value: "Clipper",
  },
];

export const SternOptions = [
  {
    text: "Transom",
    value: "Transom",
  },

  {
    text: "Reverse Transom",
    value: "Reverse Transom",
  },

  {
    text: "Rounded",
    value: "Rounded",
  },
  {
    text: "Cruiser",
    value: "Cruiser",
  },

  {
    text: "Counter",
    value: "Counter",
  },
];

export const TypeOfPropellorsOptions = [
  {
    text: "FPP",
    value: "FPP",
  },

  {
    text: "CPP",
    value: "CPP",
  },

  {
    text: "Folding",
    value: "Folding",
  },
];

export const StartArrangmentOptions = [
  {
    text: "Electric",
    value: "Electric",
  },
  {
    text: "Compressed Air",
    value: "Compressed Air",
  },
  {
    text: "Other Cranking",
    value: "Other Cranking",
  },
];

export const ScopeOptions = [
  {
    text: "Initial",
    value: "Initial",
  },
  {
    text: "Annual/Periodical",
    value: "Annual/Periodical",
  },
  {
    text: "Intermediate",
    value: "Intermediate",
  },
  {
    text: "Renewal",
    value: "Renewal",
  },
  {
    text: "Occasional",
    value: "Occasional",
  },
  {
    text: "Dry-docking",
    value: "Dry-docking",
  },
  {
    text: "Single Voyage",
    value: "Single Voyage",
  },
  {
    text: "Static Charter",
    value: "Static Charter",
  },
  {
    text: "Pre-Coding",
    value: "Pre-Coding",
  },
];

// char(1) in DB
export const CeCategoryOptions = [
  {
    text: "A",
    value: "A",
  },

  {
    text: "B",
    value: "B",
  },

  {
    text: "C",
    value: "C",
  },

  {
    text: "D",
    value: "D",
  },
];

export const EmissionStandardOptions = [
  {
    text: "TIER I",
    value: "TIER I",
  },

  {
    text: "TIER II",
    value: "TIER II",
  },

  {
    text: "TIER III",
    value: "TIER III",
  },
];

// This is an enum in the DB
export const GeneratorTypeOptions = [
  {
    text: "Main",
    value: "Main",
  },

  {
    text: "Emergency",
    value: "Emergency",
  },

  {
    text: "Harbour",
    value: "Harbour",
  },
];

export const IssuedByCertificateOption = [
  {
    text: "NAS",
    value: "NAS",
  },
  {
    text: "Class",
    value: "Class",
  },
  {
    text: "Flag",
    value: "Flag",
  },
];

export const HullInspectionActivityOptions = [
  { text: "Anchor Chain Calibration", value: "Anchor Chain Calibration" },
  { text: "Electrical Insulation Test", value: "Electrical Insulation Test" },
  { text: "Hull Bottom Inspection", value: "Hull Bottom Inspection" },
  {
    text: "Propeller Shaft - Clearances",
    value: "Propeller Shaft - Clearances",
  },
  {
    text: "Propeller Shaft Withdrawal - Visual Inspection & Dye Penetrant",
    value: "Propeller Shaft Withdrawal - Visual Inspection & Dye Penetrant",
  },
  { text: "Propellers - Dye Penetrant", value: "Propellers = Dye Penetrant" },
  { text: "Rigging Inspection", value: "Rigging Inspection" },
  { text: "Rudder Shaft - Clearances", value: "Rudder Shaft - Clearances" },
  {
    text: "Rudder Shaft - Visual Inspection & Dye Penetrant",
    value: "Rudder Shaft - Visual Inspection & Dye Penetrant",
  },
  { text: "UTMs (if Steel)", value: "UTMs (if Steel)" },
  { text: "Valve Inspection", value: "Valve Inspection" },
];

export const HullInspectionRequirementOptions = [
  { text: "36 months (Intermediate)", value: "36 months (Intermediate)" },
  { text: "60 months (Renewal)", value: "60 months (Renewal)" },
];

export const VesselEventOutcomeOptions = [
  { text: "Pass no deficiencies", value: "Pass no deficiencies" },
  { text: "Pass with deficiencies", value: "Pass with deficiencies" },
  { text: "Detained", value: "Detained" },
];

export const VesselEventAccidentTypeOptions = [
  { text: "Not Serious", value: "Not Serious" },
  { text: "Serious", value: "Serious" },
  { text: "Very Serious", value: "Very Serious" },
];

// This is an enum in the DB
export const VesselEventRefitOrModificationsOptions = [
  { text: "Refit", value: "Refit" },
  { text: "Modification", value: "Modification" },
];
