<template>
  <div class="card-body">
    <div class="row">
      <certification-editor
        v-if="showEditor"
        ref="certEditor"
        :certification="certification"
        :certificationDocuments="certificationDocuments"
        :projectOptions="projectOptions"
      />
      <certification-viewer
        v-else
        ref="certViewer"
        :certification="certification"
        :certificationDocuments="certificationDocuments"
        :projectOptions="projectOptions"
      />
      <div class="col-12 mt-4 d-flex gap-x-3 justify-content-end">
        <button
          v-if="certification.id"
          class="btn btn-danger"
          type="button"
          @click="() => toggleSupersede(certification)"
        >
          {{ certification.superseded ? "Undo Supersede" : "Supersede" }}
        </button>
        <button
          v-if="allowDelete"
          class="btn btn-danger"
          type="button"
          @click="() => deleteCertification(certification)"
        >
          Delete
        </button>
        <button class="btn btn-secondary" type="button" @click="close">
          Cancel
        </button>
        <button
          v-if="allowEdit && !showEditor"
          class="btn btn-primary ms-3"
          type="button"
          @click="showEditor = !showEditor"
        >
          Edit
        </button>
        <button
          v-if="allowEdit && showEditor"
          class="btn btn-primary ms-3"
          type="submit"
          @click="saveDetails"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CertificationEditor from "./CertificationEditor.vue";
import CertificationViewer from "./CertificationViewer.vue";
import { uploadDocument } from "@/apis/files";
import { notifyError, notifySuccess } from "@/helpers/notification";
import { supersedeCertificate } from "@/apis/vessels";

export default {
  name: "CertificationEditorModal",
  components: { CertificationEditor, CertificationViewer },
  props: {
    certification: {
      type: Object,
    },
    certificationDocuments: {
      type: Array,
      default: () => [],
    },
    projectOptions: {
      type: Array,
      default: () => [],
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    startAsEditable: {
      type: Boolean,
      default: true,
    },
    vesselData: {
      type: Object,
      default: null,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    onDeleteCertification: {
      type: Function,
      required: false,
    },
    onSupersedeCertification: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      showEditor: this.startAsEditable,
    };
  },
  methods: {
    close(state = null) {
      this.$modal.hide("certification-editor-modal", state);
    },
    async saveDetails() {
      if (this.$refs.certEditor.isInvalid()) return;
      await this.uploadCertificateDocuments();

      this.close({ value: this.$refs.certEditor.certification_ });
    },
    deleteCertification(certification) {
      if (confirm("Are you sure you want to delete this Certification?")) {
        this.onDeleteCertification(certification);
        this.close();
      }
    },
    toggleSupersede(certification) {
      const newSupersededStatus = !certification.superseded;

      supersedeCertificate(
        certification.vessel_id,
        certification.id,
        !newSupersededStatus
      )
        .then(() => {
          notifySuccess(
            this,
            certification.superseded
              ? "Certificate has been unsuperseded"
              : "Certificate has been superseded"
          );
          certification.superseded = newSupersededStatus;
          this.onSupersedeCertification(certification);
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async uploadCertificateDocuments() {
      const fileControl =
        this.$refs.certEditor?.$refs.certificationDocumentsInput?.getFiles();

      if (!fileControl || fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const uri = `vessels/${this.certification.vessel_id}/certifications/${this.certification.id}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(
            uploadDocument(file, uri).then((response) =>
              this.certificationDocuments.push(response.data.path)
            )
          );
        }
        await Promise.all(promises).then(() => {
          this.$root.$emit("reloadVesselAfterCertificationDocsUploaded");
        });
        return true;
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
        return false;
      }
    },
  },
};
</script>
