<template>
  <div class="col-12">
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="input-container border input-group has-validation mb-3">
      <div
        class="custom-file py-3 px-3"
        @dragleave="dragleave"
        @dragover="dragover"
        @drop="drop"
      >
        <div :class="{ active: isDragging }" class="drop-area"></div>
        <input
          :id="`input-${field.name}`"
          ref="file"
          :class="{ 'parsley-error is-invalid': error }"
          :multiple="field.multiple"
          :name="field.name"
          :readonly="readonly || field.readonly"
          class="custom-file-input d-none"
          type="file"
          @change="onChange"
          @input="(e) => $emit('input', e.target.value)"
        />
        <label
          v-if="files.length"
          :for="`input-${field.name}`"
          class="custom-file-label preview-container"
        >
          <div v-for="file in files" :key="file.name" class="preview-card">
            <i v-if="file.type === 'application/pdf'" class="far fa-file-pdf" />
            <i
              v-else-if="
                [
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/msword',
                ].includes(file.type)
              "
              class="far fa-file-word"
            />
            <img v-else :src="generateURL(file)" />

            <p class="name">
              {{ file.name }}
            </p>
            <!--
            <p class="size">
              {{ Math.round(file.size / 1000) + "kb" }}
            </p>
            -->
            <button
              class="btn-remove"
              title="Remove file"
              type="button"
              @click.prevent="remove(files.indexOf(file))"
            >
              remove
            </button>
          </div>
        </label>
        <label
          v-else
          :for="`input-${field.name}`"
          class="custom-file-label no-preview-container"
        >
          <i class="fas fa-cloud-upload-alt"></i>
          <span
            v-if="textToShow"
            class="select-none text-muted fw-normal small text-center"
          >
            {{ textToShow }}</span
          >
        </label>
      </div>
      <div v-if="showActionButton" class="input-group-append">
        <button
          :title="field.actionButtonTitle"
          class="btn btn-secondary h-100"
          type="button"
          @click="(e) => $emit('action', this.$refs.file)"
        >
          {{ field.actionButtonTitle || "Upload" }}
        </button>
      </div>
    </div>

    <div v-if="error" class="invalid-feedback">
      <template v-if="errorMessage">{{ errorMessage }}</template>
      <template v-else>Error has been encountered</template>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputFile",
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    getFiles() {
      return this.$refs.file;
    },
    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(event) {
      event.preventDefault();

      this.$refs.file.files = event.dataTransfer.files;
      if (!this.field.multiple) {
        const file = this.$refs.file.files[0];

        let dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        this.$refs.file.files = dataTransfer.files;
      }

      this.onChange();
      this.isDragging = false;
    },
    remove(index) {
      this.files.splice(index, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    onChange() {
      this.files = [...this.$refs.file.files];
    },
    reset() {
      this.files = [];
    },
  },
  computed: {
    textToShow() {
      if (this.isDragging) return "Release to drop files here";

      return (
        this.field.placeholder ||
        this.field.label ||
        "Drop files here or click here to upload"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/assets/css/variables";

.input-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  flex-wrap: nowrap;

  .custom-file {
    height: 100%;
    position: relative;
    flex: 1 1 0;
    display: block;

    label {
      height: 100%;

      &::after {
        content: "";
        display: none;
      }
    }

    .drop-area {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        ellipse at center,
        rgb(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s;

      &.active {
        opacity: 1;
      }
    }
  }

  .input-group-append {
    flex: 0 0 0;
    min-height: 100%;
    height: auto;
  }

  .no-preview-container {
    display: flex;
    height: auto !important;
    min-height: 100%;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    margin-bottom: 0;
    position: relative;

    i {
      font-size: 2rem;
    }
  }

  .preview-container {
    column-gap: 0.75rem;
    place-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 60px));
    margin-bottom: 0;
    height: auto !important;
    min-height: 100%;
    row-gap: 1rem;
    padding: 0.75rem;
    position: relative;

    .preview-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      row-gap: 0.113rem;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-bottom: 0.25rem;
        border-radius: 5px;
      }

      i {
        margin-top: 3px;
        font-size: 1.75rem;
        margin-bottom: 0.25rem;
      }

      p {
        margin-bottom: 0;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.name {
          color: black;
          font-size: 0.88rem;
        }

        &.size {
          color: grey;
          font-size: 0.66rem;
        }
      }

      .btn-remove {
        background: transparent;
        border: none;
        font-size: 0.65rem;
        color: $brand-copper;
      }
    }
  }
}
</style>
