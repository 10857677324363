<template>
  <ParticularsSection v-if="cells.length" title="Hull, Dimensions & Capacity">
    <ParticularsCell
      v-for="cell in cells"
      :key="cell.label"
      :label="cell.label"
    >
      <p>{{ cell.value }}</p>
    </ParticularsCell>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";
import { CountryOptions } from "@/constants/address";
import { getVesselBuilders } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";

export default {
  name: "VesselParticularsHull",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vesselBuilders: [],
      isLoadingVesselBuilders: false,
    };
  },
  computed: {
    cells() {
      return extractCells(
        {
          ...this.vessel.hull,
          ...this.vessel.dimensions,
          ...this.vessel.hull?.build_address,
        },
        [
          { label: "Hull Material", key: "hull_material" },
          { label: "GT & NT (<24m)", key: "gt_and_nt" },
          { label: "GT", key: "gt" },
          { label: "NT", key: "nt" },
          { label: "Keel Laid", key: "keel_laid" },
          { label: "Build Year", key: "build_year" },
          { label: "Length (overall)", key: "length_overall" },
          { label: "Length", key: "length" },
          {
            label: "Length (between perp)",
            key: "length_between_perp",
          },
          { label: "Length (hull)", key: "length_hull" },
          {
            label: "Length (engine room)",
            key: "length_engine_room",
          },
          { label: "Breadth", key: "breadth" },
          { label: "Depth", key: "depth" },
          { label: "Draught", key: "draught" },
          { label: "Lightship", key: "lightship" },
          { label: "Displacement", key: "displacement" },
          { label: "Deadweight", key: "deadweight" },
          {
            label: "Assigned Freeboard",
            key: "assigned_freeboard",
          },
          { label: "Hull Type", key: "hull_type" },
          { label: "Build", key: "build" },
          { label: "Stem", key: "stem" },
          { label: "Stern", key: "stern" },
          { label: "Propulsion", key: "propulsion" },
          { label: "Rigging", key: "rigging" },
          { label: "Frame Spacing", key: "frame_spacing" },
          { label: "Decks", key: "number_of_decks" },
          {
            label: "Watertight Bulkheads",
            key: "number_of_watertight_bulkheads",
          },
          {
            label: "Superstructure Material",
            key: "superstructure_material",
          },
          {
            label: "Builder",
            key: "builder_id",
            format: (vesselBuilder) =>
              this.vesselBuilders.find((vb) => vb.value === vesselBuilder)
                ?.text,
          },
          { label: "Model", key: "model" },
          {
            label: "Country Outfitted",
            key: "country_outfitted",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
          { label: "Hull Number", key: "hull_number" },
          { label: "CE Category", key: "ce_category" },
          { label: "Maximum Power", key: "max_power" },
          { label: "Max. number of Persons", key: "max_persons" },
          { label: "Max Weight", key: "max_weight" },
          { label: "Hull Colour", key: "hull_colour" },
          { label: "Masts", key: "masts" },
          { label: "Build Address Line 1", key: "line1" },
          { label: "Build Address Line 2", key: "line2" },
          { label: "Build City", key: "city" },
          { label: "Build Post Code", key: "postcode" },
          {
            label: "Build Country",
            key: "country",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
        ],
        this.excludeEmptyFields
      );
    },
  },
  methods: {
    async getVesselBuilderOptions() {
      this.isLoadingVesselBuilders = true;

      await getVesselBuilders()
        .then(({ data }) => {
          this.vesselBuilders = data.map((c) => ({
            text: c.name,
            value: c.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.isLoadingVesselBuilders = false;
    },
  },
  mounted() {
    this.getVesselBuilderOptions();
  },
};
</script>
