const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;
const { updateCountryNames } = require("../helpers/generic");

const vesselsUrl = config.apis.baseUrl.url + "vessels/";

/**
 * Create a new Machinery Maker
 * @param {object} data Details of the Machinery Maker
 * @returns Promise with an Axios response
 */
exports.createMachineryMaker = async (data) => {
  return await create(config.apis.baseUrl.url + "machinery/maker", data);
};

/**
 * Retrieve all vessel machinery makers
 * @returns Promise with an Axios response
 */
exports.getMachineryMakers = async () => {
  let url = vesselsUrl + "lookups/machinery_makers";
  const response = await get(url);
  //Override all countryCodes with countryNames.
  return updateCountryNames(response);
};

/**
 * Retrieve all vessel machinery makers with address
 * @returns Promise with an Axios response
 */
exports.getMachineryMakersList = async () => {
  let url = vesselsUrl + "lookups/machinery_makers_list";
  const response = await get(url);
  //Override all countryCodes with countryNames.
  return updateCountryNames(response);
};

/**
 * Delete a Machinery Maker by makerId
 * @param {object} data Details of the Machinery Maker
 * @returns Promise with an Axios response
 */
exports.deleteMachineryMaker = async (makerId) => {
  return await remove(config.apis.baseUrl.url + `machinery/maker/${makerId}`);
};

/**
 * NOT YET IMPLEMENTED IN API.
 * Retrieve all machinery makers models
 * @returns Promise with an Axios response
 */
exports.getMachineryMakersModels = async () => {
  let url = vesselsUrl + "lookups/makers_models";
  return await get(url);
};

/**
 * Crteate a vessel machinery maker contact
 * @returns Promise with an Axios response
 */
exports.createMachineryMakersContact = async (data) => {
  let url = config.apis.baseUrl.url + "machinery/maker/contacts";
  return await create(url, data);
};

/**
 * Retrieve all vessel machinery makers contacts
 * @returns Promise with an Axios response
 */
exports.getMachineryMakersContacts = async () => {
  let url = config.apis.baseUrl.url + "machinery/maker/contacts";
  return await get(url);
};

/**
 * Update a vessel machinery maker contacts
 * @returns Promise with an Axios response
 */
exports.updateMachineryMakersContacts = async (data) => {
  let url = config.apis.baseUrl.url + "machinery/maker/contacts";
  return await update(url, data);
};

/**
 * Delete all vessel machinery makers contacts
 * @returns Promise with an Axios response
 */
exports.deleteMachineryMakerContact = async (data) => {
  let url = config.apis.baseUrl.url + "machinery/maker/contacts";
  return await remove(url, data);
};
