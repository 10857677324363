<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-custom dataTable vessels-js-data-table"
        >
          <thead>
            <tr>
              <th>Vessel</th>
              <th>Official number</th>
              <th>Call sign</th>
              <th>IMO number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vessel, i) in vessels" :key="i" @click="openForEdit(i)">
              <!-- Table view -->
              <td>{{ vessel?.name }}</td>
              <td>{{ vessel.official_number }}</td>
              <td>{{ vessel.call_sign }}</td>
              <td>{{ vessel.imo_number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getVessel } from "@/apis/vessels";
import CertificationDownloaderModal from "./CertificationDownloaderModal.vue";

export default {
  name: "VesselsResourcesCertTable",
  components: {},
  props: {
    vessels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
      vesselsData: {},
      isLoading: false,
    };
  },
  methods: {
    async getVesselData(vesselId) {
      if (!vesselId) return null;
      let data = this.vesselsData[vesselId];

      if (!data) {
        const response = await getVessel(vesselId);
        data = response?.data;
      }

      return data;
    },
    async openForEdit(index) {
      if (!this.isLoading) {
        this.isLoading = true;
        const vessel = this.vessels[index];
        const params = {
          vesselData: await this.getVesselData(vessel.id),
        };
        this.$modal.show(
          CertificationDownloaderModal,
          params,
          {
            name: "certification-downloader-modal",
            height: "500px",
            width: "60%",
            scrollable: true,
          },
          {
            "before-close": async () => {
              this.isLoading = false;
            },
          }
        );
      }
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".vessels-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  watch: {
    vessels() {
      this.buildDataTable();
    },
  },
};
</script>

<style>
.table tr td .vdp-datepicker {
  white-space: normal;
}

.table-responsive {
  overflow-x: visible;
}
</style>
