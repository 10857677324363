<template>
  <div>
    <form @submit.prevent="$emit('search', term)" class="position-relative">
      <input
        class="form-control"
        v-model="term"
        placeholder="Search..."
        type="text"
      />
      <span
        ><button type="submit" class="btn p-0">
          <i class="ri-search-line"></i></button
      ></span>
    </form>
    <div
      v-show="shouldShowResults"
      autofocus
      class="card card-body position-absolute overflow-auto shadow-lg"
      style="max-height: 80vh; top: 4.5rem"
      tabindex="1"
      @focusout="forceHide = true"
      ref="search-results-container"
    >
      <div
        v-for="(result, i) in results"
        :key="result.link"
        class="w-100 d-flex gap-x-2 align-items-center pb-1"
        :class="{
          'mb-2 border-bottom': results.length > 1 && i < results.length - 1,
        }"
      >
        <router-link :to="result.link" class="w-100" ref="search-result">
          <p class="select-none mb-0">{{ result.title }}</p>
          <p v-if="result.subtitle" class="select-none mt-1 mb-0">
            <small>{{ result.subtitle }}</small>
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    results: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      term: "",
      forceHide: false,
    };
  },
  computed: {
    shouldShowResults() {
      if (this.forceHide) return false;

      if (!this?.term?.length) return false;

      if (this?.results?.length) return true;

      return false;
    },
  },
  watch: {
    term() {
      this.forceHide = false;

      if (!this?.term?.length) this.forceHide = true;
    },
    results() {
      this.forceHide = false;

      this.$nextTick(() => {
        if (this.shouldShowResults && this.results?.length) {
          this.$refs["search-result"]?.[0]?.focus();
        }
      });
    },
  },
};
</script>
