import { PropulsionOptions } from "../../constants/vessels";
import { store } from "../../main";
import { getVesselTypes } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";
import { getCountryName } from "../../helpers/generic";
import {
  getMeter,
  getCentiMeter,
  getofficialNumber,
  getCylinders,
  getModelAndSerial,
  getShafts,
  createMakerAddress,
} from "./utils";
import { tonnageVoulmeBase, stringNumbers } from "./constants";

export const getExtendedData = async (data) => {
  const date_now = new Date().toISOString().split("T")[0];
  const date_now_day = new Date().getDate();
  const date_now_year = new Date().getFullYear();
  const date_now_month = new Date().getMonth() + 1;
  const date_now_month_name = new Date().toLocaleString("default", {
    month: "long",
  });
  let five_year_expiry_date = new Date(
    date_now_year + 5,
    date_now_month,
    date_now_day
  );
  five_year_expiry_date.setDate(five_year_expiry_date.getDate() - 1);
  five_year_expiry_date = five_year_expiry_date.toISOString().split("T")[0];

  const official_imo_number = getofficialNumber(
    data?.official_number,
    data?.imo_number
  );

  const flag = getCountryName(data?.flag) || data?.flag || "";

  // Vessel type 1 is a Pleasure Yacht, which does not have crew accommodation
  const crew_accommodation =
    data?.vessel_type === 1 ? "N/A" : data?.crew_accommodation;
  // Replace Vessel Type Id with Vessel Type Name
  let vesselTypeOptions = store.state.vesselTypeOptions;
  if (!vesselTypeOptions) {
    await getVesselTypes()
      .then(({ data }) => {
        vesselTypeOptions = data.map((c) => ({
          text: c.type,
          value: c.id,
        }));
        store.commit("storeVesselTypeOptions", vesselTypeOptions);
      })
      .catch((err) => {
        notifyError(this, err?.response?.data);
      });
  }
  let vesselTypeName = vesselTypeOptions
    ?.find((vesselType) => vesselType.value === data?.vessel_type)
    ?.text?.toUpperCase();
  // If Vessel Type is 'PLEASURE (Commercial Compliance)', then show as 'PLEASURE YACHT'
  if (data?.vesselType < 3) {
    vesselTypeName = "PLEASURE YACHT";
  }

  const defaultComplyText = `This vessel is to comply with the requirements of 
  the Commercial Yacht Code, 2020, as an equivalent to the International Convention 
  for the Safety of Life at Sea, 1974, as amended, under the terms of IMO Circular 
  SLS.14/Circ.298 dated 8 November 2007 as a ‘Yacht engaged in trade as a commercial vessel with up to 12 passengers’.`;

  data = extendDimensionsData(data);
  data = extendMachineryData(data);
  data = extendGeneratorData(data);
  data = extendHullData(data);
  data = extendStakeholdersData(data);
  data = extendSurveysData(data);
  data = extendClientsData(data);

  return {
    ...data,
    date_now,
    date_now_day,
    date_now_month,
    date_now_month_name,
    date_now_year,
    remarks: "none",
    official_imo_number,
    crew_accommodation,
    vesselTypeName,
    defaultComplyText,
    five_year_expiry_date,
    flag,
  };
};

/**
 * Ensures the @param data object has a 'dimensions' property with additional fields required in the template
 * @param {Object} data
 * @returns {Object} data with extended dimensions
 */
function extendDimensionsData(data) {
  const lengthM = getMeter(data?.dimensions?.length);
  const lengthCm = getCentiMeter(data?.dimensions?.length);
  const breadthM = getMeter(data?.dimensions?.breadth);
  const breadthCm = getCentiMeter(data?.dimensions?.breadth);
  const depthM = getMeter(data?.dimensions?.depth);
  const depthCm = getCentiMeter(data?.dimensions?.depth);
  const draughtM = getMeter(data?.dimensions?.draught);
  const draughtCm = getCentiMeter(data?.dimensions?.draught);
  const length_engine_roomM = getMeter(data?.dimensions?.length_engine_room);
  const length_engine_roomCm =
    getCentiMeter(data?.dimensions?.length_engine_room) || 0;
  const string_default_length = `${getMeter(data?.dimensions?.length) || ""}  ${
    getCentiMeter(data?.dimensions?.length) || ""
  } `;
  const string_length = `${getMeter(data?.dimensions?.length_hull) || ""}  ${
    getCentiMeter(data?.dimensions?.length_hull) || ""
  } `;
  const string_loa = `${getMeter(data?.dimensions?.length_overall) || ""}  ${
    getCentiMeter(data?.dimensions?.length_overall) || ""
  }`;
  const string_breadth = `${getMeter(data?.dimensions?.breadth) || ""}  ${
    getCentiMeter(data?.dimensions?.breadth) || ""
  }`;
  const string_depth = `${getMeter(data?.dimensions?.depth) || ""}  ${
    getCentiMeter(data?.dimensions?.depth) || ""
  }`;
  const tonnageVolume =
    tonnageVoulmeBase *
    Number(data?.dimensions?.length_overall) *
    Number(data?.dimensions?.breadth) *
    Number(data?.dimensions?.depth);
  let string_tonnage_volume = "";
  if (Number(tonnageVolume)) {
    string_tonnage_volume = `${getMeter(tonnageVolume)}  ${getCentiMeter(
      tonnageVolume
    )}`;
  }
  // ensure data has a 'dimensions' property and add the above constants to it
  data.dimensions = {
    ...data?.dimensions,
    lengthM,
    lengthCm,
    breadthM,
    breadthCm,
    depthM,
    depthCm,
    draughtM,
    draughtCm,
    length_engine_roomM,
    length_engine_roomCm,
    string_default_length,
    string_length,
    string_loa,
    string_breadth,
    string_depth,
    string_tonnage_volume,
  };

  return data;
}

/**
 * Adds some Machinery-related aggregate and convenience properties required in the template to the @param data object
 * @param {Object} data
 * @returns {Object} data with extended machinery properties
 */
function extendMachineryData(data) {
  const mainEngines = data?.machinery?.filter(
    (machinery) => machinery?.is_main_engine
  );
  const number_of_engines =
    stringNumbers[mainEngines?.length] || mainEngines?.length;
  const number_of_engines_ssr = mainEngines?.length || 0;
  const is_diesel = mainEngines[0]?.fuel === "Diesel" ? "X" : "";
  const mainEngine = {
    maker_id: mainEngines[0]?.maker_id,
    resultString: "",
    aggregate_derated_power: "--",
    model: mainEngines[0]?.model,
    year: mainEngines[0]?.year,
    propulsion: mainEngines[0]?.propulsion,
    string_cylinders: getCylinders(mainEngines),
    model_and_serial: getModelAndSerial(mainEngines),
    model_and_serial_model: getModelAndSerial(mainEngines, "model"),
    model_and_serial_serial_number: getModelAndSerial(mainEngines, "serial"),
    string_shafts: getShafts(mainEngines),
    make: mainEngines[0]?.make,
    serial_number: mainEngines[0]?.serial_number,
    cycle: mainEngines[0]?.cycle,
    power: mainEngines[0]?.power,
    type_of_propellors: mainEngines[0]?.type_of_propellors,
    emission_standard: mainEngines[0]?.emission_standard,
    type: mainEngines[0]?.type,
    number_of_cylinders: mainEngines[0]?.number_of_cylinders,
    fuel: mainEngines[0]?.fuel,
    gearbox: mainEngines[0]?.gearbox,
  };

  // get the aggregate mechanical power of the Main generators
  const mainEnginesAggregateMechPower = mainEngines?.reduce((total, engine) => {
    if (engine.power) {
      return total + Number(engine.power);
    }
    return total;
  }, 0);

  mainEngines.forEach((element) => {
    mainEngine.resultString += element.type + " " + element.year + ", ";
  });
  // const derated_prime_aggregate_power = primeMover?.is_derated
  //   ? primeMover.power
  //   : undefined;
  // get the aggregate power of all machinery
  mainEngine.aggregate_power = mainEngines?.reduce(
    (total, machinery) =>
      total + (Number(machinery.original_power) || Number(machinery.power)),
    0
  );
  // get the aggregate derated power of all machinery if (any) main machinery is derated.
  if (mainEngines?.some((mainEngine) => mainEngine?.is_derated)) {
    mainEngine.aggregate_derated_power = mainEngines?.reduce(
      (total, machinery) => total + Number(machinery.power),
      0
    );
  }

  const engine_description = `${
    mainEngines[0]?.fuel || mainEngines[1]?.fuel || ""
  } ${mainEngines[0]?.type || mainEngines[1]?.type || ""}`?.toUpperCase();
  const maker_name = mainEngine?.make || "";
  const selectedMaker = data?.machineryMakersList?.find(
    (maker) => maker?.name === maker_name
  );
  const maker_address = createMakerAddress(selectedMaker);

  const engineOne = {
    make: data?.machinery[0]?.make || "-",
    model: data?.machinery[0]?.model || "-",
    serial_number: data?.machinery[0]?.serial_number || "-",
    emission_standard: data?.machinery[0]?.emission_standard || "-",
    power: data?.machinery[0]?.power || "-",
  };
  const engineTwo = {
    make: data?.machinery[1]?.make || "-",
    model: data?.machinery[1]?.model || "-",
    serial_number: data?.machinery[1]?.serial_number || "-",
    emission_standard: data?.machinery[1]?.emission_standard || "-",
    power: data?.machinery[1]?.power || "-",
  };
  const engineThree = {
    make: data?.machinery[2]?.make || "-",
    model: data?.machinery[2]?.model || "-",
    serial_number: data?.machinery[2]?.serial_number || "-",
    emission_standard: data?.machinery[2]?.emission_standard || "-",
    power: data?.machinery[2]?.power || "-",
  };
  const engineFour = {
    make: data?.machinery[3]?.make || "-",
    model: data?.machinery[3]?.model || "-",
    serial_number: data?.machinery[3]?.serial_number || "-",
    emission_standard: data?.machinery[3]?.emission_standard || "-",
    power: data?.machinery[3]?.power || "-",
  };
  const engineFive = {
    make: data?.machinery[4]?.make || "-",
    model: data?.machinery[4]?.model || "-",
    serial_number: data?.machinery[4]?.serial_number || "-",
    emission_standard: data?.machinery[4]?.emission_standard || "-",
    power: data?.machinery[4]?.power || "-",
  };

  return {
    ...data,
    is_diesel,
    number_of_engines,
    number_of_engines_ssr,
    engine_description,
    mainEngine,
    mainEngines,
    engineOne,
    engineTwo,
    engineThree,
    engineFour,
    engineFive,
    mainEnginesAggregateMechPower: Number(mainEnginesAggregateMechPower),
    maker_name,
    maker_address,
  };
}

/**
 * Adds some Generator-related aggregate and convenience properties required in the template to the @param data object
 * @param {Object} data
 * @returns {Object} data with extended generator properties
 */
function extendGeneratorData(data) {
  // # Main Generators/Prime Mover section
  // There can be more than one main generator.
  const mainGenerators = data?.electricGenerators?.filter(
    (generator) => generator.type === "Main"
  );
  if (!mainGenerators || mainGenerators.length === 0) {
    const firstGenerator = data?.electricGenerators[0];
    const secondGenerator = data?.electricGenerators[1];
    if (firstGenerator) {
      mainGenerators.push(data?.electricGenerators[0]);
    }
    if (secondGenerator) {
      mainGenerators.push(data?.electricGenerators[1]);
    }
  }
  const mainGenerator = {
    model: mainGenerators[0]?.model || mainGenerators[1]?.model,
    year: mainGenerators[0]?.year || mainGenerators[1]?.year,
    make: mainGenerators[0]?.make || mainGenerators[1]?.make,
    serial_number:
      mainGenerators[0]?.serial_number || mainGenerators[1]?.serial_number,
    power: mainGenerators[0]?.power || mainGenerators[1]?.power,
    emission_standard:
      mainGenerators[0]?.emission_standard ||
      mainGenerators[1]?.emission_standard,
    type: mainGenerators[0]?.type || mainGenerators[1]?.type,
    fuel: mainGenerators[0]?.fuel || mainGenerators[1]?.fuel,
    frequency: mainGenerators[0]?.frequency || mainGenerators[1]?.frequency,
    total_electrical_power:
      mainGenerators[0]?.total_electrical_power ||
      mainGenerators[1]?.total_electrical_power,
  };
  const numberAndTypeOfMainGenerators = `${
    stringNumbers[mainGenerators.length] || mainGenerators.length
  }, ROTATING FIELD TYPE`;
  // TODO - The following const is incomplete
  const mainGeneratorsManufacturerCityCountry = `${
    mainGenerators[0]?.make ?? ""
  }`;
  // The above should also append =>  `, ${mainGenerators[0]?.city ?? ""}, ${mainGenerators[0]?.country ?? ""}`
  // Currently however we do not store this data
  const mainGeneratorsModelAndYearOfManufacture = `${
    mainGenerators[0]?.model ?? ""
  } ${mainGenerators[0]?.year ?? ""}`;
  // get the aggregate mechanical power of the Main generators
  const mainGeneratorsAggregateMechPower = mainGenerators?.reduce(
    (total, generator) => {
      if (generator.power) {
        return total + Number(generator.power);
      }
      return total;
    },
    0
  );

  // # Generators/Alternator section
  // There can be more than one main generator.
  const alternators = data?.electricGenerators?.filter(
    (generator) => generator.type !== "Main"
  );
  // TODO - The following const is incomplete
  const alternatorsManufacturerCityCountry = `${alternators[0]?.make ?? ""}`;
  // The above should also append =>  `, ${alternator[0]?.city ?? ""}, ${alternator[0]?.country ?? ""}`
  // Currently however we do not store this data
  const alternatorsModelAndYearOfManufacture = `${
    alternators[0]?.model ?? ""
  } ${alternators[0]?.year ?? ""}`;
  // get the aggregate mechanical power of the Main generators
  const alternatorsAggregateElecPower = alternators?.reduce(
    (total, generator) => total + generator.total_electrical_power,
    0
  );

  data.electricGenerators = {
    ...data.electricGenerators,
    mainGenerator,
    mainGenerators,
    numberAndTypeOfMainGenerators,
    mainGeneratorsManufacturerCityCountry,
    mainGeneratorsModelAndYearOfManufacture,
    mainGeneratorsAggregateMechPower,
    alternatorsManufacturerCityCountry,
    alternatorsModelAndYearOfManufacture,
    alternatorsAggregateElecPower,
  };

  return {
    ...data,
  };
}

/**
 * Adds some Hull-related aggregate and convenience properties required in the template to the @param data object
 * @param {Object} data
 * @returns {Object} data with extended hull properties
 */
function extendHullData(data) {
  let string_number_of_watertight_bulkheads = "";
  let string_number_of_decks = "";

  const hull = data?.hull;
  if (!hull) return data;

  if (
    !isNaN(hull?.number_of_watertight_bulkheads) &&
    hull?.number_of_watertight_bulkheads < 10
  ) {
    string_number_of_watertight_bulkheads =
      stringNumbers[hull.number_of_watertight_bulkheads];
  } else {
    string_number_of_watertight_bulkheads =
      hull?.number_of_watertight_bulkheads;
  }

  if (!isNaN(hull?.number_of_decks) && hull?.number_of_decks < 10) {
    string_number_of_decks = stringNumbers[hull.number_of_decks];
  } else {
    string_number_of_decks = hull?.number_of_decks ?? "";
  }

  if (isNaN(hull?.masts) || hull?.masts === 0) {
    hull.masts = "NIL";
  }
  // Get the text from PropulsionOptions matching data.hull.propulsion
  const propulsionText = PropulsionOptions.find(
    (propulsion) => propulsion.value === hull?.propulsion
  )?.text.toUpperCase();
  // Round the gt and nt values to the nearest integer and add them to the data object
  const roundGt = Math.round(hull?.gt) || hull?.gt;
  const roundNt = Math.round(hull?.nt) || hull?.nt;
  // Replace country ISO code with proper name in English from CountryOptions
  const buildCountryFull = getCountryName(hull?.build_address?.country);

  const hull_number = hull?.hull_number || "--";
  // Get the builder name and address
  const builder = hull?.builder;
  let builderAddress, builderNameAndAddress, builderName;
  if (builder) {
    builderAddress = builder.address?.city ?? "";
    if (builderAddress.length > 0) {
      builderAddress += ", ";
    }
    builderAddress += getCountryName(builder.address?.country);

    builderName = builder.name ?? "";
    builderNameAndAddress = builderName + "\n" + builderAddress;
  }

  // Capitalize all string properties in the hull object
  Object.keys(hull).forEach((key) => {
    if (typeof hull[key] === "string") {
      hull[key] = hull[key].toUpperCase();
    }
  });

  data.hull = {
    ...hull,
    hull_number,
    propulsionText,
    string_number_of_watertight_bulkheads,
    string_number_of_decks,
    roundGt,
    roundNt,
    buildCountryFull,
    builderAddress,
    builderName,
    builderNameAndAddress,
  };

  return {
    ...data,
  };
}

function extendStakeholdersData(data) {
  const owner = data?.stakeholders?.find(
    (stackeholder) => stackeholder.type?.toLowerCase() === "owner"
  );

  const owner_name = owner?.name || "";
  const owner_email = owner?.email || "";
  const owner_country = `${getCountryName(owner?.address?.country) || ""}`;
  const owner_address = `${getCountryName(owner?.address?.country) || ""} ${
    owner?.address?.postcode || ""
  } ${owner?.address?.city || ""} ${owner?.address?.line1 || ""} ${
    owner?.address?.line2 || ""
  }`;

  const manager = data?.stakeholders?.find(
    (stackeholder) => stackeholder.type?.toLowerCase() === "manager"
  );

  const manager_name = manager?.name || "";
  const manager_email = manager?.email || "";
  const manager_country = `${manager?.address?.country || ""}`;
  const manager_address = `${manager?.address?.country || ""} ${
    manager?.address?.postcode || ""
  } ${manager?.address?.city || ""} ${manager?.address?.line1 || ""} ${
    manager?.address?.line2 || ""
  }`;
  return {
    ...data,
    owner_name,
    owner_address,
    owner_country,
    owner_email,
    manager_name,
    manager_email,
    manager_country,
    manager_address,
  };
}

const extendSurveysData = (data) => {
  data?.surveys?.forEach((survey) => {
    survey.start_date = survey.start_date
      ? new Date(survey.start_date).toISOString().split("T")[0]
      : "";
    survey.end_date = survey.end_date
      ? new Date(survey.end_date).toISOString().split("T")[0]
      : "";
  });

  data?.hullInspections?.forEach((inspection) => {
    inspection.date = inspection.date
      ? new Date(inspection.date).toISOString().split("T")[0]
      : "";
    inspection.nextDue = inspection.nextDue
      ? new Date(inspection.nextDue).toISOString().split("T")[0]
      : "";
  });

  data?.certifications?.forEach((certification) => {
    certification.issue_date = certification.issue_date
      ? new Date(certification.issue_date).toISOString().split("T")[0]
      : "";
    certification.expiry_date = certification.expiry_date
      ? new Date(certification.expiry_date).toISOString().split("T")[0]
      : "";
    certification.last_endorsed = certification.last_endorsed
      ? new Date(certification.last_endorsed).toISOString().split("T")[0]
      : "";
    certification.due_open = certification.due_open
      ? new Date(certification.due_open).toISOString().split("T")[0]
      : "";
    certification.due_close = certification.due_close
      ? new Date(certification.due_close).toISOString().split("T")[0]
      : "";
  });

  data?.events?.forEach((event) => {
    event.event_date = event.event_date
      ? new Date(event.event_date).toISOString().split("T")[0]
      : "";
  });

  const pscEvents = data?.events?.filter((event) => event.event_type === "psc");
  const fsiEvents = data?.events?.filter((event) => event.event_type === "fsi");
  const incidentEvents = data?.events?.filter(
    (event) => event.event_type === "incidents"
  );

  return {
    ...data,
    pscEvents,
    fsiEvents,
    incidentEvents,
  };
};

const getInvoicingClientMainContact = (invoicingClient) => {
  if (!invoicingClient || !invoicingClient?.contacts) {
    return;
  }
  return (
    invoicingClient?.contacts?.find(
      (contact) => contact.type?.toLowerCase() === "main"
    ) || invoicingClient?.contacts[0]
  );
};

const getInvoicingClientAddress = (invoicingClient) => {
  if (!invoicingClient || !invoicingClient?.addresses) {
    return;
  }
  return (
    invoicingClient?.addresses?.find(
      (address) => address.type?.toLowerCase() === "main"
    ) || invoicingClient?.addresses[0]
  );
};

const generateAddressline = (address) => {
  if (!address) {
    return "--";
  }
  return `${address?.postcode && address?.postcode} ${
    address?.city && address?.city
  } ${address?.line1 && address?.line1} ${address?.line2 && address?.line2}`;
};
const extendClientsData = (data) => {
  const invoicingClientName = data?.invoicingClient?.name || "--";
  const invoicingClientVAT = data?.invoicingClient?.vat_number || "--";
  const mainAddress = getInvoicingClientAddress(data?.invoicingClient);
  const invoicingClientAddress = generateAddressline(mainAddress);
  const invoicingClientCountry = mainAddress?.country || "--";
  const mainContact = getInvoicingClientMainContact(data?.invoicingClient);
  const invoicingClientContactName = mainContact?.name || "--";
  const invoicingClientContactEmail = mainContact?.email || "--";

  return {
    ...data,
    invoicingClientName,
    invoicingClientVAT,
    invoicingClientAddress,
    invoicingClientCountry,
    invoicingClientContactName,
    invoicingClientContactEmail,
  };
};
