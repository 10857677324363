<template>
  <div class="d-flex flex-column gap-y-3">
    <QuickButton
      :is-selected="
        btnEnabled === btnNames.SEARCH_VESSELS_BY_TYPE_COUNTRY_CERT_EXP
      "
      title="Search vessels by type, country, port, and certificate expiry"
      @toggled="enableButton(btnNames.SEARCH_VESSELS_BY_TYPE_COUNTRY_CERT_EXP)"
    >
      <VesselsByTypeCountryPortCert />
    </QuickButton>
    <QuickButton
      :is-selected="btnEnabled === btnNames.SEARCH_CLIENTS_AND_CONTACTS"
      title="Search clients and contacts by name, email, and phone number"
      @toggled="enableButton(btnNames.SEARCH_CLIENTS_AND_CONTACTS)"
    >
      <ClientsAndContactsByNameEmailOrPhone />
    </QuickButton>
  </div>
</template>

<script>
import { quickSearchBtnNames as btnNames } from "../../constants";

import VesselsByTypeCountryPortCert from "./quickSearchComponents/VesselsByTypeCountryPortCert.vue";
import ClientsAndContactsByNameEmailOrPhone from "./quickSearchComponents/ClientsAndContactsByNameEmailOrPhone.vue";
import QuickButton from "@/components/search/QuickButton.vue";

export default {
  name: "QuickSearch",
  components: {
    QuickButton,
    VesselsByTypeCountryPortCert,
    ClientsAndContactsByNameEmailOrPhone,
  },
  props: {},
  data() {
    return {
      btnEnabled: null,
      btnNames,
    };
  },
  methods: {
    enableButton(btnName) {
      if (this.btnEnabled === btnName) {
        this.btnEnabled = null;
        return;
      }

      this.btnEnabled = btnName;
    },
  },
};
</script>
