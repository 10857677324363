var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"fancy-checkbox d-flex"},[_c('label',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isDetailed},on:{"change":_vm.changeDetailed}}),_c('span',{staticClass:"ms-2"},[_vm._v("Detailed Mode")])]),_vm._m(0)])]),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn me-2 mb-2",class:_vm.projectsActive ? 'btn-danger' : 'btn-info',attrs:{"type":"button"},on:{"click":function($event){_vm.projectsActive
          ? _vm.removeColumns(_vm.internalTableNames.PROJECTS)
          : _vm.addColumns(_vm.internalTableNames.PROJECTS)}}},[_vm._v(" "+_vm._s(_vm.projectsActive ? "Remove" : "Add")+" Project Columns ")]),_c('button',{staticClass:"btn me-2 mb-2",class:_vm.vesselsActive ? 'btn-danger' : 'btn-info',attrs:{"type":"button"},on:{"click":function($event){_vm.vesselsActive
          ? _vm.removeColumns(_vm.internalTableNames.VESSELS)
          : _vm.addColumns(_vm.internalTableNames.VESSELS)}}},[_vm._v(" "+_vm._s(_vm.vesselsActive ? "Remove" : "Add")+" Vessel Columns ")]),_c('button',{staticClass:"btn me-2 mb-2",class:_vm.clientsActive ? 'btn-danger' : 'btn-info',attrs:{"type":"button"},on:{"click":function($event){_vm.clientsActive
          ? _vm.removeColumns(_vm.internalTableNames.CLIENTS)
          : _vm.addColumns(_vm.internalTableNames.CLIENTS)}}},[_vm._v(" "+_vm._s(_vm.clientsActive ? "Remove" : "Add")+" Client Columns ")])]),_c('div',{staticClass:"body mb-3"},[_c('multi-select-dropdown',{ref:"columnGroup",attrs:{"field":_vm.columnList},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1),_vm._l((_vm.filters),function(filter){return _c('div',{key:`${filter.id}`,staticClass:"row clearfix"},[_c('div',{staticClass:"col-11"},[_c('SimpleFilterCriterion',{ref:"simpleCriterion",refInFor:true})],1),_c('div',{staticClass:"col-1"},[_c('button',{staticClass:"btn btn-danger me-3 btn-sm",attrs:{"title":"Delete filter","type":"button"},on:{"click":function($event){return _vm.removeFilter(filter)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Delete filter")]),_c('i',{staticClass:"fa fa-minus"})])])])}),_c('button',{staticClass:"btn btn-primary mt-3",attrs:{"title":"Add filter","type":"button"},on:{"click":_vm.addFilter}},[_vm._v(" Add filter ")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ms-4",staticStyle:{"margin-top":"1px"}},[_c('small',[_vm._v(" enables "),_c('strong',[_vm._v("\"deleted\"")]),_vm._v(", "),_c('strong',[_vm._v("\"created_on\"")]),_vm._v(", "),_c('strong',[_vm._v("\"updated_on\" ")]),_vm._v(" and all "),_c('strong',[_vm._v("\"id\"")]),_vm._v(" columns to apper in the "),_c('strong',[_vm._v("search options")]),_vm._v(". ")])])
}]

export { render, staticRenderFns }