<template>
  <wrapper-page ref="wrapper" @search="search">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <div class="input-group">
          <router-link
            :to="{ name: 'clients-create' }"
            class="btn btn-sm btn-info"
            style="margin-left: auto"
          >
            New Client
          </router-link>
        </div>
      </div>
    </template>

    <template v-slot:search>
      <div class="d-flex align-items-center gap-x-2 position-relative">
        <SearchInput @search="search" :results="searchResult?.results ?? []" />
        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            type="button"
          >
            <i class="ri-settings-4-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-4">
            <div class="d-flex flex-column gap-y-2">
              <div
                v-for="field in searchProperties"
                :key="field.value"
                class="w-100 d-flex gap-x-2 align-items-center"
              >
                <input
                  type="checkbox"
                  v-model="searchFields[field.value]"
                  :name="`search-property-${field.value}`"
                />
                <label :for="`search-property-${field.value}`" class="mb-0">
                  {{ field.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-3">
        <button
          class="btn btn-lg p-0 me-2"
          title="Show as Grid"
          @click="showAsGrid = true"
        >
          <i class="ri-layout-grid-line"></i>
        </button>
        <button
          class="btn btn-lg p-0"
          title="Show as Table"
          @click="showAsGrid = false"
        >
          <i class="ri-list-check"></i>
        </button>
      </div>
    </div>
    <clients-grid v-if="showAsGrid" :clients="clients" />
    <clients-table v-else :clients="clients" :showSearchBar="false" />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import { getClients } from "@/apis/clients";
import ClientsGrid from "@/components/clients/ClientsGrid";
import ClientsTable from "@/components/clients/ClientsTable";
import { notifyError } from "@/helpers/notification";
import SearchInput from "@/components/SearchInput.vue";

const ClientSearchableProperties = [
  { text: "Name", value: "name" },
  { text: "ID Card", value: "idCard" },
  { text: "Company Number", value: "companyNumber" },
  { text: "Website", value: "website" },
  { text: "IMO Number", value: "imoNumber" },
  { text: "VAT Number", value: "vatNumber" },
];

export default {
  name: "ClientsIndex",
  components: {
    SearchInput,
    ClientsTable,
    ClientsGrid,
    WrapperPage,
  },
  data() {
    return {
      clients: [],
      showAsGrid: false,
      searchFields: { name: true },
      searchProperties: ClientSearchableProperties,
      searchResult: { results: [] },
    };
  },
  methods: {
    getFilters() {
      let filters = [];
      if (this.$route.query.filterBy === "companies") {
        filters.push("isCompany=true");
      }

      if (this.$route.query.filterBy === "individuals") {
        filters.push("isCompany=false");
      }

      return filters;
    },
    async getFilteredClients() {
      const country = this.$route.query.country || "";
      await getClients({
        filters: this.getFilters(),
        country,
      })
        .then(({ data }) => {
          this.clients = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    getFakeIconForSearchResult(client) {
      return client.name
        .split(" ")
        .map((part) => (part[0] || "").toString().toUpperCase())
        .slice(0, 2)
        .join(""); // Split name and fetch first letter
    },
    getSubtitleForSearchResult(client) {
      for (let i = 0; i < this.searchFields.length; i++) {
        const field = this.searchFields[i];
        if (field.value === "name") continue;
        if (client[field.value])
          return field.text + " : " + client[field.value];
      }
    },
    async search(searchString) {
      if (
        Object.keys(this.searchFields).filter((key) => this.searchFields[key])
          .length === 0
      ) {
        notifyError(this, "Choose 1 or more properties to search in");
        return;
      }

      let filters = Object.keys(this.searchFields)
        .filter((key) => this.searchFields[key])
        .map((f) => `${f}=${searchString}`);

      const searchStartTime = new Date().getTime();
      await getClients({ filters, matchAny: 1 })
        .then(({ data }) => {
          var searchDuration = new Date().getTime() - searchStartTime;
          this.searchResult = {
            durationInSeconds: searchDuration / 1000,
            results: data.map((client) => ({
              icon: {
                text: this.getFakeIconForSearchResult(client),
                backgroundColour: "green",
              },
              title: client.name,
              subtitle: this.getSubtitleForSearchResult(client),
              link: `/clients/${client.id}`,
            })),
          };
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  async mounted() {
    this.getFilteredClients();
  },
};
</script>
