<template>
  <div :class="`${size} number-card`">
    <a :href="url" class="card" target="_blank">
      <div class="card-body">
        <div class="d-flex text-muted">
          <div class="flex-shrink-0 me-3 align-self-center">
            <div class="avatar-sm">
              <div
                class="avatar-title bg-light rounded-circle text-primary font-size-20"
              >
                <i :class="icon"></i>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 overflow-hidden">
            <p class="mb-1">{{ label }}</p>
            <h5 class="mb-3">{{ value }}</h5>
            <p v-if="subtitle" class="text-truncate mb-0">
              {{ subtitle }}
            </p>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "NumberCard",
  props: {
    label: String,
    value: [String, Number],
    icon: String,
    url: String,
    subtitle: String,
    size: {
      type: String,
      default: "col-lg-3 col-md-6",
    },
  },
};
</script>
