import {
  BuildOptions,
  CeCategoryOptions,
  ClassOptions,
  EmissionStandardOptions,
  GeneratorFuelOptions,
  GeneratorTypeOptions,
  HullMaterialOptions,
  HullTypeOptions,
  MachineryCycleOptions,
  MachineryEmissionStadardOptions,
  MachineryFuelOptions,
  MachineryGearboxOptions,
  MachineryPositionOptions,
  MachineryPropulsionOptions,
  MachineryTypeOptions,
  OperationalRange,
  PropulsionOptions,
  RadioRangeOptions,
  RegulatoryRegimeOptions,
  RiggingOptions,
  StakeholderRoleOptions,
  StartArrangmentOptions,
  StemOptions,
  SternOptions,
  TypeOfPropellorsOptions,
} from "@/constants/vessels";
import { CountryOptions } from "@/constants/address";

export const VesselFields = [
  { label: "Name", name: "name", type: "text" },
  { label: "Official Number", name: "official_number", type: "text" },
  { label: "S-Reg", name: "s_reg", type: "text", placeholder: "S-*****" },
  {
    label: "Fisheries ID",
    name: "fisheries_id",
    type: "text",
    placeholder: "MF*****",
  },
  { label: "Call Sign", name: "call_sign", type: "text" },
  { label: "IMO Number", name: "imo_number", type: "text" },
  { label: "MMSI Number", name: "mmsi_number", type: "text" },
  { label: "Port of Registry", name: "registry", type: "text" },
  {
    label: "Flag",
    name: "flag",
    type: "select",
    options: CountryOptions,
    sortOptions: false,
  },
  {
    label: "Former Flag",
    name: "former_registry",
    type: "select",
    options: CountryOptions,
    sortOptions: false,
    includeNA: true,
  },
  { label: "Base Port", name: "base_port", type: "text" },
  {
    label: "Country",
    name: "country",
    type: "select",
    options: CountryOptions,
    sortOptions: false,
  },
  { label: "Vessel Type", name: "vessel_type", type: "select" },
  { label: "Other Service", name: "other_service", type: "select" },
  { label: "Speed", name: "speed", placeholder: "Speed: kn", type: "number" },
  { label: "Crew Accommodation", name: "crew_accommodation", type: "number" },
  { label: "Passengers", name: "passengers", type: "number" },
  {
    label: "Day Charter Passenger",
    name: "day_charter_passenger",
    type: "number",
  },
  { label: "Class", name: "class", type: "select", options: ClassOptions },
  { label: "Class Notation", name: "class_notation", type: "text" },
  { label: "Class Maintained", name: "class_maintained", type: "check" },
  {
    label: "Regulatory Regime",
    name: "regulatory_regime",
    type: "multiselect",
    options: RegulatoryRegimeOptions,
  },
  {
    label: "Operational Range",
    name: "operational_range",
    type: "select",
    options: OperationalRange,
    includeOther: true,
  },
  {
    label: "Radio Range",
    name: "radio_range",
    type: "select",
    options: RadioRangeOptions,
  },
];

export const VesselDimensionsFields = [
  {
    label: "Length (overall)",
    name: "length_overall",
    type: "number",
    placeholder: "Unit: m",
  },
  { label: "Length", name: "length", type: "number", placeholder: "Unit: m" },
  {
    label: "Length (between perp)",
    name: "length_between_perp",
    type: "number",
    placeholder: "Unit: m",
  },
  { label: "Length (hull)", name: "length_hull", type: "number" },
  {
    label: "Length (engine room)",
    name: "length_engine_room",
    type: "number",
    placeholder: "Unit: m",
  },
  { label: "Breadth", name: "breadth", type: "number" },
  { label: "Depth", name: "depth", type: "number" },
  { label: "Draught", name: "draught", type: "number" },
  {
    label: "Lightship",
    name: "lightship",
    type: "number",
    placeholder: "Unit: Metric tonnes",
  },
  {
    label: "Displacement",
    name: "displacement",
    type: "number",
    placeholder: "Unit: Metric tonnes",
  },
  { label: "Deadweight", name: "deadweight", type: "number" },
  { label: "Assigned Freeboard", name: "assigned_freeboard", type: "number" },
];

export const VesselHullFields = [
  {
    label: "Hull Material",
    name: "hull_material",
    type: "select",
    options: HullMaterialOptions,
  },
  {
    label: "Hull Type",
    name: "hull_type",
    type: "select",
    options: HullTypeOptions,
    includeOther: true,
  },
  { label: "Build", name: "build", type: "select", options: BuildOptions },
  {
    label: "Stem",
    name: "stem",
    type: "select",
    options: StemOptions,
    includeOther: true,
  },
  {
    label: "Stern",
    name: "stern",
    type: "select",
    options: SternOptions,
    includeOther: true,
  },
  {
    label: "Propulsion",
    name: "propulsion",
    type: "select",
    options: PropulsionOptions,
  },
  {
    label: "Rigging",
    name: "rigging",
    type: "select",
    options: RiggingOptions,
  },
  { label: "Masts", name: "masts", type: "number" },
  {
    label: "Frame Spacing",
    name: "frame_spacing",
    type: "number",
    placeholder: "Unit: mm",
  },
  {
    label: "Decks",
    name: "number_of_decks",
    type: "number",
    placeholder: "Num of Decks",
  },
  {
    label: "Watertight Bulkheads",
    name: "number_of_watertight_bulkheads",
    type: "number",
    placeholder: "Num of Watertight Bulkheads",
  },
  {
    label: "Superstructure Material",
    name: "superstructure_material",
    type: "select",
    options: HullMaterialOptions,
  },
  {
    label: "Keel Laid",
    name: "keel_laid",
    type: "number",
    placeholder: "YYYY",
  },
  {
    label: "Build Year",
    name: "build_year",
    type: "number",
    placeholder: "YYYY",
  },
  { label: "Builder", name: "builder_id", type: "select", sortOptions: false },
  {
    label: "Model",
    name: "model",
    type: "select",
    includeOther: true,
    options: [],
  },
  {
    label: "Country Outfitted",
    name: "country_outfitted",
    type: "select",
    includeNA: true,
    options: CountryOptions,
    sortOptions: false,
  },
  { label: "Hull Number", name: "hull_number", type: "text" },
  {
    label: "CE Category",
    name: "ce_category",
    type: "select",
    options: CeCategoryOptions,
    includeNA: true,
  },
  {
    label: "Maximum Power",
    placeholder: "Maximum Power: kW",
    name: "max_power",
    type: "number",
  },
  { label: "Max. number of Persons", name: "max_persons", type: "number" },
  { label: "Max Weight", name: "max_weight", type: "number" },
  { label: "Hull Colour", name: "hull_colour", type: "text" },
  { label: "GT & NT (<24m)", name: "gt_and_nt", placeholder: "#.##" },
  { label: "GT", name: "gt" },
  { label: "NT", name: "nt" },
];

export const VesselGeneratorFields = [
  { label: "Make", name: "make", type: "select", includeOther: true },
  {
    label: "Model",
    name: "model",
    type: "select",
    includeOther: true,
  },
  { label: "Year", name: "year", type: "number" },
  { label: "Serial Number", name: "serial_number", type: "text" },
  { label: "Power", name: "power", type: "number", placeholder: "Unit: kW" },
  { label: "Frequency", name: "frequency", type: "number" },
  {
    label: "Total Electrical Power",
    name: "total_electrical_power",
    type: "number",
    placeholder: "Unit: kVA",
  },
  {
    label: "Starting Arrangement",
    name: "starting_arrangement",
    type: "select",
    options: StartArrangmentOptions,
  },
  {
    label: "Fuel",
    name: "fuel",
    type: "select",
    options: GeneratorFuelOptions,
  },
  {
    label: "Emission Standard",
    name: "emission_standard",
    type: "select",
    options: EmissionStandardOptions,
    includeOther: true,
    includeNA: true,
  },
  {
    label: "Type",
    name: "type",
    type: "select",
    options: GeneratorTypeOptions,
  },
];

export const VesselStakeholderFields = [
  {
    label: "Role",
    name: "type",
    type: "select",
    options: StakeholderRoleOptions,
  },
  { label: "Name", name: "name", readonly: true },
  { label: "Phone", name: "phone", readonly: true },
  { label: "Email", name: "email", type: "email", readonly: true },
];

export const VesselMachineryFields = [
  {
    label: "Make",
    name: "make",
    type: "select",
    sortOptions: false,
  },
  {
    label: "Model",
    name: "model",
    type: "select",
    includeOther: true,
  },
  { label: "Serial Number", name: "serial_number", type: "text" },
  {
    label: "Type",
    name: "type",
    type: "select",
    options: MachineryTypeOptions,
  },
  { label: "Year", name: "year", type: "number" },
  { label: "Number of Cylinders", name: "number_of_cylinders", type: "number" },
  {
    label: "Cycle",
    name: "cycle",
    type: "select",
    options: MachineryCycleOptions,
    includeOther: true,
  },
  {
    label: "Fuel",
    name: "fuel",
    type: "select",
    options: MachineryFuelOptions,
  },
  { label: "Power", name: "power", type: "number", placeholder: "Unit: kW" },
  {
    label: "Propulsion",
    name: "propulsion",
    type: "select",
    options: MachineryPropulsionOptions,
  },
  {
    label: "Number of Propellors",
    name: "number_of_propellors",
    type: "number",
  },
  {
    label: "Type of Propellors",
    name: "type_of_propellors",
    type: "select",
    options: TypeOfPropellorsOptions,
    includeNA: true,
  },
  {
    label: "Starting Arrangement",
    name: "starting_arrangement",
    type: "select",
    options: StartArrangmentOptions,
  },
  {
    label: "Gearbox",
    name: "gearbox",
    type: "select",
    options: MachineryGearboxOptions,
  },
  { label: "Engine Hours", name: "engine_hours", type: "number" },
  { label: "Engine Hours Date", name: "engine_hours_date", type: "date" },
  {
    label: "Position",
    name: "position",
    type: "select",
    options: MachineryPositionOptions,
  },
  {
    label: "Emission Standard",
    name: "emission_standard",
    type: "select",
    options: MachineryEmissionStadardOptions,
    includeOther: true,
    includeNA: true,
  },
  {
    label: "Main/Auxiliary Engine",
    name: "is_main_engine",
    type: "radio",
    options: [
      { text: "Main", value: "1" },
      { text: "Auxiliary", value: "0" },
    ],
  },
  { label: "De-Rated", name: "is_derated", type: "check" },
  {
    label: "Original Power",
    name: "original_power",
    type: "number",
    placeholder: "Unit: kW",
  },
];

export const VesselBuilderContactFields = [
  { label: "Role", name: "type", type: "text" },
  { label: "Name", name: "name", type: "text" },
  { label: "Email", name: "email", type: "text" },
  { label: "Phone", name: "phone", type: "text" },
];
