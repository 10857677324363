const config = require("./config.json");
const { get } = require("./common").default;

const baseUrl = config.apis.baseUrl.url;
const kpisUrl = baseUrl + "kpis/";

/**
 * Retrieve KPIs for a client
 * @param {number} id ID of the client to retrieve
 * @returns Promise with an Axios response
 */
exports.getClientKPIs = async (id) => {
  return await get(`${kpisUrl}clients/${id}`);
};

exports.getClientDashboardKpis = async () => {
  return await get(`${kpisUrl}clients/dashboard`);
};

exports.getProjectDashboardKpis = async () => {
  return await get(`${kpisUrl}projects/dashboard`);
};

/**
 * Retrieve KPIs for a user
 * @param {number|string} id Optioanl. ID of the user to retrieve, 'all' for fetching all users' KPIs
 * @returns Promise with an Axios response
 */
exports.getUserKPIs = async (filter) => {
  let url = `${kpisUrl}users`;
  if (filter) {
    url = url + `?user_id=` + filter;
  }
  return await get(url);
};

exports.getFinancialKPIs = async (filter) => {
  let url = `${kpisUrl}projects/invoices`;
  if (filter) {
    url = url + `?user_id=` + filter;
  }
  return await get(url);
};

exports.getVesselsWeightSummary = async () => {
  let url = `${baseUrl}vessels/lookups/vessels_weight_summary`;
  return await get(url);
};

exports.getVesselsStatus = async () => {
  const url = `${kpisUrl}vessels/status`;
  return await get(url);
};
