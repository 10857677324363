<template>
  <div>
    <form @submit.prevent="searchClientsAndContacts">
      <div class="row">
        <div
          v-for="field in clientContactSearchFields"
          :key="field.name"
          class="col-6 col-md-4 col-lg-3"
        >
          <form-input-text v-model="details[field.name]" :field="field" />
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <form-input-check v-model="allowAll" :field="checkField" />
          <p class="fs-6 text-muted">
            If ticked then we return every match from the DB.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary" type="submit">Search</button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-12">
        <search-results-table
          v-if="searchResult"
          v-model="searchResult"
          class="mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultsTable from "../../../pages/search/SearchResultsTable.vue";
import { quickSearchBtnNames as btnNames } from "../../../constants";
import { clientContactSearchFields } from "../../../constants/clients";
import FormInputText from "../../ui/FormInputText.vue";
import { searchClientsAndContacts } from "@/apis/search";
import FormInputCheck from "../../ui/FormInputCheck.vue";

export default {
  name: "ClientsAndContactsByNameEmailOrPhone",
  components: {
    SearchResultsTable,
    FormInputText,
    FormInputCheck,
  },
  props: {},
  data() {
    return {
      clientContactSearchFields,
      btnNames,
      searchResult: null,
      allowAll: false,
      checkField: {
        label: "Allow all results",
        name: "allowAll",
        type: "checkbox",
      },
      details: {
        client_name: "",
        contact_name: "",
        email: "",
        phone_number: "",
      },
    };
  },
  mounted() {},
  watch: {},
  methods: {
    async searchClientsAndContacts() {
      const query = {
        client_name: this.details.client_name,
        contact_name: this.details.contact_name,
        email: this.details.email,
        phone: this.details.phone_number,
        includeAll: this.allowAll,
      };
      const response = await searchClientsAndContacts(query);
      this.searchResult = response?.data;
    },
  },
  computed: {},
};
</script>

<style></style>
