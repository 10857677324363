export const tonnageVoulmeBase = 0.16;

export const stringNumbers = [
  "ZERO",
  "ONE",
  "TWO",
  "THREE",
  "FOUR",
  "FIVE",
  "SIX",
  "SEVEN",
  "EIGHT",
  "NINE",
  "TEN",
  "ELEVEN",
  "TWELVE",
  "THIRTEEN",
  "FOURTEEN",
  "FIFTEEN",
  "SIXTEEN",
  "SEVENTEEN",
  "EIGHTEEN",
  "NINETEEN",
  "TWENTY",
  "TWENTY ONE",
  "TWENTY TWO",
  "TWENTY THREE",
  "TWENTY FOUR",
];

export const machinePropulsionShaft = "shaft";
