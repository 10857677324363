import { getVessel } from "@/apis/vessels";
import { nullToDoubleDash, toDecimalPlaces } from "@/helpers/generic";
import moment from "moment";

export const loadVessel = async (vesselId) => {
  let vessel = await getVessel(vesselId).then(({ data }) => data);

  if (!vessel.vessel_type) vessel.vessel_type = null;

  if (!vessel.other_service) vessel.other_service = null;

  if (!vessel.class) vessel.class = null;

  if (vessel.class_maintained)
    vessel.class_maintained = !!vessel.class_maintained;

  if (!vessel.radio_range) vessel.radio_range = null;

  // We need the empty with nulls so that the bindings for errors and computed properties work
  const emptyDimensions = {
    length_overall: null,
    length: null,
    length_between_perp: null,
    lightship: null,
    displacement: null,
  };
  const emptyHull = {
    stem: null,
    stern: null,
    max_power: null,
    max_persons: null,
    max_weight: null,
    gt_and_nt: null,
    gt: null,
    nt: null,
    builder_id: null,
  };
  vessel.dimensions = { ...emptyDimensions, ...vessel.dimensions };
  vessel.hull = { ...emptyHull, ...vessel.hull };
  if (!vessel.machinery) {
    vessel.machinery = [];
  } else {
    vessel.machinery.forEach((machinery) => {
      if (machinery.is_main_engine !== undefined)
        machinery.is_main_engine = String(machinery.is_main_engine);
      if (machinery.is_derated !== undefined)
        machinery.is_derated = !!machinery.is_derated;
      if (machinery.engine_hours_date) {
        machinery.engine_hours_date = moment(
          machinery.engine_hours_date
        ).format("YYYY-MM-DD");
      }
    });
  }

  if (vessel.hull.gt_and_nt)
    vessel.hull.gt_and_nt = toDecimalPlaces(vessel.hull.gt_and_nt, 2);

  if (vessel.hull.builder) vessel.hull.builder_id = vessel.hull.builder?.id;

  if (!vessel.electricGenerators) vessel.electricGenerators = [];

  vessel = nullToDoubleDash(vessel, [
    "name",
    "official_number",
    "s_reg",
    "fisheries_id",
    "call_sign",
    "imo_number",
    "mmsi_number",
  ]);

  return vessel;
};

export const formatVesselForReadOnly = (vessel) => {
  // Convert any bool property to 'Yes' & 'No'
  for (const key in vessel) {
    if (Object.hasOwnProperty.call(vessel, key)) {
      const element = vessel[key];
      if (typeof element === "boolean") {
        if (element) vessel[key] = "Yes";
        else vessel[key] = "No";
      }
    }
  }

  return vessel;
};

/*
@params: vesselId - the id of the vessel
@returns: an array of objects with the following structure:
{
  name: "Certification", // This is the name of the main tab.
  urls: [] is an array of url patterns that are selected when the tab is active. In case of a subtab, these urls will be common to all subtabs.
  subTabs: [] is an array of subtab names. Somw tabs will need to be devided betwenn two subtabs. If there are no subtabs then this can be omitted.
  "Subtab name": {} is an object that contains the list of url patterns that are selected when the subtab is active and it contains the docType (string) 
  what is used to determine the DB doctype when we are uploading a file.
}
*/

export const getTabInfo = (vesselId) => {
  const DOC_NAMES = additionalVesselDocumentTypes;
  return [
    {
      name: "Certification", // This is the name of the tab.
      urls: [
        // These are the urls that will be used to check when the tab is active.
        // If we have subtabs then these will be common to all subtabs.
        `vessels/${vesselId}/${DOC_NAMES.CERTIFICATES}`,
        `vessels/${vesselId}/additional/${DOC_NAMES.CERTIFICATES}`,
      ],
      docType: DOC_NAMES.CERTIFICATES,
    },
    {
      name: "Surveys",
      urls: [`vessels/${vesselId}/surveys`],
      subTabs: ["Other or Class Surveys", "Survey Follow-up"],
      "Other or Class Surveys": {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.SURVEYS_OTHER}`],
        docType: DOC_NAMES.SURVEYS_OTHER,
      },
      "Survey Follow-up": {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.SURVEYS_FOLLOWUP}`],
        docType: DOC_NAMES.SURVEYS_FOLLOWUP,
      },
    },
    {
      name: "PSC – FSI",
      urls: [],
      subTabs: ["PSC or Events", "FSI"],
      "PSC or Events": {
        urls: [
          `vessels/${vesselId}/${DOC_NAMES.PSC}`,
          `vessels/${vesselId}/additional/${DOC_NAMES.PSC}`,
        ],
        docType: DOC_NAMES.PSC,
      },
      FSI: {
        urls: [
          `vessels/${vesselId}/${DOC_NAMES.FSI}`,
          `vessels/${vesselId}/additional/${DOC_NAMES.FSI}`,
        ],
        docType: DOC_NAMES.FSI,
      },
    },
    {
      name: "Incidents",
      urls: [
        `vessels/${vesselId}/${DOC_NAMES.INCIDENTS}`,
        `vessels/${vesselId}/additional/${DOC_NAMES.INCIDENTS}`,
      ],
      docType: DOC_NAMES.INCIDENTS,
    },
    {
      name: "GMDSS",
      urls: [`vessels/${vesselId}/additional/${DOC_NAMES.GMDSS}`],
      docType: DOC_NAMES.GMDSS,
    },
    {
      name: "MLC",
      urls: [],
      subTabs: ["Reports & Documentation", "Crew"],
      "Reports & Documentation": {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.MLC_REPORTS}`],
        docType: DOC_NAMES.MLC_REPORTS,
      },
      Crew: {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.MLC_CREW}`],
        docType: DOC_NAMES.MLC_CREW,
      },
    },
    {
      name: "Original Documents",
      urls: [`vessels/${vesselId}/additional/${DOC_NAMES.ORIGINAL}`],
      docType: DOC_NAMES.ORIGINAL,
    },
    {
      name: "Plans & Stability",
      urls: [],
      subTabs: ["Plans", "Stability"],
      Plans: {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.PLANS}`],
        docType: DOC_NAMES.PLANS,
      },
      Stability: {
        urls: [`vessels/${vesselId}/additional/${DOC_NAMES.STABILITY}`],
        docType: DOC_NAMES.STABILITY,
      },
    },
  ];
};

export const additionalVesselDocumentTypes = Object.freeze({
  SURVEYS_OTHER: "surveys-other",
  SURVEYS_FOLLOWUP: "surveys-followup",
  MLC_REPORTS: "mlc-reports",
  MLC_CREW: "mlc-crew",
  PLANS: "plans",
  STABILITY: "stability",
  ORIGINAL: "original",
  GMDSS: "gmdss",
  PSC: "psc",
  FSI: "fsi",
  INCIDENTS: "incidents",
  CERTIFICATES: "certifications",
});
