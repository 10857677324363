<template>
  <div class="card-body">
    <div class="row">
      <div class="col-12 d-flex flex-column align-items-center mb-4">
        <p class="my-2 text-center">Name: {{ vessel.name }}</p>
        <p class="my-2 text-center">Call Sign: {{ vessel.call_sign }}</p>
        <p class="my-2 text-center">Length Overall: {{ vesselLOA }}</p>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-primary me-3"
          type="button"
          @click="() => close(false)"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VesselQuickViewModal",
  props: { vessel: Object },
  methods: {
    close(state = null) {
      this.$modal.hide("vessel-preview-modal", state);
    },
  },
  computed: {
    vesselLOA() {
      return this.vessel.dimensions?.length_overall;
    },
  },
};
</script>
