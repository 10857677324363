<template>
  <div class="col-12">
    <div v-if="survey_" class="row flex-grow-1 d-flex">
      <div
        v-for="field in surveyFields"
        :key="`${field.name}`"
        :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
        class="col-md-6"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.survey_[field.name].$model"
          :error="$v.survey_[field.name].$error"
          :error-message="validationMessage($v.survey_[field.name])"
          :field="field"
        />
        <form-input-check
          v-else-if="field.type === 'check'"
          v-model="$v.survey_[field.name].$model"
          :error="$v.survey_[field.name].$error"
          :error-message="validationMessage($v.survey_[field.name])"
          :field="field"
        />
        <form-input-select
          v-else-if="field.type === 'select' && field.name === 'project_id'"
          v-model.number="$v.survey_[field.name].$model"
          :error="$v.survey_[field.name].$error"
          :error-message="validationMessage($v.survey_[field.name])"
          :field="field"
          @input="(project) => showJobsForProject(project)"
        />
        <form-input-select
          v-else-if="field.type === 'select'"
          v-model="$v.survey_[field.name].$model"
          :error="$v.survey_[field.name].$error"
          :error-message="validationMessage($v.survey_[field.name])"
          :field="field"
          :parent="$v.survey_.$model"
        />
        <form-input-text
          v-else
          v-model="$v.survey_[field.name].$model"
          :error="$v.survey_[field.name].$error"
          :error-message="validationMessage($v.survey_[field.name])"
          :field="field"
        />
      </div>
    </div>

    <!-- Projects -->
    <div class="row mt-3">
      <div v-if="this.survey_ && this.survey_.project_id" class="col-12">
        <b>Project Jobs</b>
        <div v-if="jobs" class="table-responsive">
          <table class="table table-hover table-custom spacing8">
            <thead v-if="jobs.length">
              <tr>
                <th>Job Category</th>
                <th>Job</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in attachedJobs" :key="job.value">
                <td>{{ job.category }}</td>
                <td>{{ job.text }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>There are no Jobs for this project</div>
      </div>
    </div>

    <!-- Documents, Upload & List -->
    <div v-if="this.survey_ && this.survey_.id" class="row mt-3">
      <div class="col-md-6">
        <form-input-file
          :field="uploadField"
          :show-action-button="true"
          @action="uploadSurveyDocuments"
        />
      </div>
      <div class="col-md-6">
        <documents-list
          :documents="surveyDocuments"
          _title="Survey Documents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { CountryOptions } from "@/constants/address";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../ui/FormInputText.vue";
import FormInputDate from "../../../ui/FormInputText.vue";
import FormInputCheck from "../../../ui/FormInputCheck.vue";
import FormInputFile from "../../../ui/FormInputFile.vue";
import FormInputSelect from "../../../ui/FormInputSelect";
import { ScopeOptions } from "@/constants/vessels";
import { uploadDocument } from "../../../../apis/files";
import { getProject } from "@/apis/projects";
import { notifyError, notifySuccess } from "@/helpers/notification";
import jobsMixin from "../../../../mixins/projects/jobs";
import DocumentsList from "../../../ui/DocumentsList.vue";

export default {
  name: "SurveyEditor",
  components: {
    FormInputText,
    FormInputDate,
    FormInputCheck,
    FormInputFile,
    FormInputSelect,
    DocumentsList,
  },
  mixins: [jobsMixin],
  props: {
    survey: {
      type: Object,
    },
    surveyDocuments: {
      type: Array,
      default: () => [],
    },
    projectOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      survey_: null,

      surveyFields: [
        {
          label: "Scope",
          name: "scope",
          type: "select",
          options: ScopeOptions,
          includeOther: true,
        },
        {
          label: "Project",
          name: "project_id",
          type: "select",
          options: [{ text: "loading", value: -1 }],
        },
        { label: "Start Date", name: "start_date", type: "date" },
        { label: "End Date", name: "end_date", type: "date" },
        { label: "Visits", name: "visits", type: "number" },
        { label: "Port", name: "port", maxLength: 50 },
        {
          label: "Country",
          name: "country",
          type: "select",
          includeNA: true,
          options: CountryOptions,
          sortOptions: false,
        },
        {
          label: "Completed",
          name: "completed",
          type: "check",
        },
        { label: "Notes", name: "notes", type: "textarea" },
      ],
      uploadField: {
        label: "Survey Documents",
        name: "survey_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload survey document/s",
      },
    };
  },
  validations: {
    survey_: {
      scope: { required },
      project_id: {},
      start_date: { Date },
      end_date: { Date },
      visits: {},
      port: {},
      country: {},
      completed: {},
      notes: {},
    },
  },
  methods: {
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    async showJobsForProject(projectId) {
      let project = await getProject(projectId).then(
        (response) => response.data
      );
      this.jobs = project.jobs;
    },
    prepareInternalSurvey(survey) {
      const emptySurvey = {
        scope: null,
        project_id: null,
        start_date: null,
        end_date: null,
      };
      this.survey_ = { ...emptySurvey, ...survey };
      this.surveyFields.find((f) => f.name === "project_id").options =
        this.projectOptions;

      if (this.survey_.start_date)
        this.survey_.start_date = moment(this.survey_.start_date).format(
          "YYYY-MM-DD"
        );
      if (this.survey_.end_date)
        this.survey_.end_date = moment(this.survey_.end_date).format(
          "YYYY-MM-DD"
        );

      // Ensure we have any custom scopes in the options
      if (survey.scope) {
        const scopeField = this.surveyFields.find(
          (field) => field.name === "scope"
        );
        let found = scopeField.options.find(
          (option) => option.value === survey.scope
        );
        if (!found) {
          scopeField.options.push({ text: survey.scope, value: survey.scope });
        }
      }
      // Load Project Jobs
      if (survey.project_id) {
        this.showJobsForProject(survey.project_id);
      }
    },
    async uploadSurveyDocuments(fileControl) {
      if (fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const uri = `vessels/${this.survey_.vessel_id}/surveys/${this.survey_.id}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(
            uploadDocument(file, uri)
              .then((response) => {
                this.surveyDocuments.push(response.data.path);
                notifySuccess(this, "File(s) have been uploaded!");
              })
              .catch((error) => {
                notifyError(this, "Files are not uploaded", error);
              })
          );
        }
        await Promise.all(promises);
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
      }
    },
  },
  watch: {
    survey(newSurvey) {
      this.prepareInternalSurvey(newSurvey);
    },
    projectOptions() {
      this.surveyFields.find((f) => f.name === "project_id").options =
        this.projectOptions;
    },
  },
  created() {
    this.prepareInternalSurvey(this.survey);
  },
};
</script>
