<template>
  <div class="card-body">
    <form @submit.prevent="saveDetails">
      <div class="row">
        <div class="col-12">
          <form-input-text
            v-model="$v.newValue.$model"
            :error="$v.newValue.$error"
            :error-message="validationMessage($v.newValue)"
            :field="field"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3 d-flex justify-content-end">
          <button class="btn btn-danger" type="button" @click="close">
            Cancel
          </button>
          <button
            :class="{ disabled: $v.$anyError }"
            class="btn btn-primary ms-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "@/components/ui/FormInputText";
import { notifyError } from "@/helpers/notification";

export default {
  name: "DropdownOtherEditorModal",
  components: { FormInputText },
  props: {
    field: Object,
  },
  data() {
    return {
      newValue: null,
    };
  },
  validations: {
    newValue: {
      required,
    },
  },
  methods: {
    validationMessage,
    close(state = null) {
      this.$modal.hide("dropdown-other-editor-modal", state);
    },
    async saveDetails() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        notifyError(
          this,
          `Please enter the new ${this.field.label} you want to use`
        );
        return;
      }

      this.field.options.push({ text: this.newValue, value: this.newValue });
      this.close({ value: this.newValue });
    },
  },
};
</script>
