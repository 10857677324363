<template>
  <div class="row">
    <div class="col-12 col-md-9" style="overflow-x: auto">
      <sub-events-list
        :documents="documents"
        :events="events"
        :reducedMode="reducedMode"
        @editEvent="editEvent"
        @removeEvent="removeEvent"
      />
    </div>
    <sub-event-editor
      v-if="subEventEditorEnabled"
      ref="subEventsEditor"
      :event_type="event_type"
      :vessel="vessel"
      @addEvent="addEvent"
      @documentUploaded="documentUploaded"
      @updateEvent="updateEvent"
    />
  </div>
</template>

<script>
import moment from "moment";
import { getDocuments } from "@/apis/files";
import { deleteVesselEventById } from "@/apis/vessels";
import SubEventsList from "./SubEventsList.vue";
import SubEventEditor from "./SubEventEditor.vue";
import { notifyError, notifySuccess } from "@/helpers/notification";

export default {
  name: "VesselsEventsSubEventTab",
  components: { SubEventsList, SubEventEditor },
  props: {
    vessel: Object,
    event_type: String,
    event_type_list: Array,
  },
  data() {
    return {
      events: [],
      documents: [],
      subEventEditorEnabled: true,
      reducedMode: false,
    };
  },
  methods: {
    /** Public methods */
    getEvents() {
      return [...this.events];
    },

    /** Private methods */
    sort() {
      // Sort list, latest change first
      this.events.sort(function (event1, event2) {
        if (moment(event1.event_date).isBefore(moment(event2.event_date)))
          return 1;
        else if (moment(event1.event_date).isAfter(moment(event2.event_date)))
          return -1;
        else return 0;
      });
    },
    addEvent(event) {
      this.events.push({ ...event });
      this.sort();
    },
    async removeEvent(index, event) {
      if (event.id) {
        const answer = window?.confirm(
          "Are you sure you want to delete this event? This action is not reversible!"
        );
        if (!answer) {
          return;
        }
        deleteVesselEventById(event.id)
          .then(() => {
            notifySuccess(this, "Event is deleted successfully");
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
      this.events.splice(index, 1);
      if (index === this.$refs.subEventsEditor.isEditing) {
        this.$refs.subEventsEditor.resetEditor();
      }
    },
    updateEvent() {
      this.sort();
    },
    editEvent(event, index) {
      this.$refs.subEventsEditor.setEventForEdit(event, index);
    },
    documentUploaded(filePath) {
      // get only the filename
      const index = filePath.lastIndexOf("/");
      this.documents.push(filePath.substring(index + 1));
    },
  },
  mounted() {
    if (!this.vessel.events) return;
    // if we have an event_type_list it means that we are on the Stakeholders Principals & Status tab
    // and we need to filter events according to a list of event types.
    if (this.event_type_list && this.event_type_list.length > 0) {
      this.subEventEditorEnabled = false; // We are not allowing users to edit vessel_update events manually.
      this.reducedMode = true;
      this.events = [
        ...this.vessel.events.filter((event) =>
          this.event_type_list.includes(event.event_type)
        ),
      ];

      return this.sort(); // Since we don't have documents for these event types
    }

    // Other wise use the event_type to filter events.
    this.events = [
      ...this.vessel.events.filter(
        (event) => event.event_type === this.event_type
      ),
    ];
    this.sort();

    if (this.vessel && this.vessel.events) {
      getDocuments(
        `vessels/${this.vessel.id}/${this.event_type}`,
        this.vessel.id
      ).then((resp) => (this.documents = resp.data));
    }
  },
};
</script>
