<template>
  <wrapper-page>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4">
                <form-input-select
                  v-model="documentType"
                  :field="documentTypeField"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <form-input-file
                  :field="{
                    name: 'file',
                    type: 'file',
                    label: 'Upload file',
                  }"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  class="btn btn-primary"
                  title="Upload documents"
                  type="button"
                  @click="uploadDocuments"
                >
                  Upload documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- List files -->
    <documents-list :documents="documents" title="Documents" />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputSelect from "../../components/ui/FormInputSelect.vue";
import { getDocuments, uploadDocument } from "../../apis/files";
import { notifyError } from "@/helpers/notification";
import DocumentsList from "../../components/ui/DocumentsList.vue";
import FormInputFile from "@/components/ui/FormInputFile.vue";

export default {
  name: "FilesMainPage",
  components: {
    FormInputFile,
    WrapperPage,
    FormInputSelect,
    DocumentsList,
  },
  data() {
    return {
      documentType: "pics",
      documentTypeField: {
        options: [
          {
            text: "Plans",
            value: "plans",
          },
          {
            text: "Docs & Manuals",
            value: "manuals",
          },
          {
            text: "Previous Certificates",
            value: "prev-certificates",
          },
          {
            text: "Correspondence",
            value: "correspondence",
          },
          {
            text: "Stability",
            value: "stability",
          },
          {
            text: "Flag Docs",
            value: "flag-docs",
          },
          {
            text: "Pics",
            value: "pics",
          },
          {
            text: "Survey Reports",
            value: "survey-reports",
          },
          {
            text: "MLC/Crew",
            value: "mlc-crew",
          },
          {
            text: "GMDSS",
            value: "gmdss",
          },
          {
            text: "Servicing & Certification",
            value: "service-certification",
          },
          {
            text: "Certificates",
            value: "certificates",
          },
          {
            text: "Survey Follow-ups",
            value: "survey-follow-ups",
          },
        ],
      },
      documents: [],
    };
  },
  methods: {
    async uploadDocuments() {
      if (this.$refs.file.files.length == 0) return; // no file selected, nothing to upload

      try {
        // Upload the document and update the database
        await uploadDocument(
          this.$refs.file.files[0],
          "files/" + this.documentType
        );
      } catch (err) {
        notifyError(this, err?.response?.data); //, "Document upload");
      }
    },
  },
  async mounted() {
    this.documents = await getDocuments().then((resp) => resp.data); //this.documentType);
  },
};
</script>
