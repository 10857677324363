<template>
  <div class="row">
    <number-card
      :url="getUrlFor('total')"
      :value="projectKpis.totalProjects"
      icon="ri-briefcase-line"
      label="Total open projects"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('dna')"
      :value="projectKpis.departmentDna"
      icon="ri-anchor-line"
      label="Total open projects by Department “D&NA”"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('sscs')"
      :value="projectKpis.departmentSscs"
      icon="ri-anchor-line"
      label="Total open projects by Department “SS&CS”"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('other')"
      :value="projectKpis.departmentOther"
      icon="ri-anchor-line"
      label="Total OTHER open projects"
      size="col-xl-4 col-sm-6"
    ></number-card>
  </div>
</template>

<script>
import NumberCard from "../ui/NumberCard.vue";

export default {
  name: "ProjectKpis",
  components: { NumberCard },
  props: {
    projectKpis: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrlFor(filterBy) {
      return `/projects/list?isActive=true&filterBy=${filterBy}`;
    },
  },
};
</script>
