var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.fields),function(field){return _c('div',{key:`machinery-${_vm.index}-${field.name}`,staticClass:"col-lg-3 col-md-6"},[(field.type === 'date')?_c('form-input-date',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(
          field.type === 'select' &&
          field.name === 'make' &&
          !_vm.isLoadingVesselMachineryMakeAndModelsOptions
        )?_c('form-input-select',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field},on:{"input":_vm.makeSelected},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(
          field.type === 'select' &&
          field.name === 'model' &&
          !_vm.isLoadingVesselMachineryMakeAndModelsOptions
        )?_c('form-input-select',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field,"parent":_vm.machinery.$model},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(
          field.type === 'select' &&
          field.name !== 'make' &&
          field.name !== 'model'
        )?_c('form-input-select',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field,"parent":_vm.machinery.$model},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(field.type === 'check')?_c('form-input-check',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(field.type === 'radio')?_c('form-input-radio',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field,"groupIndex":_vm.groupIndex},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(field.name === 'original_power' && _vm.showOriginalPower)?_c('form-input-text',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):(field.name !== 'original_power')?_c('form-input-text',{attrs:{"error":_vm.$v.machinery_[field.name].$error,"error-message":_vm.validationMessage(_vm.$v.machinery_[field.name]),"field":field},model:{value:(_vm.$v.machinery_[field.name].$model),callback:function ($$v) {_vm.$set(_vm.$v.machinery_[field.name], "$model", $$v)},expression:"$v.machinery_[field.name].$model"}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }