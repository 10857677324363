<template>
  <wrapper-page ref="wrapper" @search="localSearch">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <div class="input-group">
          <router-link
            :to="{ name: 'vessels-create' }"
            class="btn btn-sm btn-info"
            style="margin-left: auto"
          >
            New Vessel
          </router-link>
        </div>
      </div>
    </template>

    <template v-slot:search>
      <div class="d-flex align-items-center gap-x-2 position-relative">
        <SearchInput
          @search="localSearch"
          :results="searchResult?.results ?? []"
        />
        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            type="button"
          >
            <i class="ri-settings-4-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-4">
            <div class="d-flex flex-column gap-y-2">
              <div
                v-for="field in searchProperties"
                :key="field.value"
                class="w-100 d-flex gap-x-2 align-items-center"
              >
                <input
                  type="checkbox"
                  v-model="searchFields[field.value]"
                  :name="`search-property-${field.value}`"
                />
                <label :for="`search-property-${field.value}`" class="mb-0">
                  {{ field.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-3">
        <button
          class="btn btn-lg p-0 me-2"
          title="Show as Grid"
          @click="showAsGrid = true"
        >
          <i class="ri-layout-grid-line"></i>
        </button>
        <button
          class="btn btn-lg p-0"
          title="Show as Table"
          @click="showAsGrid = false"
        >
          <i class="ri-list-check"></i>
        </button>
      </div>
    </div>
    <template v-if="!isLoadingVessels && sortedVessels">
      <vessels-grid v-if="showAsGrid" :vessels="sortedVessels" />
      <vessels-table v-else :showSearchBar="false" :vessels="sortedVessels" />
    </template>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import {
  getVessels,
  getVesselsCertifications,
  getVesselsForClientId,
} from "@/apis/vessels";
import VesselsGrid from "@/components/vessels/VesselsGrid";
import VesselsTable from "@/components/vessels/VesselsTable";
import { notifyError } from "@/helpers/notification";
import moment from "moment";
import { getExpiryLevel } from "@/helpers/certificates";
import SearchInput from "@/components/SearchInput.vue";

const VesselSearchableProperties = [
  { text: "Name", value: "name" },
  { text: "Official Number", value: "officialNumber" },
  { text: "S Reg", value: "sReg" },
  { text: "Fisheries Id", value: "fisheriesId" },
  { text: "IMO Number", value: "imoNumber" },
  { text: "Call Sign", value: "callSign" },
  { text: "MMSI Number", value: "mmsiNumber" },
  { text: "Registry", value: "registry" },
  { text: "Flag", value: "flag" },
  { text: "Former Registry", value: "formerRegistry" },
  { text: "Class", value: "class" },
  { text: "Class Notation", value: "classNotation" },
  { text: "Operational Range", value: "operationalRange" },
  { text: "Radio Range", value: "radioRange" },
  { text: "Operational Restrictions", value: "operationalRestrictions" },
];

export default {
  name: "VesselsIndex",
  components: {
    SearchInput,
    VesselsTable,
    VesselsGrid,
    WrapperPage,
  },
  data() {
    return {
      isLoadingVessels: true,
      vessels: [],
      showAsGrid: false,
      searchFields: { name: true },
      searchProperties: VesselSearchableProperties,
      searchResult: { results: [] },
    };
  },
  computed: {
    sortedVessels() {
      if (!this.vessels?.length) return [];

      return [...this.vessels].sort((v1, v2) =>
        v1.last_updated < v2.last_updated
          ? 1
          : v1.last_updated > v2.last_updated
          ? -1
          : 0
      );
    },
  },
  methods: {
    getFakeIconForSearchResult(vessel) {
      return vessel.name
        .split(" ")
        .map((part) => (part[0] || "").toString().toUpperCase())
        .slice(0, 2)
        .join(""); // Split name and fetch first letter
    },
    getSubtitleForSearchResult(vessel) {
      for (let i = 0; i < this.searchFields.length; i++) {
        const field = this.searchFields[i];
        if (field.value === "name") continue;
        if (vessel[field.value])
          return field.text + " : " + vessel[field.value];
      }
    },
    /**
     * Called when we use the Searchbar in the Vessels Table (not the advanced search or DataTables search)
     */
    async localSearch(searchString) {
      if (
        Object.keys(this.searchFields).filter((key) => this.searchFields[key])
          .length === 0
      ) {
        notifyError(this, "Choose 1 or more properties to search in");
        return;
      }

      let filters = Object.keys(this.searchFields)
        .filter((key) => this.searchFields[key])
        .map((f) => `${f}=${searchString}`);

      filters = filters.concat(this.getFiltersFromUrl()); // Append also the filters from the current page/url

      const searchStartTime = new Date().getTime();
      await getVessels({ filters, matchAny: 1 })
        .then(({ data }) => {
          var searchDuration = new Date().getTime() - searchStartTime;
          this.searchResult = {
            durationInSeconds: searchDuration / 1000,
            results: data.map((vessel) => ({
              icon: {
                text: this.getFakeIconForSearchResult(vessel),
                backgroundColour: "green",
              },
              title: vessel.name,
              subtitle: this.getSubtitleForSearchResult(vessel),
              link: `/vessels/${vessel.id}`,
            })),
          };
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    async getVesselsAnalyticsResult(clientId) {
      let params = "";
      if (this.$route.query.isDetained === "true") {
        params += "?isDetained=true";
      }

      if (this.$route.query.inService === "true") {
        params += params ? "&inService=true" : "?inService=true";
      }

      await getVesselsForClientId(clientId, params).then((result) => {
        this.vessels = result?.data;
      });
    },
    async loadVessels() {
      this.isLoadingVessels = true;
      const clientId = this.$route.query.clientId;
      if (clientId) {
        await this.getVesselsAnalyticsResult(clientId);
        this.isLoadingVessels = false;
        return;
      }
      const filters = this.getFiltersFromUrl();
      await getVessels({ filters })
        .then(({ data }) => {
          let result = [...data];

          // Filtering vessels by regualtory regime.
          const filter = this.$route.query.filterBy;
          if (filter === "CVC") {
            result = result?.filter((vessel) =>
              vessel?.regulatory_regime?.includes(filter)
            );
          }

          if (filter === "NCV") {
            result = result?.filter(
              (vessel) =>
                vessel?.regulatory_regime?.includes(filter) ||
                vessel?.regulatory_regime?.includes("IACS99") // Requirement from Krt because they are technically the same.
            );
          }
          this.vessels = result;
          this.loadVesselsCertifications();
        })
        .catch((err) => {
          this.isLoadingVessels = false;
          notifyError(this, err?.response?.data);
        });
    },
    getFiltersFromUrl() {
      let filters = [];

      const filterBy = this.$route.query.filterBy;
      if (filterBy) {
        filters.push("inService=true");
        switch (filterBy) {
          case "commercial":
            filters.push(`vesselTypeText=${this.$route.query.filterBy}`);
            break;
          case "NCV":
            // Filter this info after we get the response.
            break;
          case "CVC":
            // Filter this info after we get the response.
            break;
          case "passenger":
            filters.push(`vesselTypeText=${this.$route.query.filterBy}`);
            break;
          case "tuna":
            filters.push(`otherService=tuna`);
            break;

          default:
            break;
        }
      }

      return filters;
    },
    async loadVesselsCertifications() {
      await getVesselsCertifications()
        .then(({ data }) => {
          let allCertifications = data;
          let certifications = [];

          allCertifications
            .sort((a, b) => {
              if (moment(a.issue_date).isBefore(moment(b.issue_date))) return 1;
              else if (moment(a.issue_date).isAfter(moment(b.issue_date)))
                return -1;
              else return 0;
            })
            .forEach((certification) => {
              // if certifications does not already include a certification of this type
              if (
                !certifications.some(
                  (c) =>
                    c.certificate === certification.certificate &&
                    c.vessel_id === certification.vessel_id
                )
              ) {
                const certificationVessel = this.vessels.find(
                  (v) => v.id === certification.vessel_id
                );

                const isInService =
                  certificationVessel?.service_status === "In Service";

                certification.isExpired = getExpiryLevel(
                  certification,
                  isInService
                );

                certifications.push(certification);
              }
            });

          const vessels = [...this.vessels];
          certifications.forEach((c) => {
            const v = vessels.find((v) => v.id === c.vessel_id);
            if (!v) return;
            const currentCertExpiredLevel = v?.isCertExpired;
            if (
              !currentCertExpiredLevel ||
              currentCertExpiredLevel < c.isExpired
            ) {
              v.isCertExpired = c.isExpired;
            }
          });
          this.vessels = vessels;
          this.isLoadingVessels = false;
        })
        .catch((err) => {
          this.isLoadingVessels = false;
          notifyError(this, err?.response?.data);
        });
    },
  },
  async mounted() {
    this.loadVessels();
  },
  watch: {
    "$route.query"() {
      // In case we had a filter at the top and we click on the 'List Vessels' link with no filters
      this.loadVessels();
    },
  },
};
</script>
