<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-start align-items-center mb-4"
            >
              <div class="page-title-box pb-0">
                <h4 class="mb-0">Surveys</h4>
              </div>
              <button
                class="ms-2 btn btn-sm btn-primary"
                title="Add Surveys"
                type="button"
                @click="addSurveys"
              >
                <span class="sr-only">Add Surveys</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <surveys-table
            ref="tblSurveys"
            :projectOptions="projectOptions"
            :surveyDocuments="surveyDocuments"
            :surveys="surveys"
            @removeSurveyByIndex="removeSurveys($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocuments } from "@/apis/files";
import { getProjects } from "@/apis/projects";
import { notifyError } from "@/helpers/notification";
import SurveysTable from "./SurveysTable.vue";

export default {
  name: "SurveysTab",
  components: { SurveysTable },
  props: {
    vessel: Object,
  },
  data() {
    return {
      projectOptions: [],
      surveys: [],
      surveyDocuments: [],
    };
  },
  methods: {
    addSurveys() {
      this.surveys.push({
        scope: null,
        start_date: null,
        end_date: null,
        visits: null,
        completed: null,
        notes: null,
      });
      this.$refs.tblSurveys.openForEdit(this.surveys.length - 1);
    },
    removeSurveys(index) {
      this.surveys.splice(index, 1);
    },

    /**
     * Get the list of Projects to show in the Survey Editor dropdown
     * We load these options once here and then re-use them in the different editors
     */
    async loadProjectNamesForDropdown() {
      await getProjects()
        .then(({ data }) => {
          this.projectOptions = data.map((p) => ({
            text: p.project_number + " : " + p.name,
            value: p.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },

    isInvalid() {
      return this.$refs.tblSurveys.isInvalid();
    },
    getAllDocuments() {
      getDocuments(`vessels/${this.vessel.id}/surveys`, this.vessel.id).then(
        (resp) => (this.surveyDocuments = resp.data)
      );
    },
  },
  created() {
    this.loadProjectNamesForDropdown();
  },
  mounted() {
    if (this.vessel && this.vessel.surveys) {
      this.surveys = [...this.vessel.surveys];
      this.getAllDocuments();
    }
    // This is a temporary solution for reloading documents in certifications an surveys tab.
    // Its is a shorcut until we store the documents in VUE store and we can soerve all documents from one source of thruth.
    // Please note that this functions will execute even if we are working with project documents.
    this.$root.$on("reloadVesselDocumentsAfterDocumentsIsDeleted", () => {
      this.getAllDocuments();
    });
  },
};
</script>
