<template>
  <div class="row">
    <div class="col-6">
      <table class="table header-border table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th>Old Name</th>
            <th>New Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(former, i) in former_names" :key="i">
            <td>{{ moment(former.change_date).format("DD-MM-YYYY") }}</td>
            <td>{{ former.old_name }}</td>
            <td>{{ former.new_name }}</td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                title="Delete former name"
                type="button"
                @click="removeFormerName(i)"
              >
                <span class="sr-only">Delete former name</span>
                <i class="fa fa-times"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6">
      <former-names-editor
        ref="formerNamesEditor"
        @addFormerName="addFormerName($event)"
      />
    </div>
  </div>
</template>

<script>
import FormerNamesEditor from "./FormerNamesEditor.vue";
import moment from "moment";

export default {
  name: "FormerNames",
  components: { FormerNamesEditor },
  props: {
    formerNames: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
      former_names: [],
    };
  },
  methods: {
    sort() {
      // Sort list, latest change first
      this.former_names.sort(function (formerName1, formerName2) {
        if (
          moment(formerName1.change_date).isBefore(
            moment(formerName2.change_date)
          )
        )
          return 1;
        else if (
          moment(formerName1.change_date).isAfter(
            moment(formerName2.change_date)
          )
        )
          return -1;
        else return 0;
      });
    },
    addFormerName(formerName) {
      this.former_names.push({ ...formerName });
      this.sort();
    },
    removeFormerName(index) {
      if (confirm("Are you sure you want to delete this Former Name?"))
        this.former_names.splice(index, 1);
    },
  },
  mounted() {
    if (!this.formerNames) return;
    this.former_names = [...this.formerNames];
    this.sort();
  },
};
</script>
