<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-start align-items-center mb-4"
            >
              <div class="page-title-box pb-0">
                <h4 class="mb-0">Hull Inspections</h4>
              </div>
              <button
                class="ms-2 btn btn-sm btn-primary"
                title="Add Hull Inspections"
                type="button"
                @click="addHullInspection"
              >
                <span class="sr-only">Add Hull Inspections</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <hull-inspections-table
            ref="tblHullInspections"
            :hullInspectionDocuments="hullInspectionDocuments"
            :hullInspections="hullInspections"
            @removeHullInspectionByIndex="removeHullInspection($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocuments } from "@/apis/files";
import HullInspectionsTable from "./HullInspectionsTable.vue";

export default {
  name: "HullInspectionsTab",
  components: { HullInspectionsTable },
  props: {
    vessel: Object,
  },
  data() {
    return {
      hullInspections: [],
      hullInspectionDocuments: [],
    };
  },
  methods: {
    addHullInspection() {
      const emptyHullInspection = {
        activity: null,
        requirement: null,
        date: null,
        nextDue: null,
        comments: null,
      };
      this.hullInspections.push(emptyHullInspection);
      this.$refs.tblHullInspections.openForEdit(
        this.hullInspections.length - 1
      );
    },
    removeHullInspection(index) {
      this.hullInspections.splice(index, 1);
    },
    isInvalid() {
      return this.$refs.tblHullInspections.isInvalid();
    },
    getAllDocuments() {
      getDocuments(
        `vessels/${this.vessel?.id}/hull-inspections`,
        this.vessel?.id
      ).then((resp) => (this.hullInspectionDocuments = resp.data));
    },
  },
  mounted() {
    if (this.vessel && this.vessel.hullInspections) {
      this.hullInspections = [...this.vessel.hullInspections];
      this.getAllDocuments();
    }
    this.$root.$on("reloadVesselDocumentsAfterDocumentsIsDeleted", () => {
      this.getAllDocuments();
    });
  },
};
</script>
