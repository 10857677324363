<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-start align-items-center mb-3">
      <div class="page-title-box pb-0">
        <h4 class="mb-0">Operational Restrictions</h4>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="input-group">
              <input
                id="OperationalRestrictionsForm"
                class="form-control"
                placeholder="Press Enter to add to list"
                type="text"
                @keypress="addOperationalRestrictionsOnEnter"
              />
              <div class="input-group-append">
                <button
                  class="ms-4 btn btn-primary"
                  title="Add Restriction"
                  type="button"
                  @click="addOperationalRestrictions"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>

            <div
              v-if="operational_restrictions.length"
              class="table-responsive mt-3"
            >
              <table class="table table-striped table-hover mb-0">
                <tbody>
                  <tr
                    v-for="(
                      operational_restriction, index
                    ) in operational_restrictions"
                    :key="operational_restriction"
                  >
                    <td>
                      {{ operational_restriction }}
                    </td>
                    <td class="text-end">
                      <button
                        class="btn btn-danger btn-sm"
                        title="Delete Restriction"
                        type="button"
                        @click="deleteOperationalRestrictions(index)"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VesselsOperationalRestrictionsEditor",
  props: {
    vessel: Object,
  },
  data() {
    return {
      operational_restrictions: [],
    };
  },
  methods: {
    /**
     * Public Getter to fetch the operational_restrictions as a string
     */
    getOperationalResitrictions() {
      return this.operational_restrictions.join("\n");
    },
    addOperationalRestrictionsOnEnter(event) {
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        this.addOperationalRestrictions();
      }
    },
    addOperationalRestrictions() {
      var input = document.getElementById("OperationalRestrictionsForm");
      if (input.value !== "") {
        this.operational_restrictions.push(input.value);
        input.value = "";
        input.focus();
      }
    },
    deleteOperationalRestrictions: function (index) {
      this.operational_restrictions.splice(index, 1);
    },
  },
  mounted() {
    if (this.vessel.operational_restrictions)
      this.operational_restrictions =
        this.vessel.operational_restrictions.split("\n");
  },
};
</script>
