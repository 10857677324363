import moment from "moment";
import { CertificateTypes } from "../constants/certificates";

export const IGNORE = 0;
export const OK = 1;
export const WARN = 2;
export const SEVERE = 3;

/**
 * Get the expiry situation of a certificate
 * @param certificate the certificate object
 * @returns {number} status according to the `IGNORE`, `OK`, `WARN`, `SEVERE` variables
 */
export const getExpiryLevel = (certificate, isVesselInService) => {
  if (!isVesselInService) return IGNORE;

  if (!certificate.expiry_date) return IGNORE; // certificates without an expiry date shouldn't be considered

  const expiryInDays = moment(certificate.expiry_date).diff(moment(), "days");

  if (
    !["CYC", "CVC", "NCV", "SOC", "ERA", "IACS99", "FAC"].includes(
      certificate.certificate
    ) &&
    !(
      certificate.issued_by === "NAS" &&
      ["ILLC", "MLC", "IOPP", "ISPP", "IAPP", "SAFRAD"].includes(
        certificate.certificate
      )
    )
  )
    return IGNORE;

  if (expiryInDays < 0) return SEVERE;
  if (expiryInDays >= 0 && expiryInDays <= 90) return WARN;

  const daysSinceDueOpen = moment().diff(certificate.due_open, "days");
  const daysTillDueClose = moment(certificate.due_close).diff(moment(), "days");

  if (daysTillDueClose <= 0) return SEVERE;
  if (daysSinceDueOpen >= 60 && daysTillDueClose > 0) return WARN;
  if (daysSinceDueOpen >= 0 && daysSinceDueOpen < 60) return OK;

  return IGNORE;
};

export const getExpiryLevelText = (level) => {
  if (level === SEVERE) return "Certificate requires immediate attention";
  if (level === WARN) return "Certificate requires attention";
  if (level === OK) return "Certificate will soon require attention";

  return "";
};

export const getExpiryLevelColorClass = (level) => {
  if (level === SEVERE) return "text-danger";
  if (level === WARN) return "text-warning";
  if (level === OK) return "text-success";

  return "";
};

/*
 This function is used to determine which certificates are available for a given vessel.
 The logic is to add all the certifcates that are fulfilling the requirement based on vessel data.
 Then we filter the array to get only the unique values. 
*/
const certificateTypeSelector = (
  regulatoryRegime,
  lessThan15m,
  lessThan24m,
  lessThan300GT,
  lessThan400GT,
  lessThan500GT
) => {
  let certTypesResult = [];
  if (lessThan24m) {
    certTypesResult = ["Tonn", "AFSD", "ISPP", "Insurance", "UKT"]; // From Kurts excell sheet.
  }
  if (!lessThan24m && lessThan400GT) {
    const types = ["CoS", "ITC", "AFSD", "ISPP"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (!lessThan24m && !lessThan400GT) {
    const types = ["CoS", "ITC", "IOPP", "ISPP", "IAPP", "IEE", "AFS", "BWM"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("Small Ships Register")) {
    const types = ["CoSSmall", "Insurance"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("Fisheries") && lessThan15m) {
    const types = ["MF", "Insurance", "Tonn"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("Fisheries") && !lessThan15m && lessThan24m) {
    const types = ["MF", "Seaworthiness", "FA15m", "Insurance"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("Fisheries") && !lessThan24m) {
    const types = ["Cos", "ITC", "Torremolinos", "ISPP"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }

  const cycTypes = ["CoS", "ITC", "ILLC", "MLC"];
  if (regulatoryRegime.includes("CYC") && !lessThan24m && lessThan300GT) {
    const types = cycTypes; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (
    regulatoryRegime.includes("CYC") &&
    !lessThan24m &&
    !lessThan300GT &&
    lessThan400GT
  ) {
    const types = [...cycTypes, "SAFRAD"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }

  if (
    regulatoryRegime.includes("CYC") &&
    !lessThan24m &&
    !lessThan400GT &&
    lessThan500GT
  ) {
    const types = [
      ...cycTypes,
      "SAFRAD",
      "IOPP",
      "ISPP",
      "IAPP",
      "IEE",
      "AFS",
      "BWM",
    ]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("MARPOL")) {
    const types = ["IOPP", "ISPP", "IAPP", "IEE", "AFS", "BWM"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }
  if (regulatoryRegime.includes("NCV")) {
    const types = ["Freeboard", "Bilge"]; // From Kurts excell sheet.
    certTypesResult.push(...types);
  }

  // Add generic certificates.
  const genericTypes = ["WOF", "SSR"];
  certTypesResult.push(...genericTypes);
  // Select uniq types from array.
  const uniqTypes = certTypesResult.filter(
    (value, index, self) => self.indexOf(value) === index
  );
  return uniqTypes;
};

// This function is used to create the base requred data for the certificate types.
// The information that is created is based on Kurt Excel file
const baseDataFromVessel = (vesselData) => {
  if (!vesselData) {
    return;
  }

  const length = vesselData.dimensions?.length;
  const lengthOverall = vesselData.dimensions?.length_overall;
  const grossTonnage = vesselData.hull?.gt;
  const regulatoryRegime = vesselData.regulatory_regime || [];

  const lessThan15m = lengthOverall < 15 || length < 15;
  const lessThan24m = !length || length <= 24 || lengthOverall <= 24;
  const lessThan300GT = grossTonnage < 300;
  const lessThan400GT = grossTonnage < 400;
  const lessThan500GT = grossTonnage < 500;

  return {
    regulatoryRegime,
    lessThan15m,
    lessThan24m,
    lessThan300GT,
    lessThan400GT,
    lessThan500GT,
  };
};

// This function is filtering the certificates from CertificateTypes const based on the certificates result
// from the certificateTypeSelector function.
const getCertificateTypes = (certificates) => {
  return CertificateTypes.filter((cert) => {
    return certificates.indexOf(cert.value) >= 0;
  });
};

// This function is used to create the list of possible certificate types based on the vessel data from Kurt Excell file.
// It returns an array of certificate types with text and value properties.
export const CreatePossibleCertificateTypes = (vesselData) => {
  const baseData = baseDataFromVessel(vesselData);
  const certTypesResult = certificateTypeSelector(
    baseData.regulatoryRegime,
    baseData.lessThan15m,
    baseData.lessThan24m,
    baseData.lessThan300GT,
    baseData.lessThan400GT,
    baseData.lessThan500GT
  );

  return getCertificateTypes(certTypesResult);
};
