<template>
  <wrapper-page>
    <div v-if="!isLoadingJobOptions" class="row">
      <div class="col-12">
        <div class="row">
          <div
            class="col-12 d-flex justify-content-start align-items-center mb-3"
          >
            <div class="page-title-box pb-0">
              <h4 class="mb-0">Jobs</h4>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-x-3">
                  <form-input-select
                    v-model="jobCategoryInput"
                    :field="{
                      label: 'Job Category',
                      name: 'job_category',
                      options: jobCategoryOptions,
                    }"
                    class="col-md-4 mb-n3"
                  />

                  <form-input-text
                    v-model="jobTitle"
                    :field="{ label: 'Job Title', name: 'title' }"
                    class="col-md-4 mb-n3"
                  />

                  <button
                    class="mt-auto btn btn-primary"
                    title="Add Job"
                    type="button"
                    @click="saveDetails"
                  >
                    <span class="sr-only">Add Job</span>
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import { createProjectJob } from "@/apis/projects";
import { notifyError, notifySuccess } from "@/helpers/notification";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputSelect from "../../components/ui/FormInputSelect.vue";
import FormInputText from "../../components/ui/FormInputText.vue";
import jobsMixin from "../../mixins/projects/jobs";

export default {
  name: "ProjectJobsCreate",
  components: { WrapperPage, FormInputSelect, FormInputText },
  mixins: [jobsMixin],
  data() {
    return {
      isSavingDetails: false,

      jobTitle: "",
    };
  },
  methods: {
    async saveDetails() {
      // this.$v.$touch();
      // if (this.$v.$anyError) {
      //   notifyError(this, "not all required fields have been entered");
      //   return;
      // }

      this.isSavingDetails = true;

      const details = {
        category: this.jobCategoryInput,
        title: this.jobTitle,
      };

      await createProjectJob(details)
        .then(({ data }) => {
          if (data.id) {
            notifySuccess(this, "Job has been created");
            this.$router.push({
              name: "project-jobs",
            });
          } else
            notifyError(
              this,
              "Warning: No ID was returned, but no error was thrown"
            );
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.isSavingDetails = false;
    },
  },
};
</script>
