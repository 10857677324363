import { render, staticRenderFns } from "./VesselParticularsCertification.vue?vue&type=template&id=255d8385&scoped=true&"
import script from "./VesselParticularsCertification.vue?vue&type=script&lang=js&"
export * from "./VesselParticularsCertification.vue?vue&type=script&lang=js&"
import style0 from "./VesselParticularsCertification.vue?vue&type=style&index=0&id=255d8385&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255d8385",
  null
  
)

export default component.exports