<template>
  <div>
    <div class="row flex-grow-1 d-flex">
      <div
        v-for="field in formerNameFields"
        :key="`${field.name}`"
        :class="field.type === 'textarea' ? 'col-lg-6' : 'col-lg-3'"
        class="col-md-6"
      >
        <form-input-date
          v-if="field.type === 'date'"
          v-model="$v.formerName[field.name].$model"
          :error="$v.formerName[field.name].$error"
          :error-message="validationMessage($v.formerName[field.name])"
          :field="field"
        />

        <form-input-text
          v-else
          v-model="$v.formerName[field.name].$model"
          :error="$v.formerName[field.name].$error"
          :error-message="validationMessage($v.formerName[field.name])"
          :field="field"
        />
      </div>
      <div class="col-12">
        <button
          class="btn btn-primary"
          type="button"
          @click="(e) => $emit('addFormerName', formerName)"
        >
          Add former name
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../ui/FormInputText.vue";
import FormInputDate from "../../../ui/FormInputText.vue";

export default {
  name: "FormerNamesEditor",
  components: { FormInputText, FormInputDate },
  data() {
    return {
      formerName: { new_name: "", old_name: "", change_date: new Date() },
      formerNameFields: [
        { label: "New name", name: "new_name", type: "text" },
        { label: "Old name", name: "old_name", type: "text" },
        { label: "Date Changed", name: "change_date", type: "date" },
      ],
    };
  },
  validations: {
    formerName: {
      new_name: { required },
      old_name: { required },
      change_date: { required, Date },
    },
  },
  methods: {
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
  },
};
</script>
