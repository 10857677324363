<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box pb-3">
        <h4>Client Analytics</h4>
      </div>
    </div>
    <number-card
      :url="getUrlFor('projects', 'open')"
      :value="kpis.projects_in_progress"
      icon="ri-briefcase-line"
      label="Open Projects"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('projects', 'completed')"
      :value="kpis.projects_closed"
      icon="ri-briefcase-line"
      label="Completed Projects"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :label="`${new Date().getFullYear()} Projects`"
      :url="getUrlFor('projects', 'thisYear')"
      :value="kpis.projects_this_year"
      icon="ri-briefcase-line"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :label="`${new Date().getFullYear() - 1} Projects`"
      :url="getUrlFor('projects', 'lastYear')"
      :value="kpis.projects_last_year"
      icon="ri-briefcase-line"
      size="col-xl-4 col-sm-6"
    ></number-card>

    <number-card
      :url="getUrlFor('vessels')"
      :value="kpis.total_vessels"
      icon="ri-anchor-line"
      label="Total vessels"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'inService')"
      :value="kpis.vessels_in_service"
      icon="ri-anchor-line"
      label="Vessels In Service"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.detained_psc_vessels"
      icon="ri-anchor-line"
      label="Detained PSC Vessels"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.numberOfPscInvestigation"
      icon="ri-anchor-line"
      label="Number of PSC Investigations"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.detainedPscPercentage?.toFixed(2)"
      icon="ri-anchor-line"
      label="% Detained PSC Vessels"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.detained_fsi_vessels"
      icon="ri-anchor-line"
      label="Detained FSI Vessels"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.numberOfFsiInvestigation"
      icon="ri-anchor-line"
      label="Number of FSI Investigations"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('vessels', 'isDetained')"
      :value="kpis.detainedFsiPercentage?.toFixed(2)"
      icon="ri-anchor-line"
      label="% Detained FSI Vessels"
      size="col-xl-4 col-sm-6"
    ></number-card>
  </div>
</template>

<script>
import { getClientKPIs } from "@/apis/kpis";
import NumberCard from "../ui/NumberCard.vue";

export default {
  name: "ClientAnalytics",
  components: { NumberCard },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      kpis: {},
    };
  },
  methods: {
    getUrlFor(page, query) {
      const root = page === "vessels" ? "/vessels/list" : "/projects/list";
      let queryString = `?clientId=${this.clientId}&`;
      const thisYear = new Date().getFullYear()?.toString();
      const lastYear = (new Date().getFullYear() - 1).toString();
      switch (query) {
        case "open":
          queryString += "isActive=true";
          break;
        case "completed":
          queryString += "isActive=false";
          break;
        case "lastYear":
          queryString += `startDate=${lastYear}`;
          break;
        case "thisYear":
          queryString += `startDate=${thisYear}`;
          break;
        case "inService":
          queryString += "inService=true";
          break;
        case "isDetained":
          queryString += "isDetained=true";
          break;
      }
      const url = root + queryString;
      return url;
    },
    async updateClientKPIs(clientId) {
      const response = await getClientKPIs(clientId);
      this.kpis = response.data;
    },
  },
  mounted() {
    this.updateClientKPIs(this.clientId);
  },
};
</script>
