import { getClient, getClients } from "@/apis/clients";
import { notifyError } from "@/helpers/notification";

export default {
  data() {
    return {
      isLoadingClientOptions: false,
      allClientOptions: [],
      preferAllClientOptions: false,
    };
  },
  methods: {
    async clientSelected(client_id, type) {
      if (client_id === "other") {
        this.preferAllClientOptions = true;
        return;
      }

      if (type === "invoicing_client") return;

      // get contacts of the chosen client
      let contacts = [{}];
      await getClient(client_id)
        .then(({ data }) => {
          if (
            data.contacts &&
            data.contacts.constructor === Array &&
            data.contacts.length > 0
          )
            contacts = data.contacts.map((c) => ({
              type: c.type,
              name: c.name,
              email: c.email,
              phone: c.phone,
            }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.contacts = contacts;
    },
    async getClientOptions() {
      this.isLoadingClientOptions = true;

      let clientOptions = [];

      await getClients()
        .then(({ data }) => {
          clientOptions = data.map((c) => ({
            text: c.name,
            value: c.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.allClientOptions = clientOptions;

      const invoicingClientFieldIndex = this.fields.findIndex(
        (f) => f.name === "invoicing_client"
      );
      if (invoicingClientFieldIndex >= 0)
        this.$set(
          this.fields[invoicingClientFieldIndex],
          "options",
          this.allClientOptions
        );

      this.isLoadingClientOptions = false;
    },
  },
  computed: {
    relevantClientOptions() {
      if (this.preferAllClientOptions || !this.vessels || !this.vessels.length)
        return this.allClientOptions;

      const options = [];

      if (this.vessel_clients && this.vessel_clients.length) {
        const clientIds = this.vessel_clients.map((vc) => vc.client_id);
        const clientOptionsToAdd = this.allClientOptions.filter((co) =>
          clientIds.includes(co.value)
        );
        options.push(...clientOptionsToAdd);
      }

      if (this.details.client_id) {
        if (!options.some((o) => o.value === this.details.client_id)) {
          const clientOptionToAdd = this.allClientOptions.find(
            (co) => co.value === this.details.client_id
          );
          if (clientOptionToAdd) {
            options.push(clientOptionToAdd);
          }
        }
      }

      if (this.details.invoicing_client?.id) {
        if (
          !options.some((o) => o.value === this.details.invoicing_client.id)
        ) {
          const clientOptionToAdd = this.allClientOptions.find(
            (co) => co.value === this.details.invoicing_client.id
          );
          if (clientOptionToAdd) {
            options.push(clientOptionToAdd);
          }
        }
      }

      if (options.length === 0) return this.allClientOptions;

      options.push({ text: "Other", value: "other" });

      return options;
    },
  },
  mounted() {
    this.getClientOptions();
  },
};
