export const ChartColors = [
  "#001e2a", // Navy brand color
  "#71a88e", // Rain Water brand color
  "#ab624c", // Copper brand color
  "#125661", // Petrol Blue brand color
  "#209AAC", // Petrol Blue light
  "#2F4C3E", // Rain Water darker
  "#C69180", // Copper light
  "#0092CC", // Navy lighter

  "#F4743B",
  "#902D41",
  "#9A7AA0",
  "#E01A4F",
  "#00438F",
  "#E9B44C",
  "#cdcdc8",
];
