<template>
  <div>
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="input-group has-validation mb-3">
      <div class="input-group-prepend">
        <slot name="input-group-prepend"></slot>
      </div>
      <input
        v-if="field.type !== 'textarea'"
        :id="`input-${field.name}`"
        :aria-label="field.name"
        :class="{ 'parsley-error is-invalid': error }"
        :maxlength="field.maxLength"
        :name="field.name"
        :placeholder="field.placeholder || field.label"
        :readonly="readonly || field.readonly"
        :type="field.type || 'text'"
        :value="value"
        aria-describedby="basic-addon1"
        class="form-control"
        @input="(e) => $emit('input', e.target.value)"
      />
      <textarea
        v-if="field.type === 'textarea'"
        :id="`input-${field.name}`"
        :aria-label="field.name"
        :class="{ 'parsley-error is-invalid': error }"
        :maxlength="field.maxLength"
        :name="field.name"
        :placeholder="field.placeholder || field.label"
        :readonly="readonly || field.readonly"
        :rows="field.rows"
        :type="field.type || 'text'"
        :value="value"
        aria-describedby="basic-addon1"
        class="form-control"
        @input="(e) => $emit('input', e.target.value)"
      />
      <div class="input-group-append">
        <slot name="input-group-append"></slot>
      </div>
      <div v-if="error" class="invalid-feedback">
        <template v-if="errorMessage">{{ errorMessage }}</template>
        <template v-else>Error has been encountered</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputText",
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    value: [String, Number, Date],
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal) {
      if (this.field.type === "number" && newVal === "") {
        this.$emit("input", null);
      }
    },
  },
};
</script>
