<template>
  <div class="row">
    <number-card
      :url="getUrlFor('inService')"
      :value="vesselsStatus.in_service"
      icon="ri-anchor-line"
      label="In operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('commercial')"
      :value="vesselsStatus.commercial"
      icon="ri-anchor-line"
      label="Commercial Yachts in operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('NCV')"
      :value="vesselsStatus.ncv_in_operation"
      icon="ri-anchor-line"
      label="NCV in operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('CVC')"
      :value="vesselsStatus.cvc_in_operation"
      icon="ri-anchor-line"
      label="CVC in operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('passenger')"
      :value="vesselsStatus.passenger"
      icon="ri-anchor-line"
      label="Passenger Vessels in operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('tuna')"
      :value="vesselsStatus.tuna_farm"
      icon="ri-anchor-line"
      label="Tuna Farm Vessels in operation"
      size="col-xl-4 col-sm-6"
    ></number-card>
  </div>
</template>

<script>
import NumberCard from "../../ui/NumberCard.vue";

export default {
  name: "VesselsStatusKpis",
  components: { NumberCard },
  props: {
    vesselsStatus: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrlFor(filterBy) {
      return `/vessels/list?filterBy=${filterBy}`;
    },
  },
};
</script>
