import moment from "moment";
import { hasEntry } from "@/helpers/generic";

export const extractCells = (entities, fields, excludeEmptyFields = false) => {
  const isEntitiesArray = Array.isArray(entities);

  // if `entities` is not an array, temporary make it an array
  // so that the logic is the same, and convert it back afterwards
  if (!isEntitiesArray) entities = [entities];

  let result = entities.map((entity) => {
    const cells = fields.map((field) => {
      let value = entity[field.key] ?? field.default;

      if (field.format === "date" && value) {
        value = moment(value).format("DD-MM-YYYY");
      } else if (field.format === "currency") {
        value = hasEntry(value) ? `€ ${value.toString().trim()}` : null;
      } else if (field.format instanceof Function) {
        value = field.format(value);
      }

      return {
        key: field.key,
        label: field.label,
        visible: field.visible,
        value,
      };
    });

    // check if the entity has any valuable cells, otherwise skip
    if (
      !cells.some((cell) => hasEntry(cell.value, ["scope"].includes(cell.key)))
    ) {
      return null;
    }

    return cells;
  });

  // filter out null entities
  result = result.filter((entity) => entity !== null);

  if (excludeEmptyFields) {
    // filter out empty cells from each entity
    result = result.map((entity) =>
      entity.filter((cell) =>
        hasEntry(cell.value, ["scope"].includes(cell.key))
      )
    );
  }

  // if `entities` was not an array, convert `result` back to a non-array
  if (!isEntitiesArray) {
    result = result[0] ?? [];
  }

  return result;
};
