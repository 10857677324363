<template>
  <div class="row">
    <small v-if="label" class="text-muted">{{ label }}</small>
    <div :class="{ 'is-prominent': isProminent }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    isProminent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/assets/css/variables";

.row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 0.5rem;
  border-width: 1px 0 0 0;
  border-color: $brand-off-white;
  border-style: solid;
}

small {
  margin-top: 1px;
}

p {
  margin-bottom: 0;
}

.is-prominent {
  p {
    font-weight: bold;
  }
}
</style>
