<template>
  <div class="row">
    <!-- Search bar -->
    <div v-if="showSearchBar" class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Name"
                  type="text"
                  @input="searchField('name', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="IMO Number"
                  type="text"
                  @input="searchField('imo_number', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Official Number"
                  type="text"
                  @input="searchField('official_number', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="S-Reg"
                  type="text"
                  @input="searchField('s_reg', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6 ms-auto">
              <a
                class="btn btn-sm btn-primary btn-block"
                href="javascript:void(0);"
                title=""
                >Search</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body table-responsive">
          <table
            class="table table-hover table-custom dataTable vessels-js-data-table"
          >
            <thead>
              <tr>
                <th v-if="hasExpiredCertificates"></th>
                <th title="Hull & Machinery">H&amp;M</th>
                <th title="Status">Status</th>
                <th>Name</th>
                <th>IMO Number</th>
                <th>Official Number</th>
                <th>V. Type</th>
                <th>Len. Overall</th>
                <th>Length</th>
                <th>GT</th>
                <th>Base Port</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(vessel, index) in filteredVessels()"
                :key="index"
                class="cursor-pointer"
                @click="openVesselDetails(vessel)"
              >
                <td
                  v-if="hasExpiredCertificates"
                  :data-order="vessel.isCertExpired ?? '0'"
                  :title="getExpiryLevelText(vessel.isCertExpired)"
                >
                  <i
                    v-if="vessel.isCertExpired"
                    :class="`${getExpiryLevelColorClass(
                      vessel.isCertExpired
                    )} ri-error-warning-line`"
                  />
                </td>
                <td>
                  <i
                    v-if="vessel.h_and_m"
                    class="ri-checkbox-blank-circle-fill text-success"
                  ></i>
                  <i
                    v-else
                    class="ri-checkbox-blank-circle-line text-secondary"
                  ></i>
                </td>
                <td>{{ vesselStatusText(vessel) }}</td>
                <td>
                  {{ vessel.name }}
                </td>
                <td>{{ vessel.imo_number }}</td>
                <td>
                  {{ vessel.official_number }}
                </td>
                <td>{{ vesselTypeText(vessel) }}</td>
                <td>{{ vesselLOAText(vessel) }}</td>
                <td>{{ vesselLengthText(vessel) }}</td>
                <td>{{ vessel_GT_Text(vessel) }}</td>
                <td>{{ vessel.base_port }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getExpiryLevelColorClass,
  getExpiryLevelText,
  WARN,
} from "@/helpers/certificates";

export default {
  name: "VesselsTable",
  props: {
    vessels: {
      type: Array,
      default: () => [],
    },
    showSearchBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      WARN,
      fieldFilters: [],
    };
  },
  methods: {
    getExpiryLevelColorClass,
    getExpiryLevelText,
    openVesselDetails(vessel) {
      let resolvedRoute = this.$router.resolve({
        name: "vessels-show",
        params: { vesselId: vessel.id },
      });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },
    filteredVessels: function () {
      let result = [...this.vessels];
      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v?.[element.field]
              ?.toLowerCase()
              ?.includes(element.searchString.toLowerCase())
          );
        }
      });
      return result;
    },
    vesselStatusText: function (vessel) {
      switch (vessel.service_status) {
        case "In Service":
          return "In Service";
        case "In Progress":
          return "In Progress";
        case "Under Construction":
          return "Under Construction";
        default:
          return "";
      }
    },
    vesselTypeText: function (vessel) {
      return vessel.vessel_type_text;
    },
    vesselLOAText: function (vessel) {
      return vessel.length_overall;
    },
    vesselLengthText: function (vessel) {
      return vessel.length;
    },
    vessel_GT_Text: function (vessel) {
      if (!vessel.gt) {
        return vessel.gt_and_nt;
      }
      return vessel.gt;
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".vessels-js-data-table", {
          columnDefs: [
            {
              targets: this.hasExpiredCertificates ? [0, 1] : 0,
              type: "numeric",
            },
          ],
          dom: '<"row"<"pl-2 col-lg-4"l><"text-center col-lg-4"B><"pr-2 col-lg-4"fr>>t<"row"<"pl-2 col-md-6"i><"pr-2 col-md-6"p>>',
          buttons: [
            {
              extend: "copy",
              className: "btn btn-sm btn-dt-action",
            },
            {
              extend: "csv",
              className: "btn btn-sm btn-dt-action",
            },
            {
              extend: "excel",
              className: "btn btn-sm btn-dt-action",
            },
            {
              extend: "pdf",
              className: "btn btn-sm btn-dt-action",
            },
            {
              extend: "print",
              className: "btn btn-sm btn-dt-action",
            },
          ],
          order: [],
          pageLength: 100,
        });
      }, 250); // Just giving Vue some time to render the table
    },
  },
  computed: {
    hasExpiredCertificates() {
      return this.vessels.some((v) => v.isCertExpired);
    },
  },
  watch: {
    vessels() {
      this.buildDataTable();
    },
  },
  mounted() {
    this.buildDataTable();
  },
};
</script>
