<template>
  <div class="row">
    <!-- Search bar -->
    <div v-if="showSearchBar" class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Name"
                  type="text"
                  @input="searchField('name', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6 ms-auto">
              <a
                class="btn btn-sm btn-primary btn-block"
                href="javascript:void(0);"
                title=""
                >Search</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-custom dataTable vessel-builders-js-data-table"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Country</th>
              <th>P.Code</th>
              <th>Associated Vessels</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(vesselBuilder, index) in filteredBuilders()"
              :key="index"
            >
              <td>
                <a href="#" @click="builderClicked(vesselBuilder)">{{
                  vesselBuilder.name
                }}</a>
              </td>
              <td>
                <a @click="builderClicked(vesselBuilder)"
                  >{{ vesselBuilder.line1 }} {{ vesselBuilder.line2 }}</a
                >
              </td>
              <td>
                <a @click="builderClicked(vesselBuilder)">{{
                  vesselBuilder.city
                }}</a>
              </td>
              <td>
                <a @click="builderClicked(vesselBuilder)">{{
                  vesselBuilder.country
                }}</a>
              </td>
              <td>
                <a @click="builderClicked(vesselBuilder)">{{
                  vesselBuilder.postcode
                }}</a>
              </td>
              <td>{{ associatedVessels(vesselBuilder) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VesselBuildersTable",
  props: {
    vesselBuilders: {
      type: Array,
      default: () => [],
    },
    showSearchBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldFilters: [],
    };
  },
  methods: {
    getBuilderObjectFromListObject(builderFromList) {
      let builder = {
        id: builderFromList.id,
        name: builderFromList.name,
      };

      let address = { ...builderFromList };
      address.id = address.address_id;
      delete address.address_id;
      delete address.associated_vessels;
      delete address.name;

      builder.address = address;

      return builder;
    },
    builderClicked(builder) {
      let resolvedRoute = this.$router.resolve({
        name: "vessel-builders-edit",
        params: { builderId: builder.id },
      });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },
    filteredBuilders: function () {
      let result = [...this.vesselBuilders];
      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v?.[element.field]
              ?.toLowerCase()
              ?.includes(element.searchString.toLowerCase())
          );
        }
      });
      return result;
    },
    associatedVessels: function (vesselBuilder) {
      return vesselBuilder.associated_vessels;
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".vessel-builders-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  mounted() {
    this.buildDataTable();
  },
  watch: {
    vesselBuilders() {
      this.buildDataTable();
    },
  },
};
</script>
