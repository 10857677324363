<template>
  <div class="card-body">
    <div
      class="d-flex justify-content-between cursor-pointer select-none"
      @click="$emit('toggled')"
    >
      <h6 class="mb-0">{{ title }}</h6>
      <div>
        <i v-if="isSelected" class="fa fa-minus"></i>
        <i v-else class="fa fa-plus"></i>
      </div>
    </div>

    <div v-if="isSelected" class="mt-4">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
