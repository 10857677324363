export const notifySuccess = (vue, message = "Success") => {
  vue.$notify({ text: message, type: "success" });
};

export const notifyChangesSaved = (vue) =>
  notifySuccess(vue, "Changes have been saved");

export const notifyError = (vue, message = "Error has been encountered") => {
  vue.$notify({ text: message, type: "error" });
};

export const notifyWarning = (vue, message = "Warning") => {
  vue.$notify({ text: message, type: "warning" });
};
