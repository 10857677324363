export const clientContactSearchFields = [
  {
    name: "client_name",
    type: "text",
    label: "Client name",
    placeholder: "Client name",
  },
  {
    name: "contact_name",
    type: "text",
    label: "Contact name",
    placeholder: "Contact name",
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Email",
  },
  {
    name: "phone_number",
    type: "text",
    label: "Phone number",
    placeholder: "Phone number",
  },
];
