<template>
  <div class="row">
    <div
      v-for="vessel in vessels"
      :key="vessel.id"
      class="col-lg-3 col-md-6 col-sm-12 d-flex flex-col"
    >
      <div class="card flex-grow-1">
        <router-link
          class="h-100"
          :to="{ name: 'vessels-show', params: { vesselId: vessel.id } }"
        >
          <div class="card-body cursor-pointer">
            <h4 class="card-title">{{ vessel.name }}</h4>
            <div class="mt-auto d-flex flex-column">
              <h6
                v-if="vessel.imo_number"
                class="card-subtitle text-muted fs-6 mt-1"
              >
                IMO Number: {{ vessel.imo_number }}
              </h6>
              <h6
                v-if="vessel.official_number"
                class="card-subtitle text-muted fs-6 mt-1"
              >
                Official Number: {{ vessel.official_number }}
              </h6>
              <h6
                v-if="vessel.s_reg"
                class="card-subtitle text-muted fs-6 mt-1"
              >
                S-Reg: {{ vessel.s_reg }}
              </h6>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VesselsGrid",
  components: {},
  props: {
    vessels: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
