<template>
  <button
    class="btn btn-primary"
    title="Download Certificates"
    type="button"
    @click.stop="downloadCertificates"
  >
    <slot />
  </button>
</template>

<script>
import { getDoc } from "./templaterFuntions";

export default {
  name: "MultiTemplateDownloader",
  components: {},
  props: {
    vesselId: {
      type: Number,
    },
    docTypes: {
      type: Array,
    },
    projectNumber: {
      type: String,
    },
    projectsInvoicingClientList: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    downloadCertificates() {
      this.docTypes?.forEach((docType) => {
        getDoc(
          this.vesselId,
          docType?.value,
          this.projectNumber,
          this.projectsInvoicingClientList
        );
      });
    },
  },
};
</script>
