<template>
  <div class="col-12">
    <div v-if="valuation_" class="row flex-grow-1 d-flex">
      <div class="col-md-6 col-lg-3">
        <form-input-date
          v-model="$v.valuation_.date.$model"
          :error="$v.valuation_.date.$error"
          :error-message="validationMessage($v.valuation_.date)"
          :field="valuationFields[0]"
        />
      </div>

      <div class="col-md-6 col-lg-3">
        <form-input-text
          v-model.number="$v.valuation_['total'].$model"
          :field="totalField"
        />
      </div>
    </div>

    <div v-if="valuation_" class="row flex-grow-1 d-flex">
      <div
        v-for="field in valuationFields.filter(
          (field) => field.type === 'number'
        )"
        :key="`${field.name}`"
        class="col-md-4 col-lg-2"
      >
        <form-input-text
          v-model.number="$v.valuation_[field.name].$model"
          :error="$v.valuation_[field.name].$error"
          :error-message="validationMessage($v.valuation_[field.name])"
          :field="field"
        />
      </div>
    </div>

    <!-- Documents, Upload & List -->
    <div v-if="this.valuation_ && this.valuation_.id" class="row">
      <div class="col-6">
        <form-input-file
          :field="uploadField"
          :show-action-button="true"
          @action="uploadValuationDocuments"
        />
      </div>
      <div class="col-6">
        <documents-list
          :documents="valuationDocuments"
          _title="Valuation Documents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import FormInputText from "../../../ui/FormInputText.vue";
import FormInputDate from "../../../ui/FormInputText.vue";
import FormInputFile from "../../../ui/FormInputFile.vue";
import DocumentsList from "../../../ui/DocumentsList.vue";
import { uploadDocument } from "../../../../apis/files";
import { notifyError, notifySuccess } from "@/helpers/notification";

export default {
  name: "ValuationEditor",
  components: {
    FormInputText,
    FormInputDate,
    FormInputFile,
    DocumentsList,
  },
  props: {
    valuation: {
      type: Object,
    },
    valuationDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valuation_: null,
      valuationFields: [
        { label: "Valuation Date", name: "date", type: "date" },
        { label: "Hull", name: "hull", type: "number" },
        { label: "Machinery", name: "machinery", type: "number" },
        { label: "Tender", name: "tender", type: "number" },
        { label: "Aux. Engine", name: "auxiliary_engine", type: "number" },
        { label: "Equipment", name: "equipment", type: "number" },
        { label: "Trailer", name: "trailer", type: "number" },
      ],
      totalField: {
        label: "Total (in EUR)",
        name: "total",
        type: "number",
        readonly: false,
      },
      uploadField: {
        label: "Valuation Documents",
        name: "valuation_file",
        type: "file",
        multiple: true,
        placeholder: "Choose one or more files to upload",
        actionButtonTitle: "Upload valuation document/s",
      },
    };
  },
  validations: {
    valuation_: {
      date: { required },
      hull: {},
      machinery: {},
      tender: {},
      auxiliary_engine: {},
      equipment: {},
      trailer: {},
      total: {},
    },
  },
  // computed: {
  //   total() {
  //     return (this.valuation_.hull || 0) + (this.valuation_.machinery || 0) + (this.valuation_.tender || 0) + (this.valuation_.auxiliary_engine || 0) + (this.valuation_.equipment || 0) + (this.valuation_.trailer || 0);
  //   }
  // },
  methods: {
    validationMessage,
    isInvalid() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    prepareInternalValuation(valuation) {
      const emptyValuation = {
        date: null,
        hull: null,
        machinery: null,
        tender: null,
        auxiliary_engine: null,
        equipment: null,
        trailer: null,
      };
      this.valuation_ = { ...emptyValuation, ...valuation };
      if (this.valuation_.date)
        this.valuation_.date = moment(this.valuation_.date).format(
          "YYYY-MM-DD"
        );
    },
    async uploadValuationDocuments(fileControl) {
      if (fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const uri = `vessels/${this.valuation_.vessel_id}/valuations/${this.valuation_.id}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(
            uploadDocument(file, uri)
              .then((response) => {
                const docs = this.valuationDocuments;
                docs.push(response.data.path);
                notifySuccess(this, "File(s) have been uploaded!");
              })
              .catch(() => {
                notifyError(this, "File upload failed.");
              })
          );
        }
        await Promise.all(promises).then();
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
      }
    },
  },
  watch: {
    valuation(newValuation) {
      this.prepareInternalValuation(newValuation);
    },
  },
  created() {
    this.prepareInternalValuation(this.valuation);
  },
};
</script>
