import axios from "axios";

export default {
  get: async (url) => {
    return axios.get(url);
  },

  create: async (url, data) => {
    return axios.post(url, data);
  },

  update: async (url, data) => {
    return axios.put(url, data);
  },

  remove: async (url, data) => {
    return axios.delete(url, { data });
  },

  fileUpload: async (url, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { "Content-Type": "multipart/form-data" };
    return axios.post(url, formData, { headers });
  },
};
