<template>
  <table class="table header-border table-hover">
    <thead>
      <tr>
        <th>Date</th>
        <th v-if="!reducedMode">Location</th>
        <th>Outcome</th>
        <th v-if="events.some((e) => e.deficiencies)">Deficiencies</th>
        <th v-if="events.some((e) => e.days_detained)">Days Detained</th>
        <th>Notes</th>
        <th v-if="!reducedMode">Documents</th>
        <th class="px-1"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(event, i) in events" :key="i">
        <td>{{ moment(event.event_date).format("DD-MM-YYYY") }}</td>
        <td v-if="!reducedMode">{{ getEventLocation(event) }}</td>
        <td :style="getTdStyle()" class="truncate">
          <span :title="event.outcome">
            {{ event.outcome }}
          </span>
        </td>
        <td v-if="events.some((e) => e.deficiencies)">
          {{ event.deficiencies }}
        </td>
        <td v-if="events.some((e) => e.days_detained)">
          {{ event.days_detained }}
        </td>
        <td :style="getTdStyle()" :title="event.notes" class="truncate">
          {{ event.notes }}
        </td>
        <td style="max-width: calc(100vw / 2 / 7)">
          <div
            v-if="getDocumentsForEvent(event).length > 0"
            class="d-flex flex-column"
          >
            <a
              v-for="file in getDocumentsForEvent(event)"
              :key="file.name"
              :href="`${storageEndpoint}${file}`"
              :title="getFilename(file)"
              class="truncate"
              target="_blank"
            >
              {{ getFilename(file) }}
            </a>
          </div>
        </td>
        <td class="px-1">
          <button
            class="btn btn-danger me-1 btn-sm"
            title="Delete Event"
            type="button"
            @click="$emit('removeEvent', i, event)"
          >
            <span class="sr-only">Delete Event</span>
            <i class="fa fa-times"></i>
          </button>
          <button
            v-if="!reducedMode"
            class="btn btn-primary me-3 btn-sm"
            title="Edit"
            type="button"
            @click="$emit('editEvent', event, i)"
          >
            <span class="sr-only">Edit Event</span>
            <i class="fa fa-pencil-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";

const config = require("@/apis/config.json");

export default {
  name: "SubEventsList",
  props: {
    events: Array,
    documents: Array,
    reducedMode: Boolean,
  },
  data() {
    return {
      moment,
      storageEndpoint: config.apis.baseUrl.az_storage_endpoint,
    };
  },
  methods: {
    getEventLocation(event) {
      if (event.port && event.country) return event.port + ", " + event.country;
      if (event.port) return event.port;
      return event.country;
    },
    getDocumentsForEvent(event) {
      return this.documents.filter((doc) =>
        doc.startsWith(
          `vessels/${event.vessel_id}/${event.event_type}/${event.id}`
        )
      );
    },
    getFilename(file) {
      if (!file) {
        return;
      }
      return file.split("\\").pop().split("/").pop();
    },
    getTdStyle() {
      return this.reducedMode
        ? {
            maxWidth: "",
          }
        : { maxWidth: "calc(100vw / 2 / 7)" };
    },
  },
};
</script>
