<template>
  <div>
    <b v-if="field.label">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>

    <div class="input-group has-validation mb-3">
      <select
        :id="`input-${field.name}`"
        :aria-label="field.name"
        :class="{ 'parsley-error is-invalid': error }"
        :name="field.name"
        aria-describedby="basic-addon1"
        class="form-control"
        @input="onInput"
      >
        <option
          v-if="field.placeholder || field.label"
          :selected="!value"
          disabled
          value="0"
        >
          {{ field.placeholder || field.label }}
        </option>
        <option
          v-if="field.includeNA == true"
          :selected="value === ''"
          value=""
        >
          Not Applicable
        </option>
        <option
          v-for="option in getSortedOptions"
          :key="optionKey(option)"
          :selected="value && option.value === value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
        <option v-if="field.includeOther" value="Other">Other</option>
      </select>
      <div class="input-group-append">
        <slot name="input-group-append"></slot>
      </div>
      <div v-if="error" class="invalid-feedback">
        <template v-if="errorMessage">{{ errorMessage }}</template>
        <template v-else>Error has been encountered</template>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownOtherEditorModal from "@/components/ui/DropdownOtherEditorModal";

export default {
  name: "FormInputSelect",
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    parent: {
      // Required if the field has includeOther:true
      type: Object,
    },
  },
  computed: {
    getSortedOptions() {
      if (!this.field.options) return [];
      const options = [...this.field.options];

      if (this.field.sortOptions === false) return options;
      if (!this.field.options || !this.field.options.length) return options;

      options.sort((a, b) => a.text.localeCompare(b.text));
      return options;
    },
  },
  methods: {
    optionKey(option) {
      let key = `${this.field?.name}${option.value}`;
      if (option.builderId) key = option.builderId + key;

      return key;
    },
    /**
     * Allows any dropdown with 'Other' as an option to add extra values
     * Sets the value to the object's property when done
     * @param {string} input The value selected from the dropdown - to see if it is 'Other'
     * @param {object} field The field defining how to show the dropdown
     */
    DropdownOtherEditorModalSelected(input, field) {
      if (input === "Other") {
        // show modal to add a new value to the dropdown list
        this.$modal.show(
          DropdownOtherEditorModal,
          { field },
          {
            name: "dropdown-other-editor-modal",
            height: "auto",
            width: "400px",
          },
          {
            "before-close": async (e) => {
              const newValue = e.params?.value;
              if (!newValue) return;
              this.parent[field.name] = newValue;
            },
          }
        );
      }
    },
    onInput(e) {
      const value = e.target.value;
      this.DropdownOtherEditorModalSelected(value, this.field);
      this.$emit("input", value);
    },
  },
  mounted() {
    if (this.field.includeOther) {
      if (this.value) {
        if (!this.field.options) this.field.options = [];
        if (!this.field.options.find((option) => option.value === this.value)) {
          this.field.options.push({ text: this.value, value: this.value });
        }
      }
    }
  },
};
</script>
