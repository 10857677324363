<template>
  <div class="card-body">
    <div
      :class="{ 'cursor-pointer': isExpandable && !forceExpanded }"
      class="d-flex justify-content-between"
      @click="$emit('toggledExpanded', !isExpanded)"
    >
      <h6 class="card-title mb-3">{{ title }}</h6>
      <div v-if="isExpandable && !forceExpanded">
        <i v-if="isExpanded" class="fa fa-angle-up"></i>
        <i v-else class="fa fa-angle-down"></i>
      </div>
    </div>

    <div
      :class="{
        'with-cells': !withSubSections,
        'with-narrow-columns': withNarrowColumns,
        'with-single-column': withSingleColumn,
      }"
      class="section-wrapper"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    withSubSections: {
      type: Boolean,
      required: false,
      default: false,
    },
    withSingleColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    withNarrowColumns: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  &.with-cells {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    justify-items: center;
    column-gap: 2rem;
    row-gap: 0;

    &.with-narrow-columns {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }

  &.with-single-column {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
