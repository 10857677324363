<template>
  <wrapper-page ref="wrapper">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <machinery-makers-table
              v-if="!isLoadingVesselMachineryMakers"
              :machineryMakers="machineryMakers"
              :showSearchBar="false"
            />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../../components/layout/WrapperPage.vue";
import MachineryMakersTable from "../../../components/vessels/VesselMachineryMakersTable.vue";
import { getMachineryMakersList } from "@/apis/machineryMakers";
import { notifyError } from "@/helpers/notification";

export default {
  name: "MachineryMakersIndex",
  components: {
    WrapperPage,
    MachineryMakersTable,
  },
  data() {
    return {
      isLoadingVesselMachineryMakers: true,
      machineryMakers: [],
    };
  },
  async mounted() {
    this.isLoadingVesselMachineryMakers = true;

    await getMachineryMakersList()
      .then(({ data }) => {
        this.machineryMakers = [...data];
      })
      .catch((err) => {
        notifyError(this, err?.response?.data);
      });

    this.isLoadingVesselMachineryMakers = false;
  },
};
</script>
