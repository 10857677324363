<template>
  <div class="row clearfix">
    <div class="form-control filter-class">{{ filter?.column }}</div>
    <multi-select-dropdown
      v-if="field && field.type !== 'check'"
      v-model="operator"
      :close-on-select="true"
      :field="operatorsField"
      :multiple="false"
      :showLabels="false"
      class="col-lg-1 col-md-2 col-sm-2 pl-0 pr-0 operand-selector"
    />
    <!-- Different inputs for different field types and operators -->
    <template v-if="operator?.value?.endsWith('between')">
      <!-- 2 fields for '[not] between'. Can be numbers or dates -->
      <form-input-text
        v-if="field && field.type !== 'date'"
        v-model="modelField"
        :field="field"
        class="col-3"
      />
      <form-input-date
        v-if="field && field.type === 'date'"
        v-model="modelField"
        :field="field"
        class="col-3"
      />
      <form-input-text
        v-if="field && field.type !== 'date'"
        v-model="modelField2"
        :field="field"
        class="col-3"
      >
        <template slot="input-group-prepend"
          ><span class="input-group-text text-white bg-secondary"
            >and</span
          ></template
        >
      </form-input-text>
      <form-input-date
        v-if="field && field.type === 'date'"
        v-model="modelField2"
        :field="field"
        class="col-3"
      >
        <template slot="input-group-prepend"
          ><span class="input-group-text text-white bg-secondary"
            >and</span
          ></template
        >
      </form-input-date>
    </template>
    <template v-else>
      <form-input-check
        v-if="field && field.type === 'check'"
        v-model="modelField"
        :field="field"
        class="col-3"
      />

      <form-input-text
        v-else
        v-model="modelField"
        :field="field"
        class="col-6"
      />
    </template>
  </div>
</template>

<script>
const operatorValues = [
  "=",
  "<>",
  ">",
  "<",
  ">=",
  "<=",
  "between",
  "not between",
  "in",
  "not in",
  "like",
  "not like",
];

import FormInputText from "../ui/FormInputText.vue";
import FormInputDate from "../ui/FormInputDate.vue";
import MultiSelectDropdown from "../ui/MultiSelectDropdown.vue";
import FormInputCheck from "../ui/FormInputCheck.vue";

export default {
  name: "StructuredFilter",
  components: {
    FormInputText,
    FormInputDate,
    MultiSelectDropdown,
    FormInputCheck,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      operator: { value: "=", text: "=" },
      modelField: null,
      modelField2: null,
    };
  },
  computed: {
    field() {
      console.log("field", this.filter);
      const dataType =
        this.filter?.data_type === "timestamp"
          ? "date"
          : this.filter?.data_type === "tinyint" ||
            this.filter?.data_type === "bit"
          ? "check"
          : this.filter?.data_type;
      return {
        type: dataType,
        readonly: false,
        includeOther: false,
      };
    },

    operatorsField() {
      return {
        name: "field_operator",
        type: "select",
        options: operatorValues.map((f) => ({ text: f, value: f })),
      };
    },
  },
  methods: {
    getProps() {
      const isBooleanOperator =
        this.filter?.data_type === "tinyint" ||
        this.filter?.data_type === "bit";

      let operand = this.modelField;
      if (isBooleanOperator) {
        operand = this.modelField ? 1 : 0;
      }
      return {
        property: `${this.filter.table}.${this.filter.column}`,
        operator: this.operator?.value,
        operand,
        operand2: this.modelField2,
      };
    },
  },

  watch: {
    operator(newOperator, oldOperator) {
      // If we're switching between a single value and multiselect,
      // then we need to convert the value to/from an array
      let oldModel = this.modelField;
      if (!oldModel) {
        //this.getDefaultValue();
        return;
      }
      if (
        newOperator?.value?.endsWith("in") &&
        !oldOperator?.value?.endsWith("in")
      ) {
        // Convert value to array
        this.modelField = [
          this.field.options.find((opt) => opt.value === oldModel),
        ];
      }
      if (
        !newOperator?.value?.endsWith("in") &&
        oldOperator?.value?.endsWith("in")
      ) {
        // Get first value from array
        this.modelField = oldModel?.[0].value;
      }
    },
    value() {
      const val = this.value?.default_value;
      if (!val) return;
      this.fieldName = val.property;
      this.operator = val.operator || { value: "=", text: "=" };
      this.modelField = val.operand;
      this.modelField2 = val.operand2;
    },
  },
};
</script>

<style scoped>
.filter-class {
  display: flex;
  width: 230px;
  height: 36px;
  margin: 0 15px;
  align-items: center;
  padding: 0 15px;
}

.operand-selector {
  min-width: 115px;
}
</style>
