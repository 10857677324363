<template>
  <div class="card-body">
    <div class="row">
      <survey-editor
        ref="survEditor"
        :projectOptions="projectOptions"
        :survey="survey"
        :surveyDocuments="surveyDocuments"
      />
      <div class="col-12 mt-4 d-flex justify-content-end">
        <button class="btn btn-danger" type="button" @click="close">
          Cancel
        </button>
        <button class="btn btn-primary ms-3" type="submit" @click="saveDetails">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyEditor from "./SurveyEditor.vue";

export default {
  name: "SurveyEditorModal",
  components: { SurveyEditor },
  props: {
    survey: {
      type: Object,
    },
    surveyDocuments: {
      type: Array,
      default: () => [],
    },
    projectOptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("survey-editor-modal", state);
    },
    async saveDetails() {
      if (this.$refs.survEditor.isInvalid()) return;

      this.close({ value: this.$refs.survEditor.survey_ });
    },
  },
};
</script>
