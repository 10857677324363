<template>
  <div>
    <div id="layout-wrapper">
      <TopHeaderNavigation>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </TopHeaderNavigation>
      <LeftSideBarNavigation />

      <!-- Main content -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <main-content-header :pageTitle="pageTitle">
              <template slot="MainContentHeaderActions">
                <slot name="MainContentHeaderActions"></slot>
              </template>
            </main-content-header>
            <!-- Actual content -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeaderNavigation from "./TopHeaderNavigation.vue";
import LeftSideBarNavigation from "./LeftSideBarNavigation.vue";
import MainContentHeader from "./MainContentHeader.vue";

export default {
  name: "WrapperPage",
  components: {
    TopHeaderNavigation,
    LeftSideBarNavigation,
    MainContentHeader,
  },
  props: {
    pageTitle: {
      type: String,
      required: false,
    },
  },
  mounted: function () {
    window.onWrapperMounted();
  },
};
</script>
