const config = require("./config.json");
const { doubleDashToNull } = require("@/helpers/generic");
const { get, create, update, remove } = require("./common").default;

const clientsUrl = config.apis.baseUrl.url + "clients/";

/**
 * Retrieve all clients
 * @returns Promise with an Axios response
 */
exports.getClients = async ({
  fetchFullClientDetails = false,
  fetchClientContactsDetails = false,
  country = "",
  filters = [],
  matchAny = false,
} = {}) => {
  let params = [];
  if (fetchFullClientDetails) {
    params.push("fullClientsData=1");
  } else {
    if (fetchClientContactsDetails) params.push("includeContactsData=1");
  }
  if (country) {
    params.push(`country=${country}`);
  }
  if (Array.isArray(filters) && filters.length > 0) {
    params = [...params, ...filters];
    if (matchAny) params.push("matchAny=1");
  }

  return await get(clientsUrl + "?" + params.join("&"));
};

/**
 * Retrieve all clients which are related to a vessel
 * @param {string} vessel_id The vessel ID to fetch related clients for
 * @returns Promise with an Axios response
 */
exports.getClientsForVessel = async (vessel_id) => {
  return await get(`${clientsUrl}vessel/${vessel_id}`);
};

/**
 * Retrieve a client with a specific ID
 * @param {number} id ID of the client to retrieve
 * @returns Promise with an Axios response
 */
exports.getClient = async (id) => {
  return await get(`${clientsUrl}${id}`);
};

/**
 * Create a new client
 * @param {object} data Details of the client
 * @returns Promise with an Axios response
 */
exports.createClient = async (data) => {
  data = doubleDashToNull(data);

  if (data.contacts && data.contacts.constructor === Array) {
    for (let i = 0; i < data.contacts.length; i++)
      data.contacts[i] = doubleDashToNull(data.contacts[i]);
  }

  return await create(clientsUrl, data);
};

/**
 * Updates a client with a specific ID
 * @param {number} id ID of the client to update
 * @param {object} data Details of the client
 * @returns Promise with an Axios response
 */
exports.updateClient = async (id, data) => {
  data = doubleDashToNull(data);

  if (data.contacts && data.contacts.constructor === Array) {
    for (let i = 0; i < data.contacts.length; i++)
      data.contacts[i] = doubleDashToNull(data.contacts[i]);
  }

  return await update(`${clientsUrl}${id}`, data);
};

/**
 * Deletes a client with a specific ID
 * @param {number} id ID of the client to delete
 * @returns Promise with an Axios response
 */
exports.deleteClient = async (id) => {
  return await remove(`${clientsUrl}${id}`);
};
