<template>
  <wrapper-page page-title="Vessels Status">
    <vessels-status-kpis v-if="!loadingKpis" :vesselsStatus="vesselsStatus" />
    <div class="row mt-3">
      <div class="col-12">
        <div
          class="page-title-box pb-3 d-sm-flex align-items-center justify-content-between"
        >
          <h4 class="mb-sm-0 d-flex align-items-center">Vessel Metrics</h4>
        </div>
      </div>
      <chart-card
        v-if="commercialGT?.length"
        :value="commercialGT"
        label="Combined GT – Commercial Yachts"
        size="col-xl-4 col-lg-6 col-md-6"
        :showChartTypes="['pie', 'bar']"
      ></chart-card>
      <chart-card
        v-if="commercialCount?.length"
        :value="commercialCount"
        label="Combined – Commercial Yachts"
        size="col-xl-4 col-lg-6 col-md-6"
        :showChartTypes="['pie', 'bar']"
      ></chart-card>
    </div>
  </wrapper-page>
</template>

<script>
import { getVesselsWeightSummary, getVesselsStatus } from "@/apis/kpis";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import VesselsStatusKpis from "../../components/vessels/kpis/VesselsStatusKpis.vue";
import ChartCard from "@/components/ui/ChartCard.vue";

export default {
  name: "VesselsDashboard",
  components: {
    ChartCard,
    WrapperPage,
    VesselsStatusKpis,
  },
  data() {
    return {
      loadingWeightSummary: true,
      loadingKpis: true,
      weightSummary: null,
      vesselsStatus: [],
    };
  },
  methods: {
    async fetchVesselWeightSummary() {
      this.loadingWeightSummary = true;
      try {
        const response = await getVesselsWeightSummary();
        this.weightSummary = response.data?.[0];
      } finally {
        this.loadingWeightSummary = false;
      }
    },
    async fetchVesselsStatuses() {
      this.loadingKpis = true;
      try {
        const response = await getVesselsStatus();
        this.vesselsStatus = response.data;
      } finally {
        this.loadingKpis = false;
      }
    },
  },
  computed: {
    commercialGT() {
      const data = [];

      if (this.weightSummary?.summ_commercial_gt_in_service)
        data.push({
          type: "In Service",
          number: this.weightSummary?.summ_commercial_gt_in_service,
        });

      if (this.weightSummary?.summ_commercial_gt_in_progress)
        data.push({
          type: "In Progress",
          number: this.weightSummary?.summ_commercial_gt_in_progress,
        });

      if (this.weightSummary?.summ_commercial_gt_under_construction)
        data.push({
          type: "Under Construction",
          number: this.weightSummary?.summ_commercial_gt_under_construction,
        });

      return data;
    },
    commercialCount() {
      const data = [];

      if (this.weightSummary?.count_commercial_vessels_in_service)
        data.push({
          type: "In Service",
          number: this.weightSummary?.count_commercial_vessels_in_service,
        });

      if (this.weightSummary?.count_commercial_vessels_in_progress)
        data.push({
          type: "In Progress",
          number: this.weightSummary?.count_commercial_vessels_in_progress,
        });

      if (this.weightSummary?.count_commercial_vessels_under_construction)
        data.push({
          type: "Under Construction",
          number:
            this.weightSummary?.count_commercial_vessels_under_construction,
        });

      return data;
    },
  },
  mounted() {
    this.fetchVesselWeightSummary();
    this.fetchVesselsStatuses();
  },
};
</script>
