<template>
  <div v-if="!isLoadingDetails && details" id="app" style="background: white">
    <div class="px-4 py-3 row sections-area-wrapper">
      <section class="sections-wrapper wide-column">
        <VesselParticularsGeneral
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsHull
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsMachinery
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsCertification
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsSurvey
          class="card"
          :exclude-empty-fields="true"
          :projects="projects"
          :vessel="roVessel"
        />
        <VesselParticularsHM
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsValuation
          class="card"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsProject
          class="card"
          v-if="roVessel.projects"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
        <VesselParticularsStakeholders
          class="card"
          v-if="roVessel?.stakeholders?.length"
          :exclude-empty-fields="true"
          :vessel="roVessel"
        />
      </section>
      <section
        v-if="roVessel.events.length"
        class="sections-wrapper narrow-column"
      >
        <VesselParticularsEvents class="card" :vessel="roVessel" />
      </section>
    </div>
  </div>
</template>

<script>
import { getVessel, getVesselProjects } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";
import { validationMessage } from "@/helpers/validations";
import { RegulatoryRegimeOptions } from "@/constants/vessels";
import moment from "moment";
import { nullToDoubleDash, toDecimalPlaces } from "@/helpers/generic";
import { getDocuments } from "@/apis/files";
import { formatVesselForReadOnly } from "@/helpers/vessels";
import VesselParticularsGeneral from "@/components/vessels/statement/VesselParticularsGeneral.vue";
import VesselParticularsHull from "@/components/vessels/statement/VesselParticularsHull.vue";
import VesselParticularsMachinery from "@/components/vessels/statement/VesselParticularsMachinery.vue";
import VesselParticularsSurvey from "@/components/vessels/statement/VesselParticularsSurvey.vue";
import VesselParticularsEvents from "@/components/vessels/statement/VesselParticularsEvents.vue";
import { getProjects } from "@/apis/projects";
import VesselParticularsProject from "@/components/vessels/statement/VesselParticularsProject.vue";
import VesselParticularsCertification from "@/components/vessels/statement/VesselParticularsCertification.vue";
import VesselParticularsHM from "@/components/vessels/statement/VesselParticularsHM.vue";
import VesselParticularsValuation from "@/components/vessels/statement/VesselParticularsValuation.vue";
import VesselParticularsStakeholders from "@/components/vessels/statement/VesselParticularsStakeholders.vue";

export default {
  name: "VesselsPrint",
  components: {
    VesselParticularsStakeholders,
    VesselParticularsValuation,
    VesselParticularsHM,
    VesselParticularsCertification,
    VesselParticularsProject,
    VesselParticularsEvents,
    VesselParticularsMachinery,
    VesselParticularsSurvey,
    VesselParticularsHull,
    VesselParticularsGeneral,
  },
  data: function () {
    return {
      isLoadingDetails: false,
      projects: [],
      details: null,
    };
  },
  methods: {
    validationMessage,
    async getDetails() {
      this.isLoadingDetails = true;
      await getVessel(this.$route.params.vesselId)
        .then(({ data }) => {
          const details = data;

          if (!details.vessel_type) details.vessel_type = null;

          if (!details.other_service) details.other_service = null;

          if (!details.class) details.class = null;

          if (details.class_maintained)
            details.class_maintained = !!details.class_maintained;

          if (
            details.regulatory_regime &&
            Array.isArray(details.regulatory_regime) &&
            details.regulatory_regime.length > 0
          ) {
            details.regulatory_regime = details.regulatory_regime.map(
              (regRegime) =>
                RegulatoryRegimeOptions.find((opt) => opt.value == regRegime)
            );
          } else {
            details.regulatory_regime = [];
          }

          if (!details.radio_range) details.radio_range = null;

          // We need the empty with nulls so that the bindings for errors and computed properties work
          const emptyDimensions = {
            length_overall: null,
            length: null,
            length_between_perp: null,
            lightship: null,
            displacement: null,
          };
          const emptyHull = {
            stem: null,
            stern: null,
            max_power: null,
            max_persons: null,
            max_weight: null,
            gt_and_nt: null,
            gt: null,
            nt: null,
            builder_id: null,
          };
          details.dimensions = { ...emptyDimensions, ...details.dimensions };
          details.hull = { ...emptyHull, ...details.hull };
          if (!details.machinery) {
            details.machinery = [];
          } else {
            details.machinery.forEach((machinery) => {
              if (machinery.is_main_engine !== undefined)
                machinery.is_main_engine = String(machinery.is_main_engine);
              if (machinery.is_derated !== undefined)
                machinery.is_derated = !!machinery.is_derated;
              if (machinery.engine_hours_date) {
                machinery.engine_hours_date = moment(
                  machinery.engine_hours_date
                ).format("YYYY-MM-DD");
              }
            });
          }

          if (details.hull.gt_and_nt)
            details.hull.gt_and_nt = toDecimalPlaces(details.hull.gt_and_nt, 2);

          if (details.hull.builder)
            details.hull.builder_id = details.hull.builder?.id;

          if (!details.electricGenerators) details.electricGenerators = [];

          this.details = nullToDoubleDash(details, [
            "name",
            "official_number",
            "s_reg",
            "fisheries_id",
            "call_sign",
            "imo_number",
            "mmsi_number",
          ]);

          this.isLoadingDetails = false;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
          this.isLoadingDetails = false;
        });
      this.documents = await getDocuments(
        `vessels/${this.details?.id}/general`,
        this.details?.id
      ).then((resp) => resp.data);
    },
    async getAllProjects() {
      await getProjects()
        .then(({ data }) => {
          this.projects = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  computed: {
    roVessel() {
      return formatVesselForReadOnly(this.details);
    },
  },
  async mounted() {
    window.onWrapperMounted();

    await this.getDetails();
    this.getAllProjects();

    getVesselProjects(this.details.id).then((data) => {
      this.$set(this.details, "projects", data.data);
    });

    window.print();
  },
};
</script>

<style lang="scss">
.card-title {
  margin-left: 1rem;
}

.sections-area-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .sections-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    &.wide-column {
      width: 100%;
      flex: 1 1 0;

      @media (min-width: 768px) {
        width: auto;
      }
    }

    &.narrow-column {
      width: 100%;
      flex: none;

      @media (min-width: 768px) {
        width: auto;
        max-width: 300px;
      }
    }
  }
}
</style>
