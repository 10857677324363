const config = require("./config.json");
const { create, get } = require("./common").default;
const { updateCountryNames } = require("../helpers/generic");

const searchUrl = config.apis.baseUrl.url + "search/";

exports.search = async (request, isStructured) => {
  const url = isStructured ? `${searchUrl}?structured=true` : searchUrl;
  const response = await create(url, request);
  //Override all countryCodes with countryNames.
  return updateCountryNames(response);
};

exports.searchClientsAndContacts = async (query) => {
  const queryParams = new URLSearchParams(query).toString();
  const response = await get(`${searchUrl}clients/contacts?${queryParams}`);
  return response;
};

exports.getAllColumns = async () => {
  const regExp = /_/gi;
  const replaceWith = "-";
  const dbColumns = await get(`${searchUrl}meta-data?structured=true`);
  const structuredColumns = dbColumns?.data?.structuredData;
  const result = dbColumns.data?.data.map((column) => {
    return {
      displayName: `${column.table.replace(
        regExp,
        replaceWith
      )}-${column.column.replace(regExp, replaceWith)}`,
      value: {
        tableName: column.table,
        columnName: column.column,
      },
      type: column.data_type,
    };
  });
  return {
    result,
    structuredColumns: structuredColumns,
  };
};
