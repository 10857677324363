<template>
  <wrapper-page ref="wrapper">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <div class="input-group">
          <router-link
            :to="{ name: 'projects-create' }"
            class="btn btn-sm btn-info"
            style="margin-left: auto"
          >
            New Project
          </router-link>
        </div>
      </div>
    </template>

    <template v-slot:search>
      <div class="d-flex align-items-center gap-x-2 position-relative">
        <SearchInput @search="search" :results="searchResult?.results ?? []" />
        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            type="button"
          >
            <i class="ri-settings-4-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-4">
            <div class="d-flex flex-column gap-y-2">
              <div
                v-for="field in searchProperties"
                :key="field.value"
                class="w-100 d-flex gap-x-2 align-items-center"
              >
                <input
                  type="checkbox"
                  v-model="searchFields[field.value]"
                  :name="`search-property-${field.value}`"
                />
                <label :for="`search-property-${field.value}`" class="mb-0">
                  {{ field.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-3">
        <button
          class="btn btn-lg p-0 me-2"
          title="Show as Grid"
          @click="showAsGrid = true"
        >
          <i class="ri-layout-grid-line"></i>
        </button>
        <button
          class="btn btn-lg p-0"
          title="Show as Table"
          @click="showAsGrid = false"
        >
          <i class="ri-list-check"></i>
        </button>
      </div>
    </div>
    <projects-grid v-if="showAsGrid" :projects="projects" />
    <projects-table v-else :projects="projects" :showSearchBar="false" />
  </wrapper-page>
</template>

<script>
import { getProjects, projectsByUserId } from "@/apis/projects";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import ProjectsTable from "../../components/projects/ProjectsTable.vue";
import ProjectsGrid from "../../components/projects/ProjectsGrid.vue";
import { notifyError } from "@/helpers/notification";
import moment from "moment";
import SearchInput from "@/components/SearchInput.vue";

const ProjectSearchableProperties = [
  { text: "Name", value: "name" },
  { text: "Project Number", value: "projectNumber" },
  { text: "Department", value: "searchDepartment" },
  { text: "Category", value: "category" },
  { text: "Status", value: "searchStatus" },
  { text: "Responsibles", value: "responsibles" },
  { text: "Comments", value: "comments" },
  { text: "Invoicing Status", value: "invoicingStatus" },
];

export default {
  name: "ProjectsIndex",
  components: {
    SearchInput,
    WrapperPage,
    ProjectsTable,
    ProjectsGrid,
  },
  props: {
    active: {
      type: Boolean,
      default: null,
    },
    query: {
      type: Array,
      default() {
        [];
      },
    },
  },
  data() {
    return {
      projects: [],
      showAsGrid: false,
      searchFields: { name: true },
      searchProperties: ProjectSearchableProperties,
      searchResult: { results: [] },
    };
  },
  methods: {
    getProjectsByUserId() {
      if (!this.currentUserId) return;

      const { filters } = this.getProjectsDecoration();

      const userId = this.$route.query.userId;

      projectsByUserId(this.currentUserId, userId, this.active, {
        filters,
        matchAny: 0,
      })
        .then(({ data }) => {
          this.projects = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    getProjectsDecoration() {
      // Hande when the query string has an override for the active prop
      if (this.$route.query.isActive) {
        if (this.$route.query.isActive === "true") {
          this.active = true;
        }
        if (this.$route.query.isActive === "false") {
          this.active = false;
        }
      }
      const clientId = this.$route.query.clientId;
      const startDate = this.$route.query.startDate;
      let filters = [];
      if (clientId) {
        filters.push(`clientId=${clientId}`);
      }
      if (startDate) {
        filters.push(`dateAssigned=${startDate}`);
        this.active = null;
      }
      if (this.$route.query.filterBy === "dna") {
        filters.push(`department=dna`);
      }
      if (this.$route.query.filterBy === "sscs") {
        filters.push(`department=sscs`);
      }
      if (this.$route.query.filterBy === "other") {
        filters.push(`department=other`);
      }

      if (this.$route.query.overdue)
        filters.push(`overdue=${moment().format("YYYY-MM-DD")}`);

      if (this.$route.query.invoiced) {
        filters.push(
          `invoiceStatuses=${encodeURIComponent(
            JSON.stringify(["rfp", "paid", "cancelled", "no_fee", "cash"])
          )}`
        );
      }

      const invoicingStatus = this.$route.query.invoicingStatus;
      if (invoicingStatus) filters.push(`invoicingStatus=${invoicingStatus}`);

      return { filters };
    },
    getFakeIconForSearchResult(project) {
      return project.name
        .split(" ")
        .map((part) => (part[0] || "").toString().toUpperCase())
        .slice(0, 2)
        .join(""); // Split name and fetch first letter
    },
    getSubtitleForSearchResult(project) {
      for (let i = 0; i < this.searchFields.length; i++) {
        const field = this.searchFields[i];
        if (field.value === "name") continue;
        if (project[field.value])
          return field.text + " : " + project[field.value];
      }
    },
    async search(searchString) {
      if (
        Object.keys(this.searchFields).filter((key) => this.searchFields[key])
          .length === 0
      ) {
        notifyError(this, "Choose 1 or more properties to search in");
        return;
      }

      let filters = Object.keys(this.searchFields)
        .filter((key) => this.searchFields[key])
        .map((f) => `${f}=${searchString}`);

      const searchStartTime = new Date().getTime();
      await getProjects(this.active, { filters, matchAny: 1 })
        .then(({ data }) => {
          var searchDuration = new Date().getTime() - searchStartTime;
          this.searchResult = {
            durationInSeconds: searchDuration / 1000,
            results: data.map((project) => ({
              icon: {
                text: this.getFakeIconForSearchResult(project),
                backgroundColour: "green",
              },
              title: project.name,
              subtitle: this.getSubtitleForSearchResult(project),
              link: `/projects/${project.id}`,
            })),
          };
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  watch: {
    storeUsers() {
      if (this.$route.query.userId) this.getProjectsByUserId();
    },
  },
  computed: {
    storeUsers() {
      return this.$store.state.users;
    },
    currentUserId() {
      return this.storeUsers?.find((u) => u.email === this.$auth.user.email)
        ?.id;
    },
  },
  async mounted() {
    const { filters } = this.getProjectsDecoration();

    const userId = this.$route.query.userId;

    if (userId) this.getProjectsByUserId();
    else
      await getProjects(this.active, { filters, matchAny: 0 })
        .then(({ data }) => {
          this.projects = data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
  },
};
</script>
