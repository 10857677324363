<template>
  <div class="line-chart-container">
    <LineChartComponent :data="chartData" :options="options" />
  </div>
</template>

<script>
import LineChartComponent from "@/components/charts/line/lineChart.vue";
import { ChartColors } from "@/constants/colors";

export default {
  name: "SearchResultsLineChart",
  components: { LineChartComponent },
  props: {
    value: Array,
    datasets: {
      type: Array,
      required: false,
    },
    dataLabel: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartData() {
      let data = [];

      if (this.datasets?.length)
        data = this.datasets.map((ds, i) => ({
          label: this.dataLabel,
          backgroundColor: ChartColors[i],
          ...ds,
          data: ds.data.map((d) => d.number),
        }));
      else
        data = [
          {
            label: this.dataLabel,
            backgroundColor: ChartColors[0],
            data: this.value.map((d) => d.number),
          },
        ];

      return {
        labels: this.datasets?.length
          ? this.datasets?.[0].data.map((d) => d.type)
          : this.value.map((d) => d.type),
        datasets: data,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.line-chart-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
