<template>
  <wrapper-page ref="wrapper" @search="search">
    <template v-slot:search>
      <div class="d-flex align-items-center gap-x-2 position-relative">
        <SearchInput @search="search" :results="searchResult?.results ?? []" />
        <div class="dropdown d-inline-block">
          <button
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            type="button"
          >
            <i class="ri-settings-4-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-4">
            <div class="d-flex flex-column gap-y-2">
              <div
                v-for="field in searchProperties"
                :key="field.value"
                class="w-100 d-flex gap-x-2 align-items-center"
              >
                <input
                  type="checkbox"
                  v-model="searchFields[field.value]"
                  :name="`search-property-${field.value}`"
                />
                <label :for="`search-property-${field.value}`" class="mb-0">
                  {{ field.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12 overflow-auto">
        <search-results-table
          v-if="adminInvoicesResult"
          v-model="adminInvoicesResult"
          class="mt-4"
        />
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "@/components/layout/WrapperPage.vue";
import { notifyError } from "@/helpers/notification";
import SearchInput from "@/components/SearchInput.vue";
import SearchResultsTable from "../search/SearchResultsTable.vue";
import { getInvoicesReport } from "@/apis/projects";

const VesselSearchableProperties = [
  { text: "Vessel Name", value: "vessel_name" },
  { text: "Certificate", value: "certificate" },
  { text: "Issue Date", value: "issue_date" },
  { text: "Expiry Date", value: "expiry_date" },
  { text: "Last Endorsed", value: "last_endorsed" },
  { text: "Due Open", value: "due_open" },
  { text: "Due Close", value: "due_close" },
];

export default {
  name: "ResourcesInvoiceReports",
  components: {
    SearchInput,
    WrapperPage,
    SearchResultsTable,
  },
  data() {
    return {
      searchFields: {},
      searchProperties: VesselSearchableProperties,
      adminInvoicesResult: null,
    };
  },
  methods: {
    async loadInvoiceReports() {
      getInvoicesReport()
        .then((response) => {
          this.adminInvoicesResult = response?.data;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },

  async mounted() {
    this.loadInvoiceReports();
  },
};
</script>
