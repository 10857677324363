<template>
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box pb-3 d-sm-flex align-items-center justify-content-between"
      >
        <h4 class="mb-sm-0">Clients Countries</h4>
      </div>
    </div>
    <number-card
      v-for="c in clientKpis.countries.slice(0, 6)"
      :key="c.countryCode"
      :label="`${c?.country}`"
      :url="getUrlFor(`${c?.countryCode}`)"
      :value="c?.amount"
      :icon="`fi fi-${c.countryCode.toLowerCase()}`"
      size="col-xl-4 col-sm-6"
    ></number-card>
  </div>
</template>

<script>
import NumberCard from "../ui/NumberCard.vue";

export default {
  name: "ClientsInCountries",
  components: { NumberCard },
  props: {
    clientKpis: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrlFor(filterBy) {
      return `/clients/list?country=${filterBy}`;
    },
  },
};
</script>
