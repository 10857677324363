<template>
  <ParticularsSection v-if="cells.length" title="Events" with-single-column>
    <ParticularsEvent v-for="cell in cells" :key="cell.key" :date="cell.date">
      <template v-if="cell.type === 'former-name'">
        <div>
          <p class="line-through">{{ cell.old }}</p>
          <p>{{ cell.new }}</p>
        </div>
      </template>
      <template v-else-if="cell.type === 'fsi'">
        <div :title="cell.notes">
          <p>{{ cell.outcome }}</p>
          <p>{{ cell.location }}</p>
        </div>
      </template>
      <template v-else-if="cell.type === 'psc'">
        <div :title="cell.notes">
          <p>{{ cell.outcome }}</p>
          <p>{{ cell.location }}</p>
        </div>
      </template>
      <template v-else-if="cell.type === 'incident'">
        <div :title="cell.notes">
          <p>{{ cell.outcome }}</p>
          <p>{{ cell.country }}</p>
        </div>
      </template>
      <template v-else-if="cell.type === 'modification'">
        <div :title="cell.notes">
          <p>{{ cell.outcome }}</p>
          <p>{{ cell.port }}</p>
        </div>
      </template>

      <template v-if="cell.type === 'former-name'" v-slot:icon>
        <i class="fa fa-comment" title="Former Name"></i>
      </template>
      <template v-else-if="cell.type === 'fsi'" v-slot:icon>
        <i class="fa fa-flag" title="FSI"></i>
      </template>
      <template v-else-if="cell.type === 'psc'" v-slot:icon>
        <i class="fa fa-anchor" title="PSC"></i>
      </template>
      <template v-else-if="cell.type === 'incident'" v-slot:icon>
        <i class="fa fa-fire" title="Incident"></i>
      </template>
      <template v-else-if="cell.type === 'modification'" v-slot:icon>
        <i class="fa fa-hammer" title="Modification"></i>
      </template>
    </ParticularsEvent>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsEvent from "@/components/ui/ParticularsEvent.vue";
import { CountryOptions } from "@/constants/address";

export default {
  name: "VesselParticularsEvents",
  components: { ParticularsEvent, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formerNamesCells() {
      return this.vessel.formerNames.map((formerName, i) => ({
        type: "former-name",
        key: `former-name-${i}`,
        date: formerName.change_date,
        old: formerName.old_name,
        new: formerName.new_name,
      }));
    },
    pscCells() {
      return this.vessel.events
        .filter((e) => e.event_type === "psc")
        .map((psc, i) => ({
          type: "psc",
          key: `psc-${i}`,
          date: psc.event_date,
          location: [psc.port, psc.country].filter((l) => l).join(", "),
          outcome: psc.outcome,
          notes: psc.notes,
          days_detained: psc.days_detained,
          deficiencies: psc.deficiencies,
        }));
    },
    fsiCells() {
      return this.vessel.events
        .filter((e) => e.event_type === "fsi")
        .map((fsi, i) => ({
          type: "fsi",
          key: `fsi-${i}`,
          date: fsi.event_date,
          location: [fsi.port, fsi.country].filter((l) => l).join(", "),
          outcome: fsi.outcome,
          notes: fsi.notes,
        }));
    },
    incidentsCells() {
      return this.vessel.events
        .filter((e) => e.event_type === "incidents")
        .map((incident, i) => ({
          type: "incident",
          key: `incident-${i}`,
          date: incident.event_date,
          country: CountryOptions.find((co) => co.value === incident.country)
            ?.text,
          outcome: incident.outcome,
          notes: incident.notes,
        }));
    },
    modificationsCells() {
      return this.vessel.events
        .filter((e) => e.event_type === "modifications")
        .map((modification, i) => ({
          type: "modification",
          key: `modification-${i}`,
          date: modification.event_date,
          outcome: modification.outcome,
          port: modification.port,
          notes: modification.notes,
        }));
    },
    cells() {
      return [
        ...this.formerNamesCells,
        ...this.pscCells,
        ...this.fsiCells,
        ...this.incidentsCells,
        ...this.modificationsCells,
      ].sort(function (a, b) {
        const x = a.date;
        const y = b.date;
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
};
</script>
