<template>
  <ParticularsSection
    v-if="stakeholders.length"
    :with-narrow-columns="true"
    title="Stakeholders"
  >
    <div
      v-for="({ key, cells }, i) in stakeholders"
      :key="key"
      class="stakeholder"
    >
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :class="cell.key === 'name' && 'col-start-1'"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </div>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";

export default {
  name: "VesselParticularsStakeholders",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cells() {
      return extractCells(
        this.vessel.stakeholders,
        [
          { label: "Name", key: "name" },
          { label: "Role", key: "type" },
          { label: "Phone", key: "phone" },
          { label: "Email", key: "email" },
        ],
        this.excludeEmptyFields
      );
    },
    stakeholders() {
      return [
        ...this.cells.map((cells, i) => ({
          key: `stakeholder-${i}`,
          cells,
        })),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.stakeholder {
  display: contents;

  &:not(:last-child) {
    div:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
