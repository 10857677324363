<template>
  <ParticularsSection
    v-if="sections.length"
    title="Main & Auxiliary Systems"
    with-sub-sections
  >
    <ParticularsSection
      v-for="({ key, title, cells }, i) in sections"
      :key="key"
      :title="title"
    >
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </ParticularsSection>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";

export default {
  name: "VesselParticularsMachinery",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    machineryCells() {
      return extractCells(
        this.vessel.machinery,
        [
          { label: "Make", key: "make" },
          { label: "Model", key: "model" },
          { label: "Serial Number", key: "serial_number" },
          { label: "Type", key: "type" },
          { label: "Year", key: "year" },
          {
            label: "Number of Cylinders",
            key: "number_of_cylinders",
          },
          { label: "Cycle", key: "cycle" },
          { label: "Fuel", key: "fuel" },
          {
            label: "Power",
            key: "power",
          },
          { label: "Propulsion", key: "propulsion" },
          {
            label: "Number of Propellors",
            key: "number_of_propellors",
          },
          {
            label: "Type of Propellors",
            key: "type_of_propellors",
          },
          {
            label: "Starting Arrangement",
            key: "starting_arrangement",
          },
          { label: "Gearbox", key: "gearbox" },
          { label: "Engine Hours", key: "engine_hours" },
          {
            label: "Engine Hours Date",
            key: "engine_hours_date",
            format: "date",
          },
          { label: "Position", key: "position" },
          {
            label: "Emission Standard",
            key: "emission_standard",
          },
          {
            label: "Main/Auxiliary Engine",
            key: "is_main_engine",
            format: (isMainEngine) => {
              if (isMainEngine === "1") return "Main";
              else if (isMainEngine === "0") return "Auxiliary";
            },
          },
          { label: "De-Rated", key: "is_derated" },
          {
            label: "Original Power",
            key: "original_power",
          },
        ],
        this.excludeEmptyFields
      );
    },
    generatorCells() {
      return extractCells(
        this.vessel.electricGenerators,
        [
          { label: "Make", key: "make" },
          { label: "Model", key: "model" },
          { label: "Year", key: "year" },
          { label: "Serial Number", key: "serial_number" },
          {
            label: "Power",
            key: "power",
          },
          { label: "Frequency", key: "frequency" },
          {
            label: "Total Electrical Power",
            key: "total_electrical_power",
          },
          {
            label: "Starting Arrangement",
            key: "starting_arrangement",
          },
          { label: "Fuel", key: "fuel" },
          {
            label: "Emission Standard",
            key: "emission_standard",
          },
          { label: "Type", key: "type" },
        ],
        this.excludeEmptyFields
      );
    },
    sections() {
      return [
        ...this.machineryCells.map((cells, i) => ({
          key: `machinery-${i}`,
          title: `Machinery ${i + 1}`,
          cells,
        })),
        ...this.generatorCells.map((cells, i) => ({
          key: `generator-${i}`,
          title: `Generator ${i + 1}`,
          cells,
        })),
      ];
    },
  },
};
</script>
