<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex justify-content-between cursor-pointer"
              @click="toggleSearch(QUICK_SEARCH)"
            >
              <h6 class="mb-0">Quick Search</h6>
              <div>
                <i
                  v-if="isExpanded === QUICK_SEARCH"
                  class="fa fa-chevron-up"
                ></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
            </div>

            <div v-show="isExpanded === QUICK_SEARCH" class="mt-3">
              <QuickSearch />

              <p class="mb-0 mt-3">
                <small>
                  Quick search enables some complex but
                  <strong>often used search results</strong> to be displayed
                  <strong>quickly and easily</strong>.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex justify-content-between cursor-pointer"
              @click="toggleSearch(ADVANCED_SEARCH)"
            >
              <h6 class="mb-0">Advanced Search</h6>
              <div>
                <i
                  v-if="isExpanded === ADVANCED_SEARCH"
                  class="fa fa-chevron-up"
                ></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
            </div>

            <div v-show="isExpanded === ADVANCED_SEARCH" class="mt-3">
              <SimpleColumnSelect ref="simpleColumnSelect" />
              <div class="mt-2">
                <button
                  class="btn btn-primary mb-2"
                  type="button"
                  @click="searchSimple"
                >
                  Search
                </button>
              </div>

              <div v-if="searchResult" class="card mt-3">
                <search-results-table v-model="searchResult" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex justify-content-between cursor-pointer"
              @click="toggleSearch(STRUCTURED_SEARCH)"
            >
              <h6 class="mb-0">Structured Search</h6>
              <div>
                <i
                  v-if="isExpanded === STRUCTURED_SEARCH"
                  class="fa fa-chevron-up"
                ></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
            </div>

            <div v-show="isExpanded === STRUCTURED_SEARCH" class="mt-3">
              <StructuredSearch ref="structuredSearch" />
              <div class="mt-2">
                <button
                  class="btn btn-primary mb-2"
                  type="button"
                  @click="searchStructured"
                >
                  Search
                </button>
              </div>

              <div v-if="structuredSearchResult" class="card mt-2">
                <div class="header pb-0">
                  <h2>Results</h2>
                </div>
                <search-results-table v-model="structuredSearchResult" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import { notifyError } from "@/helpers/notification";
import { search } from "@/apis/search";

import WrapperPage from "../../components/layout/WrapperPage.vue";
import SearchResultsTable from "./SearchResultsTable.vue";
import SimpleColumnSelect from "../../components/search/SimpleColumnSelect.vue";
import { ExcludedFieldsFromOverride } from "../../constants/project_fields";
import QuickSearch from "../../components/search/QuickSearch.vue";
import StructuredSearch from "../../components/search/StructuredSearch.vue";

const ADVANCED_SEARCH = "advanced-search";
const QUICK_SEARCH = "quick-search";
const STRUCTURED_SEARCH = "structured-search";

export default {
  name: "Search",
  components: {
    WrapperPage,
    SearchResultsTable,
    SimpleColumnSelect,
    QuickSearch,
    StructuredSearch,
  },
  data() {
    return {
      searchResult: null,
      structuredSearchResult: null,
      isExpanded: QUICK_SEARCH,
      QUICK_SEARCH,
      ADVANCED_SEARCH,
      STRUCTURED_SEARCH,
    };
  },
  methods: {
    async searchStructured() {
      const colList = this.$refs.structuredSearch.getColList();
      const show = colList.map((column) => {
        if (column.includes("responsibles")) {
          column =
            column.replace("responsibles", "name") + " as 'responsibles'";
        }
        return column;
      });
      const rawfilters = this.$refs.structuredSearch.getFilters();
      const filter = rawfilters.map((filter) => {
        if (filter.property === "users.responsibles") {
          filter.property = "users.name";
        }
        return filter;
      });

      let request = {
        show,
        filter,
      };

      await search(request, true)
        .then(({ data }) => {
          const result = data.map((dataObj) => {
            Object.keys(dataObj).forEach((key) => {
              if (dataObj[key] === 0) {
                dataObj[key] = "false";
                return;
              }
              if (dataObj[key] === 1) {
                dataObj[key] = "true";
                return;
              }
            });
            return dataObj;
          });
          const removeNullObjects = result.filter(
            (obj) =>
              !Object.values(obj).every((el) => el === undefined || el === null)
          );
          this.structuredSearchResult = removeNullObjects;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    toggleSearch(section) {
      if (this.isExpanded === section) this.isExpanded = null;
      else this.isExpanded = section;
    },
    async searchSimple() {
      let request = {
        show: this.$refs.simpleColumnSelect.getColList(),
        filter: this.$refs.simpleColumnSelect.getAsJson(true),
      };
      console.log("In Search", JSON.stringify(request));

      await search(request)
        .then(({ data }) => {
          const result = data.map((dataObj) => {
            Object.keys(dataObj).forEach((key) => {
              if (ExcludedFieldsFromOverride.includes(key)) {
                return;
              }
              if (dataObj[key] === 0) {
                dataObj[key] = "false";
                return;
              }
              if (dataObj[key] === 1) {
                dataObj[key] = "true";
                return;
              }
            });
            return dataObj;
          });
          this.searchResult = result;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
  },
  mounted() {},
};
</script>
