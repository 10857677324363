<template>
  <div>
    <b v-if="field.label && showLabel">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>
    <multiselect
      :close-on-select="closeOnSelect"
      :disabled="readonly"
      :multiple="multiple"
      :options="field.options"
      :placeholder="field.placeholder"
      :showLabels="showLabels"
      :value="value"
      :class="`mb-${marginBottom}`"
      label="text"
      track-by="value"
      @input="(e) => $emit('input', e)"
    >
      <!-- <template slot="tag" slot-scope="{ values, search, isOpen }">Your code</template> -->
      <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
      <template slot="option" slot-scope="props">
        <img class="option__image" :src="props.option.img" alt="No Man’s Sky">
        <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span class="option__small">{{ props.option.desc }}</span></div>
      </template> -->
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: [Array, Object],
    readonly: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    marginBottom: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "public/assets/css/variables";

.multiselect__tags {
  line-height: 0.5;
  min-height: 35px;

  .multiselect__tag {
    background-color: $brand-navy;
  }
}

.multiselect__placeholder {
  padding-top: 4px;
}

.multiselect__option--highlight {
  background-color: $brand-navy;

  &::after {
    background-color: $brand-navy;
  }
}

.multiselect__option--selected {
  &.multiselect__option--highlight {
    background-color: $brand-copper;

    &::after {
      background-color: $brand-copper;
    }
  }
}

.multiselect__tag-icon {
  &::after {
    color: $brand-off-white;
  }

  &:hover {
    background-color: $brand-navy;
  }
}
</style>
