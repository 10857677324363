<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body" :class="{ 'bg-light': documentList?.length }">
          <div class="page-title-box pb-3">
            <h4 class="card-title">{{ title }}</h4>
          </div>

          <div
            class="col-12 d-flex flex-row gap-x-3 flex-wrap align-items-center justify-content-center"
          >
            <div v-for="file in documentList" :key="file.name" class="card">
              <div class="card-body d-flex text-muted">
                <div class="flex-shrink-0 me-4 align-self-center">
                  <i
                    v-if="file.type === 'application/pdf'"
                    class="far fa-file-pdf fa-2x"
                  />
                  <i
                    v-else-if="
                      [
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/msword',
                      ].includes(file.type)
                    "
                    class="far fa-file-word fa-2x"
                  />
                  <i v-else class="far fa-file-alt fa-2x" />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <a :href="`${storageEndpoint}${file}`" target="_blank">
                    <p class="mb-3 text-truncate">{{ getFilename(file) }}</p>
                  </a>
                  <div class="w-100 d-flex gap-x-2 gap-y-2 flex-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="saveFile(file)"
                    >
                      Download
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      type="button"
                      @click="deleteFile(file)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const config = require("@/apis/config.json");
import { deleteDocument } from "@/apis/files";
import { notifyChangesSaved, notifyError } from "@/helpers/notification";

export default {
  name: "DocumentsList",
  props: {
    title: String,
    documents: Array,
  },
  data() {
    return {
      storageEndpoint:
        config.apis.baseUrl.az_storage_endpoint || "http://localhost:8081/",
      documentList: this.documents,
    };
  },
  methods: {
    saveFile(file) {
      window.open(`${this.storageEndpoint}${file}`, "_blank");
    },
    getFilename(file) {
      if (!file) {
        return;
      }
      const result = file.split("\\").pop().split("/").pop();
      return result.substring(0, 40);
    },
    deleteFile(file) {
      if (!file) {
        return;
      }

      if (
        confirm(
          `Are you sure you want to delete this file? ${this.getFilename(file)}`
        )
      ) {
        deleteDocument(file)
          .then(() => {
            this.documentList = this.documentList?.filter(
              (doc) => doc !== file
            );
            this.$root.$emit("reloadVesselDocumentsAfterDocumentsIsDeleted");
            notifyChangesSaved(this);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
          });
      }
    },
  },
};
</script>
