<template>
  <div class="ms-2 me-2 d-flex flex-column">
    <div v-for="item in Object.keys(tableObject)" :key="`${item}`">
      <div class="d-flex flex-row">
        <div class="main-card mb-2" @click="() => changeActive(item)">
          <span class="ms-2 me-2">{{ item }}</span
          ><i class="fa fa-chevron-right me-2"></i>
        </div>
        <div v-if="isExpanded === item">
          <ColumnSelector :columnArray="tableObject[item]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnSelector from "./ColumnSelector.vue";

export default {
  name: "SearchableList",
  components: {
    ColumnSelector,
  },
  props: {
    tableObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: "",
    };
  },
  mounted() {},
  methods: {
    changeActive(item) {
      if (this.isExpanded === item) {
        this.isExpanded = "";
        return;
      }
      this.isExpanded = item;
    },
    addFilter() {
      const newFilter = {
        id: new Date().getTime(), // For the Vue for:key
      };
      this.filters.push(newFilter);
    },
    removeFilter(filter) {
      const indexToRemove = this.filters.findIndex((x) => x.id === filter.id);
      this.filters.splice(indexToRemove, 1);
    },
  },
  computed: {},
};
</script>

<style scoped>
.main-card {
  display: flex;
  width: 35%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid black;
}
</style>
