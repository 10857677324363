import { validationMessage as vuelidateMessage } from "vuelidate-messages";
// import { toCommasAndSeparator } from "@/helpers/generic";

/**
 * A mapping of error message per validation from vuelidate
 * By default, it only returns the first error, to show one error at a time
 * https://gitlab.com/rhythnic/vuelidate-messages
 */
export const errorMessages = {
  required: () => "Field is required",
  requiredVesselIdentification: () =>
    "At least 1 vessel identifier field is required",
  email: () => "Field has an incorrect email format",
  positiveNumber: () => "Field must be a positive number",
  integer: () => "Value must be a whole number",
  decimal: () => "Value must be a decimal number",
  decimal2DP: () => "Value must be precise to 2 decimal places",
  minValue: (val) => "Field must be >= " + val.$params.minValue.min,
  maxValue: (val) => "Field must be <= " + val.$params.maxValue.max,
};

export const validationMessage = vuelidateMessage(errorMessages, {});
