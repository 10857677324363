<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table
          v-if="tableData?.length"
          :id="id"
          class="table table-hover table-custom dataTable"
        >
          <thead>
            <tr>
              <th></th>
              <th>Certificate</th>
              <th v-if="showVesselNames">Vessel</th>
              <th>Issued By</th>
              <th>Issue Date</th>
              <th>Expiry Date</th>
              <th>Last Endorsed</th>
              <th>Due Open</th>
              <th>Due Close</th>
              <th>Remarks</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cert, i) in tableData" :key="i" @click="openForView(i)">
              <!-- Table view -->
              <td
                :data-order="cert.isExpired ?? '0'"
                :title="getExpiryLevelText(cert.isExpired)"
              >
                <i
                  v-if="cert.isExpired"
                  :class="`${getExpiryLevelColorClass(
                    cert.isExpired
                  )} ri-error-warning-line`"
                />
              </td>
              <td>{{ cert.certificate }}</td>
              <td v-if="showVesselNames">{{ cert.vessel_name }}</td>
              <td>{{ cert.issued_by }}</td>
              <td>
                {{
                  cert.issue_date
                    ? moment(cert.issue_date).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  cert.expiry_date
                    ? moment(cert.expiry_date).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  cert.last_endorsed
                    ? moment(cert.last_endorsed).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  cert.due_open
                    ? moment(cert.due_open).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  cert.due_close
                    ? moment(cert.due_close).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>{{ truncate(cert.remarks, 25) }}</td>
              <td>
                <button
                  v-if="cert.documents > 0"
                  class="btn btn-sm btn-primary"
                  title="Download Certificate"
                  type="button"
                  @click.stop="openDocumentDownloader(i)"
                >
                  <i class="fa fa-download" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CertificationEditorModal from "./CertificationEditorModal.vue";
import CertificationDownloaderModal from "./CertificationDownloaderModal.vue";
import moment from "moment";
import { getVessel } from "@/apis/vessels";
import { getDocuments } from "@/apis/files";
import {
  getExpiryLevelColorClass,
  getExpiryLevelText,
} from "@/helpers/certificates";

export default {
  name: "CertificationsTable",
  components: {},
  props: {
    id: {
      type: String,
      default: "datatable",
    },
    certifications: {
      type: Array,
      default: () => [],
    },
    certificationDocuments: {
      type: Array,
      default: () => [],
    },
    showVesselNames: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      moment,
      dataTable: null,
      vesselsData: {},
      tableData: [],
    };
  },
  async mounted() {
    await this.getVesselFromParam();
  },
  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
  methods: {
    getExpiryLevelColorClass,
    getExpiryLevelText,
    async getVesselData(vesselId) {
      if (!vesselId) return null;
      let data = this.vesselsData[vesselId];

      if (!data) {
        const response = await getVessel(vesselId);
        data = response?.data;
      }

      return data;
    },
    populateVesselData(vesselId) {
      if (this.vesselsData[vesselId]?.isLoading) return;

      this.vesselsData[vesselId] = { isLoading: true };
      getVessel(vesselId).then((result) => {
        this.$set(this.vesselsData, vesselId, {
          ...result.data,
          isLoading: false,
        });
        this.$forceUpdate();
      });
    },
    async getVesselFromParam() {
      const vesselId = this.$route.params.vesselId;
      if (!vesselId) return;

      await getVessel(vesselId).then((result) => {
        this.$set(this.vesselsData, vesselId, result.data);
        this.$forceUpdate();
      });
    },
    /* Table row operations */
    openForView(index) {
      return this.openForEdit(index, false);
    },
    openDocumentDownloader(index) {
      return this.documentDownloader(index, false);
    },
    /**
     * Public method used from parent page to open a particular row for editing (used on creating a new certification)
     */
    async openForEdit(index, startAsEditable = true) {
      const field = this.certifications[index];
      const params = {
        certification: field,
        certificationDocuments: await this.getCertificationDocumentsForCert(
          field
        ),
        projectOptions: this.projectOptions,
        allowEdit: this.allowEdit,
        startAsEditable,
        vesselData: await this.getVesselData(field.vessel_id),
        allowDelete: this.allowDelete,
        onDeleteCertification: (certification) => {
          this.$emit("removeCertificationByIndex", certification);
        },
        onSupersedeCertification: (certification) => {
          this.$emit("updateCertificate", certification);
        },
      };
      this.$modal.show(
        CertificationEditorModal,
        params,
        {
          name: "certification-editor-modal",
          height: "auto",
          width: "80%",
          scrollable: true,
        },
        {
          "before-close": async (e) => {
            const newValue = e.params?.value;
            if (!newValue) return;
            this.$emit("updateCertificate", newValue);
          },
        }
      );
    },
    async documentDownloader(index) {
      const field = this.certifications[index];
      const params = {
        certification: field,
        certificationDocuments: await this.getCertificationDocumentsForCert(
          field
        ),
      };
      this.$modal.show(
        CertificationDownloaderModal,
        params,
        {
          name: "CertificationDownloaderModal",
          height: "auto",
          width: "80%",
          scrollable: true,
        },
        {
          "before-close": async () => {},
        }
      );
    },
    /* END of Table row operations */
    async getCertificationDocumentsForCert(cert) {
      const certId = cert?.id || cert?.certification_id;
      const documents = this.certificationDocuments?.filter(
        (doc) => doc.indexOf(`/certifications/${certId}/`) > -1
      );
      if (documents && documents.length > 0) {
        return documents;
      }

      const downloadedDocs = await getDocuments(
        `vessels/${cert?.vessel_id}/certifications`,
        cert?.vessel_id
      );

      return downloadedDocs?.data?.filter(
        (doc) => doc.indexOf(`/certifications/${certId}/`) > -1
      );
    },
    truncate(text, len) {
      if (!text) return text;
      if (len < 3) {
        console.error("No point in truncating text to less than 3 chars");
        return text;
      }
      if (text.length <= len) {
        return text;
      } else {
        return text.substring(0, len - 3) + "...";
      }
    },
    isInvalid() {
      // TODO : This is redundant until we find a way how to validate each cert
      // Before we had the editor for each cert to check, now there isn't anymore
      return false;
    },
    buildDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }

      this.tableData = [...this.certifications];

      if (!this.tableData?.length) {
        return;
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.dataTable = window.jsDataTable(`#${this.id}`, {
            order: [[0, "desc"]],
          });
        }, 250); // Just giving Vue some time to render the table
      });
    },
  },
  watch: {
    certifications: {
      immediate: true,
      handler() {
        this.buildDataTable();
      },
    },
  },
};
</script>

<style>
.table tr td .vdp-datepicker {
  white-space: normal;
}

.table-responsive {
  overflow-x: visible;
}
</style>
