<template>
  <div class="row clearfix">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-striped dataTable valuations-js-data-table"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Total</th>
              <th>Hull</th>
              <th>Machinery</th>
              <th>Tender</th>
              <th>Aux. Engine</th>
              <th>Equipment</th>
              <th>Trailer</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in valuations" :key="i">
              <!-- Table view -->
              <td>
                {{ val.date ? moment(val.date).format("DD-MM-YYYY") : "" }}
              </td>
              <td>{{ getTotal(val) }}</td>
              <td>{{ val.hull }}</td>
              <td>{{ val.machinery }}</td>
              <td>{{ val.tender }}</td>
              <td>{{ val.auxiliary_engine }}</td>
              <td>{{ val.equipment }}</td>
              <td>{{ val.trailer }}</td>

              <td>
                <button
                  class="btn btn-sm btn-primary me-2"
                  type="button"
                  @click="openForEdit(i)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  title="Remove Valuations"
                  type="button"
                  @click="deleteValuation(i)"
                >
                  <span class="sr-only">Remove Valuation</span>
                  <i class="fa fa-minus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ValuationEditorModal from "./ValuationEditorModal.vue";
import moment from "moment";

export default {
  name: "ValuationsTable",
  props: {
    valuations: {
      type: Array,
      default: () => [],
    },
    valuationDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    /* Table row operations */
    /**
     * Public method used from parent page to open a particular row for editing (used on creating a new valuation)
     */
    openForEdit(index) {
      const field = this.valuations[index];
      const modalOptions = {
        name: "valuation-editor-modal",
        width: "80%",
      };
      if (field.id) {
        modalOptions.scrollable = true;
        modalOptions.minHeight = 420;
        modalOptions.height = "auto";
      } else {
        modalOptions.height = "auto";
      }
      this.$modal.show(
        ValuationEditorModal,
        {
          valuation: field,
          valuationDocuments: this.getValuationDocumentsForValuation(field),
        },
        modalOptions,
        {
          "before-close": async (e) => {
            const newValue = e.params?.value;
            if (!newValue) return;
            this.valuations[index] = e.params.value;
            // The next 2 lines force an update for DataTables to re-draw the table
            this.valuations.push({});
            this.valuations.pop();
            // TODO : Try to fix the above with the 'invalidate' methods from DataTables
          },
        }
      );
    },
    deleteValuation(index) {
      if (confirm("Are you sure you want to delete this Valuation?"))
        this.$emit("removeValuationByIndex", index);
    },
    /* END of Table row operations */

    getValuationDocumentsForValuation(valuation) {
      return this.valuationDocuments.filter(
        (doc) => doc.indexOf("/valuations/" + valuation.id + "/") > -1
      );
    },

    getTotal(valuation) {
      if (!valuation) return "€ 0";

      if (valuation.total) return "€ " + valuation.total;

      return (
        "€ " +
        ((valuation.hull || 0) +
          (valuation.machinery || 0) +
          (valuation.tender || 0) +
          (valuation.auxiliary_engine || 0) +
          (valuation.equipment || 0) +
          (valuation.trailer || 0))
      );
    },

    isInvalid() {
      // TODO : This is redundant until we find a way how to validate each valuation
      // Before we had the editor for each valuation to check, now there isn't anymore
      return false;
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".valuations-js-data-table");
      }, 250); // Just giving Vue some time to render the table
    },
  },
  watch: {
    valuations() {
      this.buildDataTable();
    },
  },
};
</script>

<style>
.table tr td .vdp-datepicker {
  white-space: normal;
}

.table-responsive {
  overflow-x: visible;
}
</style>
