import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import vmodal from "vue-js-modal";
import Vuex from "vuex";
import { defaultStore } from "./store/defaultStore";
import "@/app.scss";
import "@/print.scss";
import AsyncComputed from "vue-async-computed";

// Import the Auth0 configuration
import { audience, clientId, domain } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

import "/node_modules/flag-icons/css/flag-icons.min.css";

Vue.use(Vuelidate);
Vue.use(Notifications, { ignoreDuplicates: true, duration: 4000 });
Vue.use(vmodal);

Vue.config.productionTip = false;

Vue.prototype.$appName = "NOE";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    console.log("onredirect", appState, " or", window.location.pathname);
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(AsyncComputed);
Vue.use(Vuex);

export const store = new Vuex.Store(defaultStore);

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
