<template>
  <div>
    <div class="mt-2">
      <div class="fancy-checkbox d-flex">
        <label
          ><input
            :checked="isDetailed"
            type="checkbox"
            @change="changeDetailed"
          /><span class="ms-2">Detailed Mode</span></label
        >
        <p class="ms-4" style="margin-top: 1px">
          <small>
            enables <strong>"deleted"</strong>, <strong>"created_on"</strong>,
            <strong>"updated_on" </strong> and all <strong>"id"</strong> columns
            to apper in the <strong>search options</strong>.
          </small>
        </p>
      </div>
    </div>
    <div class="mt-2">
      <button
        :class="projectsActive ? 'btn-danger' : 'btn-info'"
        class="btn me-2 mb-2"
        type="button"
        @click="
          projectsActive
            ? removeColumns(internalTableNames.PROJECTS)
            : addColumns(internalTableNames.PROJECTS)
        "
      >
        {{ projectsActive ? "Remove" : "Add" }} Project Columns
      </button>
      <button
        :class="vesselsActive ? 'btn-danger' : 'btn-info'"
        class="btn me-2 mb-2"
        type="button"
        @click="
          vesselsActive
            ? removeColumns(internalTableNames.VESSELS)
            : addColumns(internalTableNames.VESSELS)
        "
      >
        {{ vesselsActive ? "Remove" : "Add" }} Vessel Columns
      </button>
      <button
        :class="clientsActive ? 'btn-danger' : 'btn-info'"
        class="btn me-2 mb-2"
        type="button"
        @click="
          clientsActive
            ? removeColumns(internalTableNames.CLIENTS)
            : addColumns(internalTableNames.CLIENTS)
        "
      >
        {{ clientsActive ? "Remove" : "Add" }} Client Columns
      </button>
    </div>
    <div class="body mb-3">
      <multi-select-dropdown
        ref="columnGroup"
        v-model="selectedColumns"
        :field="columnList"
      />
      <!-- <div class="col-11">{{ this.selectedColumns }}</div> -->
    </div>

    <div v-for="filter in filters" :key="`${filter.id}`" class="row clearfix">
      <div class="col-11">
        <SimpleFilterCriterion ref="simpleCriterion" />
      </div>

      <div class="col-1">
        <button
          class="btn btn-danger me-3 btn-sm"
          title="Delete filter"
          type="button"
          @click="removeFilter(filter)"
        >
          <span class="sr-only">Delete filter</span>
          <i class="fa fa-minus"></i>
        </button>
      </div>
    </div>

    <button
      class="btn btn-primary mt-3"
      title="Add filter"
      type="button"
      @click="addFilter"
    >
      Add filter
    </button>
  </div>
</template>

<script>
import SimpleFilterCriterion from "../../components/search/SimpleFilterCriterion.vue";
import MultiSelectDropdown from "../ui/MultiSelectDropdown.vue";
import { getAllColumns } from "@/apis/search";
import { excludedColumns, internalTableNames } from "../../constants";

export default {
  name: "SimpleColumnSelect",
  components: {
    SimpleFilterCriterion,
    MultiSelectDropdown,
  },
  props: {},
  data() {
    return {
      //   columns: { label: "Label", name: "valami", options: ["egz", "ketto"] }, // List of columns from all over the DB.
      columns: [],
      selectedColumns: [],
      filters: [],
      internalTableNames,
      excludedColumns,
      projectsActive: false,
      vesselsActive: false,
      clientsActive: false,
      isDetailed: false,
    };
  },
  mounted() {
    getAllColumns().then((resp) => {
      console.log(resp);
      this.columns = resp?.result;
      return;
    });
  },
  methods: {
    changeDetailed() {
      this.isDetailed = !this.isDetailed;
    },
    options() {
      return this.columns
        .map((col) => {
          if (this.isDetailed) {
            return { text: col.displayName, value: col.value };
          } else {
            if (!this.excludeColumn(col)) {
              return { text: col.displayName, value: col.value };
            }
            return;
          }
        })
        .filter((item) => item); // console.log(options);
    },
    excludeColumn(column) {
      if (!column) {
        return false;
      }
      if (
        this.excludedColumns.includes(column.value.columnName) ||
        column.value.columnName?.toLowerCase()?.includes("_id") ||
        column.value.columnName?.toLowerCase() === "id"
      ) {
        return true;
      }
      return false;
    },

    changeTableActive(tableName) {
      if (tableName === internalTableNames.PROJECTS) {
        this.projectsActive = !this.projectsActive;
      }
      if (tableName === internalTableNames.VESSELS) {
        this.vesselsActive = !this.vesselsActive;
      }
      if (tableName === internalTableNames.CLIENTS) {
        this.clientsActive = !this.clientsActive;
      }
    },
    addColumns(tableName) {
      this.changeTableActive(tableName);
      const columnsToAdd = this.options()?.filter((column) => {
        return column.value.tableName === tableName;
      });

      this.selectedColumns.push(...columnsToAdd);
    },
    removeColumns(tableName) {
      this.changeTableActive(tableName);
      const filteredColmns = this.selectedColumns?.filter((column) => {
        return column.value.tableName !== tableName;
      });

      this.selectedColumns = [...filteredColmns];
    },
    removeColumnGroup(index) {
      this.selectedColumns.splice(index, 1);
    },
    getColList() {
      if (!this.selectedColumns?.length) return [];

      // Check if there are conflicting names and add an 'as table_columnName' only to them
      return this.selectedColumns
        .map((column) => `${column.value.tableName}.${column.value.columnName}`)
        .flat();
    },
    getAsJson() {
      if (!this.filters?.length) return [];

      return this.$refs.simpleCriterion.map((filter) => filter.getAsJson());
    },

    addFilter() {
      const newFilter = {
        id: new Date().getTime(), // For the Vue for:key
      };
      this.filters.push(newFilter);
    },
    removeFilter(filter) {
      const indexToRemove = this.filters.findIndex((x) => x.id === filter.id);
      this.filters.splice(indexToRemove, 1);
    },
  },
  computed: {
    columnList() {
      const options = this.options();
      return {
        name: "DB_columns",
        type: "select",
        options,
      };
    },
  },
};
</script>

<style></style>
