<template>
  <div class="col-12">
    <ul class="nav nav-pills mb-4">
      <li v-for="tab in tabs" :key="tab" class="nav-item">
        <a
          :class="{ active: activeTab === tab }"
          class="nav-link"
          data-toggle="tab"
          href="#"
          @click="activeTab = tab"
        >
          {{ tab }}
        </a>
      </li>
    </ul>
    <ul v-if="subTabs?.length" class="nav nav-tabs">
      <li v-for="subTab in subTabs" :key="subTab" class="nav-item">
        <a
          :class="{ active: activeSubTab === subTab }"
          class="nav-link w-100 text-center"
          data-toggle="tab"
          href="#"
          @click="getSubTabDocuments(subTab)"
        >
          {{ subTab }}
        </a>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <p class="text-danger text-center">
              Please make sure that the names of the files that you intend to
              upload are correct and unique.
            </p>
          </div>
          <form-input-file
            class="my-3"
            ref="additionalDocumentsInput"
            :field="{ name: 'file', type: 'file', multiple: true }"
          />
          <div class="col-12 mb-2">
            <div
              class="d-flex flex-row justify-content-between align-items-center gap-x-3"
            >
              <div class="d-flex gap-x-2 align-items-start">
                <button
                  class="btn btn-primary"
                  title="Upload document"
                  type="button"
                  @click="uploadAdditionalDocuments"
                >
                  Upload documents
                </button>

                <form-input-check
                  v-model="allowDeleted"
                  :field="checkField"
                  class=""
                />
              </div>

              <div class="d-flex justify-content-end">
                <button
                  class="btn btn-lg p-0 me-2"
                  title="Show as Grid"
                  @click="showAsGrid = true"
                >
                  <i class="ri-layout-grid-line"></i>
                </button>
                <button
                  class="btn btn-lg p-0"
                  title="Show as Table"
                  @click="showAsGrid = false"
                >
                  <i class="ri-list-check"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- List of files -->
        <documents-list
          v-if="showAsGrid"
          :key="listKey"
          :documents="tabDocumentsUrls"
          title="Documents"
        />
        <VesselDocumentsTable
          v-else
          :key="tableKey"
          ref="refDocumentsTable"
          :documents="tabDocuments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { getDocumentsDetails, uploadDocument } from "@/apis/files.js";
import FormInputFile from "@/components/ui/FormInputFile.vue";
import DocumentsList from "../../../ui/DocumentsList.vue";
import FormInputCheck from "../../../ui/FormInputCheck.vue";
import VesselDocumentsTable from "./VesselDocumentsTable.vue";
import { store } from "../../../../main";
import { getTabInfo } from "../../../../helpers/vessels";
import { notifyError, notifySuccess } from "@/helpers/notification";

export default {
  name: "VesselDocuments",
  components: {
    FormInputFile,
    DocumentsList,
    VesselDocumentsTable,
    FormInputCheck,
  },
  data() {
    return {
      tabs: [
        "Certification",
        "Surveys",
        "PSC – FSI",
        "Incidents",
        "GMDSS",
        "MLC",
        "Original Documents",
        "Plans & Stability",
      ],
      subTabs: [],
      activeSubTab: "",
      listKey: false,
      tableKey: false,
      showAsGrid: false,
      activeTab: "Certification",
      allDocuments: [],
      tabDocuments: [],
      tabDocumentsUrls: [],
      vesselId: this.$route.params.vesselId,
      checkField: {
        placeholder: "Show deleted files",
        name: "enableDeleted",
        type: "checkbox",
      },
      allowDeleted: false,
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
    },
  },
  methods: {
    getSelectedTabInfo() {
      const tab = this.activeTab;
      return getTabInfo(this.vesselId).find((tabUrl) => tabUrl.name === tab);
    },
    getUploadDocType() {
      const tabInfo = this.getSelectedTabInfo();

      if (this.activeSubTab) {
        return tabInfo[this.activeSubTab]?.docType;
      }

      return tabInfo?.docType;
    },
    async uploadAdditionalDocuments() {
      const fileControl = this.$refs?.additionalDocumentsInput?.getFiles();

      if (!fileControl || fileControl.files.length == 0) return; // no file selected, nothing to upload

      try {
        const docType = this.getUploadDocType();
        if (!docType) {
          notifyError(this, "No document type found! Please contact support.");
          return false;
        }
        const uri = `vessels/${this.vesselId}/additional/${docType}/files`;

        const promises = [];
        // Upload the documents (and update the database)
        for (let i = 0; i < fileControl.files.length; i++) {
          const file = fileControl.files[i];
          promises.push(uploadDocument(file, uri));
        }
        await Promise.all(promises)
          .then(() => {
            notifySuccess(this, "Documents uploaded successfully");
            this.$root.$emit("reloadVesselAfterCertificationDocsUploaded");
          })
          .catch((err) => {
            console.log(err);
            notifyError(this, err?.response?.data);
            this.$root.$emit("reloadVesselAfterCertificationDocsUploaded");
          });
        return true;
      } catch (err) {
        console.log(err);
        notifyError(this, err?.response?.data); //, "Document upload");
        return false;
      }
    },

    async getVesselDocuments() {
      const folder = `vessels/${this.vesselId}`;
      const showdeleted = this.allowDeleted?.toString();
      const documents = await getDocumentsDetails(
        folder,
        this.vesselId,
        showdeleted
      );

      this.allDocuments = documents.data;
      store.commit("storeVesselDocuments", documents, this.vesselId);
    },
    getTabDocuments() {
      const tabInfo = this.getSelectedTabInfo();
      let tabUrls = tabInfo?.urls;

      // If the tab has subtabs, then we need to get the active subtab and its urls.
      if (tabInfo?.subTabs) {
        this.subTabs = tabInfo?.subTabs;
        const activeSubTab = tabInfo?.subTabs[0];
        tabUrls = [...tabUrls, ...tabInfo[activeSubTab]?.urls];
        this.activeSubTab = activeSubTab;
      } else {
        this.subTabs = [];
        this.activeSubTab = "";
      }

      const tabDocumentsList = this.allDocuments.filter((document) =>
        tabUrls.some((text) => document?.path?.includes(text))
      );
      this.tabDocuments = tabDocumentsList;
      this.tabDocumentsUrls = tabDocumentsList.map((document) => document.path);
      // This is necessary for rerendering the children.
      this.listKey = !this.listKey;
      this.tableKey = !this.tableKey;
    },
    getSubTabDocuments(subtab) {
      this.activeSubTab = subtab;
      const tabInfo = this.getSelectedTabInfo();
      const tabUrls = [...tabInfo?.urls, ...tabInfo[subtab]?.urls];

      const tabDocumentsList = this.allDocuments.filter((document) =>
        tabUrls.some((text) => document?.path?.includes(text))
      );
      this.tabDocuments = tabDocumentsList;
      this.tabDocumentsUrls = tabDocumentsList.map((document) => document.path);
      // This is necessary for rerendering the children.
      this.listKey = !this.listKey;
      this.tableKey = !this.tableKey;
    },
  },
  async mounted() {
    this.getVesselDocuments();
    this.$root.$on("reloadVesselDocumentsAfterDocumentsIsDeleted", () => {
      this.getVesselDocuments();
    });
  },
  watch: {
    allDocuments() {
      this.getTabDocuments();
    },
    activeTab() {
      this.getTabDocuments();
    },
    allowDeleted() {
      this.getVesselDocuments();
    },
  },
};
</script>
