<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-striped dataTable surveys-js-data-table"
        >
          <thead>
            <tr>
              <th>Scope</th>
              <th>Project #</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Visits</th>
              <th>Location</th>
              <th>Completed</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(surv, i) in surveys" :key="i">
              <!-- Table view -->
              <td>{{ surv.scope }}</td>
              <td>{{ getProjectNumberForProject(surv.project_id) }}</td>
              <td>
                {{
                  surv.start_date
                    ? moment(surv.start_date).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>
                {{
                  surv.end_date
                    ? moment(surv.end_date).format("DD-MM-YYYY")
                    : ""
                }}
              </td>
              <td>{{ surv.visits }}</td>
              <td>{{ getSurveyLocation(surv) }}</td>
              <td>
                <i
                  v-if="surv.completed"
                  class="fa fa-check-square text-success"
                ></i>
                <i v-else class="fa fa-square"></i>
              </td>
              <td>{{ truncate(surv.notes, 20) }}</td>
              <td class="d-flex">
                <button
                  class="btn btn-sm btn-primary me-2"
                  type="button"
                  @click="openForEdit(i)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  title="Remove Surveys"
                  type="button"
                  @click="deleteSurvey(i)"
                >
                  <span class="sr-only">Remove Survey</span>
                  <i class="fa fa-minus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyEditorModal from "./SurveyEditorModal.vue";
import moment from "moment";

export default {
  name: "SurveysTable",
  props: {
    surveys: {
      type: Array,
      default: () => [],
    },
    surveyDocuments: {
      type: Array,
      default: () => [],
    },
    projectOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    /* Table row operations */
    /**
     * Public method used from parent page to open a particular row for editing (used on creating a new survey)
     */
    openForEdit(index) {
      const field = this.surveys[index];
      this.$modal.show(
        SurveyEditorModal,
        {
          survey: field,
          surveyDocuments: this.getSurveyDocumentsForSurvey(field),
          projectOptions: this.projectOptions,
        },
        {
          name: "survey-editor-modal",
          height: "auto",
          width: "80%",
          scrollable: true,
        },
        {
          "before-close": async (e) => {
            const newValue = e.params?.value;
            if (!newValue) return;
            this.surveys[index] = e.params.value;
            // The next 2 lines force an update for DataTables to re-draw the table
            this.surveys.push({});
            this.surveys.pop();
          },
        }
      );
    },
    deleteSurvey(index) {
      if (confirm("Are you sure you want to delete this Survey?"))
        this.$emit("removeSurveyByIndex", index);
    },
    getProjectNumberForProject(project_id) {
      if (!project_id) return "--";

      const option = this.projectOptions.find((po) => po.value === project_id);
      if (option) {
        return option.text.split(":")[0];
      }
      return "ID: " + project_id;
    },
    /* END of Table row operations */

    getSurveyLocation(survey) {
      if (survey.port && survey.country)
        return survey.port + ", " + survey.country;
      if (survey.port) return survey.port;
      return survey.country;
    },

    getSurveyDocumentsForSurvey(survey) {
      return this.surveyDocuments.filter(
        (doc) => doc.indexOf("/surveys/" + survey.id + "/") > -1
      );
    },
    truncate(text, len) {
      if (!text) return text;
      if (len < 3) {
        console.error("No point in truncating text to less than 3 chars");
        return text;
      }
      if (text.length <= len) {
        return text;
      } else {
        return text.substring(0, len - 3) + "...";
      }
    },
    isInvalid() {
      // TODO : This is redundant until we find a way how to validate each survey
      // Before we had the editor for each survey to check, now there isn't anymore
      return false;
    },
    buildDataTable() {
      if (this.table) this.table.destroy();
      setTimeout(() => {
        this.table = window.jsDataTable(".surveys-js-data-table", {
          order: [[2, "asc"]],
        });
      }, 250); // Just giving Vue some time to render the table
    },
  },
  watch: {
    surveys() {
      this.buildDataTable();
    },
  },
};
</script>

<style>
.table tr td .vdp-datepicker {
  white-space: normal;
}

.table-responsive {
  overflow-x: visible;
}
</style>
