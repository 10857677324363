<template>
  <div class="pie-chart-container">
    <PieChartComponent :data="chartData" :options="options" />
  </div>
</template>

<script>
import PieChartComponent from "@/components/charts/pie/pieChart.vue";
import { ChartColors } from "@/constants/colors";

export default {
  name: "SearchResultsPieChart",
  components: { PieChartComponent },
  props: {
    value: Array,
    datasets: {
      type: Array,
      required: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartData() {
      let data = [];

      if (this.datasets?.length)
        data = this.datasets.map((ds, i) => ({
          backgroundColor: ChartColors[i],
          ...ds,
          data: ds.data.map((d) => d.number),
        }));
      else
        data = [
          {
            backgroundColor: ChartColors,
            data: this.value?.map((d) => d.number),
          },
        ];

      return {
        labels: this.datasets?.length
          ? this.datasets?.[0].data.map((d) => d.type)
          : this.value.map((d) => d.type),
        datasets: data,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
