<template>
  <div class="col-12">
    <div class="row flex-grow-1 d-flex">
      <div v-if="certification_.vessel_name" class="col-12">
        <div class="header d-flex align-items-center" style="float: left">
          <h3>{{ certification_.vessel_name }}</h3>
          <h4>
            &nbsp;&nbsp;<a
              :href="`/vessels/${certification_.vessel_id}`"
              class="fa fa-eye"
              target="_blank"
              title="View Vessel"
            ></a>
          </h4>
        </div>

        <div style="float: right">
          <label for="show-as-textboxes-switch">Show as Textboxes&nbsp;</label>
          <label class="switch my-auto" style="margin-right: 22px">
            <input
              id="show-as-textboxes-switch"
              v-model="showTextboxes"
              title="Show as textboxes"
              type="checkbox"
            />
            <span class="slider round"></span>
          </label>
          &nbsp;&nbsp;&nbsp;
          <label for="show-empty-fields-switch">Show Empty Fields&nbsp;</label>
          <label class="switch my-auto" style="margin-right: 22px">
            <input
              id="show-empty-fields-switch"
              v-model="showEmptyFields"
              title="Show empty fields"
              type="checkbox"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <template v-if="showTextboxes">
        <div
          v-for="field in certificationFields"
          v-show="certification_[field.name] || showEmptyFields"
          :key="`${field.name}`"
          :class="
            field.type === 'textarea'
              ? 'col-md-6 col-lg-6'
              : 'col-md-6 col-lg-3'
          "
        >
          <template v-if="field.name === 'superseded'">
            <b>&nbsp;</b>
            <div
              v-if="certification.superseded"
              class="input-group mb-3"
              style="margin-top: 7px"
            >
              <label
                class="fancy-checkbox form-control form-check-control me-0"
              >
                <input :checked="true" :disabled="true" type="checkbox" />
                <span class="ms-2">Superseded</span>
              </label>
            </div>
          </template>
          <form-input-text
            v-else
            v-model="certification_[field.name]"
            :field="field"
            :readonly="true"
          />
        </div>
      </template>

      <template v-else>
        <div class="col-md-12">
          <b>Certificate</b> <span>{{ certification_.certificate }}</span>
          <em class="text-muted">{{
            certificationFields[0].options.find(
              (c) => c.value === certification_.certificate
            ).text
          }}</em>
        </div>
        <div
          v-if="certification_.issued_by || showEmptyFields"
          class="col-md-12"
        >
          <b>Issued By</b> <em>{{ certification_.issued_by }}</em>
        </div>

        <div
          v-for="field in certificationFields.filter((f) => f.type === 'date')"
          v-show="certification_[field.name] || showEmptyFields"
          :key="`${field.name}`"
          :class="field.type === 'textarea' ? 'col-lg-4' : 'col-lg-3 col-md-3'"
        >
          <b>{{ field.label }}</b> <span>{{ certification_[field.name] }}</span>
        </div>

        <div v-if="certification_.remarks || showEmptyFields" class="col-md-12">
          <b>Remarks</b> <em>{{ certification_.remarks }}</em>
        </div>
      </template>
    </div>

    <!-- Documents List -->
    <div v-if="this.certification_.id" class="row">
      <div class="col-12">
        <documents-list
          :documents="certificationDocuments"
          _title="Certificates"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  VesselCertificates,
  IssuedByCertificateOption,
} from "@/constants/vessels";
import FormInputText from "../../../ui/FormInputText.vue";
import DocumentsList from "../../../ui/DocumentsList.vue";

export default {
  name: "CertificationViewer",
  components: { DocumentsList, FormInputText },
  props: {
    certification: {
      type: Object,
    },
    certificationDocuments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      certification_: null,

      certificationFields: [
        {
          label: "Certificate",
          name: "certificate",
          type: "multiselect",
          options: VesselCertificates,
        },
        {
          label: "Issued by",
          name: "issued_by",
          type: "select",
          options: IssuedByCertificateOption,
          includeOther: true,
        },
        { label: "Issue Date", name: "issue_date", type: "date" },
        { label: "Expiry Date", name: "expiry_date", type: "date" },
        { label: "Last Endorsed", name: "last_endorsed", type: "date" },
        { label: "Due Open", name: "due_open", type: "date" },
        { label: "Due Close", name: "due_close", type: "date" },
        { label: "Remarks", name: "remarks", type: "textarea" },
        { label: "Superseded", name: "superseded" },
      ],

      showEmptyFields: true,
      showTextboxes: true,
    };
  },
  methods: {
    prepareInternalCertification(certification) {
      const emptyCertification = { certificate: null, issued_by: null };
      this.certification_ = { ...emptyCertification, ...certification };
      if (this.certification_.issue_date)
        this.certification_.issue_date = moment(
          this.certification_.issue_date
        ).format("YYYY-MM-DD");
      if (this.certification_.expiry_date)
        this.certification_.expiry_date = moment(
          this.certification_.expiry_date
        ).format("YYYY-MM-DD");
      if (this.certification_.last_endorsed)
        this.certification_.last_endorsed = moment(
          this.certification_.last_endorsed
        ).format("YYYY-MM-DD");
      if (this.certification_.due_open)
        this.certification_.due_open = moment(
          this.certification_.due_open
        ).format("YYYY-MM-DD");
      if (this.certification_.due_close)
        this.certification_.due_close = moment(
          this.certification_.due_close
        ).format("YYYY-MM-DD");
    },
  },
  watch: {
    certification(newCertification) {
      this.prepareInternalCertification(newCertification);
    },
  },
  created() {
    this.prepareInternalCertification(this.certification);
  },
};
</script>
