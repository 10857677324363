<template>
  <ParticularsSection v-if="cells.length" title="General">
    <ParticularsCell
      v-for="cell in cells"
      :key="cell.label"
      :label="cell.label"
    >
      <p>{{ cell.value }}</p>
    </ParticularsCell>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";
import { CountryOptions } from "@/constants/address";
import { getOtherServices, getVesselTypes } from "@/apis/vessels";
import { notifyError } from "@/helpers/notification";

export default {
  name: "VesselParticularsGeneral",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vesselTypes: [],
      otherServices: [],
      isLoadingVesselTypes: false,
      isLoadingOtherServices: false,
    };
  },
  computed: {
    cells() {
      return extractCells(
        this.vessel,
        [
          { label: "Name", key: "name" },
          { label: "IMO Number", key: "imo_number" },
          { label: "Official Number", key: "official_number" },
          { label: "S-Reg", key: "s_reg" },
          { label: "Call Sign", key: "call_sign" },
          { label: "Port of Registry", key: "registry" },
          { label: "Speed (kn)", key: "speed" },
          { label: "Classification", key: "class" },
          {
            label: "Vessel Type",
            key: "vessel_type",
            format: (vesselType) =>
              this.vesselTypes.find((vt) => vt.value === vesselType)?.text,
          },
          { label: "Passengers", key: "passengers" },
          { label: "Crew Accommodation", key: "crew_accommodation" },
          { label: "Range", key: "operational_range" },
          { label: "Radio Coverage", key: "radio_range" },
          { label: "Notation", key: "class_notation" },

          { label: "Fisheries ID", key: "fisheries_id" },
          { label: "MMSI Number", key: "mmsi_number" },
          {
            label: "Flag",
            key: "flag",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
          {
            label: "Former Flag",
            key: "former_registry",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
          { label: "Base Port", key: "base_port" },
          {
            label: "Country",
            key: "country",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
          {
            label: "Other Service",
            key: "other_service",
            format: (otherService) =>
              this.otherServices.find((os) => os.value === otherService)?.text,
          },
          {
            label: "Day Charter Passenger",
            key: "day_charter_passenger",
          },
          {
            label: "Class Maintained",
            key: "class_maintained",
            default: "No",
          },
          {
            label: "Regulatory Regime",
            key: "regulatory_regime",
            format: (regulatory_regime) =>
              regulatory_regime.map((rr) => rr.text).join(", "),
          },
        ],
        this.excludeEmptyFields
      );
    },
  },
  methods: {
    async getVesselTypeOptions() {
      this.isLoadingVesselTypes = true;

      await getVesselTypes()
        .then(({ data }) => {
          this.vesselTypes = data.map((c) => ({
            text: c.type,
            value: c.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.isLoadingVesselTypes = false;
    },
    async getOtherServiceOptions() {
      this.isLoadingOtherServices = true;

      await getOtherServices()
        .then(({ data }) => {
          this.otherServices = data.map((c) => ({
            text: c.title,
            value: c.id,
          }));
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });

      this.isLoadingOtherServices = false;
    },
  },
  mounted() {
    this.getVesselTypeOptions();
    this.getOtherServiceOptions();
  },
};
</script>
