import Vue from "vue";
import Router from "vue-router";

import { authGuard } from "./auth";

import Dashboard from "./pages/Dashboard";
import Search from "./pages/search/Search";
import ClientsIndex from "./pages/clients/ClientsIndex.vue";
import ClientsCreate from "@/pages/clients/ClientsCreate";
import ClientsShow from "./pages/clients/ClientsShow";
import ClientsDashboard from "./pages/clients/ClientsDashboard";
import ProjectsDashboard from "./pages/projects/ProjectsDashboard.vue";
import ProjectsIndex from "./pages/projects/ProjectsIndex.vue";
import ProjectsCreate from "@/pages/projects/ProjectsCreate";
import ProjectsShow from "./pages/projects/ProjectsShow";
import VesselsDashboard from "./pages/vessels/VesselsDashboard.vue";
import VesselsIndex from "./pages/vessels/VesselsIndex.vue";
import VesselsCreate from "./pages/vessels/VesselsCreate.vue";
import VesselsEdit from "./pages/vessels/VesselsEdit.vue";
import VesselsShow from "./pages/vessels/VesselsShow.vue";
import VesselsPrint from "./pages/vessels/VesselsPrint.vue";
import VesselsCertificatesIndex from "./pages/vessels/VesselsCertificatesIndex.vue";
import FilesPage from "./pages/files/MainPage.vue";
import VesselBuildersIndex from "./pages/lists/vessel-builders/VesselBuildersIndex.vue";
import VesselBuilderUpdate from "./pages/lists/vessel-builders/VesselBuilderUpdate.vue";
import NotFound from "./pages/errors/NotFound.vue";
import ProjectJobsCreate from "./pages/settings/ProjectJobsCreate";
import MakersSearch from "@/pages/lists/machinery-makers/MakersSearch.vue";
import ReportsIndex from "@/pages/reports/ReportsIndex.vue";
import MachineryMakersIndex from "@/pages/lists/machinery-makers/MachineryMakersIndex.vue";
import MachineryMakersUpdate from "./pages/lists/machinery-makers/MachineryMakersUpdate.vue";
import ResourcesIndex from "./pages/resources";
import ResourcesGenerateDocuments from "./pages/resources/ResourcesGenerateDocuments.vue";
import ResourcesPublications from "./pages/resources/ResourcesPublications.vue";
import ResourcesCertificates from "./pages/resources/ResourcesCertificates.vue";
import ResourcesInvoiceReports from "./pages/resources/ResourcesInvoiceReports.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      beforeEnter: authGuard,
      meta: {
        label: "Dashboard",
      },
    },
    {
      path: "/search",
      name: "search",
      component: Search,
      beforeEnter: authGuard,
      meta: {
        label: "Search",
      },
    },
    {
      path: "/vessels",
      name: "vessels-dashboard",
      component: VesselsDashboard,
      beforeEnter: authGuard,
      meta: {
        label: "Vessels",
      },
    },
    {
      path: "/vessels/list",
      name: "vessels-index",
      component: VesselsIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Vessels",
      },
    },
    {
      path: "/vessels/create",
      name: "vessels-create",
      component: VesselsCreate,
      beforeEnter: authGuard,
      meta: {
        label: "Create Vessel",
      },
    },
    {
      path: "/vessels/:vesselId/edit",
      name: "vessels-edit",
      component: VesselsEdit,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Details",
      },
    },
    {
      path: "/vessels/:vesselId/print",
      name: "vessels-print",
      component: VesselsPrint,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Print",
      },
    },
    {
      path: "/vessels/certificates",
      name: "vessels-certificates-index",
      component: VesselsCertificatesIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Certificates",
      },
    },
    {
      path: "/makes/makers-search",
      name: "makers-search",
      component: MakersSearch,
      beforeEnter: authGuard,
      meta: {
        label: "Makers",
      },
    },
    {
      path: "/makes/machinery-makers",
      name: "machinery-makers-index",
      component: MachineryMakersIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Machinery Makers",
      },
    },
    {
      path: "/makes/machinery-makers/:makerId",
      name: "machinery-makers-edit",
      component: MachineryMakersUpdate,
      beforeEnter: authGuard,
      meta: {
        label: "Machinery Maker Update",
      },
    },
    {
      path: "/makes/vessel-builders",
      name: "vessel-builders-index",
      component: VesselBuildersIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Builders",
      },
    },
    {
      path: "/makes/vessel-builders/:builderId",
      name: "vessel-builders-edit",
      component: VesselBuilderUpdate,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Builder Update",
      },
    },
    {
      path: "/vessels/:vesselId",
      name: "vessels-show",
      component: VesselsShow,
      beforeEnter: authGuard,
      meta: {
        label: "Vessel Details",
      },
    },
    {
      path: "/projects",
      name: "projects-dashboard",
      component: ProjectsDashboard,
      beforeEnter: authGuard,
      meta: {
        label: "Projects",
      },
    },
    {
      path: "/projects/list",
      name: "projects-index",
      component: ProjectsIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Projects",
      },
      props: (route) => ({
        active: true,
        ...route.params,
      }),
    },
    {
      path: "/projects/closed",
      name: "projects-index-closed",
      component: ProjectsIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Closed Projects",
      },
      props: { active: false },
    },
    {
      path: "/projects/create",
      name: "projects-create",
      component: ProjectsCreate,
      beforeEnter: authGuard,
      meta: {
        label: "Create Project",
      },
    },
    {
      path: "/projects/:projectId",
      name: "projects-show",
      component: ProjectsShow,
      beforeEnter: authGuard,
      meta: {
        label: "Project Details",
      },
    },
    {
      path: "/clients",
      name: "clients-dashboard",
      component: ClientsDashboard,
      beforeEnter: authGuard,
      meta: {
        label: "Clients",
      },
    },
    {
      path: "/clients/list",
      name: "clients-index",
      component: ClientsIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Clients",
      },
    },
    {
      path: "/clients/create",
      name: "clients-create",
      component: ClientsCreate,
      beforeEnter: authGuard,
      meta: {
        label: "Create Client",
      },
    },
    {
      path: "/clients/:clientId",
      name: "clients-show",
      component: ClientsShow,
      beforeEnter: authGuard,
      meta: {
        label: "Client Details",
      },
    },
    {
      path: "/files",
      name: "files",
      component: FilesPage,
      beforeEnter: authGuard,
      meta: {
        label: "Plans & Docs",
      },
    },
    {
      path: "/settings/project-jobs",
      name: "project-jobs",
      component: ProjectJobsCreate,
      beforeEnter: authGuard,
      meta: {
        label: "Project Jobs",
      },
    },
    {
      path: "/reports",
      name: "reports-index",
      component: ReportsIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Reports",
      },
    },
    {
      path: "/resources",
      name: "resources-index",
      component: ResourcesIndex,
      beforeEnter: authGuard,
      meta: {
        label: "Resources",
      },
    },
    {
      path: "/resources/publication",
      name: "resources-publication",
      component: ResourcesPublications,
      beforeEnter: authGuard,
      meta: {
        label: "Publication & Codes",
      },
    },
    {
      path: "/resources/certificates",
      name: "resources-certificates",
      component: ResourcesCertificates,
      beforeEnter: authGuard,
      meta: {
        label: "Generate Certificates",
      },
    },
    {
      path: "/resources/invoices",
      name: "invoice-reports",
      component: ResourcesInvoiceReports,
      beforeEnter: authGuard,
      meta: {
        label: "Invoice Reports",
      },
    },
    {
      path: "/resources/generate-documents",
      name: "resources-documents",
      component: ResourcesGenerateDocuments,
      beforeEnter: authGuard,
      meta: {
        label: "Generate Documents",
      },
    },
    {
      path: "*",
      name: "errors-not-found",
      component: NotFound,
      meta: {
        label: "Page not found",
      },
    },
  ],
});

export default router;
