<template>
  <ParticularsSection
    v-if="surveys.length"
    :with-narrow-columns="true"
    title="Surveys"
    with-sub-sections
  >
    <ParticularsSection
      v-for="({ key, title, cells }, i) in surveys"
      :key="key"
      :title="title"
    >
      <ParticularsCell
        v-for="cell in cells"
        :key="`${i}-${cell.label}`"
        :label="cell.label"
      >
        <p>{{ cell.value }}</p>
      </ParticularsCell>
    </ParticularsSection>
  </ParticularsSection>
</template>

<script>
import ParticularsSection from "@/components/ui/ParticularsSection.vue";
import ParticularsCell from "@/components/ui/ParticularsCell.vue";
import { extractCells } from "@/helpers/particulars";
import moment from "moment";
import { CountryOptions } from "@/constants/address";

export default {
  name: "VesselParticularsSurvey",
  components: { ParticularsCell, ParticularsSection },
  props: {
    vessel: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
      default: () => [],
    },
    excludeEmptyFields: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    surveyCells() {
      // sort surveys by latest start date
      const surveys = [...this.vessel.surveys]
        .map((survey) => ({ scope: "--", ...survey }))
        .sort((a, b) => {
          if (moment(a.start_date).isBefore(moment(b.start_date))) return 1;
          else if (moment(a.start_date).isAfter(moment(b.start_date)))
            return -1;
          else return 0;
        });

      return extractCells(
        surveys,
        [
          { label: "Scope", key: "scope", default: "--" },
          {
            label: "Start Date",
            key: "start_date",
            format: "date",
          },
          {
            label: "End Date",
            key: "end_date",
            format: "date",
          },
          { label: "Visits", key: "visits" },
          {
            label: "Country",
            key: "country",
            format: (countryCode) =>
              CountryOptions.find((co) => co.value === countryCode)?.text,
          },
          { label: "Port", key: "port" },
          {
            label: "Completed",
            key: "completed",
            format: (completed) => (completed ? "Yes" : "No"),
          },
          {
            label: "Project",
            key: "project_id",
            format: (project_id) => {
              if (!project_id) return null;

              const project = this.projects.find(
                (project) => project.id === project_id
              );

              return project && project.name;
            },
          },
        ],
        this.excludeEmptyFields
      );
    },
    surveys() {
      return [
        ...this.surveyCells.map((cells, i) => {
          const scope = cells.find((c) => c.key === "scope")?.value;

          return {
            key: `survey-${i}`,
            title: scope ? `${scope} Survey` : `Survey ${i + 1}`,
            cells,
          };
        }),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  display: contents;

  &:not(:last-child) {
    div:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
