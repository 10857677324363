<template>
  <wrapper-page ref="wrapper">
    <div class="row clearfix">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column gap-y-3">
              <QuickButton
                :is-selected="
                  btnEnabled === btnNames.NUM_VESSELS_BY_VESSEL_TYPE
                "
                title="Number of vessels by vessel type"
                @toggled="enableButton(btnNames.NUM_VESSELS_BY_VESSEL_TYPE)"
              >
                <NumberOfVesselsByType />
              </QuickButton>
              <QuickButton
                :is-selected="
                  btnEnabled === btnNames.NUMBER_OF_PROJ_WITH_CERT_EXP
                "
                title="Number of vessels where a certificate is expiring in X days"
                @toggled="enableButton(btnNames.NUMBER_OF_PROJ_WITH_CERT_EXP)"
              >
                <NumberOfVesselsExpiringInXDays />
              </QuickButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO ADD Number of projects per client -->
  </wrapper-page>
</template>

<script>
import { quickSearchBtnNames as btnNames } from "@/constants";
import WrapperPage from "@/components/layout/WrapperPage.vue";
import NumberOfVesselsExpiringInXDays from "@/components/search/quickSearchComponents/NumberOfVesselsExpiringInXDays.vue";
import NumberOfVesselsByType from "@/components/search/quickSearchComponents/NumberOfVesselsByType.vue";
import QuickButton from "@/components/search/QuickButton.vue";

export default {
  name: "ReportsIndex",
  components: {
    QuickButton,
    NumberOfVesselsByType,
    NumberOfVesselsExpiringInXDays,
    WrapperPage,
  },
  data() {
    return {
      btnEnabled: null,
      btnNames,
    };
  },
  methods: {
    enableButton(btnName) {
      if (this.btnEnabled === btnName) {
        this.btnEnabled = null;
        return;
      }

      this.btnEnabled = btnName;
    },
  },
};
</script>
