<template>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div
        class="page-title-box pb-3 d-sm-flex align-items-center justify-content-between"
      >
        <h4 class="mb-sm-0">{{ pageTitle ?? this.$route.meta.label }}</h4>
      </div>
      <div
        class="page-title-box d-sm-flex align-items-center justify-content-between"
      >
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link to="/">{{ this.$appName }}</router-link>
          </li>
          <li
            v-for="page in parentPages"
            v-bind:key="page.path"
            class="breadcrumb-item"
          >
            <router-link :to="page.path">{{ page.name }}</router-link>
          </li>
          <li aria-current="page" class="breadcrumb-item active">
            {{ currentPage }}
          </li>
        </ol>
      </div>
    </div>
    <slot name="MainContentHeaderActions"></slot>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    parentPages() {
      var fullPath = this.$route.path.replace(/^\//, "").replace(/\/$/, ""); // Trim leading or ending '/'
      let path = "";
      let pages = [];
      for (let i = 0; i < fullPath.split("/").length - 1; i++) {
        const name = fullPath.split("/")[i];
        path += "/" + name;
        pages.push({
          path,
          name,
        });
      }
      return pages;
    },
    currentPage() {
      return this.$route.path
        .replace(/^\//, "")
        .replace(/\/$/, "")
        .split("/")
        .slice(-1)[0];
    },
  },
};
</script>

<style scoped>
.breadcrumb-item {
  text-transform: capitalize;
}
</style>
