<template>
  <div class="row">
    <number-card
      :url="getUrlFor('companies')"
      :value="clientKpis.companiesAmount"
      icon="ri-building-2-line"
      label="Companies"
      size="col-xl-4 col-sm-6"
    ></number-card>
    <number-card
      :url="getUrlFor('individuals')"
      :value="clientKpis.individualsAmount"
      icon="ri-user-line"
      label="Individuals"
      size="col-xl-4 col-sm-6"
    ></number-card>
  </div>
</template>

<script>
import NumberCard from "../ui/NumberCard.vue";

export default {
  name: "ClientKpis",
  components: { NumberCard },
  props: {
    clientKpis: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrlFor(filterBy) {
      return `/clients/list?filterBy=${filterBy}`;
    },
  },
};
</script>
